import { Container } from 'inversify'
import { useEffect, useRef } from 'react'

import * as modules from './modules'
import { DI_PROBE_TYPES } from './DITypes'
import { ProbeViewModel } from '../vm/ProbeViewModel'
import { InstanceStage, ModuleStage } from '@clain/core/utils'
import { useIsFirstRender } from '@clain/core/hooks'

const probeContainer = new Container({ defaultScope: 'Request' })

Object.values(modules).forEach((module) => probeContainer.load(module))

export const getProbeModule = <T>(key: symbol): T => {
  return probeContainer.get<T>(key)
}

const moduleStage = new ModuleStage(probeContainer)

const getProbeVM = () => {
  const probeVM = moduleStage.getModule<ProbeViewModel>(
    DI_PROBE_TYPES.ProbeViewModel,
    'cached',
    true
  )
  return probeVM.instance
}

const useProbeVM = () => {
  const probeVMRef = useRef<InstanceStage<ProbeViewModel>>()
  const isFirstRender = useIsFirstRender()
  if (isFirstRender) {
    probeVMRef.current = moduleStage.getModule<ProbeViewModel>(
      DI_PROBE_TYPES.ProbeViewModel,
      'cached'
    )
  }

  useEffect(() => {
    return () => {
      probeVMRef.current?.dispose()
    }
  }, [])

  return probeVMRef.current?.instance
}

export { probeContainer, getProbeVM, useProbeVM }
