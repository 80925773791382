import { APIServiceStateViewModel } from '@clain/core/utils/mobxUtils'
import { inject, injectable } from 'inversify'
import { APIServiceStateFacade } from '@clain/core/utils/mobxUtils'
import type {
  ICategoriesRestAPIService,
  RawCategoriesResponse,
} from '@clain/api/platform/types'
import { DI_TYPES } from '@platform/di/DITypes'

@injectable()
export class CategoriesVMModule {
  private categoriesApiServiceState: APIServiceStateFacade<
    [],
    RawCategoriesResponse['data']
  >
  constructor(
    @inject(DI_TYPES.CategoriesRestAPIService)
    private categoriesService: ICategoriesRestAPIService
  ) {
    this.categoriesApiServiceState = new APIServiceStateFacade<
      [],
      RawCategoriesResponse['data']
    >(new APIServiceStateViewModel())
    this.categoriesApiServiceState.injectRequestMethod(
      this.categoriesService.getCategories
    )
  }

  public fetchCategories = () => {
    return this.categoriesApiServiceState.request()
  }
  public get categoriesState() {
    return (
      this.categoriesApiServiceState.stateViewModel.state || ([] as string[])
    )
  }

  public get categoriesError() {
    return this.categoriesApiServiceState.stateViewModel.error
  }

  public get categoriesStatus() {
    return this.categoriesApiServiceState.stateViewModel.status
  }

  public clear = () => {
    this.categoriesApiServiceState.stateViewModel.clearData()
  }
}
