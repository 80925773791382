import {
  action,
  makeObservable,
  observable,
  autorun,
  IReactionDisposer,
} from 'mobx'
import { injectable, inject } from 'inversify'
import {
  IAutoSelectTransaction,
  AutoSelectTransactionFlowData,
} from '../models'
import { getBoundedDomainBlock } from '../utils/getDomainBlock'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from './ProbeState'
import { IProbeEvents } from './ProbeEvents'
import { IProbeGraph } from './ProbeGraph'

@injectable()
export class AutoSelectTransaction implements IAutoSelectTransaction {
  private reactionDisposers: Array<IReactionDisposer> = []
  @observable private flowId?: AutoSelectTransactionFlowData['flowId'] | null
  @observable private transactionHash?: string | null
  private autoSelectHandle: Parameters<
    IAutoSelectTransaction['injectAutoSelectHandle']
  >[0]

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeGraph) private probeGraph: IProbeGraph
  ) {
    makeObservable(this)
    this.clear()
    this.initReaction()
  }

  public injectAutoSelectHandle: IAutoSelectTransaction['injectAutoSelectHandle'] =
    (autoSelectHandle) => {
      this.autoSelectHandle = autoSelectHandle
    }

  private initReaction = () => {
    this.reactionDisposers.push(
      autorun(() => {
        const flowProcessing = this.probeEvents.meta.nodesInProcessingFlow

        if (this.flowId && !flowProcessing[this.flowId]) {
          this.setSelectedTransaction(this.transactionHash)
          this.clearFlowMeta()
        }
      })
    )
  }

  @action
  public setFlowData: IAutoSelectTransaction['setFlowData'] = (data) => {
    this.flowId = data.flowId
    this.transactionHash = data.transactionHash
  }

  @action
  private setSelectedTransaction = (hash: string) => {
    const transactionBlock = getBoundedDomainBlock(this.probeGraph, hash)

    if (transactionBlock) {
      this.probeState.selectedNodeIds = new Set(transactionBlock.nodeKeys)
      this.probeState.selectedEdgeIds = new Set(transactionBlock.edgeKeys)

      const { data } = this.probeState.selectedNode
      if (data.nodeType !== 'utxo_transaction') return
      this.autoSelectHandle(data)
    }
  }

  private clearFlowMeta = () => {
    this.flowId = undefined
    this.transactionHash = undefined
  }

  public clear = () => {
    this.clearFlowMeta()
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
  }
}
