import { proxyDefaultTokenId } from '../../../../utils/convertTokenBalances'
import { Token } from '@clain/api/platform/types'

export const normalizeIncludeTokensPayload = (tokens: Token[]) => {
  const parsedTokens = tokens
    ?.map((token) => proxyDefaultTokenId(token.id))
    ?.filter((tokenId) => tokenId != null)

  return parsedTokens?.length
    ? {
        include_tokens: parsedTokens,
      }
    : {}
}
