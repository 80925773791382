import { injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import type { LayoutType } from '@clain/graph-layout'

@injectable()
export class ProbeViewModelState {
  @observable public isInitialized = false
  @observable public activeSpace = false
  @observable public activeMouse = false
  @observable public isRightSidebarActive = false
  @observable public isAnalyticsLayerActive = false
  @observable public isShortcutsModalActive = false
  @observable public mouseDownNodeKey: string
  @observable public isMagneticGridActive = true
  @observable public isUsdCurrency = false
  @observable public layout: LayoutType = 'elk'

  constructor() {
    makeObservable(this)
  }

  @action
  public setIsInitialized(value: boolean) {
    this.isInitialized = value
  }

  @action
  public setActiveSpace(value: boolean) {
    this.activeSpace = value
  }

  @action
  public setActiveMouse(value: boolean) {
    this.activeMouse = value
  }

  @action
  public setIsRightSidebarActive(value: boolean) {
    this.isRightSidebarActive = value
  }

  @action
  public setIsAnalyticsLayerActive(value: boolean) {
    this.isAnalyticsLayerActive = value
  }

  @action
  public setIsShortcutsModalActive(value: boolean) {
    this.isShortcutsModalActive = value
  }

  @action
  public setMouseDownNodeKey(value: string) {
    this.mouseDownNodeKey = value
  }

  @action
  public setIsMagneticGridActive(value: boolean) {
    this.isMagneticGridActive = value
  }

  @action
  public setIsUsdCurrency(value: boolean) {
    this.isUsdCurrency = value
  }

  @action
  public setLayout(value: LayoutType) {
    this.layout = value
  }
}
