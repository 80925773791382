import React, { memo } from 'react'
import { RecoilRoot, RecoilState } from 'recoil'
import '../ui-kit/index.css'

import { appState, layoutState } from '../state'
import { FormatProvider } from '../utils/format'
import NavMenu, { NavMenuItem } from '../Layout/NavMenu'
import { Notification } from '@caudena/web-platform'
import { NavMenuSlot } from '../Layout/slots'

interface AppSlots {
  sidebar?: React.ReactNode
  sidebarTop?: React.ReactNode
  sidebarBottom?: React.ReactNode
  navbar?: React.ReactNode
  search?: React.ReactNode
  userDropdown?: React.ReactNode
}

interface RouterParams {
  [key: string]: string
}

type ParamsState = {
  coin: string
}

interface AppProps {
  navItems: NavMenuItem[]
  initialState?: Record<string, unknown>
  defaultParams?: RouterParams
  slots?: AppSlots
  paramsState?: RecoilState<ParamsState>
  sidebarTop?: React.ComponentType<{ isOpen: boolean }>
  sidebarBottom?: React.ComponentType<{ isOpen: boolean }>
  logout: () => void
  children: React.ReactNode
}

function Recoil({
  layoutState: layoutInitialState,
  appState: appInitialState = {},
  children,
}) {
  function initializeState({ set }) {
    set(layoutState, layoutInitialState)
    // TODO: remove other __INITIAL_STATE__?
    set(appState, appInitialState)
  }

  return <RecoilRoot initializeState={initializeState}>{children}</RecoilRoot>
}

const AppContent = ({
  navItems,
  paramsState,
  initialState,
  defaultParams,
  slots,
  sidebarTop,
  sidebarBottom,
  logout,
  children,
}: AppProps) => {
  const layoutOptions = {
    navItems,
    defaultParams,
    sidebar: slots?.sidebar,
    sidebarTop: slots?.sidebarTop,
    sidebarBottom: slots?.sidebarBottom,
    navbar: slots?.navbar,
    search: slots?.search,
    userDropdown: slots?.userDropdown,
  }

  NavMenuSlot.useContent(
    ({ isOpen }) => (
      <NavMenu
        topComponent={sidebarTop}
        bottomComponent={sidebarBottom}
        logout={logout}
        navItems={navItems}
        paramsState={paramsState}
        defaultParams={defaultParams}
        isOpen={isOpen}
      />
    ),
    [navItems]
  )

  return (
    <Recoil layoutState={layoutOptions} appState={initialState}>
      <FormatProvider>
        {children}
        <Notification.Container />
      </FormatProvider>
    </Recoil>
  )
}

export default memo(AppContent)
