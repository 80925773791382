import { action, makeObservable } from 'mobx'
import {
  WorldClickStrategyInterface,
  WorldClickStrategyType,
} from '../GraphEntityEvent.types'
import PixiEvent from '@clain/graph/src/core/PixiEvent'
import { IEntititiesGhosted } from '@platform/components/ProbeSandbox/models'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from '../../ProbeState'
import { CircularMenuViewModel } from '../../CircularMenuViewModel'
import { CommentsController } from '../../CommentsController'
import { ProbeApp } from '@platform/components/ProbeSandbox/types/ProbeApp'
import { getProbeModule } from '@platform/components/ProbeSandbox/di'

class WorldClickStrategy implements WorldClickStrategyInterface {
  constructor(private action: WorldClickStrategyInterface['handle']) {}

  handle(
    ...payload: Parameters<WorldClickStrategyInterface['handle']>
  ): ReturnType<WorldClickStrategyInterface['handle']> {
    this.action(...payload)
  }
}

@injectable()
export class WorldEventsController {
  private strategies: Record<WorldClickStrategyType, WorldClickStrategy>

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.CircularMenuViewModel)
    private circularMenuController: CircularMenuViewModel,
    @inject(DI_PROBE_TYPES.CommentsController)
    private commentsController: CommentsController,
    @inject(DI_PROBE_TYPES.EntititiesGhosted)
    private entitiesGhosted: IEntititiesGhosted
  ) {
    makeObservable(this)
    this.strategies = {
      rightClick: new WorldClickStrategy(this.openWorldMenu),
      leftClick: new WorldClickStrategy(this.unselectedEntities),
    }
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  private openWorldMenu = (event: PixiEvent<unknown>) => {
    if (!event) {
      return
    }

    const { domEvent } = event

    const toWordCoordinates = this.app.toWorldCoordinates({
      x: domEvent.offsetX,
      y: domEvent.offsetY,
    })

    this.circularMenuController.open(toWordCoordinates, 'world')
  }

  private unselectedEntities = (event: PixiEvent<unknown>) => {
    if (!event?.worldTarget) {
      return
    }

    this.entitiesGhosted.removeGhostedEntities()

    if (this.probeState.selectedEdgeIds.size) {
      this.probeState.selectedEdgeIds.clear()
    }

    if (this.probeState.selectedNodeIds.size) {
      this.probeState.selectedNodeIds.clear()
    }
  }

  @action
  public onClick = (event: PixiEvent<unknown>) => {
    if (this.commentsController.isPositioningInProgress) return

    const { domEvent } = event

    const isRightClick = domEvent.button === 2
    const isSimpleLeftClick =
      domEvent.button === 0 &&
      !domEvent.shiftKey &&
      !domEvent.ctrlKey &&
      !domEvent.metaKey &&
      !domEvent.altKey

    const strategyKeys = []

    if (isSimpleLeftClick) {
      strategyKeys.push('leftClick')
    }

    if (isRightClick) {
      strategyKeys.push('rightClick')
    }

    strategyKeys.forEach((strategyKey) => {
      this.strategies[strategyKey].handle(event)
    })
  }
}
