import { Subscribable } from '../../../../utils/Subscribable'
import { ReciveEvent } from './ReciveEvents.types'
import { injectable } from 'inversify'

@injectable()
export class ReciveEvents extends Subscribable<ReciveEvent[]> {
  constructor() {
    super()
  }

  public emitEvent = (event: ReciveEvent[]): void => {
    this.publish(event)
  }
}
