import { action, computed, makeObservable, observable } from 'mobx'
import { ServerSettings, Settings } from '../types/serverData'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeService } from './services/ProbeService'
import { ProbeViewModelState } from './states/ProbeViewModelState'

export interface ILayersViewModel {
  score: boolean
  osint: boolean
  comments: boolean
  text: boolean

  geography: boolean
  balance: boolean
  size: boolean

  crossChainFlowLabel: boolean

  clusterLabel: boolean
  clusterFlowLabel: boolean

  addressFeatures: boolean
  addressClusterLabel: boolean
  addressLabel: boolean

  trxAddressLabel: boolean
  trxAmount: boolean
  trxTimestamp: boolean
  trxFeatures: boolean

  demixLabel: boolean
  demixDepositLabel: boolean

  init: (layers: ServerSettings['layers']) => void
  get getLayers(): {
    score: boolean
    osint: boolean
    comments: boolean
    text: boolean
    geography: boolean
    balance: boolean
    size: boolean
    addressFeatures: boolean
    clusterLabel: boolean
    trxFeatures: boolean
  }
  setScore: (score: boolean) => void
  setOsint: (osint: boolean) => void
  setComments: (comments: boolean) => void
  setText: (text: boolean) => void
  setClusterGeography: (geography: boolean) => void
  setClusterBalance: (balance: boolean) => void
  setClusterSize: (size: boolean) => void
  setAddressFeatures: (addressFeatures: boolean) => void
  setAddressClusterLabel: (addressClusterLabel: boolean) => void
  setTransactionFeatures: (transactionFeatures: boolean) => void
  setTimestamp: (trxTimestamp: boolean) => void
}

@injectable()
export class LayersViewModel implements ILayersViewModel {
  @observable public score = true
  @observable public osint = true
  @observable public comments = true
  @observable public text = true

  @observable public geography = true
  @observable public balance = true
  @observable public size = true

  @observable public clusterLabel = true
  @observable public clusterFlowLabel = true

  @observable public addressFeatures = true
  @observable public addressLabel = true
  @observable public addressClusterLabel = true

  @observable public trxAddressLabel = true
  @observable public trxAmount = true
  @observable public trxFeatures = true
  @observable public trxTimestamp = true

  @observable public demixLabel = true
  @observable public demixDepositLabel = true

  @observable public crossChainFlowLabel = true

  constructor(
    @inject(DI_PROBE_TYPES.ProbeServiceProvider)
    private probeServiceProvider: () => Promise<ProbeService>,
    @inject(DI_PROBE_TYPES.ProbeViewModelState)
    private probeViewModelState: ProbeViewModelState
  ) {
    makeObservable(this)
  }

  @action public init = ({
    score = true,
    osint = true,
    comments = true,
    geography = true,
    balance = true,
    size = true,
    addressFeatures = true,
    clusterLabel = true,
    trxFeatures = true,
    trxTimestamp = true,
  }: ServerSettings['layers']) => {
    this.score = score
    this.osint = osint
    this.comments = comments
    this.geography = geography
    this.balance = balance
    this.size = size
    this.addressFeatures = addressFeatures
    this.addressClusterLabel = clusterLabel
    this.trxFeatures = trxFeatures
    this.trxTimestamp = trxTimestamp
  }

  @computed public get getLayers() {
    return {
      score: this.score,
      osint: this.osint,
      comments: this.comments,
      text: this.text,
      geography: this.geography,
      balance: this.balance,
      size: this.size,
      addressFeatures: this.addressFeatures,
      clusterLabel: this.addressClusterLabel,
      trxFeatures: this.trxFeatures,
    }
  }

  @computed
  public get currentSettings(): Settings {
    return {
      grid: this.probeViewModelState.isMagneticGridActive,
      layers: this.getLayers,
    }
  }

  @action
  public async updateSettings(settingsData: Settings) {
    const currentSettings = this.currentSettings
    const probeService = await this.probeServiceProvider()

    if (!probeService) return

    probeService.updateSettings({
      grid:
        this.probeViewModelState.isMagneticGridActive ?? currentSettings.grid,
      layers: {
        ...currentSettings.layers,
        ...settingsData.layers,
      },
    })
  }

  @action public setScore = (score: boolean) => {
    this.score = score
    this.updateSettings({ layers: { score } })
  }

  @action public setOsint = (osint: boolean) => {
    this.osint = osint
    this.updateSettings({ layers: { osint } })
  }

  @action public setComments = (comments: boolean) => {
    this.comments = comments
    this.updateSettings({ layers: { comments } })
  }

  @action public setText = (text: boolean) => {
    this.text = text
  }

  @action public setClusterGeography = (geography: boolean) => {
    this.geography = geography
    this.updateSettings({ layers: { geography } })
  }

  @action public setClusterBalance = (clusterBalance: boolean) => {
    this.balance = clusterBalance
    this.updateSettings({ layers: { balance: clusterBalance } })
  }

  @action public setClusterSize = (clusterSize: boolean) => {
    this.size = clusterSize
    this.updateSettings({ layers: { size: clusterSize } })
  }

  @action public setAddressFeatures = (addressFeatures: boolean) => {
    this.addressFeatures = addressFeatures
    this.updateSettings({
      layers: { address_features: addressFeatures },
    })
  }

  @action public setAddressClusterLabel = (addressClusterLabel: boolean) => {
    this.addressClusterLabel = addressClusterLabel
    this.updateSettings({
      layers: { cluster_label: addressClusterLabel },
    })
  }

  @action public setTransactionFeatures = (transactionFeatures: boolean) => {
    this.trxFeatures = transactionFeatures
    this.updateSettings({
      layers: { trx_features: transactionFeatures },
    })
  }

  @action public setTimestamp = (trxTimestamp: boolean) => {
    this.trxTimestamp = trxTimestamp
    this.updateSettings({
      layers: { trx_timestamp: trxTimestamp },
    })
  }
}
