import { makeAutoObservable } from 'mobx'
import { inject, injectable } from 'inversify'
import { IProbeState } from '../ProbeState'
import { IContainerLayoutState } from '../../models/IContainerLayoutState'
import { DI_PROBE_TYPES } from '../../di/DITypes'

@injectable()
export class ContainerLayoutState implements IContainerLayoutState {
  private _customHeight: number | null = null
  private _previousHeight: number | null = null

  readonly ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT = 34
  readonly ACTIVE_BOTTOMBAR_HEIGHT = 360
  readonly ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT = 100
  readonly PROBE_HEADER_HEIGHT = 56
  readonly BOTTOMBAR_TABLE_CELL_HEIGHT = 32 // px
  readonly BOTTOMBAR_TOP_PANEL_HEIGHT = 34 // px
  private readonly THRESHOLD_TO_HEADER = 86

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private readonly probeState: IProbeState
  ) {
    makeAutoObservable(this)
  }

  get height(): string {
    if (!this.isBottombarActive) {
      return '0%'
    }
    if (this.bottombarStatus === 'fullScreen') {
      return `${this.ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT}%`
    }
    if (this._customHeight) {
      return `${this._customHeight}px`
    }
    return `${this.ACTIVE_BOTTOMBAR_HEIGHT}px`
  }

  get infobarWidth(): string {
    return this.isBottombarActive ? '360px' : '0'
  }

  get infobarHeight(): string {
    if (!this.isBottombarActive) {
      return '100%'
    }
    if (this.bottombarStatus === 'fullScreen') {
      return `calc(100% - ${this.ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT}%)`
    }
    const currentHeight =
      this._customHeight ||
      (this.bottombarStatus === 'hidden'
        ? this.ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT
        : this.ACTIVE_BOTTOMBAR_HEIGHT)
    return `calc(100% - ${currentHeight}px)`
  }

  get isBottombarActive(): boolean {
    return this.probeState.isBottombarActive
  }

  get bottombarStatus(): 'hidden' | 'default' | 'fullScreen' {
    return this.probeState.bottombarStatus
  }

  setBottombarCustomHeight(height: number): void {
    this._customHeight = height
    this._previousHeight = height
    if (height && Math.abs(height - this.ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT) < 10) {
      this.probeState.setBottombarStatus('hidden')
    } else if (height && Math.abs(height - this.ACTIVE_BOTTOMBAR_HEIGHT) < 10) {
      this.probeState.setBottombarStatus('default')
    }
  }

  setBottombarHidden(): void {
    this._customHeight = this.ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT
    this.probeState.setBottombarStatus('hidden')
  }

  setBottombarDefault(): void {
    const newHeight = this._previousHeight || this.ACTIVE_BOTTOMBAR_HEIGHT
    const toggleHeight =
      newHeight < this.ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT + 40
        ? this.ACTIVE_BOTTOMBAR_HEIGHT
        : newHeight
    this._customHeight = toggleHeight
    this._previousHeight = toggleHeight
    this.probeState.setBottombarStatus('default')
  }

  get isBottombarHidden(): boolean {
    return parseInt(this.height) === this.ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT
  }

  get isNearHeader(): boolean {
    const currentHeight = this._customHeight || this.ACTIVE_BOTTOMBAR_HEIGHT
    const availableHeight = window.innerHeight - this.PROBE_HEADER_HEIGHT
    return availableHeight - currentHeight <= this.THRESHOLD_TO_HEADER
  }
}
