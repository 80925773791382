import type { Channel } from 'phoenix'
import debounce from 'lodash/debounce'
import {
  ChannelState,
  INotificationService,
  ISentryService,
} from './WebSocket.types'
import {
  notificationConfig,
  notificationToastOptions,
  REJECT_REASON,
} from '@clain/core/constants'

const TIMEOUT = 60_000

export class PhoenixChannel {
  private _topic: string
  public phoenixChannel: Channel
  private errorHandler: (reason: unknown) => unknown
  private notificationService: INotificationService | null
  private sentryService: ISentryService | null
  private state: ChannelState = 'offline'
  private lastRespFromJoin = null
  private debouncedPhoenixErrorHandler: (
    cb: (reason: Error | unknown) => void,
    reason?: Error | unknown
  ) => void

  constructor(
    topic: string,
    phoenixChannel: Channel,
    errorHandler: (reason: unknown) => unknown,
    notificationService?: INotificationService | null,
    sentryService?: ISentryService | null
  ) {
    this._topic = topic
    this.phoenixChannel = phoenixChannel
    this.errorHandler = errorHandler
    this.notificationService = notificationService
    this.sentryService = sentryService

    this.debouncedPhoenixErrorHandler = debounce(
      (cb: (reason: Error | unknown) => void, reason) => {
        cb(reason || this.handlePhoenixError())
      },
      2000
    )
  }

  private handleReceiveError = (
    reason: unknown,
    extraContext: Record<string, any> = {}
  ): void => {
    this.sentryService?.captureException(
      new Error(reason ? JSON.stringify(reason) : 'Unknown reason'),
      {
        extra: { topic: this._topic, ...extraContext },
      }
    )
    console.error(
      `An error occurred while trying to push to "${this._topic}" channel`,
      reason
    )
  }

  public handlePhoenixError = () => {
    const error = new Error(REJECT_REASON.phx_error)
    this.sentryService?.captureException(error, {
      extra: {
        topic: this._topic,
      },
    })
    this.notificationService?.notify(
      notificationConfig[REJECT_REASON.phx_error].text,
      { type: notificationConfig[REJECT_REASON.phx_error].type },
      notificationToastOptions
    )
    return error
  }

  public onError = () => this.phoenixChannel.onError

  public join = <Response = unknown>() => {
    return new Promise<Response>((resolve, reject) => {
      if (this.state === 'online') {
        resolve(this.lastRespFromJoin as Response)
        return
      }
      this.phoenixChannel
        .join()
        .receive('ok', (response: Response) => {
          this.state = 'online'
          this.lastRespFromJoin = response
          resolve(response)
        })
        .receive('error', (reason: unknown) => {
          this.handleReceiveError(reason)
          this.debouncedPhoenixErrorHandler(reject, this.errorHandler(reason))
        })
    })
  }

  public push<Response, Payload extends object = any>(
    event: string,
    payload?: Payload,
    timeout?: number
  ) {
    return new Promise<Response>((resolve, reject) => {
      let resolved = false
      const errorHandler = () => {
        if (!resolved) {
          console.log('error handler')
          this.debouncedPhoenixErrorHandler(reject)
          resolved = true
        }
      }

      this.phoenixChannel.onError(errorHandler)
      this.phoenixChannel
        .push(event, payload, timeout || TIMEOUT)
        .receive('ok', (response: Response) => {
          resolved = true
          resolve(response)
        })
        .receive('error', (reason: unknown) => {
          resolved = true
          if (this.state === 'offline') {
            reject(new Error(REJECT_REASON.offline))
            return
          }
          this.handleReceiveError(reason, { event, payload })
          this.debouncedPhoenixErrorHandler(reject, this.errorHandler(reason))
        })
        .receive('timeout', (reason: unknown) => {
          resolved = true
          if (this.state === 'offline') {
            reject(new Error(REJECT_REASON.offline))
            return
          }
          this.handleReceiveError(reason, { event, payload })
          this.debouncedPhoenixErrorHandler(reject, reason)
        })
    })
  }

  public leave = <Response = unknown>() => {
    return new Promise<Response>((resolve, reject) => {
      this.phoenixChannel
        .leave()
        .receive('ok', (response: Response) => {
          this.state = 'offline'
          resolve(response)
        })
        .receive('error', (reason: unknown) => {
          this.handleReceiveError(reason)
          this.debouncedPhoenixErrorHandler(reject, this.errorHandler(reason))
        })
    })
  }
  public subscribe<Payload extends object>(
    event: string,
    cb: (payload: Payload) => void
  ): number {
    return this.phoenixChannel.on(event, cb)
  }

  public unsubscribe(event: string, ref?: number) {
    this.phoenixChannel.off(event, ref)
  }
}
