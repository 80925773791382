import { injectable, inject } from 'inversify'
import { action, computed, makeObservable } from 'mobx'
import debounce from 'lodash/debounce'

import {
  AddressSearchResult,
  AddressSocketSearch,
  SearchResults,
  TransactionSearchResult,
  TransactionSocketSearch,
  SearchService,
} from './services/SearchService'

import { isEthAddress } from '@clain/core/utils'
import { IProbeEvents, GraphEmitEvents } from './ProbeEvents'
import type { CoinType } from '../../../types/coin'
import { isEVM } from '@clain/core/types/coin'
import type { ISearchState } from './states'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

const SEARCH_DEBOUNCE = 300

@injectable()
export class SearchController {
  public invokeSearchEntitiesRequestDebounced: () => void
  public invokeSearchBlockchainsRequestDebounced: () => void

  constructor(
    @inject(DI_PROBE_TYPES.SearchService) private searchService: SearchService,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.SearchState) public state: ISearchState
  ) {
    makeObservable(this)
    this.invokeSearchEntitiesRequestDebounced = debounce(
      this.invokeSearchEntitiesRequest,
      SEARCH_DEBOUNCE
    )
    this.invokeSearchBlockchainsRequestDebounced = debounce(
      this.invokeSearchBlockchainsRequest,
      SEARCH_DEBOUNCE
    )
  }

  @computed
  public get searchQuery() {
    return this.state.searchQuery
  }

  @computed
  public get searchEntitiesResults() {
    return this.state.searchEntitiesResults
  }

  @computed
  public get searchBlockchainsResults() {
    return this.state.searchBlockchainsResults
  }

  @computed
  public get searchEntitiesInProgress() {
    return this.state.searchEntitiesInProgress
  }

  @computed
  public get searchBlockchainsInProgress() {
    return this.state.searchBlockchainsInProgress
  }

  @computed
  public get addMultipleNodesId() {
    return this.state.addMultipleNodesId
  }

  @action
  public setSearchQuery = (searchQuery: string) => {
    this.state.setSearchQuery(searchQuery)
  }

  @action
  public clearSearchQuery = () => {
    this.setSearchQuery('')
  }

  @action
  public setSearchEntitiesResults = (searchResults: Array<SearchResults>) => {
    this.state.setSearchEntitiesResults(searchResults)
  }

  @action
  public setSearchBlockchainsResults = (
    searchResults: Array<SearchResults>
  ) => {
    this.state.setSearchBlockchainsResults(searchResults)
  }

  @action
  public search = (searchQuery: string) => {
    this.setSearchQuery(searchQuery)
    if (!searchQuery?.length) {
      this.setSearchEntitiesResults([])
      this.setSearchBlockchainsResults([])
    }
    this.state.setSearchEntitiesInProgress(false)
    this.state.setSearchBlockchainsInProgress(false)

    if (searchQuery.length >= 3) {
      this.invokeSearchEntitiesRequestDebounced()
    }

    if (isEthAddress(searchQuery)) {
      if (searchQuery.length >= 6) {
        this.invokeSearchBlockchainsRequestDebounced()
      }
    } else if (searchQuery.length >= 4) {
      this.invokeSearchBlockchainsRequestDebounced()
    }
  }

  @action public handleClickOnCluster = async (
    clusterId: number,
    currency: CoinType
  ) => {
    this.state.close()

    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: { strategy: 'cluster', clusterId: clusterId, currency },
        },
      ],
      {
        animation: true,
        accumulationEntitiesKeyType: 'all',
      }
    )
  }

  @action public handleClickOnAddress = async (
    { aid, address, clusterId }: AddressSearchResult,
    currency: CoinType
  ) => {
    this.state.close()

    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            strategy: 'address',
            id: aid,
            currency,
            address,
            clusterId,
          },
        },
      ],
      { animation: true, accumulationEntitiesKeyType: 'all' }
    )
  }

  @action public handleClickOnTransaction = async (
    data: TransactionSearchResult,
    currency: CoinType
  ) => {
    this.state.close()
    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: isEVM(data)
            ? {
                strategy: 'transaction',
                type: 'transfer',
                currency: data.currency,
                index: 0,
                sender: data.transfers[0]?.sender,
                receiver: data.transfers[0]?.receiver,
                hash: data.hash,
                id: data.trxId,
              }
            : {
                strategy: 'transaction',
                currency,
                createBy: 'by-trx-id',
                direction: 'out',
                ...data,
              },
        },
      ],
      {
        animation: true,
        accumulationEntitiesKeyType: 'all',
      }
    )
  }

  @action public handleAddMultipleNodes = (
    addresses: AddressSocketSearch[],
    transactions: TransactionSocketSearch[]
  ) => {
    const accEvents: GraphEmitEvents[] = []

    if (addresses.length) {
      for (const [, { hash, id, currency, cluster }] of addresses.entries()) {
        accEvents.push({
          type: 'add_node',
          data: {
            id,
            currency,
            address: hash,
            ...(cluster?.clusterId
              ? { strategy: 'address-cluster', clusterId: cluster.clusterId }
              : { strategy: 'address' }),
          },
        })
      }
    }

    if (transactions.length) {
      for (const transaction of transactions) {
        accEvents.push({
          type: 'add_node',
          data: isEVM(transaction)
            ? {
                strategy: 'transaction-multiple',
                type: 'transfer',
                sender: transaction.transfers[0]?.sender,
                receiver: transaction.transfers[0]?.receiver,
                hash: transaction.hash,
                id: transaction.trxId,
                signatureId: transaction.signature.signatureId,
                currency: transaction.currency,
                index: 0,
              }
            : {
                strategy: 'transaction',
                createBy: 'by-trx-id',
                direction: 'out',
                inputs: transaction.inputs,
                outputs: transaction.outputs,
                id: transaction.id,
                currency: transaction.currency,
                hash: transaction.hash,
              },
        } as GraphEmitEvents)
      }
    }

    const result = this.probeEvents.emit(accEvents, {
      animation: true,
      accumulationEntitiesKeyType: 'all',
      animationType: {
        strategy: 'moveToCentroid',
        scaleStrategy: 'auto',
      },
    })
    this.state.setAddMultipleNodesId(result.meta.id)
  }

  @computed
  public get addMultipleNodesInProgress() {
    return this.probeEvents.meta.loading[this.addMultipleNodesId]
  }

  @action
  private invokeSearchEntitiesRequest = () => {
    const queryBeforeRequest = this.searchQuery
    this.state.setSearchEntitiesInProgress(true)

    return this.searchService
      .getEntitiesResults(this.searchQuery)
      .then((results) => {
        this.state.setSearchEntitiesInProgress(false)
        if (queryBeforeRequest === this.searchQuery) {
          this.setSearchEntitiesResults(results)
        }
      })
  }

  @action
  private invokeSearchBlockchainsRequest = () => {
    const queryBeforeRequest = this.searchQuery
    this.state.setSearchBlockchainsInProgress(true)
    return this.searchService
      .getBlockchainResults(this.searchQuery)
      .then((results) => {
        this.state.setSearchBlockchainsInProgress(false)
        if (queryBeforeRequest === this.searchQuery) {
          this.setSearchBlockchainsResults(results)
        }
      })
  }
}
