import { injectable } from 'inversify'
import { EventOsint } from '../../types/EventOsint'
import { GenerateEdge } from './GenerateEdge'

import type { IGenerateEntities } from '../../GraphEvents.types'
import { ServerAddEvents } from '../../types'
import { addressKey, clusterKey, osintKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeAttribution extends GenerateEdge<EventOsint> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventOsint>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const selectedEntityData = this.probeState.selectedNode
    const edges = this.edges({ meta })

    let selectedKey = null

    if (selectedEntityData) {
      selectedKey = selectedEntityData.key
    } else if (data.targetClusterId) {
      selectedKey = clusterKey(
        { clusterId: data.targetClusterId },
        data.currency
      )
    } else if (data.targetAddress) {
      selectedKey = addressKey({
        address: data.targetAddress,
        currency: data.currency,
      })
    }

    if (!selectedKey) return edges.acc

    const key = osintKey(data)

    if (!this.isEdgeExists(edgeKey(key, selectedKey))) {
      edges.push({
        type: 'add_edge',
        key: edgeKey(key, selectedKey),
        data: {
          srcKey: key,
          dstKey: selectedKey,
          type: 'attribution',
        },
      })
    }

    return edges.acc
  }
}
