import { Point } from 'pixi.js'
import { MultiDirectedGraph } from 'graphology'
import PixiEvent, { TARGET } from '../core/PixiEvent'
import PixiHighlighter, { HighlighterOptions } from '../highlighter'
import { Container } from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import { PixiGraphEvents } from '../GraphApp.types'
import { TypedEmitter } from 'tiny-typed-emitter'
import { shiftOrMod } from '@clain/core/utils/tools'

interface SelectionPayload {
  nodeKeys: string[]
  edgeKeys: string[]
  isExpanding: boolean
}

type HighlightMode = 'both' | 'deselect'

export class HighlighterModule {
  private highliteMode: HighlightMode = 'both'
  private selectedNodes: Set<string> = new Set()
  private selectedEdges: Set<string> = new Set()
  private nodesSelectedInCurrentDrag: Set<string> = new Set()
  private edgesSelectedInCurrentDrag: Set<string> = new Set()
  private highlighterInstance: PixiHighlighter
  private highlighterOptions: HighlighterOptions
  private highlighterLayer: Container

  constructor(
    private readonly graph: MultiDirectedGraph,
    private readonly world: Viewport,
    private readonly emit: TypedEmitter<PixiGraphEvents>['emit'],
    private readonly renderTick: () => void
  ) {
    this.initializeHighlighter()
  }

  private initializeHighlighter = () => {
    this.highlighterLayer = new Container({
      isRenderGroup: true,
    })
    this.highlighterOptions = {
      from: new Point(),
      to: new Point(),
    }

    this.highlighterInstance = new PixiHighlighter({
      options: this.highlighterOptions,
    })

    this.highlighterLayer.addChild(this.highlighterInstance.highlighterGfx.gfx)
  }

  private isPointInBounds = (
    point: Point,
    worldFrom: Point,
    worldTo: Point
  ): boolean => {
    return (
      point.x <= Math.max(worldTo.x, worldFrom.x) &&
      point.x >= Math.min(worldTo.x, worldFrom.x) &&
      point.y <= Math.max(worldTo.y, worldFrom.y) &&
      point.y >= Math.min(worldTo.y, worldFrom.y)
    )
  }

  private getEdgeCenterPosition = (edgeKey: string): Point => {
    const sourcePos = this.graph.getNodeAttributes(
      this.graph.source(edgeKey)
    ).position
    const targetPos = this.graph.getNodeAttributes(
      this.graph.target(edgeKey)
    ).position
    return new Point(
      (targetPos.x + sourcePos.x) / 2,
      (targetPos.y + sourcePos.y) / 2
    )
  }

  private emitSelectionEvent = (
    event: MouseEvent,
    payload: SelectionPayload,
    type: 'select' | 'unselect'
  ) => {
    this.emit(
      type,
      new PixiEvent({
        event,
        target: TARGET.WORLD,
        payload,
      })
    )

    if (type === 'select') {
      this.handleSelect(payload.nodeKeys, payload.edgeKeys)
    } else {
      this.handleUnselect(payload.nodeKeys, payload.edgeKeys)
    }
  }

  private handleSelect = (nodeKeys: string[], edgeKeys: string[]) => {
    nodeKeys.forEach((key) => this.selectedNodes.add(key))
    edgeKeys.forEach((key) => this.selectedEdges.add(key))
  }

  private handleUnselect = (nodeKeys: string[], edgeKeys: string[]) => {
    nodeKeys.forEach((key) => this.selectedNodes.delete(key))
    edgeKeys.forEach((key) => this.selectedEdges.delete(key))
  }

  private processSelections = (
    worldFrom: Point,
    worldTo: Point,
    event: MouseEvent
  ) => {
    const nodesToSelect: string[] = []
    const edgesToSelect: string[] = []
    const nodesToUnselect: string[] = []
    const edgesToUnselect: string[] = []

    // Process nodes
    this.graph.forEachNode((nodeKey) => {
      const nodePos = this.graph.getNodeAttributes(nodeKey).position
      const isInBounds = this.isPointInBounds(nodePos, worldFrom, worldTo)

      if (isInBounds) {
        if (!this.selectedNodes.has(nodeKey)) {
          nodesToSelect.push(nodeKey)
          this.nodesSelectedInCurrentDrag.add(nodeKey)
        } else if (!this.nodesSelectedInCurrentDrag.has(nodeKey)) {
          nodesToUnselect.push(nodeKey)
        }
      } else if (this.nodesSelectedInCurrentDrag.has(nodeKey)) {
        nodesToUnselect.push(nodeKey)
        this.nodesSelectedInCurrentDrag.delete(nodeKey)
      }
    })

    // Process edges
    this.graph.forEachEdge((edgeKey) => {
      const edgeCenter = this.getEdgeCenterPosition(edgeKey)
      const isInBounds = this.isPointInBounds(edgeCenter, worldFrom, worldTo)

      if (isInBounds) {
        if (!this.selectedEdges.has(edgeKey)) {
          edgesToSelect.push(edgeKey)
          this.edgesSelectedInCurrentDrag.add(edgeKey)
        } else if (!this.edgesSelectedInCurrentDrag.has(edgeKey)) {
          edgesToUnselect.push(edgeKey)
        }
      } else if (this.edgesSelectedInCurrentDrag.has(edgeKey)) {
        edgesToUnselect.push(edgeKey)
        this.edgesSelectedInCurrentDrag.delete(edgeKey)
      }
    })

    // Emit selection events if needed
    if (
      (nodesToSelect.length > 0 || edgesToSelect.length > 0) &&
      this.highliteMode === 'both'
    ) {
      this.emitSelectionEvent(
        event,
        {
          nodeKeys: nodesToSelect,
          edgeKeys: edgesToSelect,
          isExpanding: shiftOrMod(event, 'shift'),
        },
        'select'
      )
    }

    if (
      nodesToUnselect.length > 0 ||
      edgesToUnselect.length > 0 ||
      this.highliteMode === 'deselect'
    ) {
      this.emitSelectionEvent(
        event,
        {
          nodeKeys: nodesToUnselect,
          edgeKeys: edgesToUnselect,
          isExpanding: false,
        },
        'unselect'
      )
    }
  }

  private onDocumentMouseMoveOnHighlighting = (event: MouseEvent): void => {
    const eventPosition = new Point(event.offsetX, event.offsetY)
    const worldPositionFrom = this.world.toWorld(this.highlighterOptions.from)
    const worldPositionTo = this.world.toWorld(eventPosition)

    this.processSelections(worldPositionFrom, worldPositionTo, event)

    this.highlighterOptions.to.copyFrom(eventPosition)
    this.highlighterInstance.update()
    this.world.drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
    this.renderTick()
  }

  private onDocumentMouseUpOnHighlighting = (): void => {
    document.removeEventListener(
      'mousemove',
      this.onDocumentMouseMoveOnHighlighting
    )

    this.highlighterOptions.from.set(0, 0)
    this.highlighterOptions.to.set(0, 0)

    this.highlighterInstance.update()
    this.world.drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
    this.renderTick()
  }

  public getHighlighterLayer = (): Container => {
    return this.highlighterLayer
  }

  public enableHighlighting = ({
    mode = 'both',
  }: { mode?: HighlightMode } = {}) => {
    this.highliteMode = mode
    this.nodesSelectedInCurrentDrag.clear()
    this.edgesSelectedInCurrentDrag.clear()

    document.addEventListener(
      'mousemove',
      this.onDocumentMouseMoveOnHighlighting
    )
    document.addEventListener('mouseup', this.onDocumentMouseUpOnHighlighting, {
      once: true,
    })
  }

  public startHighlighting = (position: Point) => {
    this.highlighterOptions.from.copyFrom(position)
  }
}
