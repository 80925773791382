import { injectable, inject } from 'inversify'
import { action, computed, makeObservable, toJS } from 'mobx'
import { GraphTransactionUtxo } from '../../../types/converted/GraphTransactionUtxo'
import type { IPaletteController } from '../../PaletteController'
import { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'

import {
  activeEntityTransactionUtxoState,
  activeEntityTransactionUtxoFiltersState,
} from './TransactionUtxoActiveEntity.states'
import { getTransactionAddressFilteredAndSortedData } from '@platform/components/ProbeSandbox/vm/active-entity/helpers/getTransactionAddressFilteredAndSortedData'
import { IAutoSelectTransaction } from '@platform/components/ProbeSandbox/models'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type {
  TransactionAddressUtxo,
  TransactionAddressUtxoInput,
  TransactionAddressUtxoOutput,
} from '@clain/api/platform/types'

@injectable()
export class TransactionUtxoActiveEntity {
  private transactionsVM = activeEntityTransactionUtxoState
  private filtersVM = activeEntityTransactionUtxoFiltersState

  constructor(
    @inject(DI_PROBE_TYPES.ActiveEntityEvents)
    private activeEntityEvents: IActiveEntityEvents,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController,
    @inject(DI_PROBE_TYPES.AutoSelectTransaction)
    private autoSelectTransaction: IAutoSelectTransaction
  ) {
    makeObservable(this)
  }

  public get transactionUtxoFilteredData() {
    const { inputs, outputs } = getTransactionAddressFilteredAndSortedData({
      inputs: toJS(this.inputs),
      outputs: toJS(this.outputs),
      currency: toJS(this.currency),
      filters: toJS(this.filters),
    })

    return {
      ...this.transactionUtxoData,
      inputs,
      outputs,
    }
  }

  public get transactionUtxoData() {
    return this.transactionsVM.state
  }

  private get inputs() {
    return this.transactionsVM.state?.inputs
  }

  private get outputs() {
    return this.transactionsVM.state?.outputs
  }

  private get currency() {
    return this.transactionsVM.state?.currency
  }

  public get filters() {
    return this.filtersVM.state
  }

  public get setFilters() {
    return this.filtersVM.updateState
  }

  public get resetFilters() {
    return this.filtersVM.resetState
  }

  public clear = () => {
    this.filtersVM.clearState()
    this.transactionsVM.clearState()
  }

  public initState = (
    ...args: Parameters<typeof this.transactionsVM.initState>
  ) => {
    this.clear()
    this.transactionsVM.initState(...args)
  }

  @action
  public update = (
    ...args: Parameters<typeof this.transactionsVM.updateState>
  ) => {
    this.transactionsVM.updateState(...args)
  }

  @action
  public toggleToken = (
    {
      tokenTransfers,
      id,
      inputs,
      outputs,
    }: Pick<
      GraphTransactionUtxo,
      'id' | 'inputs' | 'outputs' | 'tokenTransfers'
    >,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionTokens',
      [
        {
          tokenTransfers,
          id,
          inputs,
          outputs,
          hash: this.transactionUtxoData.hash,
        },
      ],
      select
    )
  }

  @action
  public toggleTransactionAddress = (
    data: TransactionAddressUtxo,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.toggleAllTransactionAddresses([data], direction, select)
  }

  @action
  public toggleAllTransactionAddresses = async (
    list: Array<TransactionAddressUtxo>,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionAddresses',
      list.map((data) => ({
        trxAddressData: data,
        direction,
        hash: this.transactionUtxoData.hash,
        trxId: this.transactionUtxoData.id,
      })),
      select
    )
  }

  @action
  public openInput = (data: TransactionAddressUtxoInput) => {
    const { meta } = this.activeEntityEvents.emit(
      'transactionUtxoInput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoData.hash,
          trxId: this.transactionUtxoData.id,
        },
      ],
      true
    )

    this.autoSelectTransaction.setFlowData({
      flowId: meta.id,
      transactionHash: data?.previous?.trxHash,
    })
  }

  @action
  public openOutput = (data: TransactionAddressUtxoOutput) => {
    const { meta } = this.activeEntityEvents.emit(
      'transactionUtxoOutput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoData.hash,
          trxId: this.transactionUtxoData.id,
        },
      ],
      true
    )

    this.autoSelectTransaction.setFlowData({
      flowId: meta.id,
      transactionHash: data?.next?.trxHash,
    })
  }

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
