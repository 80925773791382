import { injectable, inject } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { Position } from '../../types/Position'
import { Direction } from './TextController.types'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ITextNodeController } from './TextNodeController.types'

@injectable()
export class TextAreaController {
  public pivotPosition = { x: 0, y: 0 }
  public padding = 8
  public placeholder = 'Start typing'
  @observable private textAreaScrollHeight = null
  @observable public isAspectRatioLocked = false
  @observable public isEditTextMode = false

  constructor(
    @inject(DI_PROBE_TYPES.TextNodeController)
    private textNodeController: ITextNodeController
  ) {
    makeObservable(this)
  }

  private get textNode() {
    return this.textNodeController.temporaryTextNode
  }

  public get textAreaWidth() {
    if (this.textNode?.data.scale) {
      return (
        (this.textNode?.data?.width - this.padding * 2) /
        this.textNode.data.scale
      )
    }
    return 0
  }

  public get textAreaScale() {
    return this.textNode?.data.scale || 1
  }

  @action
  public onMoveTextNode = (position: Position) => {
    this.textNode?.moveTo({
      x: this.textNode?.position?.x + position.x,
      y: this.textNode?.position?.y + position.y,
    })
  }

  @action
  public onResize = ({
    direction,
    containerOffsetWidth,
    textAreaOffsetWidth,
    textAreaScrollHeight,
  }: {
    direction: Direction
    containerOffsetWidth: number
    containerOffsetHeight: number
    textAreaOffsetWidth: number
    textAreaScrollHeight: number
  }) => {
    const containerWidth = containerOffsetWidth - this.padding * 2
    const scaleX = containerWidth / textAreaOffsetWidth

    if (direction === 'right' || direction === 'left') {
      this.isAspectRatioLocked = false
      if (this.textAreaScrollHeight !== textAreaScrollHeight) {
        this.textAreaScrollHeight = textAreaScrollHeight
        this.textNode.updateData({
          width: containerOffsetWidth,
          height: textAreaScrollHeight * scaleX + this.padding * 2,
        })
      } else {
        this.textNode.updateData({
          width: containerOffsetWidth,
        })
      }
    } else {
      this.textNode.updateData({
        scale: scaleX,
        width: containerOffsetWidth,
        height: textAreaScrollHeight * scaleX + this.padding * 2,
      })
      this.isAspectRatioLocked = true
    }
  }

  @action
  public onTextAreaInput = (event) => {
    if (!this.isEditTextMode) {
      this.isEditTextMode = true
    }
    this.textNode?.updateData({
      text: event.target.textContent,
      height:
        event.target.scrollHeight * this.textNode.data.scale + this.padding * 2,
    })
  }

  @action
  public onTextAreaBlur = () => {
    if (this.isEditTextMode) {
      this.isEditTextMode = false
    }
  }

  @action
  public onTextAreaClick = () => {
    this.isEditTextMode = true
  }

  @action
  public clear = () => {
    this.isAspectRatioLocked = false
    this.isEditTextMode = false
  }
}
