import {
  Application,
  autoDetectRenderer,
  Container,
  FederatedMouseEvent,
  FederatedPointerEvent,
  Graphics,
  Point,
  Text,
  TilingSprite,
} from 'pixi.js'
import 'pixi.js/unsafe-eval'
import { Viewport } from 'pixi-viewport'
import { MultiDirectedGraph } from 'graphology'
import { Attributes } from 'graphology-types'
import { TypedEmitter } from 'tiny-typed-emitter'
import { debounce } from 'lodash'

import TextureCache from './textureCache'
import PixiNode from './node'
import { CurvePixiEdge } from './edge'
import PixiEvent, { TARGET } from './core/PixiEvent'
import {
  AppGraph,
  AppGraphController,
  EdgeAttributes,
  NodeAttributes,
  NodeOptions,
  Position,
  ResizeParams,
} from './types'
import { isDev, shiftOrMod } from '@clain/core/utils/tools'
import {
  AREA_Z_INDEX,
  EDGE_ABOVE_OVERLAY_Z_INDEX,
  EDGE_Z_INDEX,
  GRID_SIZE,
  NODE_ABOVE_OVERLAY_Z_INDEX,
  NODE_Z_INDEX,
  OVERLAY_Z_INDEX,
  POINTER_MOVE_DELTA,
} from './pixi.constatns'
import { GraphController } from './GraphController'
import { AreaGfx } from './area'
import {
  AnimationAreaModule,
  NodeLinkingModule,
  HighlighterModule,
} from './modules'
import { PixiGraphEvents, PixiGraphOptions } from './GraphApp.types'
import { AnimationRender } from './modules/AnimationRender.module'
import { IAnimationRender } from './modules/AnimationRender.module'

class GraphApp<
  NodeMetaInfo extends Attributes = Attributes,
  EdgeMetaInfo extends Attributes = Attributes,
  GraphMetaInfo extends Attributes = Attributes,
> extends TypedEmitter<PixiGraphEvents> {
  private needsRender = false
  private isDestroyed = false
  private continuousRender = false

  public graph: AppGraph<NodeMetaInfo, EdgeMetaInfo, GraphMetaInfo>
  public graphController: AppGraphController<
    NodeMetaInfo,
    EdgeMetaInfo,
    GraphMetaInfo
  >
  private app: Application
  private world: Viewport
  private container: HTMLElement
  private options: PixiGraphOptions
  private textureCache: TextureCache
  public nodeKeyToNodeInstance: Map<string, PixiNode>
  public edgeKeyToEdgeInstance: Map<string, CurvePixiEdge>
  public nodeKeyToAreaGfx: Map<string, AreaGfx> = new Map()
  private mousedownNodeKey: undefined | string
  private gridLayer: Container
  private areaLayer: Container
  private overlayLayer: Container
  private lastClickPointerPositionX: number
  private lastClickPointerPositionY: number
  private isActiveGrid: boolean
  private lastUpliftedNode: string
  public nodeLinkingModule: NodeLinkingModule
  public animationAreaModule: AnimationAreaModule
  public highlighterModule: HighlighterModule
  private animationRender: IAnimationRender

  constructor(
    options: PixiGraphOptions<NodeMetaInfo, EdgeMetaInfo, GraphMetaInfo>
  ) {
    super()
    this.app = new Application()
    globalThis.__PIXI_APP__ = this.app
    this.options = options
    this.graph =
      options.graph ||
      new MultiDirectedGraph<
        NodeAttributes<NodeMetaInfo>,
        EdgeAttributes<EdgeMetaInfo>,
        GraphMetaInfo
      >()
    this.graphController = new GraphController<
      NodeAttributes<NodeMetaInfo>,
      EdgeAttributes<EdgeMetaInfo>,
      GraphMetaInfo
    >(this.graph)
  }

  public renderTick = () => {
    this.app.renderer.render(this.app.stage)
  }

  public scheduleRender = () => {
    this.needsRender = true
  }

  private animationLoop = () => {
    if (this.isDestroyed) return

    if (this.needsRender || this.continuousRender) {
      this.renderTick()
      this.needsRender = false
    }
    requestAnimationFrame(this.animationLoop)
  }

  public init = async () => {
    await this.app.init({ autoStart: false })
    requestAnimationFrame(this.animationLoop)
    this.app.renderer = await autoDetectRenderer({
      eventMode: 'passive',
      eventFeatures: {
        move: true,
        /** disables the global move events which can be very expensive in large scenes */
        globalMove: false,
        click: true,
        wheel: true,
      },
      width: 100,
      height: 100,
      resolution: this.options.resolution,
      autoDensity: true,
      backgroundColor: this.options.backgroundColor,
      antialias: false,
      hello: true,
    })

    this.textureCache = new TextureCache(this.app.renderer)
    this.nodeKeyToNodeInstance = new Map<string, PixiNode>()
    this.edgeKeyToEdgeInstance = new Map<string, CurvePixiEdge>()

    this.container = this.options.container

    this.createWorld()
    this.createGridPanel()
    this.createOverlayLayer()

    this.container.appendChild(this.app.canvas)
    this.app.canvas.setAttribute('data-sl', 'canvas-hq')
    this.resize({
      width: this.container.clientWidth,
      height: this.container.clientHeight,
    })

    this.initListeners()
    this.initGraphListeners()
    this.animationRender = new AnimationRender(
      this.startContinuousRender,
      this.stopContinuousRender
    )
    this.nodeLinkingModule = new NodeLinkingModule(this.animationRender, {
      nodeKeyToNodeInstance: this.nodeKeyToNodeInstance,
      nodeKeyToAreaGfx: this.nodeKeyToAreaGfx,
      graph: this.graph,
      getScaled: () => this.scaled,
    })
    this.animationAreaModule = new AnimationAreaModule(this.animationRender, {
      nodeKeyToNodeInstance: this.nodeKeyToNodeInstance,
      nodeKeyToAreaGfx: this.nodeKeyToAreaGfx,
      graph: this.graph,
    })
    this.app.stage.sortableChildren = true
    this.app.stage.sortChildren()
    this.scheduleRender()
  }

  public get appInstance(): Application {
    return this.app
  }

  public get worldInstance(): Viewport {
    return this.world
  }

  private get zoomStep(): number {
    const zoom = this.world.scale.x
    const zoomSteps = [0.01, 0.25, 0.35, Infinity]
    const zoomStep = zoomSteps.findIndex((zoomStep) => zoom <= zoomStep)

    return zoomStep
  }

  public get canvas(): HTMLCanvasElement {
    return this.app.canvas
  }

  public get center(): Position {
    return this.world.center
  }

  public toWorldCoordinates = ({ x, y }: { x: number; y: number }) => {
    return this.world.toWorld(x, y)
  }

  public toGlobalCoordinates = (point: { x: number; y: number }) => {
    return this.world.toGlobal(point)
  }

  private createEdge = (edgeKey: string) => {
    if (this.edgeKeyToEdgeInstance.has(edgeKey)) return

    const edgeOptions = this.graph.getEdgeAttributes(edgeKey)
    const sourceNodeOptions = this.graph.getNodeAttributes(
      this.graph.source(edgeKey)
    )
    const targetNodeOptions = this.graph.getNodeAttributes(
      this.graph.target(edgeKey)
    )
    const edge = new CurvePixiEdge({
      id: edgeKey,
      options: edgeOptions,
      sourceNodeOptions,
      targetNodeOptions,
      textureCache: this.textureCache,
    })

    edge.on('mouseover', (event) => this.emit('edge:mouseover', event))
    edge.on('mouseout', (event) => this.emit('edge:mouseout', event))
    edge.on('mousedown', (event) => this.emit('edge:mousedown', event))
    edge.on('mouseup', (event) => {
      if (shiftOrMod(event.domEvent)) {
        event.payload.isExpanding = true
      }
      this.emit('edge:mouseup', event)
    })

    edge.container.zIndex = EDGE_Z_INDEX

    this.world.addChild(edge.container)

    this.edgeKeyToEdgeInstance.set(edgeKey, edge)

    const relevantEdges = this.graph
      .edges(this.graph.source(edgeKey))
      .filter((key) => {
        return (
          this.graph.target(key) === this.graph.target(edgeKey) ||
          this.graph.source(key) === this.graph.target(edgeKey)
        )
      })

    const edgesPerSide = relevantEdges.length / 2 - 0.5

    relevantEdges.forEach((key, index) => {
      const factor =
        this.graph.source(key) === this.graph.source(edgeKey)
          ? edgesPerSide - index
          : index - edgesPerSide
      this.edgeKeyToEdgeInstance.get(key).updateFactor(factor)
      this.edgeKeyToEdgeInstance.get(key).updatePosition()
      this.edgeKeyToEdgeInstance.get(key).updateStyle()
    })
    this.scheduleRender()
  }

  private createNode = (nodeKey: string) => {
    if (this.nodeKeyToNodeInstance.has(nodeKey)) return

    const nodeOptions = this.graph.getNodeAttributes(nodeKey)
    const node = new PixiNode(this.animationRender, {
      id: nodeKey,
      options: nodeOptions,
      textureCache: this.textureCache,
    })

    node.on('mouseover', (event) => this.emit('node:mouseover', event))
    node.on('mouseout', (event) => this.emit('node:mouseout', event))

    node.on('mousedown', (event) => {
      if (shiftOrMod(event.domEvent)) {
        event.payload.isExpanding = true
      }

      this.emit('node:mousedown', event)
      this.mousedownNodeKey = nodeKey

      const handlePointerUp = () => {
        window.removeEventListener('mouseup', handlePointerUp)
        this.app.stage.eventMode = 'passive'
        node.emit('mouseup', event)
      }

      window.addEventListener('mouseup', handlePointerUp)
      this.app.stage.eventMode = 'none'
    })

    node.on('mouseup', (event) => {
      this.emit('node:mouseup', event)

      const pointerDiffX = Math.abs(
        event.domEvent.clientX - this.lastClickPointerPositionX
      )
      const pointerDiffY = Math.abs(
        event.domEvent.clientY - this.lastClickPointerPositionY
      )

      if (shiftOrMod(event.domEvent)) {
        event.payload.isExpanding = true
      }

      if (
        pointerDiffX < POINTER_MOVE_DELTA &&
        pointerDiffY < POINTER_MOVE_DELTA
      ) {
        this.emit('node:click', event)
      }

      this.mousedownNodeKey = undefined
    })

    node.container.zIndex = NODE_Z_INDEX
    this.world.addChild(node.container)

    if (nodeOptions.area) {
      const areaGfx = new AreaGfx(nodeOptions.area)
      areaGfx.gfx.position.copyFrom(nodeOptions.position)
      this.areaLayer.addChild(areaGfx.gfx)
      this.nodeKeyToAreaGfx.set(nodeKey, areaGfx)
    }

    if (nodeOptions.position) {
      node.updatePosition()
      node.update()
    }

    this.nodeKeyToNodeInstance.set(nodeKey, node)
    this.scheduleRender()
  }

  private onNodeAdded = (data: { key: string }) => {
    this.createNode(data.key)
  }

  private onEdgeAdded = (data: { key: string }) => {
    this.createEdge(data.key)
  }

  private onNodeDropped = (data: { key: string }) => {
    const node = this.nodeKeyToNodeInstance.get(data.key)

    this.world.removeChild(node.container)

    this.nodeKeyToNodeInstance.delete(data.key)

    const areaGfx = this.nodeKeyToAreaGfx.get(data.key)
    if (areaGfx) {
      this.areaLayer.removeChild(areaGfx.gfx)
      this.nodeKeyToAreaGfx.delete(data.key)
    }
    this.scheduleRender()
  }

  private onEdgeDropped = (data: {
    key: string
    source: string
    target: string
  }) => {
    const edge = this.edgeKeyToEdgeInstance.get(data.key)

    this.world.removeChild(edge.container)

    const relevantEdges = this.graph.edges(data.source).filter((key) => {
      return (
        this.graph.target(key) === data.target ||
        this.graph.source(key) === data.target
      )
    })

    const edgesPerSide = relevantEdges.length / 2 - 0.5

    relevantEdges.forEach((key, index) => {
      const factor =
        this.graph.source(key) === data.source
          ? edgesPerSide - index
          : index - edgesPerSide
      this.edgeKeyToEdgeInstance.get(key).updateFactor(factor)
      this.edgeKeyToEdgeInstance.get(key).updatePosition()
      this.edgeKeyToEdgeInstance.get(key).updateStyle()
    })

    this.edgeKeyToEdgeInstance.delete(data.key)
    this.scheduleRender()
  }

  private onCleared = () => {
    this.edgeKeyToEdgeInstance.forEach((edge, edgeKey) => {
      this.world.removeChild(edge.container)

      this.edgeKeyToEdgeInstance.delete(edgeKey)
    })

    this.nodeKeyToNodeInstance.forEach((node, nodeKey) => {
      this.world.removeChild(node.container)
      this.nodeKeyToNodeInstance.delete(nodeKey)
    })
    this.scheduleRender()
  }

  private onNodeAttributesUpdated = async (data: {
    key: string
    name: string
    attributes?: NodeOptions
  }) => {
    if (data.name === 'position') {
      this.graph.edges(data.key).forEach((edgeKey) => {
        this.updateEdgePosition(edgeKey)
      })

      this.updateNodePosition(data.key)
      this.upliftNode(data.key)
    } else {
      await this.updateNodeStyle(data.key)
    }
    this.scheduleRender()
  }

  private onEdgeAttributesUpdated = (data: {
    key: string
    name: string
  }): void => {
    this.updateEdgeStyle(data.key)
    this.scheduleRender()
  }

  private updateNodeStyle = async (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    await node.update()

    this.updateNodeArea(nodeKey)
  }
  private updateNodeArea = (nodeKey: string) => {
    const nodeOptions = this.graph.getNodeAttributes(nodeKey)
    const areaGfx = this.nodeKeyToAreaGfx.get(nodeKey)

    if (nodeOptions.area) {
      let areaGfx = this.nodeKeyToAreaGfx.get(nodeKey)
      if (!areaGfx) {
        areaGfx = new AreaGfx(nodeOptions.area)
        this.areaLayer.addChild(areaGfx.gfx)
        this.nodeKeyToAreaGfx.set(nodeKey, areaGfx)
      } else {
        areaGfx.updateGfx(nodeOptions.area)
      }
      areaGfx.gfx.position.copyFrom(nodeOptions.position)
    } else {
      if (areaGfx) {
        this.areaLayer.removeChild(areaGfx.gfx)
        this.nodeKeyToAreaGfx.delete(nodeKey)
      }
    }
  }

  private updateNodePosition = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)

    node.updatePosition()

    const areaGfx = this.nodeKeyToAreaGfx.get(nodeKey)
    if (areaGfx) {
      const nodeOptions = this.graph.getNodeAttributes(nodeKey)
      areaGfx.gfx.position.copyFrom(nodeOptions.position)
    }
  }

  private upliftNode = (nodeKey: string) => {
    if (this.lastUpliftedNode === nodeKey) return
    this.lastUpliftedNode = nodeKey

    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    const nodeContainerIndex = this.world.getChildIndex(node.container)

    this.world.removeChildAt(nodeContainerIndex)
    this.world.addChild(node.container)
  }

  private updateEdgeStyle = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)

    edge.updateStyle()
  }

  private updateEdgePosition = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)

    edge.updatePosition()
  }

  private handleSelectEntities = (event: FederatedPointerEvent) => {
    const pixiEvent = new PixiEvent({ event, target: TARGET.WORLD })
    if (pixiEvent.target !== TARGET.WORLD) return

    const pointerDiffX = Math.abs(
      pixiEvent.domEvent.clientX - this.lastClickPointerPositionX
    )
    const pointerDiffY = Math.abs(
      pixiEvent.domEvent.clientY - this.lastClickPointerPositionY
    )

    if (
      pointerDiffX < POINTER_MOVE_DELTA &&
      pointerDiffY < POINTER_MOVE_DELTA
    ) {
      this.emit(
        'select',
        new PixiEvent({
          event,
          target: TARGET.WORLD,
          payload: {
            nodeKeys: [],
            edgeKeys: [],
          },
        })
      )
    }
    this.scheduleRender()
  }

  private initListeners = () => {
    this.app.canvas.addEventListener('wheel', this.handleWorldWheel)
    this.app.canvas.addEventListener('contextmenu', this.handleContextMenu)
    this.app.canvas.addEventListener('mousedown', this.handlePointerPosition)

    this.world.on('mousedown', (event: FederatedMouseEvent) => {
      const { button, global } = event
      if (!this.mousedownNodeKey && button !== 1) {
        if (shiftOrMod(event)) {
          this.world.drag({
            wheel: true,
            pressDrag: true,
            mouseButtons: 'middle',
          })
          this.highlighterModule.startHighlighting(global)
          if (shiftOrMod(event, 'shift')) {
            this.highlighterModule.enableHighlighting()
          }
          if (shiftOrMod(event, 'mod')) {
            this.highlighterModule.enableHighlighting({ mode: 'deselect' })
          }
        }
        this.scheduleRender()
      }
    })

    // Удаляем глобальный обработчик mouseup, так как теперь используем pointerup
  }

  private createWorld = () => {
    this.world = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight - 56,
      worldWidth: this.options.worldWidth,
      worldHeight: this.options.worldHeight,
      events: this.app.renderer.events,
    })
      .wheel({ trackpadPinch: true, wheelZoom: true })
      .drag({ wheel: true, pressDrag: true, mouseButtons: 'left' })
      .clampZoom({
        maxScale: this.options.maxScale,
        minScale: this.options.minScale,
      })
      .setZoom(isDev ? this.options.maxScale : this.options.minScale, true)
      .moveCenter(this.options.worldWidth / 2, this.options.worldHeight / 2)

    this.gridLayer = new Container()
    this.overlayLayer = new Container({
      isRenderGroup: true,
    })
    this.areaLayer = new Container({
      isRenderGroup: true,
    })

    this.areaLayer.zIndex = AREA_Z_INDEX

    this.overlayLayer.zIndex = OVERLAY_Z_INDEX

    this.app.stage.addChild(this.world)

    this.app.stage.interactive = false
    this.app.stage.eventMode = 'passive'

    this.overlayLayer.interactive = false
    this.overlayLayer.eventMode = 'none'

    this.gridLayer.interactive = false
    this.gridLayer.eventMode = 'none'

    this.areaLayer.interactive = false
    this.areaLayer.eventMode = 'none'

    this.world.addChild(this.areaLayer)
    this.world.addChild(this.gridLayer)
    this.world.addChild(this.overlayLayer)

    this.world.sortableChildren = true
    this.world.sortChildren()

    // Initialize SelectionModule
    this.highlighterModule = new HighlighterModule(
      this.graph,
      this.world,
      this.emit.bind(this),
      this.scheduleRender
    )
    this.app.stage.addChild(this.highlighterModule.getHighlighterLayer())

    this.world.on('mouseup', (event) => {
      const worldTarget = event?.target === this.world

      this.emit(
        'world:mouseup',
        new PixiEvent({ event, worldTarget, target: TARGET.WORLD })
      )
    })

    this.world.on('mousedown', (event) => {
      const worldTarget = event?.target === this.world

      this.emit(
        'world:mousedown',
        new PixiEvent({ event, worldTarget, target: TARGET.WORLD })
      )
    })

    this.world.on('clicked', (event) => {
      const worldTarget = event?.event?.target === this.world

      return this.emit(
        'world:click',
        new PixiEvent({
          event: event?.event,
          worldTarget,
          target: TARGET.WORLD,
        })
      )
    })

    this.world.on('moved', (event) => {
      this.disableNodesEdgesInteraction()

      setTimeout(() => {
        if (!this.world.moving) {
          this.enableNodesEdgesInteraction()
        }
      }, 100)

      this.emit(
        'moved',
        new PixiEvent({
          event: event as unknown as MouseEvent,
          target: TARGET.WORLD,
        })
      )
      this.scheduleRender()
    })
  }

  public setWordZoom = (zoom: number) => {
    this.world.setZoom(zoom, true)
  }

  public setWordPosition = (x: number, y: number) => {
    this.world.moveCenter(x, y)
  }

  public resize = (...args: ResizeParams) => {
    const canvas = args[0]
    if (!canvas?.width || !canvas?.height) {
      return
    }

    const viewport = args[1] || {
      width: canvas?.width,
      height: canvas?.height,
      offsetX: 0,
      offsetViewportX: 0,
    }

    this.app.renderer.resize(canvas.width, canvas.height)
    this.resizeViewport(
      viewport.width,
      viewport.height,
      viewport.offsetX,
      viewport.offsetViewportX
    )
    this.scheduleRender()
  }

  private resizeViewport = (
    width: number,
    height: number,
    offsetX = 0,
    offsetViewportX = 0
  ) => {
    this.world.resize(width, height)
    this.app.stage.x = offsetViewportX
    this.world.position.x = this.world.position.x - offsetX
  }

  private createGridPanel = () => {
    this.gridLayer.visible = false
    this.gridLayer.renderable = false
    const gridPanel = new Container()
    gridPanel.width = this.world.worldWidth
    gridPanel.height = this.world.worldHeight

    const dotTexture = this.textureCache.get('dot-grid', () => {
      const graphics = new Graphics()
      graphics.rect(0, 0, GRID_SIZE, GRID_SIZE)
      graphics.fill({ color: 0x000000, alpha: 0 })
      graphics.circle(GRID_SIZE / 2, GRID_SIZE / 2, 2)
      graphics.fill({ color: 0xbdc8df, alpha: 0.5 })

      const container = new Container()
      container.addChild(graphics)
      return container
    })

    const tilingSprite = new TilingSprite({
      texture: dotTexture,
      width: this.world.worldWidth,
      height: this.world.worldHeight,
    })

    gridPanel.addChild(tilingSprite)
    this.gridLayer.addChild(gridPanel)
  }

  private enableNodesEdgesInteractionDebounce = debounce(() => {
    this.enableNodesEdgesInteraction()
    this.scheduleRender()
  }, 100)

  private enableNodesEdgesInteraction = () => {
    this.nodeKeyToNodeInstance.forEach((node) => {
      node.container.eventMode = 'passive'
    })
    this.edgeKeyToEdgeInstance.forEach((edge) => {
      edge.container.eventMode = 'passive'
    })
  }

  private disableNodesEdgesInteraction = () => {
    this.nodeKeyToNodeInstance.forEach((node) => {
      node.container.eventMode = 'none'
    })
    this.edgeKeyToEdgeInstance.forEach((edge) => {
      edge.container.eventMode = 'none'
    })
  }

  private handleWorldWheel = (event: MouseEvent) => {
    this.disableNodesEdgesInteraction()
    this.enableNodesEdgesInteractionDebounce()

    this.emit(
      'world:wheel',
      new PixiEvent({
        event,
        target: TARGET.WORLD,
        payload: { scaled: this.scaled },
      })
    )
    event.preventDefault()
    this.updateGridVisibility(this.zoomStep)
  }

  private handleContextMenu = (event: MouseEvent) => {
    event.preventDefault()
  }

  private handlePointerPosition = (event: MouseEvent) => {
    this.lastClickPointerPositionX = event.clientX
    this.lastClickPointerPositionY = event.clientY
  }

  private initGraphListeners = () => {
    this.graph.on('nodeAttributesUpdated', this.onNodeAttributesUpdated)
    this.graph.on('edgeAttributesUpdated', this.onEdgeAttributesUpdated)

    this.graph.on('nodeAdded', this.onNodeAdded)
    this.graph.on('edgeAdded', this.onEdgeAdded)
    this.graph.on('nodeDropped', this.onNodeDropped)
    this.graph.on('edgeDropped', this.onEdgeDropped)
    this.graph.on('cleared', this.onCleared)
  }

  private removeInitListeners = () => {
    this.app.canvas.removeEventListener('wheel', this.handleWorldWheel)
    this.app.canvas.removeEventListener('contextmenu', this.handleContextMenu)
    this.app.canvas.removeEventListener('mousedown', this.handlePointerPosition)
    /**
     * Select handler
     */
    this.world.off('mouseup', this.handleSelectEntities)
  }

  private removeGraphListeners = () => {
    this.graph.off('nodeAttributesUpdated', this.onNodeAttributesUpdated)
    this.graph.off('edgeAttributesUpdated', this.onEdgeAttributesUpdated)

    this.graph.off('nodeAdded', this.onNodeAdded)
    this.graph.off('edgeAdded', this.onEdgeAdded)
    this.graph.off('nodeDropped', this.onNodeDropped)
    this.graph.off('edgeDropped', this.onEdgeDropped)
    this.graph.off('cleared', this.onCleared)
  }

  public destroy = () => {
    this.isDestroyed = true
    this.removeInitListeners()
    this.removeGraphListeners()
    this.app.destroy(true, true)
  }

  private updateGridVisibility = (zoomStep: number) => {
    if (zoomStep <= 2 || !this.isActiveGrid) {
      if (this.gridLayer.visible) {
        this.gridLayer.renderable = false
        this.gridLayer.visible = false
        this.scheduleRender()
      }
    } else {
      if (!this.gridLayer.visible) {
        this.gridLayer.visible = true
        requestAnimationFrame(() => {
          this.gridLayer.renderable = true
          this.scheduleRender()
        })
      }
    }
  }

  public setMode = (mode: 'drag' | 'select'): void => {
    if (mode === 'drag') {
      this.world.drag({
        wheel: true,
        pressDrag: true,
        mouseButtons: 'middle',
      })
    }

    if (mode === 'select') {
      this.world.drag({
        wheel: true,
        pressDrag: true,
        mouseButtons: 'left',
      })
    }
  }

  public setIsActiveGrid = (isActiveGrid: boolean) => {
    this.isActiveGrid = isActiveGrid
    this.updateGridVisibility(this.zoomStep)
  }

  public moveScreenTo = (x: number, y: number) => {
    this.world.moveCenter(x, y)
    this.scheduleRender()
  }

  public moveScreenWithAnimate = (options: {
    time?: number
    scale?: number
    position?: Position
    ease?: string
    onFinish?: () => void
  }) => {
    const {
      time = 500,
      scale = 1,
      ease = 'easeOutSine',
      position,
      onFinish,
    } = options
    const point = position ? new Point(position.x, position.y) : undefined

    this.world.animate({
      time,
      scale,
      ease,
      position: point,
      callbackOnComplete: (viewport) => {
        this.emit('animated:zoom', { payload: { scaled: viewport.scaled } })
        if (onFinish) {
          onFinish()
          this.scheduleRender()
        }
      },
    })
  }

  public get scaled(): number {
    return this.world.scaled
  }

  public get dragging(): boolean {
    return (this.world.moving && !this.world.zooming) || false
  }

  private showFPS = () => {
    const text = new Text({
      text: 'FPS: ',
      style: {
        fontFamily: 'Arial',
        fontSize: 28,
        fill: 'red',
        align: 'center',
      },
    })

    text.position.x = 5
    text.position.y = 5
    this.app.stage.addChild(text)
    this.app.ticker.add(() => {
      text.text = `FPS: ${Math.floor(this.app.ticker.FPS)}`
    })
  }

  private createOverlayLayer = () => {
    this.overlayLayer.visible = false
    const overlayLayer = new Container()
    overlayLayer.width = this.world.worldWidth
    overlayLayer.height = this.world.worldHeight

    const overlay = new Graphics()
    overlay.rect(0, 0, this.world.worldWidth, this.world.worldHeight)
    overlay.fill({ color: this.options.backgroundColor, alpha: 0.6 })

    overlayLayer.addChild(overlay)

    this.overlayLayer.addChild(overlayLayer)
  }

  public toggleOverlay = (visible: boolean) => {
    this.overlayLayer.visible = visible
    this.scheduleRender()
  }

  public setNodeAboveOverlay = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    if (node) {
      node.container.zIndex = NODE_ABOVE_OVERLAY_Z_INDEX
    }
  }

  public setNodeBelowOverlay = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    if (node) {
      node.container.zIndex = NODE_Z_INDEX
    }
  }

  public setEdgeAboveOverlay = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)
    if (edge) {
      edge.container.zIndex = EDGE_ABOVE_OVERLAY_Z_INDEX
    }
  }

  public setEdgeBelowOverlay = (edgeKey: string) => {
    const edge = this.edgeKeyToEdgeInstance.get(edgeKey)
    if (edge) {
      edge.container.zIndex = EDGE_Z_INDEX
    }
  }

  public startContinuousRender = () => {
    this.continuousRender = true
  }

  public stopContinuousRender = () => {
    this.continuousRender = false
  }
}

export default GraphApp
export { MultiDirectedGraph }
export { GraphController }
export type {
  NodeAttributes,
  EdgeAttributes,
  TipType,
  Orbit,
  IconSatellite,
  PillsSatellite,
  AppGraph,
  AppGraphController,
} from './types'
