import { ContainerModule, interfaces } from 'inversify'
import { CategoriesVMModule } from '@platform/modules/categories'
import { DI_TYPES } from '@platform/di/DITypes'
const initializeModule = (bind: interfaces.Bind) => {
  bind<CategoriesVMModule>(DI_TYPES.CategoriesVMModule)
    .to(CategoriesVMModule)
    .inSingletonScope()
}

export const categoriesModule = new ContainerModule(initializeModule)
