import { isEVM } from '@clain/core/types/coin'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { pick } from 'ramda'
import {
  Alert,
  RawAlert,
  AlertCluster,
  RawAlertCluster,
  RawAlerts,
  Alerts,
  RawAlertEvent,
  AlertEvent,
  RawAlertEvents,
  AlertEvents,
  AlertTransactionEvm,
  AlertTransactionUtxo,
} from '../types'
import { convertToPaginated } from './convertToPaginated'

const convertToCluster = (rawCluster: RawAlertCluster): AlertCluster => {
  return {
    entity: rawCluster.entity,
    clusterId: rawCluster.cluster_id,
    entityId: rawCluster.entity_id,
    score: rawCluster.score,
  }
}

export const convertToAlert = (rawAlert: RawAlert): Alert => {
  return {
    active: rawAlert.active,
    address: rawAlert.address,
    direction: rawAlert.direction,
    id: rawAlert.id,
    tag: rawAlert.tag,
    threshold: rawAlert.threshold,
    updatedAt: new Date(rawAlert.updated_at),
    cluster: normalizeSnakeToCamelCase(rawAlert.cluster),
    type: rawAlert.type,
    recurrent: rawAlert.recurrent ? 'everyTxn' : 'once',
    currency: rawAlert.currency,
    asset: normalizeSnakeToCamelCase(rawAlert.asset),
    native: rawAlert.native,
    addressId: rawAlert.address_id,
  }
}

export const convertToAlerts = (rawAlerts: RawAlerts): Alerts =>
  rawAlerts?.alerts.map(convertToAlert)

export const convertToAlertEvent = (
  rawAlertEvent: RawAlertEvent
): AlertEvent => {
  return {
    alert: convertToAlert(rawAlertEvent.alert),
    amount: rawAlertEvent.amount,
    hash: rawAlertEvent.hash,
    id: rawAlertEvent.id,
    time: new Date(rawAlertEvent.time * 1000),
    transactionId: rawAlertEvent.transaction_id,
    tag: rawAlertEvent.alert_tag,
    threshold: rawAlertEvent.threshold,
    amountUsd: rawAlertEvent.amount_usd || rawAlertEvent.usd,
    direction: rawAlertEvent.direction,
    counterparties: rawAlertEvent.counterparties.map(convertToCluster),
    ...(isEVM(rawAlertEvent.currency)
      ? (normalizeSnakeToCamelCase(
          pick(['from', 'to'], rawAlertEvent)
        ) as unknown as AlertTransactionEvm)
      : (normalizeSnakeToCamelCase(
          pick(['inputs', 'outputs'], rawAlertEvent)
        ) as unknown as AlertTransactionUtxo)),
  }
}

export const convertToAlertEvents = (
  rawAlertEvents: RawAlertEvents
): AlertEvents => {
  const { alert_events: events, ...pagination } = convertToPaginated(
    rawAlertEvents,
    'alert_events',
    convertToAlertEvent
  )

  return { ...pagination, events }
}
