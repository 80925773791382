import { injectable } from 'inversify'
import type { Position } from '../types/Position'
import { action, makeObservable, observable } from 'mobx'

type Listener = (position: Position) => unknown

export interface IPointerController {
  position: Position
  x: number
  y: number
  addListener: (onDrag: Listener, onDragEnd?: Listener) => void
  removeListener: (onDrag: Listener) => void
}

@injectable()
export class PointerController implements IPointerController {
  @observable public position: Position

  public get x() {
    return this.position.x
  }

  public get y() {
    return this.position.y
  }

  private listeners: Array<Listener>

  constructor() {
    makeObservable(this)
    this.position = { x: 0, y: 0 }
    this.listeners = []

    document.addEventListener('mousemove', this.onDocumentMouseMove)
    document.addEventListener('wheel', this.onWheel)
  }

  private removeMouseup = (onDragEnd, onDrag) => () => {
    onDragEnd(this.position)
    this.removeListener(onDrag)
  }

  @action
  public addListener(onDrag: Listener, onDragEnd?: Listener) {
    this.listeners.push(onDrag)

    if (onDragEnd) {
      document.addEventListener(
        'mouseup',
        this.removeMouseup(onDragEnd, onDrag),
        { once: true }
      )
    }
  }

  @action
  public removeListener(onDrag: Listener) {
    this.listeners = this.listeners.filter((listener) => listener !== onDrag)
  }

  @action
  private onDocumentMouseMove = (event: MouseEvent) => {
    this.position = { x: event.x, y: event.y - 56 }
    this.listeners.forEach((listener) => listener(this.position))
  }

  @action
  private onWheel = (event: WheelEvent) => {
    this.position = { x: event.x, y: event.y - 56 }
    this.listeners.forEach((listener) => listener(this.position))
  }
}
