import { DI_ACTIVE_ENTITY_TYPES } from './activeEntityTypes'
import { DI_PLOT_ENTITIES_TYPES } from './plotEntitiesTypes'
import { DI_PROBE_BASE_TYPES } from './probeBaseTypes'
import { DI_PROBE_STATE_TYPES } from './probeStateTypes'

export const DI_PROBE_TYPES = {
  ...DI_ACTIVE_ENTITY_TYPES,
  ...DI_PLOT_ENTITIES_TYPES,
  ...DI_PROBE_BASE_TYPES,
  ...DI_PROBE_STATE_TYPES,
}
