import { ContainerModule, interfaces } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { CURRENCY } from '../../constants/currency'
import { CoinType } from '../../../../types/coin'
import { ITheme } from '../../../../modules/theme'
import { theme } from '@platform/modules/theme'

export const probeBaseModule = new ContainerModule((bind) => {
  bind<ITheme>(DI_PROBE_TYPES.Theme).toConstantValue(theme)

  bind<CoinType[]>(DI_PROBE_TYPES.Currencies).toConstantValue(CURRENCY)

  bind<HTMLDivElement>(DI_PROBE_TYPES.CanvasContainer).toDynamicValue(
    () => null
  )

  bind<interfaces.Provider<HTMLDivElement>>(
    DI_PROBE_TYPES.CanvasContainerProvider
  ).toProvider<HTMLDivElement>(({ container }) => {
    return async () => {
      return container.get<HTMLDivElement>(DI_PROBE_TYPES.CanvasContainer)
    }
  })
})
