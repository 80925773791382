export const ERROR = {
  serverIssues: 'Server Issues: please contact support.',
  generateAIReport:
    'There was an error generating the report. Please try again.',
  networkError: 'An error has occurred, please try to reload page.',
  settingsProfile: 'There is an issue with getting Profile information.',
}

export enum REJECT_REASON {
  offline = 'Timeout error captured after channel was closed',
  phx_error = 'phx_error',
}
