import { CasesState } from './states/CasesState'
import { CasesService } from './services/CasesService'
import type { IPhoenixSocketController } from '@clain/api/WebSocket'

interface CasseCtxInit {
  wsState: IPhoenixSocketController
}

export class CasesCtx {
  public casesService = new CasesService()
  public casesState = new CasesState()

  public init({ wsState }: CasseCtxInit) {
    this.casesService.init({ wsState })
  }
}
