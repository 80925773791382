import { injectable } from 'inversify'

@injectable()
class Queue {
  private storage: { [key: number]: unknown } = {}
  private head = 0
  private tail = 0

  public enQueue(item: unknown): void {
    this.storage[this.tail] = item
    this.tail++
  }

  public deQueue(): unknown {
    if (this.size <= 0) return undefined

    const item = this.storage[this.head]

    delete this.storage[this.head]

    this.head++

    if (this.head === this.tail) {
      this.head = 0
      this.tail = 0
    }

    return item
  }

  public get size(): number {
    return this.tail - this.head
  }
}

export default Queue
