import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ServerEventNodeEdgeReceive } from '../../../types/serverData'
import { isAllowedRemoveSnapshotType } from './RemoveSnapshots.utils'
import { GraphHistoryState } from '../GraphHistoryState'

@injectable()
export class RemoveSnapshots {
  constructor(
    @inject(DI_PROBE_TYPES.GraphHistoryState) private history: GraphHistoryState
  ) {}

  public emitEvent = (events: ServerEventNodeEdgeReceive[]): void => {
    if (!this.history.isEmptyHistory) {
      for (const event of events) {
        if (isAllowedRemoveSnapshotType(event)) {
          this.history.removeNodeInSnapshot(event.key)
        }
      }
    }
  }
}
