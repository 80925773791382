import {
  getBoundedDomainBlock,
  getNotGhostedBoundedDomainBlock,
  GetTransactionBlockReturn,
} from '@platform/components/ProbeSandbox/utils/getDomainBlock'
import {
  GhostedEntityOptions,
  IEntititiesGhosted,
} from '@platform/components/ProbeSandbox/models'
import { IProbeState } from '../ProbeState'
import { inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { makeObservable } from 'mobx'
import { ProbeApp } from '../../types/ProbeApp'
import { injectable } from 'inversify'
import { getProbeModule } from '../../di'

@injectable()
export class EntititiesGhosted implements IEntititiesGhosted {
  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState
  ) {
    makeObservable(this)
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  public removeGhostedEntities = () => {
    this.probeState.aboveOverlayNodeIds.forEach((nodeKey) => {
      this.app.setNodeBelowOverlay(nodeKey)
    })
    this.probeState.aboveOverlayEdgeIds.forEach((edgeKey) => {
      this.app.setEdgeBelowOverlay(edgeKey)
    })
    this.probeState.aboveOverlayNodeIds.clear()
    this.probeState.aboveOverlayEdgeIds.clear()
    this.app.toggleOverlay(false)
  }

  public removeGhostedEntitiesByKeys: IEntititiesGhosted['removeGhostedEntitiesByKeys'] =
    ({ nodeKeys = [], edgeKeys = [] }) => {
      nodeKeys.forEach((key) => {
        const domainBlock = getNotGhostedBoundedDomainBlock(this.app.graph, key)
        const domainSelectedBlock = getBoundedDomainBlock(this.app.graph, key)

        if (domainBlock) {
          domainBlock.nodeKeys.forEach((nodeKey) => {
            const isSelectedNode = this.probeState.selectedNodeIds.has(nodeKey)

            if (
              !isSelectedNode &&
              !this.nodeHasSelectedNeighborNodes(
                key,
                domainSelectedBlock,
                nodeKey
              )
            ) {
              this.app.setNodeBelowOverlay(nodeKey)
              this.probeState.aboveOverlayNodeIds.delete(nodeKey)
            }
          })
          domainBlock.edgeKeys.forEach((edgeKey) => {
            const isSelectedEdge = this.probeState.selectedEdgeIds.has(edgeKey)

            if (
              !isSelectedEdge &&
              !this.nodeIsConnectedToSelectedVisibleNode(
                key,
                domainSelectedBlock,
                edgeKey
              )
            ) {
              this.app.setEdgeBelowOverlay(edgeKey)
              this.probeState.aboveOverlayEdgeIds.delete(edgeKey)
            }
          })
        }
      })

      edgeKeys.forEach((key) => {
        const domainBlock = getNotGhostedBoundedDomainBlock(this.app.graph, key)
        const domainSelectedBlock = getBoundedDomainBlock(this.app.graph, key)

        if (domainBlock) {
          domainBlock.nodeKeys.forEach((nodeKey) => {
            const isSelectedNode = this.probeState.selectedNodeIds.has(nodeKey)
            if (
              !isSelectedNode &&
              !this.edgeHasSelectedNeighborNodes(
                key,
                domainSelectedBlock,
                nodeKey
              )
            ) {
              this.app.setNodeBelowOverlay(nodeKey)
              this.probeState.aboveOverlayNodeIds.delete(nodeKey)
            }
          })
          domainBlock.edgeKeys.forEach((edgeKey) => {
            const isSelectedEdge = this.probeState.selectedEdgeIds.has(edgeKey)

            if (
              !isSelectedEdge &&
              !this.edgeIsConnectedToSelectedVisibleNode(
                domainSelectedBlock,
                edgeKey
              )
            ) {
              this.app.setEdgeBelowOverlay(edgeKey)
              this.probeState.aboveOverlayEdgeIds.delete(edgeKey)
            }
          })
        }
      })

      const count =
        this.probeState.aboveOverlayNodeIds.size +
        this.probeState.aboveOverlayEdgeIds.size

      if (count === 0) {
        this.app.toggleOverlay(false)
      }
    }

  private nodeHasSelectedNeighborNodes = (
    currentKey: string,
    selectedKeys: GetTransactionBlockReturn,
    key: string
  ) => {
    const nodeKeys = this.app.graph.neighbors(key)
    const edges = this.app.graph.edges(key)

    const haveEdgeSelected = edges
      .filter((edgeKey) => {
        if (!selectedKeys) return true

        return !selectedKeys.edgeKeys.includes(edgeKey)
      })
      .some((edgeKey) => {
        return this.probeState.selectedEdgeIds.has(edgeKey)
      })

    if (haveEdgeSelected) {
      return true
    }

    if (key === currentKey) {
      return nodeKeys.some((nodeKey) => {
        return this.probeState.selectedNodeIds.has(nodeKey)
      })
    }

    return (
      nodeKeys
        .filter((nodeKey) => nodeKey !== currentKey)
        .some((nodeKey) => {
          return this.probeState.selectedNodeIds.has(nodeKey)
        }) || this.probeState.selectedNodeIds.has(key)
    )
  }

  private nodeIsConnectedToSelectedVisibleNode = (
    currentNodeKey: string,
    selectedKeys: GetTransactionBlockReturn,
    edgeKey: string
  ) => {
    const source = this.app.graph.source(edgeKey)
    const target = this.app.graph.target(edgeKey)

    if (selectedKeys && selectedKeys?.edgeKeys.includes(edgeKey)) {
      if (!selectedKeys.nodeKeys.includes(source)) {
        return this.probeState.selectedNodeIds.has(source)
      }

      if (!selectedKeys.nodeKeys.includes(target)) {
        return this.probeState.selectedNodeIds.has(target)
      }

      return false
    }

    if (this.probeState.selectedEdgeIds.has(edgeKey)) {
      return true
    }

    return (
      (this.probeState.aboveOverlayNodeIds.has(source) &&
        this.probeState.selectedNodeIds.has(source) &&
        source !== currentNodeKey) ||
      (this.probeState.aboveOverlayNodeIds.has(target) &&
        this.probeState.selectedNodeIds.has(target) &&
        target !== currentNodeKey)
    )
  }

  private edgeHasSelectedNeighborNodes = (
    currentEdgeKey: string,
    selectedKeys: GetTransactionBlockReturn,
    nodeKey: string
  ) => {
    const nodeKeys = this.app.graph.neighbors(nodeKey)
    const edges = this.app.graph.edges(nodeKey)

    const haveEdgeSelected = edges.some((edgeKey) => {
      if (selectedKeys && selectedKeys.edgeKeys.includes(edgeKey)) {
        const source = this.app.graph.source(edgeKey)
        const target = this.app.graph.target(edgeKey)

        if (!selectedKeys.nodeKeys.includes(source)) {
          return this.probeState.selectedNodeIds.has(source)
        }

        if (!selectedKeys.nodeKeys.includes(target)) {
          return this.probeState.selectedNodeIds.has(target)
        }

        return false
      }

      return (
        this.probeState.aboveOverlayEdgeIds.has(edgeKey) &&
        edgeKey !== currentEdgeKey
      )
    })

    if (haveEdgeSelected) {
      return true
    }
    const filtered = nodeKeys.filter(
      (nodeKey) =>
        !selectedKeys ||
        (selectedKeys && !selectedKeys.nodeKeys.includes(nodeKey))
    )

    return filtered.some((nodeKey) => {
      return this.probeState.selectedNodeIds.has(nodeKey)
    })
  }

  private edgeIsConnectedToSelectedVisibleNode = (
    selectedKeys: GetTransactionBlockReturn,
    edgeKey: string
  ) => {
    const source = this.app.graph.source(edgeKey)
    const target = this.app.graph.target(edgeKey)

    if (selectedKeys && selectedKeys.edgeKeys.includes(edgeKey)) {
      if (!selectedKeys.nodeKeys.includes(source)) {
        return this.probeState.selectedNodeIds.has(source)
      }

      if (!selectedKeys.nodeKeys.includes(target)) {
        return this.probeState.selectedNodeIds.has(target)
      }

      return false
    }

    return (
      this.probeState.selectedNodeIds.has(source) ||
      this.probeState.selectedNodeIds.has(target)
    )
  }

  public toggleVisibleEntities = ({
    nodeKeys = [],
    edgeKeys = [],
    isExpanding = false,
    isToggle = true,
  }: GhostedEntityOptions) => {
    if (!isExpanding) {
      this.removeGhostedEntities()
    }

    nodeKeys.forEach((key) => {
      const isSelectedNode = this.probeState.selectedNodeIds.has(key)
      const domainBlock = getNotGhostedBoundedDomainBlock(this.app.graph, key)
      const domainSelectedBlock = getBoundedDomainBlock(this.app.graph, key)

      if (domainBlock) {
        domainBlock.nodeKeys.forEach((nodeKey) => {
          if (!isSelectedNode || !isExpanding || !isToggle) {
            this.app.setNodeAboveOverlay(nodeKey)
            this.probeState.aboveOverlayNodeIds.add(nodeKey)
          } else if (
            !this.nodeHasSelectedNeighborNodes(
              key,
              domainSelectedBlock,
              nodeKey
            )
          ) {
            this.app.setNodeBelowOverlay(nodeKey)
            this.probeState.aboveOverlayNodeIds.delete(nodeKey)
          }
        })
        domainBlock.edgeKeys.forEach((edgeKey) => {
          if (!isSelectedNode || !isExpanding || !isToggle) {
            this.app.setEdgeAboveOverlay(edgeKey)
            this.probeState.aboveOverlayEdgeIds.add(edgeKey)
          } else if (
            !this.nodeIsConnectedToSelectedVisibleNode(
              key,
              domainSelectedBlock,
              edgeKey
            )
          ) {
            this.app.setEdgeBelowOverlay(edgeKey)
            this.probeState.aboveOverlayEdgeIds.delete(edgeKey)
          }
        })
      }
    })

    edgeKeys.forEach((key) => {
      const isSelectedNode = this.probeState.selectedEdgeIds.has(key)
      const domainBlock = getNotGhostedBoundedDomainBlock(this.app.graph, key)
      const domainSelectedBlock = getBoundedDomainBlock(this.app.graph, key)

      if (domainBlock) {
        domainBlock.nodeKeys.forEach((nodeKey) => {
          if (!isSelectedNode || !isExpanding || !isToggle) {
            this.app.setNodeAboveOverlay(nodeKey)
            this.probeState.aboveOverlayNodeIds.add(nodeKey)
          } else if (
            !this.edgeHasSelectedNeighborNodes(
              key,
              domainSelectedBlock,
              nodeKey
            )
          ) {
            this.app.setNodeBelowOverlay(nodeKey)
            this.probeState.aboveOverlayNodeIds.delete(nodeKey)
          }
        })
        domainBlock.edgeKeys.forEach((edgeKey) => {
          if (!isSelectedNode || !isExpanding || !isToggle) {
            this.app.setEdgeAboveOverlay(edgeKey)
            this.probeState.aboveOverlayEdgeIds.add(edgeKey)
          } else if (
            !this.edgeIsConnectedToSelectedVisibleNode(
              domainSelectedBlock,
              edgeKey
            )
          ) {
            this.app.setEdgeBelowOverlay(edgeKey)
            this.probeState.aboveOverlayEdgeIds.delete(edgeKey)
          }
        })
      }
    })

    if (
      this.probeState.aboveOverlayNodeIds.size > 0 ||
      this.probeState.aboveOverlayEdgeIds.size > 0
    ) {
      this.app.toggleOverlay(true)
    } else {
      this.app.toggleOverlay(false)
    }
  }
}
