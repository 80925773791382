import curry from 'ramda/src/curry'
import { AppGraph, IArea } from '../types'
import PixiNode from '../node'
import { AreaGfx } from '../area'
import { IAnimationRender } from './AnimationRender.module'
export class AnimationAreaModule {
  private nodeKeyToNodeInstance: Map<string, PixiNode>
  private nodeKeyToAreaGfx: Map<string, AreaGfx>
  private graph: AppGraph

  constructor(
    private animationRender: IAnimationRender,
    params: {
      nodeKeyToNodeInstance: Map<string, PixiNode>
      nodeKeyToAreaGfx: Map<string, any>
      graph: AppGraph
    }
  ) {
    this.nodeKeyToNodeInstance = params.nodeKeyToNodeInstance
    this.nodeKeyToAreaGfx = params.nodeKeyToAreaGfx
    this.graph = params.graph
  }

  private getNodeProps = (nodeKey: string) => {
    return this.graph.getNodeAttributes(nodeKey)
  }

  private nodeArea = (nodeKey: string) => {
    return this.nodeKeyToAreaGfx.get(nodeKey)
  }

  private updateArea = (nodeKey: string, options: IArea) => {
    if (
      this.nodeKeyToNodeInstance.has(nodeKey) &&
      this.nodeKeyToAreaGfx.has(nodeKey)
    ) {
      this.nodeArea(nodeKey).updateGfx(options)
      this.graph.getNodeAttributes(nodeKey).area = options
    }
  }

  private wrapAnimationWithRender = (
    nodeKey: string,
    animationCallback: (area: any, options: any) => void
  ) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    const area = this.nodeKeyToAreaGfx.get(node.id)

    if (area && node) {
      this.animationRender.onAnimationStart()

      animationCallback(area.gfx, {
        updateArea: curry(this.updateArea)(nodeKey),
        onAnimationComplete: this.animationRender.onAnimationComplete,
        onAnimationStart: this.animationRender.onAnimationStart,
      })
    }
  }

  public animationImmediately = (nodeKey: string) => {
    this.wrapAnimationWithRender(nodeKey, (areaGfx, options) => {
      this.getNodeProps(nodeKey)?.area?.animationImmediately?.(areaGfx, options)
    })
  }
}
