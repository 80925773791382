import React, { lazy } from 'react'
import { atom } from 'recoil'
import * as Sentry from '@sentry/react'
import { captureException, wrapCreateBrowserRouter } from '@sentry/react'

import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { initialCoin } from './utils/initialCoin'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import ErrorPage from '@clain/core/Router/ErrorPage'
import Root from './app'

const env = config?.ENV
if (env !== 'development') {
  Sentry.init({
    dsn: 'https://458ce858fdc44590af66aa29b1d071fb@o252637.ingest.us.sentry.io/5413542',
    environment: `PROBE-${env}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    tracePropagationTargets: [config?.PLATFORM_URL],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const defaultParams = {
  coin: initialCoin,
  entity: 'txn',
}

export const paramsState = atom({
  key: 'paramsState',
  default: { coin: initialCoin },
})

const errorPage = <ErrorPage captureException={captureException} />

const sentryWrapper = wrapCreateBrowserRouter(
  createBrowserRouter
) as typeof createBrowserRouter

const withSuspense = (Component: React.ComponentType) => (
  <React.Suspense fallback={null}>
    <Component />
  </React.Suspense>
)

const PageBlocked = lazy(() => import('./pages/BlockedPage'))

const routes = sentryWrapper([
  {
    path: '/',
    element: withSuspense(Root),
    errorElement: errorPage,
    children: [
      {
        errorElement: errorPage,
        path: '/',
        element: withSuspense(PageBlocked),
      },
      {
        errorElement: errorPage,
        path: '/*',
        element: withSuspense(PageBlocked),
      },
    ],
  },
])

export default routes
