import { injectable, inject } from 'inversify'
import { CoinType } from '../../../../../types/coin'
import { ENTITY_SERVICES, ENTITY_SERVICES_TYPE } from './constants'
import {
  EntityServiceKeyType,
  EntityServicesInstance,
  EntityServicesType,
  IEntityServices,
} from './types'
import { entityServiceKey } from './utils/entityServiceKey'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class EntityServices implements IEntityServices {
  private services = new Map<EntityServiceKeyType, EntityServicesInstance>()

  constructor(@inject(DI_PROBE_TYPES.Currencies) currencies: Array<CoinType>) {
    this.initServices(currencies)
  }

  private initServices = (currencies: Array<CoinType>) => {
    currencies.forEach((currency: CoinType) => {
      ENTITY_SERVICES_TYPE.forEach((serviceType) => {
        this.services.set(
          entityServiceKey(serviceType, currency),
          new ENTITY_SERVICES[serviceType](currency)
        )
      })
    })
  }

  public getServices = (type: EntityServicesType, currency: CoinType) => {
    return this.services.get(entityServiceKey(type, currency)) as any
  }
}
