import { ContainerModule, interfaces } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IPlotEntities } from '../../models'
import { PlotEntitiesController } from '../../vm/PlotEntitiesController'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IPlotEntities>(DI_PROBE_TYPES.PlotProbeEntites)
    .to(PlotEntitiesController)
    .inTransientScope()
}

export const probePlotEntitiesModules = new ContainerModule(initializeModule)
