import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { MultiDirectedGraph } from '@clain/graph'
import { GraphFactoryEntities } from '@clain/graph-factory-entities'
import { IProbeGraph } from '../../vm/ProbeGraph'
import { theme } from '@platform/modules/theme'
import { LayersViewModel } from '../../vm/LayersViewModel'
import { IProbeState } from '../../vm/ProbeState'
import { ProbeApp } from '../../types/ProbeApp'

export const graphModule = new ContainerModule((bind) => {
  bind<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
    .toDynamicValue(() => null)
    .inRequestScope()

  // Graph core bindings
  bind<IProbeGraph>(DI_PROBE_TYPES.ProbeGraph)
    .toDynamicValue(() => new MultiDirectedGraph())
    .inSingletonScope()

  bind<GraphFactoryEntities<any, any, any, any>>(
    DI_PROBE_TYPES.GraphFactoryEntities
  )
    .toDynamicValue(
      (context) =>
        new GraphFactoryEntities(
          theme,
          context.container.get<IProbeState>(DI_PROBE_TYPES.ProbeState),
          context.container.get<IProbeGraph>(DI_PROBE_TYPES.ProbeGraph),
          context.container.get<LayersViewModel>(DI_PROBE_TYPES.LayersViewModel)
        )
    )
    .inRequestScope()

  // Factory bindings
  bind<
    ReturnType<GraphFactoryEntities<any, any, any, any>['getFactoryEntity']>
  >(DI_PROBE_TYPES.Factory)
    .toDynamicValue((context) => {
      const factoryEntities = context.container.get<
        GraphFactoryEntities<any, any, any, any>
      >(DI_PROBE_TYPES.GraphFactoryEntities)
      return factoryEntities.getFactoryEntity()
    })
    .inTransientScope()

  bind<
    ReturnType<GraphFactoryEntities<any, any, any, any>['getFactoryEntities']>
  >(DI_PROBE_TYPES.FactoryNodeEdge)
    .toDynamicValue((context) => {
      const factoryEntities = context.container.get<
        GraphFactoryEntities<any, any, any, any>
      >(DI_PROBE_TYPES.GraphFactoryEntities)
      return factoryEntities.getFactoryEntities()
    })
    .inTransientScope()
})
