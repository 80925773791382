import {
  action,
  reaction,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  toJS,
} from 'mobx'
import type { CoinType } from '../../../../../types/coin'
import type { Address } from '../../../types/converted/Address'
import type { GraphTransactionUtxo } from '../../../types/converted/GraphTransactionUtxo'
import type { IPaletteController } from '../../PaletteController'
import type { IPlotParentController } from '../../PlotParentController'
import type { IEntityServices } from '../../services/EntitiesServices/types'
import type { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import {
  activeEntityTransactionUtxoState,
  activeEntityTransactionUtxoFiltersState,
} from './TransactionAddressUtxoActiveEntity.states'
import { getTransactionAddressFilteredAndSortedData } from '@platform/components/ProbeSandbox/vm/active-entity/helpers/getTransactionAddressFilteredAndSortedData'
import type {
  TransactionAddressUtxo,
  TransactionAddressUtxoInput,
  TransactionAddressUtxoOutput,
} from '@clain/api/platform/types'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class TransactionAddressUtxoActiveEntity {
  private transactionsVM = activeEntityTransactionUtxoState
  private filtersVM = activeEntityTransactionUtxoFiltersState
  private entityServices: IEntityServices
  private blockchain: CoinType

  @observable public address: Address
  private reactionDisposers: Array<IReactionDisposer> = []

  constructor(
    @inject(DI_PROBE_TYPES.ActiveEntityEvents)
    private activeEntityEvents: IActiveEntityEvents,
    @inject(DI_PROBE_TYPES.PlotParentController)
    private plotParentController: IPlotParentController,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController
  ) {
    makeObservable(this)
  }

  @action
  private getStatsAddress = async ({ addressId, hash }) => {
    if (addressId && hash) {
      const address = await this.entityServices
        .getServices('address', this.blockchain)
        .getStats(addressId)

      if (!this.transactionUtxoAddressData?.hash) return

      this.address = {
        ...this.address,
        ...address,
        address: this.transactionUtxoAddressData.hash,
      }
    }
  }

  @action
  public init = (currency: CoinType, entityServices: IEntityServices) => {
    this.entityServices = entityServices
    this.blockchain = currency

    this.reactionDisposers.push(
      reaction(
        () => ({
          addressId:
            this.transactionUtxoAddressData?.transactionAddress?.addressId,
          hash: this.transactionUtxoAddressData?.hash,
        }),
        ({ addressId, hash }) => this.getStatsAddress({ addressId, hash }),
        { fireImmediately: true }
      )
    )
  }

  @computed
  public get transactionUtxoAddressFilteredData() {
    const { inputs, outputs } = getTransactionAddressFilteredAndSortedData({
      inputs: toJS(this.inputs),
      outputs: toJS(this.outputs),
      currency: toJS(this.currency),
      filters: toJS(this.filters),
    })

    return {
      ...this.transactionUtxoAddressData,
      transaction: {
        ...this.transactionsVM.state?.transaction,
        inputs,
        outputs,
      },
    }
  }

  @computed
  public get transactionUtxoAddressData() {
    return this.transactionsVM.state
  }

  private get inputs() {
    return this.transactionsVM.state?.transaction?.inputs || []
  }

  private get outputs() {
    return this.transactionsVM.state?.transaction?.outputs || []
  }

  private get currency() {
    return this.transactionsVM.state?.transaction?.currency
  }

  public get filters() {
    return this.filtersVM.state
  }

  public get setFilters() {
    return this.filtersVM.updateState
  }

  public get resetFilters() {
    return this.filtersVM.resetState
  }

  public initState = (
    ...args: Parameters<typeof this.transactionsVM.initState>
  ) => {
    this.filtersVM.clearState()
    this.transactionsVM.clearState()
    this.transactionsVM.initState(...args)
  }

  public update = (
    ...args: Parameters<typeof this.transactionsVM.updateState>
  ) => {
    this.transactionsVM.updateState(...args)
  }

  @action
  public clear() {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.filtersVM.clearState()
    this.transactionsVM.clearState()
  }

  @action
  public toggleToken = (
    {
      id,
      tokenTransfers,
      inputs,
      outputs,
    }: Pick<
      GraphTransactionUtxo,
      'id' | 'inputs' | 'outputs' | 'tokenTransfers'
    >,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionTokens',
      [
        {
          id,
          tokenTransfers,
          inputs,
          outputs,
          hash: this.transactionUtxoAddressData.hash,
        },
      ],
      select
    )
  }

  @action
  public toggleTransactionAddress = (
    data: TransactionAddressUtxo,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.toggleAllTransactionAddresses([data], direction, select)
  }

  @action
  public toggleAllTransactionAddresses = async (
    list: Array<TransactionAddressUtxo>,
    direction: 'in' | 'out',
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transactionAddresses',
      list.map((data) => ({
        trxAddressData: data,
        direction,
        hash: this.transactionUtxoAddressData.transaction.hash,
        trxId: this.transactionUtxoAddressData.transaction.id,
      })),
      select
    )
  }

  @action
  public openInput = (data: TransactionAddressUtxoInput) => {
    this.activeEntityEvents.emit(
      'transactionUtxoInput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoAddressData.transaction.hash,
          trxId: this.transactionUtxoAddressData.transaction.id,
        },
      ],
      true
    )
  }

  @action
  public openOutput = (data: TransactionAddressUtxoOutput) => {
    this.activeEntityEvents.emit(
      'transactionUtxoOutput',
      [
        {
          trxAddressData: data,
          hash: this.transactionUtxoAddressData.transaction.hash,
          trxId: this.transactionUtxoAddressData.transaction.id,
        },
      ],
      true
    )
  }

  public plotParent = this.plotParentController.plotParentByActiveEntity

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
