import { pick } from 'ramda'
import { injectable, inject, optional } from 'inversify'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  EventTransactionUTXO,
  IEntitiesMainState,
  EventUtxoTransactionByTrxAddress,
  EventTransactionDirection,
  GenerateEventTransactionUtxo,
  ServerAddEvents,
  LiteTransactionNodeUtxo,
  LiteTransactionAddressUtxo,
  CoinTypeUTXO,
} from '../../types'
import {
  transactionAddressKey,
  getSelfTransactionAddress,
  transactionKey,
  addressKey,
  getTrxAddressUTXO,
} from '../../utils'
import type { IAddedEntities } from '../AddedEntities'
import { AddVirtualNodes } from '../AddVirtualNodes'
import { IPositioningEntities } from '../PositioningEntities'
import { GenerateNode } from './GenerateNode'
import { IAssociateEntity } from '../AssociateEntity'
import type { ICacheModel } from '../CacheModel'
import type { IEntityServices } from '../../models'

@injectable()
export class GenerateNodeTransactionUTXO extends GenerateNode<EventTransactionUTXO> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity,
    @inject(GRAPH_ENTITIES_TYPES.CacheModel)
    private cacheModel: ICacheModel,
    @inject(GRAPH_ENTITIES_TYPES.EntityServices)
    @optional()
    private entityServices: IEntityServices
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  private checkInputsOutputs = async (
    {
      inputs,
      outputs,
      hash,
      id,
    }: Pick<LiteTransactionNodeUtxo, 'inputs' | 'outputs' | 'hash' | 'id'>,
    currency: CoinTypeUTXO
  ) => {
    if ((inputs.length && outputs.length) || !this.entityServices) {
      return { inputs, outputs, id }
    }

    const transaction = await this.cacheModel.withCache(
      ['transaction', currency],
      this.entityServices.getTransactionUtxo(currency),
      hash
    )

    return pick(['inputs', 'outputs', 'id'], transaction)
  }

  private getTrxDataByTrxAddressKey = (
    data: EventUtxoTransactionByTrxAddress
  ) => {
    const { direction, trxAddressData, currency } = data

    const selectTrxAddress =
      trxAddressData?.[direction === 'in' ? 'previous' : 'next']
    return {
      currency,
      hash: selectTrxAddress.trxHash,
      id: selectTrxAddress.trxId,
      nodeKey: transactionAddressKey(trxAddressData),
      inputs: [],
      outputs: [],
    }
  }

  private normalizeCreateByData = (data: EventTransactionUTXO) => {
    if (data.createBy === 'by-trxAddress') {
      return this.getTrxDataByTrxAddressKey(data)
    }

    return { ...data, nodeKey: null }
  }

  private haveOneOfTransactionAddress = (
    transactionAddresses: LiteTransactionAddressUtxo[]
  ) => {
    if (!transactionAddresses?.length) return

    if (transactionAddresses.length) {
      for (const transactionAddress of transactionAddresses) {
        if (this.isNodeExists(transactionAddressKey(transactionAddress))) {
          return transactionAddress
        }
      }
    }
  }

  private selectTransactionAddress = (
    inputs: LiteTransactionAddressUtxo[],
    outputs: LiteTransactionAddressUtxo[]
  ): {
    select: LiteTransactionAddressUtxo
    input: LiteTransactionAddressUtxo
    output: LiteTransactionAddressUtxo
    direction: EventTransactionDirection | null
  } => {
    const selectedInputAddress = this.haveOneOfTransactionAddress(inputs)
    const selectedOutputAddress = this.haveOneOfTransactionAddress(outputs)

    return {
      select: selectedInputAddress ?? selectedOutputAddress,
      input: selectedInputAddress,
      output: selectedOutputAddress,
      direction: selectedInputAddress
        ? 'out'
        : selectedOutputAddress
          ? 'in'
          : null,
    }
  }

  public produce = async (
    ...params: Parameters<GenerateEventTransactionUtxo['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params

    let { direction } = data
    const { currency, nodeKey, hash, ...rest } =
      this.normalizeCreateByData(data)

    const { id, inputs, outputs } = await this.checkInputsOutputs(
      { hash, ...rest },
      currency
    )

    const nodes = this.nodes({ meta })

    const selectedTransactionAddress = this.selectTransactionAddress(
      inputs,
      outputs
    )

    const selfTransactionAddressData =
      selectedTransactionAddress?.select ??
      getSelfTransactionAddress({ inputs, outputs }, direction)

    const selfTransactionAddressKey =
      nodeKey ?? transactionAddressKey(selfTransactionAddressData)

    direction = nodeKey
      ? direction
      : (selectedTransactionAddress?.direction ?? direction)

    const position =
      params[0]?.options?.position ??
      this.positioningEntities.run('utxo-transaction', {
        sync: !!nodeKey || !!selectedTransactionAddress?.select,
        forcePivotPosition: this.getNodePosition(selfTransactionAddressKey),
        direction,
      })

    const trxKey = transactionKey({ hash })

    if (!this.isNodeExists(trxKey)) {
      nodes.push({
        type: 'add_node',
        key: trxKey,
        data: {
          id,
          position: position.transaction,
          currency: currency,
          type: 'utxo_transaction',
        },
      })
    }

    if (!this.isNodeExists(selfTransactionAddressKey)) {
      nodes.push({
        type: 'add_node',
        key: selfTransactionAddressKey,
        data: {
          id,
          position: position[direction === 'in' ? 'output' : 'input'],
          currency: currency,
          type: 'utxo_transaction_address',
          nodeData: {
            ...pick(['position', 'ioType', 'id'], selfTransactionAddressData),
            addressType: 'transaction',
          },
        },
      })

      nodes.push(
        ...this.associateEntity.transactionAddressWithExistingAddress(
          selfTransactionAddressKey,
          addressKey({ address: selfTransactionAddressData.address, currency })
        )
      )
    }

    if (direction === 'out') {
      const outputsData = selectedTransactionAddress?.output
        ? [selectedTransactionAddress.output]
        : getTrxAddressUTXO(outputs, direction)

      if (outputsData.length) {
        outputsData.forEach((outputData) => {
          const trxAddressKey = transactionAddressKey(outputData)

          if (!this.isNodeExists(trxAddressKey)) {
            nodes.push({
              type: 'add_node',
              key: trxAddressKey,
              data: {
                id,
                position: position.output,
                currency: currency,
                type: 'utxo_transaction_address',
                nodeData: {
                  ...pick(['position', 'ioType', 'id'], outputData),
                  position: outputData.position,
                  addressType: 'transaction',
                },
              },
            })
            nodes.push(
              ...this.associateEntity.transactionAddressWithExistingAddress(
                trxAddressKey,
                addressKey({ address: outputData.address, currency })
              )
            )
          }
        })
      }
    }
    if (direction === 'in') {
      const inputsData = selectedTransactionAddress?.input
        ? [selectedTransactionAddress.input]
        : getTrxAddressUTXO(inputs, direction)

      if (inputsData.length) {
        inputsData.forEach((inputData) => {
          const trxAddressKey = transactionAddressKey(inputData)

          if (!this.isNodeExists(trxAddressKey)) {
            nodes.push({
              type: 'add_node',
              key: trxAddressKey,
              data: {
                id,
                position: position.input,
                currency: currency,
                type: 'utxo_transaction_address',
                nodeData: {
                  ...pick(['position', 'ioType', 'id'], inputData),
                  addressType: 'transaction',
                },
              },
            })
            nodes.push(
              ...this.associateEntity.transactionAddressWithExistingAddress(
                trxAddressKey,
                addressKey({ address: inputData.address, currency })
              )
            )
          }
        })
      }
    }

    return nodes.acc
  }
}
