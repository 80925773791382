import 'reflect-metadata'

import { _EdgeData, _NodeData, EdgeData, NodeData } from '../types'
import {
  IFactoryNodeEdge,
  ILayers,
  ILayoutSettingsState,
  IProbeGraph,
  IProbeState,
  ITheme,
} from '../models'
import { graphFactoryEntitiesContainer } from './container'
import { DI_TYPES } from './DITypes'
import { Factory } from '../modules/Factory'

export class GraphFactoryEntities<
  TNodeData extends NodeData,
  _TNodeData extends _NodeData,
  TEdgeData extends EdgeData,
  _TEdgeData extends _EdgeData,
> {
  private _factory: Factory
  private _layoutSettingsState: ILayoutSettingsState
  private _factoryNodeEdge: IFactoryNodeEdge<
    TNodeData,
    _TNodeData,
    TEdgeData,
    _TEdgeData
  >

  constructor(
    theme: ITheme,
    probeState: IProbeState,
    probeGraph: IProbeGraph,
    layers: ILayers
  ) {
    if (!graphFactoryEntitiesContainer.isBound(DI_TYPES.Theme)) {
      graphFactoryEntitiesContainer
        .bind<ITheme>(DI_TYPES.Theme)
        .toConstantValue(theme)
    } else {
      graphFactoryEntitiesContainer
        .rebind<ITheme>(DI_TYPES.Theme)
        .toConstantValue(theme)
    }

    if (!graphFactoryEntitiesContainer.isBound(DI_TYPES.ProbeState)) {
      graphFactoryEntitiesContainer
        .bind<IProbeState>(DI_TYPES.ProbeState)
        .toConstantValue(probeState)
    } else {
      graphFactoryEntitiesContainer
        .rebind<IProbeState>(DI_TYPES.ProbeState)
        .toConstantValue(probeState)
    }

    if (!graphFactoryEntitiesContainer.isBound(DI_TYPES.ProbeGraph)) {
      graphFactoryEntitiesContainer
        .bind<IProbeGraph>(DI_TYPES.ProbeGraph)
        .toConstantValue(probeGraph)
    } else {
      graphFactoryEntitiesContainer
        .rebind<IProbeGraph>(DI_TYPES.ProbeGraph)
        .toConstantValue(probeGraph)
    }

    if (!graphFactoryEntitiesContainer.isBound(DI_TYPES.Layers)) {
      graphFactoryEntitiesContainer
        .bind<ILayers>(DI_TYPES.Layers)
        .toConstantValue(layers)
    } else {
      graphFactoryEntitiesContainer
        .rebind<ILayers>(DI_TYPES.Layers)
        .toConstantValue(layers)
    }

    this._factory = graphFactoryEntitiesContainer.get<Factory>(DI_TYPES.Factory)
    this._layoutSettingsState =
      graphFactoryEntitiesContainer.get<ILayoutSettingsState>(
        DI_TYPES.LayoutSettingsState
      )
    this._factoryNodeEdge = graphFactoryEntitiesContainer.get<
      IFactoryNodeEdge<TNodeData, _TNodeData, TEdgeData, _TEdgeData>
    >(DI_TYPES.FactoryNodeEdge)
  }

  public getFactoryEntities = () => {
    return this._factoryNodeEdge
  }

  public layoutSettingsState = () => {
    return this._layoutSettingsState
  }

  public getFactoryEntity = () => {
    return this._factory
  }
}
