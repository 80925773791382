import { injectable } from 'inversify'

import { NodeAttributes } from '@clain/graph'
import {
  icon,
  iconBlockchain,
} from '@caudena/web-platform/dist/components/Icon/iconFn'

import ProbeNode from './ProbeNode'

import { UnsupportedAddressNodeData } from '../../types'
import { ILinkType } from '@clain/graph/src/types'

const CORE_RADIUS = 22
const CORE_BACKGROUND_COLOR = '#fff'
const LABEL_ORBIT_WIDTH = 40
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const SCORE_ORBIT_WIDTH = 4
const GHOSTED_OPACITY = 0.3

@injectable()
export class UnsupportedAddressNode<
  T extends UnsupportedAddressNodeData = UnsupportedAddressNodeData,
> extends ProbeNode<T> {
  public linkType: ILinkType = 'master'

  protected generateAttributes() {
    const iconColor = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ])
    const iconSize = parseInt(this.theme.getToken(['icon', 'md', 'size']))

    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      locked: this.locked === true ? true : false,
      shape: 'circle',
      linkType: 'master',
      icon: icon({ variant: 'pin' }),
      iconColor,
      iconWidth: iconSize,
      iconHeight: iconSize,
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
      orbits: [
        {
          size: CORE_RADIUS,
          border: {
            color: this.theme.getToken([
              'color',
              'on',
              'background',
              'variant3',
            ]),
            opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
            width: SCORE_ORBIT_WIDTH,
          },
        },
        {
          size: CORE_RADIUS + 3,
          locations: [
            {
              angle: Math.PI * 0.9,
              satellite: {
                type: 'icon',
                icon: iconBlockchain({ variant: this.data.unsupportedCurrency })
                  ? iconBlockchain({ variant: this.data.unsupportedCurrency })
                  : icon({ variant: 'blockchainDefault' }),
                width: 20,
                height: 20,
                fill: this.layoutSettingsState.state.graphBackgroundColor,
                shape: 'circle',
                border: {
                  width: 2,
                  color: this.layoutSettingsState.state.graphBackgroundColor,
                },
              },
            },
          ],
          virtual: true,
        },
        {
          size: LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
              },
            },
          ],
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    if (this.layers.addressLabel) {
      attributes.orbits[2].locations[0].satellite = {
        type: 'label',
        text: this.data.address.slice(0, 10),
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [4, 2],
      }
    }

    return attributes
  }
}
