import { action, makeObservable } from 'mobx'
import { getBoundedDomainBlock } from '../../../utils/getDomainBlock'
import {
  EdgeClickPayload,
  EdgeClickStrategyInterface,
  EdgeClickStrategyType,
  GraphEntityEvent,
} from '../GraphEntityEvent.types'
import { EntityEventController } from './EntityEventController'
import { IEntititiesGhosted } from '@platform/components/ProbeSandbox/models'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from '@platform/components/ProbeSandbox/vm/ProbeState'
import { CircularMenuViewModel } from '../../CircularMenuViewModel'
import { ActiveEntityViewModel } from '../../active-entity/ActiveEntityViewModel'
import { ProbeApp } from '@platform/components/ProbeSandbox/types/ProbeApp'
import { getProbeModule } from '@platform/components/ProbeSandbox/di'

class EdgeClickStrategy implements EdgeClickStrategyInterface {
  constructor(private action: EdgeClickStrategyInterface['handle']) {}

  handle(
    ...payload: Parameters<EdgeClickStrategyInterface['handle']>
  ): ReturnType<EdgeClickStrategyInterface['handle']> {
    this.action(...payload)
  }
}

@injectable()
export class EdgeEventsController {
  private strategies: Record<EdgeClickStrategyType, EdgeClickStrategy>

  private handleTransactionBlockClick = (
    transactionBlock: { nodeKeys: Array<string>; edgeKeys: Array<string> },
    isExpanding: boolean | undefined,
    selectedNodeIds: Set<string>,
    selectedEdgeIds: Set<string>
  ) => {
    const nodeKey = transactionBlock.nodeKeys[0]
    if (isExpanding) {
      const transactionBlockIsAlreadySelected =
        selectedNodeIds.has(nodeKey) &&
        transactionBlock.edgeKeys.every((key) => selectedEdgeIds.has(key))
      transactionBlock.edgeKeys.forEach((key) =>
        selectedEdgeIds[transactionBlockIsAlreadySelected ? 'delete' : 'add'](
          key
        )
      )
      selectedNodeIds[transactionBlockIsAlreadySelected ? 'delete' : 'add'](
        nodeKey
      )
    } else {
      this.probeState.setSelectedNodeIds(new Set([nodeKey]))
      this.probeState.setSelectedEdgeIds(new Set(transactionBlock.edgeKeys))
    }
  }

  private handleRegularClick = (
    isExpanding: boolean | undefined,
    id: string,
    selectedEdgeIds: Set<string>
  ) => {
    if (isExpanding) {
      selectedEdgeIds[selectedEdgeIds.has(id) ? 'delete' : 'add'](id)
    } else {
      this.probeState.setSelectedNodeIds(new Set())
      this.probeState.setSelectedEdgeIds(new Set([id]))
    }
  }

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.CircularMenuViewModel)
    private circularMenuController: CircularMenuViewModel,
    @inject(DI_PROBE_TYPES.ActiveEntityViewModel)
    private activeEntity: ActiveEntityViewModel,
    @inject(DI_PROBE_TYPES.EntityEventController)
    private entityEventController: EntityEventController,
    @inject(DI_PROBE_TYPES.EntititiesGhosted)
    private entitiesGhosted: IEntititiesGhosted
  ) {
    makeObservable(this)
    this.strategies = {
      rightClick: new EdgeClickStrategy(this.openEdgeMenu),
      leftClick: new EdgeClickStrategy(() => this.activeEntity?.detectType()),
    }
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  private openEdgeMenu = (param: GraphEntityEvent<EdgeClickPayload>) => {
    if (!this.probeState.edges.has(param.payload.id)) {
      return
    }

    const toWordCoordinates = this.app.toWorldCoordinates({
      x: param.domEvent.offsetX,
      y: param.domEvent.offsetY,
    })

    this.circularMenuController.open(
      toWordCoordinates,
      this.probeState.edges.get(param.payload.id).key
    )
  }

  @action
  public onClick = (param: GraphEntityEvent<EdgeClickPayload>) => {
    const {
      payload: { id, isExpanding },
      domEvent,
    } = param
    const isRightClick = domEvent.button === 2
    const isLeftClick = domEvent.button === 0

    const isSimpleLeftClick =
      isLeftClick &&
      !domEvent.shiftKey &&
      !domEvent.ctrlKey &&
      !domEvent.metaKey &&
      !domEvent.altKey

    const { selectedNodeIds, selectedEdgeIds } = this.probeState
    const transactionBlock = getBoundedDomainBlock(this.app.graph, id)
    if (isRightClick && !this.probeState.selectedEdgeIds.has(id)) {
      const forceExpanding =
        this.entityEventController.prevEvent !== 'rightClick'

      this.entitiesGhosted.toggleVisibleEntities({
        edgeKeys: [id],
        isExpanding: isExpanding || forceExpanding,
      })

      if (transactionBlock) {
        this.handleTransactionBlockClick(
          transactionBlock,
          isExpanding || forceExpanding,
          selectedNodeIds,
          selectedEdgeIds
        )
      } else {
        this.handleRegularClick(
          isExpanding || forceExpanding,
          id,
          selectedEdgeIds
        )
      }
    }

    if (isLeftClick) {
      this.entitiesGhosted.toggleVisibleEntities({
        edgeKeys: [id],
        isExpanding: isExpanding,
      })

      if (transactionBlock) {
        this.handleTransactionBlockClick(
          transactionBlock,
          isExpanding,
          selectedNodeIds,
          selectedEdgeIds
        )
      } else {
        this.handleRegularClick(isExpanding, id, selectedEdgeIds)
      }
    }

    const strategyKey: EdgeClickStrategyType = isSimpleLeftClick
      ? 'leftClick'
      : isRightClick
        ? 'rightClick'
        : null

    if (strategyKey) {
      this.strategies[strategyKey].handle(param)
    }
    this.entityEventController.setPrevEvent(strategyKey)
  }

  @action
  public onMouseOver = ({
    payload: { id },
  }: GraphEntityEvent<EdgeClickPayload>) => {
    this.probeState.edges.get(id).setHovered(true)
  }

  @action
  public onMouseOut = ({
    payload: { id },
  }: GraphEntityEvent<EdgeClickPayload>) => {
    this.probeState.edges.get(id).setHovered(false)
  }
}
