import React, { useMemo } from 'react'
import { NavMenuItem } from '@clain/core/Layout/NavMenu'
import CoinSelect from './components/CoinSelect'
import { SearchEntities } from './components/SearchEntities'
import { CoinTypeUTXO } from './types/coin'
import { UTXO_COINS } from '@clain/core/utils/currency'
import { ReactComponent as DashboardIcon } from '@clain/core/assets/dashboard_outline.svg'
import { ReactComponent as ExplorerIcon } from '@clain/core/assets/view.svg'
import { ReactComponent as DocumentationIcon } from '@clain/core/assets/doc.svg'
import { ReactComponent as TxnIcon } from '@clain/core/assets/txn.svg'
import { ReactComponent as CasesIcon } from '@clain/core/assets/cases_new.svg'
import { Gear, Icon } from '@clain/core/ui-kit'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import { blockchainFilterPathSchema } from './constants'

import { useTranslation } from '@clain/core/utils/i18next'

export const useNavItems = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      [
        {
          label: t('platform:blockchain'),
          component: CoinSelect,
        },
        {
          label: t('platform:cases'),
          to: '/cases',
          icon: <CasesIcon />,
        },
        {
          label: t('platform:dashboard'),
          to: '/:coin/dashboard',
          icon: <DashboardIcon />,
        },
        {
          label: t('platform:search'),
          component: SearchEntities,
        },
        {
          label: t('platform:explorer'),
          to: '/:coin/explorer',
          icon: <ExplorerIcon />,
        },
        {
          label: t('platform:bulkSearch'),
          to: '/search',
          icon: <TxnIcon />,
        },
        {
          label: t('platform:blockchainFilter'),
          to: blockchainFilterPathSchema,
          icon: <Icon variant={'TxnFilter'} />,
        },
        {
          label: t('platform:settings'),
          to: '/settings',
          icon: <Gear />,
        },
        {
          label: t('platform:docs'),
          external: true,
          to: config.DOCS_URL,
          icon: <DocumentationIcon />,
        },
      ] satisfies NavMenuItem[],
    [t]
  )
}
