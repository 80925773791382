import { injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import type { SearchResults } from '../../services/SearchService'
import type { ISearchState } from './SearchState.types'
import { FlowId } from '../../ProbeEvents'

@injectable()
export class SearchState implements ISearchState {
  @observable public isActive = false
  @observable public searchQuery = ''
  @observable public searchEntitiesResults: Array<SearchResults> = []
  @observable public searchBlockchainsResults: Array<SearchResults> = []
  @observable public searchEntitiesInProgress = false
  @observable public searchBlockchainsInProgress = false
  @observable public addMultipleNodesId: FlowId

  constructor() {
    makeObservable(this)
  }

  @action
  public close = () => {
    this.isActive = false
  }

  @action
  public open = () => {
    this.isActive = true
  }

  @action
  public setSearchQuery(query: string) {
    this.searchQuery = query
  }

  @action
  public setSearchEntitiesResults(results: Array<SearchResults>) {
    this.searchEntitiesResults = results
  }

  @action
  public setSearchBlockchainsResults(results: Array<SearchResults>) {
    this.searchBlockchainsResults = results
  }

  @action
  public setSearchEntitiesInProgress(inProgress: boolean) {
    this.searchEntitiesInProgress = inProgress
  }

  @action
  public setSearchBlockchainsInProgress(inProgress: boolean) {
    this.searchBlockchainsInProgress = inProgress
  }

  @action
  public setAddMultipleNodesId(id: FlowId) {
    this.addMultipleNodesId = id
  }
}
