import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateEdge } from './GenerateEdge'

import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventTransactionEVM,
  IEntitiesMainState,
  IEntitiesGraph,
  ServerAddEvents,
} from '../../types'
import { transactionKey, addressKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeTransactionInternalsEVM extends GenerateEdge<EventTransactionEVM> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionEVM>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { id, hash, currency } = data
    const edges = this.edges({ meta })

    const trxKey = transactionKey({ hash })

    data?.internals?.forEach((internal) => {
      const addressFromKey = addressKey({
        address: internal.sender.address,
        currency,
      })
      const addressToKey = addressKey({
        address: internal.receiver.address,
        currency,
      })
      const fromKey =
        edgeKey(addressFromKey, trxKey) +
        (internal?.index === undefined ? '' : internal.index)
      const toKey =
        edgeKey(addressToKey, trxKey) +
        (internal?.index === undefined ? '' : internal.index)

      if (
        !this.isEdgeExists(fromKey) &&
        this.isNodeExists(addressFromKey) &&
        !this.inNodeExistsEdges(addressFromKey)
      ) {
        edges.push({
          type: 'add_edge',
          key: fromKey,
          data: {
            srcKey: addressFromKey,
            dstKey: trxKey,
            type: 'evm_transaction',
            edgeData: {
              type: 'internal',
              trxId: id,
              index: internal.index,
            },
          },
        })
      }

      if (
        !this.isEdgeExists(toKey) &&
        this.isNodeExists(addressToKey) &&
        !this.inNodeExistsEdges(addressToKey)
      ) {
        edges.push({
          type: 'add_edge',
          key: toKey,
          data: {
            srcKey: trxKey,
            dstKey: addressToKey,
            type: 'evm_transaction',
            edgeData: {
              type: 'internal',
              trxId: id,
              index: internal.index,
            },
          },
        })
      }
    })

    return edges.acc
  }
}
