import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'
import ProbeNode from './ProbeNode'
import { action, makeObservable, observable } from 'mobx'
import {
  createCrossChainAction,
  createDemixActionAddon,
  createNodeCreateAtLabel,
} from './ProbeNode.utils'
import {
  ILayers,
  ILayoutSettingsState,
  IProbeGraph,
  IProbeState,
  ITheme,
  ITransactionProbeNodeBase,
} from '../../models'
import { NodeSettings, TransactionNodeDataEvm } from '../../types'
import type { Position } from '@clain/graph-entities'
import { ILinkType } from '@clain/graph/src/types'

const CORE_RADIUS = 5
const CORE_BACKGROUND_COLOR = 'rgba(55, 71, 102, 1)'
const CORE_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

@injectable()
export class TransactionProbeNodeEvm<
    T extends TransactionNodeDataEvm = TransactionNodeDataEvm,
  >
  extends ProbeNode<T>
  implements ITransactionProbeNodeBase
{
  @observable public demixActionHovered: boolean
  @observable public crossSwapActionHovered: boolean
  public linkType: ILinkType = 'master'

  constructor(
    theme: ITheme,
    layers: ILayers,
    layoutSettingsState: ILayoutSettingsState,
    probeState: IProbeState,
    graph: IProbeGraph,
    id: string,
    data: T,
    position: Position,
    settings?: NodeSettings
  ) {
    super(
      theme,
      layers,
      layoutSettingsState,
      probeState,
      graph,
      id,
      data,
      position,
      settings
    )
    makeObservable(this)
  }

  @action
  public setDemixActionHovered(isHovered: boolean) {
    this.demixActionHovered = isHovered
  }

  @action
  public setCrossSwapActionHovered(isHovered: boolean) {
    this.crossSwapActionHovered = isHovered
  }

  protected generateAttributes() {
    const attributes = {
      size: CORE_RADIUS,
      increaseHitArea: 16,
      fill: CORE_BACKGROUND_COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      locked: this.locked === true ? true : false,
      shape: 'circle',
      linkType: 'master',
      orbits: [
        {
          size: CORE_RADIUS + 16,
          locations: [
            {
              angle: -Math.PI / 2,
              satellite: this.data.crossSwap
                ? createCrossChainAction(
                    this.crossSwapActionHovered,
                    this.theme
                  )
                : {
                    id: 'demixAction',
                    type: 'label',
                    ...createDemixActionAddon({
                      theme: this.theme,
                      count: this.data?.demixTracks?.length || 0, // TODO use this.data to achieve real count
                      isHovered: this.demixActionHovered,
                    }),
                  },
            },
          ],
          virtual: true,
        },
        {
          size: -18,
          locations: [
            {
              angle: -Math.PI / 2,
              satellite: {
                id: 'createAt',
                type: 'label',
              },
            },
          ],
          virtual: true,
        },
      ],
    } as NodeAttributes<T>

    if (this.layers.trxTimestamp && this.data.time) {
      attributes.orbits[1].locations[0].satellite = {
        id: 'createAt',
        type: 'label',
        ...createNodeCreateAtLabel({
          date: this.data.time,
          timezone: this.userSettingsTimezone,
        }),
      }
    }

    if (this.highlighted) {
      attributes.fill = CORE_BACKGROUND_COLOR_HIGHLIGHTED
    }

    return attributes
  }
}
