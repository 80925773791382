import { observable, computed, makeObservable, action } from 'mobx'
import {
  HistoryActionType,
  SnapshotCommand,
  SnapshotsCommand,
} from '../../types/history'
import { MAX_SNAPSHOTS } from './GraphHistory.constants'
import { removeNodeByKey } from './GraphHistory.utils'
import { injectable } from 'inversify'

@injectable()
export class GraphHistoryState {
  @observable private history: SnapshotsCommand = []
  @observable private redos: SnapshotsCommand = []
  @observable private _processingActionType: HistoryActionType | null = null

  constructor() {
    makeObservable(this)
  }

  @action
  public push = (snapshot: SnapshotCommand) => {
    if (!snapshot.length) return

    this.history.push(snapshot)

    if (MAX_SNAPSHOTS < this.history.length) {
      this.history.shift()
    }

    if (this.redos.length) {
      this.redos = []
    }
  }

  @action
  public removeNodeInSnapshot = (key: string) => {
    if (this.history.length) {
      this.history = removeNodeByKey(this.history, key)
    }
    if (this.redos.length) {
      this.redos = removeNodeByKey(this.redos, key)
    }
  }

  @action
  public undo = () => {
    const snapshots = this.history
    if (!snapshots.length) {
      return []
    }

    const snapshot = snapshots.pop()
    this.redos.push(snapshot)

    return snapshot.map((event) => {
      return Array.isArray(event) ? event[0] : event
    })
  }

  @action
  public redo = () => {
    const snapshots = this.redos
    if (!snapshots.length) {
      return []
    }

    const snapshot = snapshots.pop()
    this.history.push(snapshot)

    return snapshot.map((event) => {
      return Array.isArray(event) ? event[1] : event
    })
  }

  @computed
  public get isDisabledUndo(): boolean {
    return !this.history.length || this.processingActionType !== null
  }

  @computed
  public get isDisabledRedo(): boolean {
    return !this.redos.length || this.processingActionType !== null
  }

  @computed
  public get isEmptyHistory(): boolean {
    return !this.history.length && !this.redos.length
  }

  @computed
  public get processingActionType() {
    return this._processingActionType
  }

  @action
  public setProcessingActionType(type: HistoryActionType | null) {
    this._processingActionType = type
  }
}
