import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { GraphTransactionUtxo } from '@platform/components/ProbeSandbox/types/converted/GraphTransactionUtxo'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
import { TransactionInputOutputUtxo } from '@clain/api/platform/types'
export const activeEntityTransactionUtxoState = new StateViewModel<{
  hash: string
  transaction: GraphTransactionUtxo
  key: string
  transactionAddress: TransactionInputOutputUtxo
}>()
export const activeEntityTransactionUtxoFiltersState =
  new StateViewModel<TransactionAddressUtxoFilters>({
    inputSortBy: 'amount',
    inputAmountFrom: '0',
    outputAmountFrom: '0',
    outputSortBy: 'amount',
    scoreFrom: 1,
    scoreTo: 10,
  })
