import { action, makeObservable } from 'mobx'
import { EdgeEventsController } from './controllers/EdgeEventsController'
import { NodeEventsController } from './controllers/NodeEventsController'
import {
  EdgeClickPayload,
  EntitySelectPayload,
  GraphEntityEvent,
  NodeClickPayload,
} from './GraphEntityEvent.types'
import { EntitySelectController } from './controllers/EntitySelectController'
import PixiEvent from '@clain/graph/src/core/PixiEvent'
import { WorldEventsController } from './controllers/WorldEventsController'
import { EntityEventController } from './controllers/EntityEventController'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class GraphEntityEventFacade {
  constructor(
    @inject(DI_PROBE_TYPES.NodeEventsController)
    private nodeClickController: NodeEventsController,
    @inject(DI_PROBE_TYPES.EdgeEventsController)
    private edgeClickController: EdgeEventsController,
    @inject(DI_PROBE_TYPES.WorldEventsController)
    private worldEventsController: WorldEventsController,
    @inject(DI_PROBE_TYPES.EntitySelectController)
    private entitySelectController: EntitySelectController,
    @inject(DI_PROBE_TYPES.EntityEventController)
    private entityEventController: EntityEventController
  ) {
    makeObservable(this)
  }

  //// NODES HANDLERS ////
  @action
  public handleNodeClick = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.onClick(event)
  }

  @action
  public handleNodeMouseOver = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseOver(event)
  }

  @action
  public handleNodeMouseOut = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseOut(event)
  }

  @action
  public handleNodeMouseDown = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseDown(event)
  }

  @action
  public handleNodeMouseUp = (event: GraphEntityEvent<NodeClickPayload>) => {
    this.nodeClickController.mouseUp(event)
  }

  //// EDGES HANDLERS ////
  @action
  public handleEdgeClick = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onClick(event)
  }

  @action
  public handleEdgeMouseOver = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onMouseOver(event)
  }

  @action
  public handleEdgeMouseOut = (event: GraphEntityEvent<EdgeClickPayload>) => {
    this.edgeClickController.onMouseOut(event)
  }
  //// ENTITY HANDLERS ////
  @action
  public handleSelectArea = (event: GraphEntityEvent<EntitySelectPayload>) => {
    this.entitySelectController.onSelectArea(event)
    this.entityEventController.setPrevEvent(null)
  }

  @action
  public handleUnSelectArea = (
    event: GraphEntityEvent<EntitySelectPayload>
  ) => {
    this.entitySelectController.onUnSelectArea(event)
    this.entityEventController.setPrevEvent(null)
  }

  //// WORLD HANDLERS ////
  @action
  public handleWorldClick = (event: PixiEvent<unknown>) => {
    this.worldEventsController.onClick(event)
  }
}
