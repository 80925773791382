import { injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
} from '../ProbeViewModel'
import { ICameraState } from '../../models/ICameraState'

@injectable()
export class CameraState implements ICameraState {
  @observable private _x: number = DEFAULT_X_COORDINATE
  @observable private _y: number = DEFAULT_Y_COORDINATE
  private _prev_x: number = DEFAULT_X_COORDINATE
  private _prev_y: number = DEFAULT_Y_COORDINATE
  @observable private _zoom: number = DEFAULT_ZOOM

  constructor() {
    makeObservable(this)
  }

  @computed public get x() {
    return this._x
  }

  @computed public get y() {
    return this._y
  }

  @computed public get zoom() {
    return this._zoom
  }

  @action
  public setCoordinates(x: number, y: number) {
    this._x = x
    this._y = y
  }

  @action
  public setZoom(zoom: number) {
    this._zoom = zoom
  }

  @action
  public setPrevCoordinates(x: number, y: number) {
    this._prev_x = x
    this._prev_y = y
  }

  public get prevCoordinates() {
    return { x: this._prev_x, y: this._prev_y }
  }
}
