import { injectable, inject } from 'inversify'

import { GenerateEdge } from './GenerateEdge'
import type { IAddedEntities } from '../AddedEntities'
import { AddVirtualNodes } from '../AddVirtualNodes'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventCluster,
  IEntitiesMainState,
  IEntitiesGraph,
  EventClusterFlow,
  ServerAddEvents,
} from '../../types'
import { addressKey, clusterKey, edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeFlow extends GenerateEdge<EventCluster> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventClusterFlow>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { currency } = data
    const edges = this.edges({ meta })

    const selectedEntityData = this.probeState.selectedNode

    let selectedKey = null

    if (selectedEntityData) {
      selectedKey = selectedEntityData.key
    } else if (data?.targetClusterId) {
      selectedKey = clusterKey({ clusterId: data.targetClusterId }, currency)
    } else if (data?.targetAddress) {
      selectedKey = addressKey({ address: data.targetAddress, currency })
    }

    if (!selectedKey) return edges.acc

    const currentKey = clusterKey({ clusterId: data.clusterId }, currency)
    if (
      !this.isEdgeExists(edgeKey(selectedKey, currentKey)) &&
      data?.totalOutUsd
    ) {
      edges.push({
        type: 'add_edge',
        key: edgeKey(selectedKey, currentKey),
        data: {
          srcKey: selectedKey,
          dstKey: currentKey,
          type: 'flow',
          edgeData: {
            tokenId: data?.tokenId,
            color: data?.color,
          },
        },
      })
    }

    if (
      !this.isEdgeExists(edgeKey(currentKey, selectedKey)) &&
      data?.totalInUsd
    ) {
      edges.push({
        type: 'add_edge',
        key: edgeKey(currentKey, selectedKey),
        data: {
          srcKey: currentKey,
          dstKey: selectedKey,
          type: 'flow',
          edgeData: {
            tokenId: data?.tokenId,
            color: data?.color,
          },
        },
      })
    }

    return edges.acc
  }
}
