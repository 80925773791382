import { interfaces } from 'inversify'
import {
  IModuleStage,
  InstanceClearFunction,
  InstanceStage,
  InstanceType,
} from './ModuleStage.types'

export class ModuleStage implements IModuleStage {
  private stage = new Map<symbol, InstanceStage<any>>()

  constructor(private container: interfaces.Container) {}

  getModule<T extends InstanceClearFunction>(
    key: symbol,
    instanceType: InstanceType = 'container',
    disabledRefCount: boolean = false
  ): InstanceStage<T> {
    if (this.stage.has(key)) {
      const moduleStage = this.stage.get(key)!
      if (!disabledRefCount) {
        moduleStage.refCount++
      }
    } else {
      this.stage.set(key, {
        instance: this.container.get<T>(key),
        refCount: 1,
        dispose: (...args) => {
          const existingStage = this.stage.get(key)

          if (existingStage) {
            existingStage.refCount--
            if (existingStage.refCount === 0) {
              const instance =
                instanceType === 'container'
                  ? this.container.get<T>(key)
                  : existingStage?.instance
              instance?.clear?.(...args)
              this.stage.delete(key)
            }
          }
        },
      })
    }

    return {
      instance:
        instanceType === 'container'
          ? this.container.get<T>(key)
          : this.stage.get(key)!.instance,
      refCount: this.stage.get(key)!.refCount,
      dispose: this.stage.get(key)!.dispose,
    }
  }

  disposeAll() {
    this.stage.forEach((moduleStage, key) => {
      moduleStage.refCount = 1
      const instance = this.container.get<any>(key)
      instance?.clear?.()
      moduleStage.dispose()
    })
    this.stage.clear()
  }
}
