import { GraphTransactionUtxo } from '../../../../types/converted/GraphTransactionUtxo'
import {
  ExplorerServiceSchema,
  EXPLORER_SERVICE_CONFIG,
  getChannelKeyExplorer,
} from './ExplorerServiceSchema'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { CoinType } from '../../../../../../types/coin'
import { GraphTransactionEvm } from '@clain/api/platform/types/Transactions'

export class ExplorerService extends ExplorerServiceSchema {
  private currency: CoinType

  constructor(currency: CoinType) {
    super({
      CHANNEL_KEY: getChannelKeyExplorer(currency),
      ...EXPLORER_SERVICE_CONFIG,
    })
    this.currency = currency
  }

  public getTokenByAddress = async (
    ...payload: Parameters<typeof this._getTokenByAddress>
  ) => {
    return this._getTokenByAddress(...payload).then((response) => {
      return response
    })
  }

  public getTransaction = (
    hash: string
  ): Promise<GraphTransactionUtxo | GraphTransactionEvm> =>
    this._getTransaction(hash).then(
      (rawTransaction) =>
        normalizeSnakeToCamelCase(rawTransaction) as
          | GraphTransactionUtxo
          | GraphTransactionEvm
    )
}

export default ExplorerService
