import { AlertState, AlertService } from '../../modules'
import type { IPhoenixSocketController } from '@clain/api/WebSocket'

interface AlertCtxInit {
  wsState: IPhoenixSocketController
}

export class AlertCtx {
  public alertService = new AlertService()
  public alertState = new AlertState()

  public init({ wsState }: AlertCtxInit) {
    this.alertService.init({ wsState })
  }
}
