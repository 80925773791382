import {
  SettingsService,
  SettingsState,
  SettingsViewModel,
} from '../../modules/settings'
import type { IPhoenixSocketController } from '@clain/api/WebSocket'

interface SettingsCtxInit {
  wsState: IPhoenixSocketController
}

export class SettingsCtx {
  public settingsService = new SettingsService()
  public settingsState = SettingsState.getInstance()
  public settingsVM = new SettingsViewModel({
    settingsCtx: {
      settingsService: this.settingsService,
      settingsState: this.settingsState,
    },
  })

  public init({ wsState }: SettingsCtxInit) {
    this.settingsVM.init()
  }
}
