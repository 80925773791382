import { action, computed, makeObservable, observable } from 'mobx'
import { Address } from '../../../types/converted/Address'
import { Cluster } from '../../../types/converted/Cluster'
import { FlowEntitiesData } from '../ActiveEntityFlow'
import { IActiveEntityState } from './ActiveEntityState.types'
import { CrossChainSwapTrx } from '../../../types/converted/CrossChainSwap'
import { TransactionCommonEvm } from '@clain/api/platform/types/Transactions'

export class ActiveEntityState<IState = unknown, ID = unknown>
  implements IActiveEntityState<IState, ID>
{
  private static instance: ActiveEntityState
  @observable private _id: ID
  @observable private _state: IState

  constructor() {
    makeObservable(this)
  }

  public static getInstance<IState = unknown, ID = number>() {
    if (!ActiveEntityState.instance) {
      this.instance = new ActiveEntityState()
    }

    return ActiveEntityState.instance as IActiveEntityState<IState, ID>
  }

  @action
  public initState = (state: IState) => {
    this._state = state
  }

  @computed
  public get state() {
    return this._state
  }

  @action
  public setState = (state: IState) => {
    this._state = state
  }

  @action
  public resetState = () => {
    this._state = undefined
    this._id = undefined
  }

  @computed
  public get id() {
    return this._id
  }

  @action
  public setId(id: ID) {
    this._id = id
  }
}

export const activeEntityClusterState = ActiveEntityState.getInstance<Cluster>()

export const activeEntityFlowState =
  ActiveEntityState.getInstance<FlowEntitiesData>()

export const activeEntityCrossChainSwapState =
  ActiveEntityState.getInstance<CrossChainSwapTrx>()

export const activeEntityTransactionEvmState =
  ActiveEntityState.getInstance<TransactionCommonEvm>()

export const activeEntityAddressState = ActiveEntityState.getInstance<Address>()
