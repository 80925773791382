import { injectable, inject } from 'inversify'
import { ColorPickerCoreItem } from '@clain/core/ui-kit'
import { action, computed, makeObservable } from 'mobx'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type { ITheme } from '../../../../modules/theme'
import type {
  CircularMenuEntity,
  CircularMenuItemsType,
} from '../../types/CircularMenu'
import type { Position } from '../../types/Position'
import type { IPaletteController } from '../PaletteController'
import type { CircularMenuKeyType } from './CircularMenuViewModel.types.ts'
import type { IProbeState } from '../ProbeState'
import type { ShortcutMenuController } from '../shortcut-menu/ShortcutMenuController'
import { i18next } from '@platform/i18Manager'

@injectable()
export class CircularMenuViewModel {
  private activeKey: CircularMenuKeyType

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ShortcutMenuController)
    private shortcutMenuController: ShortcutMenuController,
    @inject(DI_PROBE_TYPES.Theme) private theme: ITheme,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController,
    @inject(DI_PROBE_TYPES.CircularMenuNodes)
    private circularMenuNodes: CircularMenuEntity,
    @inject(DI_PROBE_TYPES.CircularMenuEdges)
    private circularMenuEdges: CircularMenuEntity,
    @inject(DI_PROBE_TYPES.CircularMenuWorld)
    private circularMenuWorld: CircularMenuEntity
  ) {
    makeObservable(this)
  }

  private selectEntity(key: CircularMenuKeyType) {
    const entitySize =
      this.probeState.selectedEdgeIds.size +
      this.probeState.selectedNodeIds.size

    if (key === 'world' || entitySize > 1) {
      return this.circularMenuWorld
    }

    if (this.probeState.nodes.has(key)) {
      return this.circularMenuNodes
    } else {
      return this.circularMenuEdges
    }
  }

  @action
  public open(position: Position, key: CircularMenuKeyType) {
    this.hide()
    this.activeKey = key
    const itemsType = this.selectEntity(key).init(key)

    if (!itemsType.length) return

    this.shortcutMenuController.open(key, 'circularMenu', position)
  }

  @computed
  private get colorItems(): CircularMenuItemsType[] {
    const colors = [
      this.theme.getToken(['grey500']),
      this.theme.getToken(['custom', 'magenta']),
      this.theme.getToken(['custom', 'neon']),
      this.theme.getToken(['custom', 'sky']),
      this.theme.getToken(['custom', 'wave']),
      this.theme.getToken(['custom', 'emerald']),
      this.theme.getToken(['custom', 'grass']),
      this.theme.getToken(['custom', 'lemon']),
      this.theme.getToken(['custom', 'orange']),
      this.theme.getToken(['custom', 'rose']),
    ]

    return colors.map((color) => ({
      id: 'color',
      value: color,
      selected: this.paletteController.isSelectedColor(color),
      render: (props) => <ColorPickerCoreItem color={color} {...props} />,
    }))
  }

  @computed
  private get allItems(): CircularMenuItemsType[] {
    return [
      {
        id: 'delete',
        value: this.activeKey,
        iconVariant: 'Delete',
        tooltip: i18next.t('platform:delete'),
      },
      {
        id: 'addNode',
        value: this.activeKey,
        iconVariant: 'PlusCircle',
        tooltip: i18next.t('platform:addNode'),
      },
      this.selectEntity(this.activeKey).proxyMenuItem('addAlert', {
        id: 'addAlert',
        value: this.activeKey,
        iconVariant: 'NotificationAddRule',
        tooltip: i18next.t('platform:addAlert'),
      }),
      {
        id: 'rearrangeNodes',
        value: this.activeKey,
        iconVariant: 'RealignNodesRelayout',
        tooltip: i18next.t('platform:realignSelectedNodes'),
      },
      {
        id: 'plotParent',
        value: this.activeKey,
        iconVariant: 'Parent',
        tooltip: i18next.t('platform:plotParent'),
      },
      {
        id: 'pickerColor',
        value: this.activeKey,
        iconVariant: 'Pallete',
        tooltip: i18next.t('platform:palette'),
        items: [
          {
            id: 'restorePickerColor',
            value: this.activeKey,
            iconVariant: 'Restore',
            tooltip: i18next.t('platform:restoreDefault'),
          },
          ...this.colorItems,
        ],
      },
    ]
  }

  @computed
  public get items(): CircularMenuItemsType[] {
    return this.allItems.filter((item) =>
      this.selectEntity(this.activeKey).renderMenuIds.includes(item.id)
    )
  }

  @action
  public hide() {
    this.shortcutMenuController.hide(this.activeKey)
    this.activeKey = undefined
  }

  public onMenuClick = (item: CircularMenuItemsType) => {
    this.selectEntity(this.activeKey).onMenuClick(item)
    this.hide()
  }
}
