import { Broadcast, IBrodcast } from '@clain/core/utils/Broadcast'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import type {
  EventAddNodeData,
  GraphEventAddNode,
  FlowId,
} from '@clain/graph-entities'
import { IPlotEntities } from '../models'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from './ProbeState'
import { IProbeEvents } from './ProbeEvents'

@injectable()
export class PlotEntitiesController implements IPlotEntities {
  @observable private plotNodesId: FlowId
  private reactionDisposers: Array<IReactionDisposer> = []
  public broadcast: IBrodcast<{
    probeId: number
    entities: EventAddNodeData[]
  }>

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents
  ) {
    this.broadcast = new Broadcast('PlotEntitiesController')
    makeObservable(this)
  }

  public initReactionRenderEntities = () => {
    this.broadcast.onMessage(({ entities, probeId }) => {
      if (probeId === this.probeState.probeId) {
        this.renderEntities(entities)
      }
    })

    this.reactionDisposers = []

    this.reactionDisposers.push(
      reaction(
        () => ({
          isInitialized: this.probeState.isInitialized,
        }),
        ({ isInitialized }) => {
          if (isInitialized) {
            this.broadcast.postConnectedSubscriber('plotingIsReady')
          }
        }
      )
    )
  }

  @action
  public renderEntities = (entities: EventAddNodeData[]) => {
    const normalizedToAddEvents = entities.map(
      (data) =>
        ({
          type: 'add_node',
          data,
        }) as GraphEventAddNode
    )
    const result = this.probeEvents.emit(normalizedToAddEvents, {
      animation: true,
      animationType: {
        strategy: 'moveToCentroid',
        scaleStrategy: 'force',
      },
    })
    this.plotNodesId = result.meta.id
  }

  @action
  public pushPlotEntities = (probeId: number, entities: EventAddNodeData[]) => {
    this.broadcast.onConnectedSubscriber(() => {
      this.broadcast.postMessage({
        probeId,
        entities,
      })
    })
  }

  @computed
  public get plotNodesIsInProcessing() {
    return this.probeEvents.meta.loading[this.plotNodesId]
  }

  @action
  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.broadcast.close()
  }
}
