import 'reflect-metadata'
import App, { RootContent } from '@clain/core/App'
import React, { PropsWithChildren } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import { useAuth, useHttpInterceptorEffect } from '@clain/core/Auth'
import { ctx, useCtx } from './ctx'
import { ThemeProvider } from 'styled-components'
import { enterprise, InternationalizationProvider } from '@clain/core/ui-kit'
import SubscriptionExpired from './components/SubscriptionExpired'
import NoAccess from './components/NoAccess'
import { useNavItems } from './navItems'
import { defaultParams, paramsState } from './routes'
import SidebarTop from './components/SidebarTop'
import SidebarBottom from './components/SidebarBottom'
import http from '@clain/core/http'
import { getConfig } from '@clain/core/useConfig'
import { useTranslation } from 'react-i18next'
import { UIKIT_LANG_NAMESPACE } from './constants/languege'
const config = getConfig()

const wss = config?.PLATFORM_WS
const baseURL = config?.PLATFORM_API

const refreshApiToken = async (token: string) => {
  http.config({ token, baseURL })
  await ctx.reconnectWithNewToken(token)
}
const initApiToken = async (
  token: string,
  refreshToken: () => Promise<string>
) => {
  http.config({ token, baseURL })
  ctx.init({ wss, token, refreshToken })
}

const AppComponent: React.FC<PropsWithChildren> = withAuthenticationRequired(
  observer(({ children }) => {
    const { isTokenProvided, logout, refreshToken } = useAuth({
      initApiToken,
      refreshApiToken,
    })
    useHttpInterceptorEffect({
      refreshToken,
      logout,
    })
    const {
      userState: { noAccess, subscriptionExpired },
    } = useCtx()
    const renderApp = !noAccess && !subscriptionExpired && isTokenProvided
    const navItems = useNavItems()

    const { t } = useTranslation()

    return (
      <InternationalizationProvider
        t={(key) => t(`${UIKIT_LANG_NAMESPACE}:${key}`)}
      >
        <ThemeProvider theme={enterprise['light']}>
          {subscriptionExpired && <SubscriptionExpired />}
          {noAccess && <NoAccess />}
          {renderApp && (
            <App
              navItems={navItems}
              paramsState={paramsState}
              defaultParams={defaultParams}
              sidebarTop={SidebarTop}
              sidebarBottom={SidebarBottom}
              logout={logout}
            >
              {children}
            </App>
          )}
        </ThemeProvider>
      </InternationalizationProvider>
    )
  })
)
export default function RootApp() {
  return <RootContent AppComponent={AppComponent} />
}
