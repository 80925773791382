import {
  AddressSearchResult,
  ClusterSearchResult,
  TransactionSearchResult,
} from '../ProbeSandbox/vm/services/SearchService/SearchService.types'
import { EntityType } from '@caudena/web-platform/dist/components/SearchEntities/constants'
import { SearchActionButtonType, SearchResult } from './SearchEntities.types'

export const getEntityUrl = (
  type: SearchActionButtonType,
  entityType: EntityType,
  value: SearchResult
) => {
  const path = {
    explorer: {
      transaction: `/explorer/transaction/${
        (value as TransactionSearchResult)?.hash
      }`,
    },
    cluster: {
      address: `/cluster/${(value as AddressSearchResult)?.clusterId}`,
      entity: `/cluster/${(value as ClusterSearchResult)?.clusterId}`,
      cluster: `/cluster/${(value as ClusterSearchResult)?.clusterId}`,
    },
    address: {
      address: `/address/${(value as AddressSearchResult)?.address}`,
    },
  }
  if (!path[type][entityType] || !value) {
    return ''
  }
  // @ts-ignore
  return `/${value?.currency}${path[type][entityType]}`
}
