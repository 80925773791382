import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeEvents } from '../../vm/ProbeEvents'
import { GraphEvents, IGraphEventsSettings } from '@clain/graph-entities'
import { ServerEventNodeEdgeReceive } from '../../types/serverData'
import { ProbeGraphEventsMeta } from '../../types/ProbeGraphEvents'
import { EntityServices } from '../../vm/services/EntitiesServices/EntityServices'
import { EventsGraphReaction } from '../../vm/EventsGraphReaction'
import { GraphEntityEventFacade } from '../../vm/GraphEntityEvent'
import { NodeEventsController } from '../../vm/GraphEntityEvent/controllers/NodeEventsController'
import { EdgeEventsController } from '../../vm/GraphEntityEvent/controllers/EdgeEventsController'
import { WorldEventsController } from '../../vm/GraphEntityEvent/controllers/WorldEventsController'
import { EntitySelectController } from '../../vm/GraphEntityEvent/controllers/EntitySelectController'
import { IReactionDisposer, reaction } from 'mobx'
import { settingsState } from '@platform/modules/settings'
import { ReciveEvents } from '../../vm/ReciveEvents/ReciveEvents'
import { AsyncQueue } from '../../vm/queue/AsyncQueue'
import { ReziseGraphViewport } from '../../vm/ReziseGraphViewport'

export const eventsModule = new ContainerModule((bind) => {
  bind<IProbeEvents>(DI_PROBE_TYPES.ProbeEvents)
    .toDynamicValue((context) =>
      new GraphEvents<ServerEventNodeEdgeReceive, ProbeGraphEventsMeta>({
        graphEventsDefaultMetaOptions: {
          snapshotToHistory: true,
        },
        graphEventsSettings: context.container.get(
          DI_PROBE_TYPES.GraphEventsSettings
        ),
        entitiesState: context.container.get(DI_PROBE_TYPES.ProbeState),
        entitiesGraph: context.container.get(DI_PROBE_TYPES.ProbeGraph),
        proccesingEntityState: context.container.get(
          DI_PROBE_TYPES.ProccesingEntityState
        ),
        entityServices: {
          getTransactionUtxo: (currency) =>
            context.container
              .get<EntityServices>(DI_PROBE_TYPES.EntityServices)
              .getServices('explorer', currency).getTransaction,
        },
      }).getInstance()
    )
    .inSingletonScope()

  bind<EventsGraphReaction>(DI_PROBE_TYPES.EventsGraphReaction)
    .to(EventsGraphReaction)
    .inRequestScope()

  bind<GraphEntityEventFacade>(DI_PROBE_TYPES.GraphEntityEventFacade).to(
    GraphEntityEventFacade
  )

  bind<NodeEventsController>(DI_PROBE_TYPES.NodeEventsController).to(
    NodeEventsController
  )

  bind<EdgeEventsController>(DI_PROBE_TYPES.EdgeEventsController).to(
    EdgeEventsController
  )

  bind<WorldEventsController>(DI_PROBE_TYPES.WorldEventsController).to(
    WorldEventsController
  )

  bind<EntitySelectController>(DI_PROBE_TYPES.EntitySelectController).to(
    EntitySelectController
  )

  bind<ReciveEvents>(DI_PROBE_TYPES.ReciveEvents)
    .to(ReciveEvents)
    .inSingletonScope()

  bind<AsyncQueue>(DI_PROBE_TYPES.AsyncQueue).to(AsyncQueue).inSingletonScope()

  bind<ReziseGraphViewport>(DI_PROBE_TYPES.ReziseGraphViewport)
    .to(ReziseGraphViewport)
    .inSingletonScope()

  bind<IReactionDisposer>(
    DI_PROBE_TYPES.ReactionGraphEventsSettings
  ).toDynamicValue((context) =>
    reaction(
      () => settingsState?.graph?.utxoAutoconnect,
      (utxoAutoconnect) => {
        context.container
          .get<IGraphEventsSettings>(DI_PROBE_TYPES.GraphEventsSettings)
          .setSettings({
            generateEntity: { utxoAutoconnect: utxoAutoconnect },
          })
      }
    )
  )
})
