import { ERROR, REJECT_REASON } from './errors'

export const notificationConfig = {
  [REJECT_REASON.phx_error]: {
    text: ERROR.serverIssues,
    type: 'warning',
  },
} as const

export const notificationToastOptions = {
  position: 'top-right',
} as const
