import { injectable } from 'inversify'
import Queue from './Queue'

interface AsyncQueueItem {
  action: () => Promise<unknown>
  resolve: (value: unknown | PromiseLike<unknown>) => void
  reject: (reason?: Error) => void
}

@injectable()
export class AsyncQueue extends Queue {
  private pending = false

  public async enQueue<T extends () => Promise<unknown>>(
    action: T
  ): Promise<AsyncQueueItem> {
    return new Promise((resolve, reject) => {
      super.enQueue({ action, resolve, reject })
      this.deQueue()
    })
  }

  public async deQueue() {
    if (this.pending) return

    const item = super.deQueue() as AsyncQueueItem

    if (!item) return

    try {
      this.pending = true

      const payload = await item.action()

      this.pending = false
      item.resolve(payload)
    } catch (e) {
      this.pending = false
      item.reject(e)
    }

    this.deQueue()
  }
}
