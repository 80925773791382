import { computed, action, makeObservable, observable } from 'mobx'
import { IGraphEventsSettings, IGraphEventsSettingsValues } from '../../types'
import { injectable } from 'inversify'

@injectable()
export class GraphEventsSettings implements IGraphEventsSettings {
  @observable private _settings: IGraphEventsSettingsValues = {}

  constructor() {
    makeObservable(this)
  }

  @computed
  public get generateEntity() {
    return this._settings?.generateEntity
  }

  @action
  public setSettings(settings?: IGraphEventsSettingsValues) {
    this._settings = settings
  }
}
