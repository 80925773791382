import { useRouteError } from 'react-router-dom'
import { TypographyNew } from '@caudena/web-platform'
import { CaptureContext } from '@sentry/types'
import { useEffect } from 'react'

interface ErrorPageProps {
  captureException?: (error: Error, context: CaptureContext) => void
}

export default function ErrorPage({ captureException }: ErrorPageProps) {
  const error = useRouteError()

  useEffect(() => {
    if (captureException) {
      captureException(error as Error, { level: 'error' })
    }
  }, [captureException, error])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <TypographyNew variant={'heading200Accent'} color={'onBackgroundBase'}>
        An error has occurred, please try to reload page
      </TypographyNew>
    </div>
  )
}
