export interface IAnimationRender {
  onAnimationStart: () => void
  onAnimationComplete: () => void
}

export class AnimationRender implements IAnimationRender {
  private activeAnimationsCount: number = 0

  constructor(
    private startContinuousRender: () => void,
    private stopContinuousRender: () => void
  ) {}

  public onAnimationStart = () => {
    this.activeAnimationsCount++
    if (this.activeAnimationsCount === 1) {
      this.startContinuousRender()
    }
  }

  public onAnimationComplete = () => {
    this.activeAnimationsCount--
    if (this.activeAnimationsCount === 0) {
      this.stopContinuousRender()
    }
  }
}
