import { action, makeObservable } from 'mobx'
import { injectable, inject } from 'inversify'

import {
  transactionKey,
  addressKey,
  transactionAddressKey,
  randomKey,
  osintKey,
  demixKey,
  edgeKey,
  edgeEvmTrxByTypeKey,
  belongsEdgeKey,
} from '@clain/graph-entities'

import { DI_TYPES } from '../di/DITypes'
import {
  ClusterProbeNode,
  TransactionProbeNodeUtxo,
  TransactionProbeNodeEvm,
  AddressProbeNodeUtxo,
  AddressProbeNodeEvm,
  TransactionAddressNode,
  CommentPinProbeNode,
  CommentPlugProbeNode,
  FONT_SIZE,
  WIDTH,
  HEIGHT,
  FONT_SCALE,
  TextProbeNode,
  OsintNode,
  DemixNode,
  CustomNode,
  UnsupportedAddressNode,
  TransactionProbeEdge,
  AddressBelongsProbeEdge,
  TransactionAddressBelongsProbeEdge,
  FlowProbeEdge,
  CommentProbeEdge,
  AttributionEdge,
  DemixEdge,
  CrossChainSwapFlowEdge,
  CustomEdge,
  LinkEdge,
} from '../entities'
import {
  ILayers,
  ILayoutSettingsState,
  IProbeNode,
  IProbeTextProbeNodeAdditionals,
  ITheme,
} from '../models'
import {
  TransactionEdgeData,
  FlowEdgeData,
  DemixEdgeData,
  CrossChainSwapFlowEdgeData,
  CustomEdgeData,
  ClusterNodeData,
  AddressNodeDataEvm,
  AddressNodeDataUtxo,
  TransactionAddressNodeData,
  DemixNodeData,
  TransactionNodeDataUtxo,
  UnsupportedAddressNodeData,
  TransactionNodeDataEvm,
  OsintNodeData,
  CustomNodeData,
  NodeSettings,
  CommentPinNodeData,
  TransactionEdgeEVMData,
  TransactionEdgeUTXOData,
} from '../types'
import { IProbeState, IProbeGraph } from '../models'
import type {
  LiteTextNode,
  LiteTransactionNodeUtxo,
  TransactionAddressNodeDataType,
} from '@clain/graph-entities'
import { Token } from '../types/Token'
import { CoinTypeUTXO } from '../types/CointType'

@injectable()
export class Factory {
  constructor(
    @inject(DI_TYPES.Theme)
    private theme: ITheme,
    @inject(DI_TYPES.ProbeState)
    private probeState: IProbeState,
    @inject(DI_TYPES.ProbeGraph)
    private probeGraph: IProbeGraph,
    @inject(DI_TYPES.LayoutSettingsState)
    private layoutSettingsState: ILayoutSettingsState,
    @inject(DI_TYPES.Layers)
    private layers: ILayers
  ) {
    makeObservable(this)
  }

  @action public produceClusterProbeNode = (
    data: ClusterNodeData,
    x: number,
    y: number,
    key: string,
    settings?: NodeSettings
  ): { key: string; node: ClusterProbeNode } => {
    if (this.probeState.nodes.has(key)) return
    const clusterProbeNode = new ClusterProbeNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'cluster', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, clusterProbeNode)

    return { key, node: clusterProbeNode }
  }

  @action public produceTransactionProbeNodeUtxo = (
    data: Omit<TransactionNodeDataUtxo, 'nodeType'>,
    x: number,
    y: number,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: TransactionProbeNodeUtxo } => {
    const key = outerKey ?? transactionKey(data)

    if (this.probeState.nodes.has(key)) return

    const transactionProbeNodeBtc = new TransactionProbeNodeUtxo(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'utxo_transaction', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, transactionProbeNodeBtc)

    return { key, node: transactionProbeNodeBtc }
  }

  @action public produceTransactionProbeNodeEvm = (
    data: Omit<TransactionNodeDataEvm, 'nodeType'>,
    x: number,
    y: number,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: TransactionProbeNodeEvm } => {
    const key = outerKey ?? transactionKey(data)

    if (this.probeState.nodes.has(key)) return

    const transactionProbeNodeEvm = new TransactionProbeNodeEvm(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'evm_transaction', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, transactionProbeNodeEvm)

    return { key, node: transactionProbeNodeEvm }
  }

  @action public produceAddressProbeNodeUtxo = (
    data: Omit<AddressNodeDataUtxo, 'nodeType'>,
    x: number,
    y: number,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: AddressProbeNodeUtxo } => {
    const key = outerKey ?? addressKey(data)

    if (this.probeState.nodes.has(key)) return

    const addressProbeNodeUtxo = new AddressProbeNodeUtxo(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'address', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, addressProbeNodeUtxo)

    return { key, node: addressProbeNodeUtxo }
  }

  @action public produceAddressProbeNodeEvm = (
    data: Omit<AddressNodeDataEvm, 'nodeType'>,
    x: number,
    y: number,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: AddressProbeNodeEvm } => {
    const key = outerKey ?? addressKey(data)

    if (this.probeState.nodes.has(key)) return

    const addressProbeNodeEth = new AddressProbeNodeEvm(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'address', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, addressProbeNodeEth)

    return { key, node: addressProbeNodeEth }
  }

  @action public produceTransactionAddressNode = (
    data: Omit<TransactionAddressNodeData, 'nodeType'>,
    transaction: Pick<LiteTransactionNodeUtxo, 'hash'> &
      Partial<Pick<LiteTransactionNodeUtxo, 'id'>>,
    x: number,
    y: number,
    currency: CoinTypeUTXO,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: TransactionAddressNode } => {
    const key = outerKey ?? transactionAddressKey(data)

    if (this.probeState.nodes.has(key)) return

    if (data.addressId == null) {
      throw new Error('addressId is required')
    }
    if (data.id == null) {
      throw new Error('id is required')
    }

    const transactionAddressNode = new TransactionAddressNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      {
        nodeType: 'utxo_transaction_address',
        trxHash: transaction.hash,
        currency,
        ...data,
        addressType: 'transaction',
      },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, transactionAddressNode)

    return { key, node: transactionAddressNode }
  }

  @action public produceTransactionAddressTokenNode = (
    data: Omit<TransactionAddressNodeData, 'nodeType'>,
    transaction: Pick<TransactionNodeDataUtxo, 'hash'> &
      Partial<Pick<TransactionNodeDataUtxo, 'id'>>,
    x: number,
    y: number,
    token: Token,
    addressType: Exclude<TransactionAddressNodeDataType, 'transaction'>,
    outerKey?: string,
    settings?: NodeSettings
  ): { key: string; node: TransactionAddressNode } => {
    const key = outerKey

    if (this.probeState.nodes.has(key)) return

    const id = data?.addressId ?? data?.id
    //Todo: remove addressId after backend change in all endpoints
    const addressId = data?.id ?? data?.addressId

    const transactionAddressNode = new TransactionAddressNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      {
        nodeType: 'utxo_transaction_address',
        trxHash: transaction.hash,
        currency: 'btc',
        token,
        addressType,
        id,
        addressId,
        ...data,
      },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, transactionAddressNode)

    return { key: key, node: transactionAddressNode }
  }

  @action public produceCommentPinNode = <
    T extends Omit<CommentPinNodeData, 'nodeType' | 'commentPinKey'>,
  >(
    data: T,
    x = -100,
    y = -100,
    key: string = randomKey()
  ) => {
    const commentPinNode = new CommentPinProbeNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'comment_pin', ...data },
      { x, y }
    )

    this.probeState.nodes.set(key, commentPinNode)

    return { key, node: commentPinNode }
  }

  @action public produceCommentPlugNode = (
    x = -100,
    y = -100,
    key: string = randomKey()
  ) => {
    const commentPlugNode = new CommentPlugProbeNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'comment_plug' },
      { x, y }
    )

    this.probeState.nodes.set(key, commentPlugNode)

    return { key, node: commentPlugNode }
  }

  @action public produceTextNode = (
    data = {
      text: '',
      fontSize: FONT_SIZE,
      width: WIDTH,
      height: HEIGHT,
      scale: FONT_SCALE,
    },
    x = -100,
    y = -100,
    key: string = randomKey(),
    isSetToState = true
  ) => {
    const textProbeNode = new TextProbeNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { ...data, nodeType: 'text' },
      { x, y }
    )
    if (isSetToState) {
      this.probeState.nodes.set(key, textProbeNode)
    }

    return {
      key,
      node: textProbeNode as unknown as IProbeNode<LiteTextNode> &
        IProbeTextProbeNodeAdditionals,
    }
  }

  @action public produceOsintNode = (
    data: Omit<OsintNodeData, 'nodeType'>,
    x: number,
    y: number,
    settings?: NodeSettings
  ): { key: string; node: OsintNode } => {
    const key = osintKey(data)

    if (this.probeState.nodes.has(key)) return

    const osintNode = new OsintNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'osint', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, osintNode)

    return { key, node: osintNode }
  }

  @action public produceDemixNode = (
    data: Omit<DemixNodeData, 'nodeType'>,
    x: number,
    y: number,
    settings?: NodeSettings
  ): { key: string; node: DemixNode } => {
    const key = demixKey(data)

    if (this.probeState.nodes.has(key)) return

    const demixNode = new DemixNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'demix', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, demixNode)

    return { key, node: demixNode }
  }

  @action public produceCustomNode = (
    data: Omit<CustomNodeData, 'nodeType'>,
    x: number,
    y: number,
    settings?: NodeSettings,
    outerKey?: string
  ): { key: string; node: CustomNode } => {
    const key = outerKey

    if (this.probeState.nodes.has(key)) return

    const node = new CustomNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'custom', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, node)

    return { key, node }
  }

  @action public produceUnsupportedAddressNode = (
    data: Omit<UnsupportedAddressNodeData, 'nodeType'>,
    x: number,
    y: number,
    settings?: NodeSettings,
    outerKey?: string
  ): { key: string; node: UnsupportedAddressNode } => {
    const key = outerKey

    if (this.probeState.nodes.has(key)) return

    const node = new UnsupportedAddressNode(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeState,
      this.probeGraph,
      key,
      { nodeType: 'unsupported_address', ...data },
      { x, y },
      settings
    )

    this.probeState.nodes.set(key, node)

    return { key, node }
  }

  //edges

  @action public produceTransactionProbeEdge = (
    source: string,
    target: string,
    data: TransactionEdgeData
  ) => {
    let key = edgeKey(source, target)

    if (data.edgeType === 'evm_transaction') {
      key = edgeEvmTrxByTypeKey(source, target, {
        index: data?.index,
        type: data?.type,
      })
    }

    if (this.probeState.edges.has(key)) return

    const transactionProbeEdge = new TransactionProbeEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      { ...data }
    )

    this.probeState.edges.set(key, transactionProbeEdge)

    return { key, edge: transactionProbeEdge } as
      | { key: string; edge: TransactionProbeEdge<TransactionEdgeEVMData> }
      | { key: string; edge: TransactionProbeEdge<TransactionEdgeUTXOData> }
  }

  @action public produceAddressBelongsProbeEdge = (
    source: string,
    target: string
  ): { key: string; edge: AddressBelongsProbeEdge } => {
    const key = belongsEdgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const addressBelongsProbeEdge = new AddressBelongsProbeEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      { edgeType: 'address_belongs' }
    )

    this.probeState.edges.set(key, addressBelongsProbeEdge)

    return { key, edge: addressBelongsProbeEdge }
  }

  @action public produceTransactionAddressBelongsProbeEdge = (
    source: string,
    target: string
  ): { key: string; edge: TransactionAddressBelongsProbeEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const transactionAddressBelongsProbeEdge =
      new TransactionAddressBelongsProbeEdge(
        this.theme,
        this.layers,
        this.layoutSettingsState,
        this.probeGraph,
        key,
        source,
        target,
        {
          edgeType: 'transaction_address_belongs',
        }
      )

    this.probeState.edges.set(key, transactionAddressBelongsProbeEdge)

    return { key, edge: transactionAddressBelongsProbeEdge }
  }

  @action public produceFlowProbeEdge = (
    data: Omit<FlowEdgeData, 'edgeType'>,
    source: string,
    target: string
  ): { key: string; edge: FlowProbeEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const flowProbeEdge = new FlowProbeEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      {
        edgeType: 'flow',
        ...data,
      }
    )

    this.probeState.edges.set(key, flowProbeEdge)

    return { key, edge: flowProbeEdge }
  }

  @action public produceCommentProbeEdge = (
    source: string,
    target: string
  ): { key: string; edge: CommentProbeEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const commentProbeEdge = new CommentProbeEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      { edgeType: 'comment' }
    )
    commentProbeEdge.setDisabled(true)
    this.probeState.edges.set(key, commentProbeEdge)

    return { key, edge: commentProbeEdge }
  }

  @action public produceAttributionEdge = (
    source: string,
    target: string
  ): { key: string; edge: AttributionEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const attributionEdge = new AttributionEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      { edgeType: 'attribution' }
    )

    this.probeState.edges.set(key, attributionEdge)

    return { key, edge: attributionEdge }
  }

  @action public produceLinkEdge = (
    source: string,
    target: string
  ): { key: string; edge: LinkEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const linkEdge = new LinkEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      { edgeType: 'link' }
    )

    this.probeState.edges.set(key, linkEdge)

    return { key, edge: linkEdge }
  }

  @action public produceDemixEdge = (
    source: string,
    target: string,
    data: DemixEdgeData
  ): { key: string; edge: DemixEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const demixEdge = new DemixEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      data
    )

    this.probeState.edges.set(key, demixEdge)

    return { key, edge: demixEdge }
  }

  @action public produceCrossChainSwapFlowEdge = (
    source: string,
    target: string,
    data: CrossChainSwapFlowEdgeData
  ): { key: string; edge: CrossChainSwapFlowEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const crossChainEdge = new CrossChainSwapFlowEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      data
    )

    this.probeState.edges.set(key, crossChainEdge)

    return { key, edge: crossChainEdge }
  }

  @action public produceCustomEdge = (
    source: string,
    target: string,
    data: CustomEdgeData
  ): { key: string; edge: CustomEdge } => {
    const key = edgeKey(source, target)

    if (this.probeState.edges.has(key)) return

    const edge = new CustomEdge(
      this.theme,
      this.layers,
      this.layoutSettingsState,
      this.probeGraph,
      key,
      source,
      target,
      data
    )

    this.probeState.edges.set(key, edge)

    return { key, edge }
  }
}
