import { makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'
import type { CoinType } from '../../../../types/coin'
import type { ActiveEntityType } from '../active-entity/ActiveEntityViewModel.types'

@injectable()
export class ActiveEntityState {
  @observable public currency: CoinType
  @observable public type: ActiveEntityType
  @observable public selectedKey: string

  constructor() {
    makeObservable(this)
  }

  public setCurrency(currency: CoinType) {
    this.currency = currency
  }

  public setType(type: ActiveEntityType) {
    this.type = type
  }

  public setSelectedKey(key: string) {
    this.selectedKey = key
  }

  public clear() {
    this.currency = undefined
    this.type = undefined
    this.selectedKey = undefined
  }
}
