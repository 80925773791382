import { COINS } from '@clain/core/utils/currency'
import { useTranslation } from 'react-i18next'

export const useSearchEntitiesTranslation = () => {
  const { t } = useTranslation()

  const actionButtons = [
    {
      label: t('platform:addressPage'),
      type: 'address',
      icon: 'AddressPage',
    },
    {
      label: t('platform:openInExplorer'),
      type: 'explorer',
      icon: 'Explorer',
    },
    {
      label: t('platform:clusterPage'),
      type: 'cluster',
      icon: 'ClusterPage',
    },
    {
      label: t('platform:visualizeOnGraph'),
      type: 'graph',
      icon: 'GraphPlot',
    },
  ] as const

  return {
    initialPlaceholder: t('platform:searchMinSymbols'),
    actionButtons,
  }
}

export const filterShortcutRegex = /^(btc|eth|doge|ltc|trx|bnb):(.*)$/
export const blockchains = COINS
export const DEBOUNCE_TIMEOUT = 500
