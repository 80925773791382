import { Container, ContainerModule, interfaces } from 'inversify'
import { DI_TYPES } from './types'
import { IPhoenixSocketController, PhoenixSocketController } from '../WebSocket'

const initializePhoenixSocketControllerModule = (bind: interfaces.Bind) => {
  bind<IPhoenixSocketController>(DI_TYPES.PhoenixSocketController)
    .to(PhoenixSocketController)
    .inSingletonScope()
}

const phoenixSocketControllerModule = new ContainerModule(
  initializePhoenixSocketControllerModule
)

export const phoenixSocketControllerContainer = new Container({
  defaultScope: 'Singleton',
})

phoenixSocketControllerContainer.load(phoenixSocketControllerModule)
