import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import {
  IAddressesFilterSocketAPIService,
  RawAddressesServerData,
  AddressesSearchRequestParams,
  AddressTokensSearchRequestParams,
  Token,
} from '../types'
import { inject, injectable } from 'inversify'
import { DI_TYPES } from '../../DI/types'
import { IPhoenixSocketController, PhoenixChannel } from '../../WebSocket'

const CHANNEL_KEY = 'address_filter:global'

@injectable()
export class AddressesFilterRestAPIService
  implements IAddressesFilterSocketAPIService
{
  constructor(
    @inject(DI_TYPES.PhoenixSocketController)
    private socket: IPhoenixSocketController
  ) {
    this.channel = this.socket.channel(CHANNEL_KEY)
  }
  private channel: PhoenixChannel

  public joinChannel = async () => this.channel.join()

  public getAddresses = (data: AddressesSearchRequestParams) =>
    this.channel
      .push<RawAddressesServerData>('address', data)
      .then(normalizeSnakeToCamelCase)

  public getTokens = (data: AddressTokensSearchRequestParams) =>
    this.channel.push<Token[]>('tokens', data).then(normalizeSnakeToCamelCase)
}
