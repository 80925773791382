import { injectable, inject } from 'inversify'
import { action, computed } from 'mobx'
import { IProbeEvents } from '../ProbeEvents'
import { IProbeState } from '../ProbeState'
import {
  AllowedPaintEdges,
  IPaletteController,
} from './PaletteController.types'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class PaletteController implements IPaletteController {
  private allowedPaintEdges: AllowedPaintEdges[] = []

  constructor(
    @inject(DI_PROBE_TYPES.ProbeEvents)
    private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeState)
    private probeState: IProbeState
  ) {
    this.allowedPaintEdges = [
      'flow',
      'evm_transaction',
      'utxo_transaction',
      'custom',
    ]
  }

  private paintActiveEdges = (color: string | null) => {
    if (!this.getActiveEdgesForPaint?.length) return

    this.probeEvents.emit(
      this.getActiveEdgesForPaint.map((edge) => ({
        type: 'update_edge',
        key: edge.key,
        data:
          edge.data.edgeType === 'flow'
            ? {
                type: edge.data.edgeType,
                edgeData: {
                  color,
                  category: edge.data?.category,
                  tokenId: edge.data?.token?.id,
                },
              }
            : {
                type: edge.data.edgeType,
                edgeData: {
                  color,
                },
              },
      }))
    )
  }

  @computed
  private get getActiveEdgesForPaint() {
    return this.probeState.getSelectedEdgesDataByType(
      'data.edgeType',
      this.allowedPaintEdges
    )
  }

  @action
  public paintActiveEntities = (color: string) => {
    this.paintActiveEdges(color)
  }

  @action
  public restoreColorActiveEntities = () => {
    this.paintActiveEdges(null)
  }

  @computed
  public get hidePalette() {
    return !this.getActiveEdgesForPaint.length
  }

  @computed
  private get selectedColors(): Set<string> {
    return new Set(
      this.getActiveEdgesForPaint.map((edge) => edge.data.color).filter(Boolean)
    )
  }

  @computed
  public get selectedColor() {
    const colors = Array.from(this.selectedColors.values())

    if (colors.length > 1) return

    return colors[0]
  }

  @action
  public isSelectedColor(color: string) {
    if (this.selectedColors.size === 1) {
      return this.selectedColors.has(color)
    }

    return false
  }
}
