import { CoinType } from '@clain/core/types/coin'
import { AddressesFilters } from '../../../types/filters/AddressesFilters'
import { CounterpartiesFilters } from '../../../types/filters/CounterpartiesFilters'
import { OsintsFilters } from '../../../types/filters/OsintsFilters'
import { TransactionsBtcFilters } from '../../../types/filters/TransactionsBtcFilters'
import {
  DEFAULT_BTC_TOKEN,
  DEFAULT_DOGE_TOKEN,
  DEFAULT_LTC_TOKEN,
} from '../../../utils/convertTokenBalances'
import { GetTokensPayload } from '../../services/EntitiesServices/ClusterService'

type MappingFiltersByCurrency = Record<
  CoinType,
  | CounterpartiesFilters
  | TransactionsBtcFilters
  | AddressesFilters
  | OsintsFilters
>

type MappingExcludeFiltersByCurrency = Record<CoinType, ['asset'] | null>

const BASE_FILTERS: OsintsFilters = {
  page: 1,
  pageSize: 40,
}

const EXCLUDE_ASSET_FILTER = 'asset'

export const DEFAULT_SORT: Pick<CounterpartiesFilters, 'sortBy' | 'sortOrder'> =
  {
    sortBy: undefined,
    sortOrder: undefined,
  }

export const DEFAULT_FILTERS: CounterpartiesFilters = {
  ...DEFAULT_SORT,
  ...BASE_FILTERS,
}

export const ETH_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
}

export const TRX_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
}

export const BNB_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
}

export const BTC_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_BTC_TOKEN.token],
}

export const DOGE_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_DOGE_TOKEN.token],
}

export const LTC_INITIAL_FILTERS: CounterpartiesFilters = {
  ...BASE_FILTERS,
  includeTokens: [DEFAULT_LTC_TOKEN.token],
}

export const EXCLUDE_FILTERS_CURRENCY: MappingExcludeFiltersByCurrency = {
  eth: null,
  trx: null,
  btc: null,
  bnb: null,
  ltc: [EXCLUDE_ASSET_FILTER],
  doge: [EXCLUDE_ASSET_FILTER],
}

export const INITIAL_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  btc: BTC_INITIAL_FILTERS,
  eth: ETH_INITIAL_FILTERS,
  ltc: LTC_INITIAL_FILTERS,
  doge: DOGE_INITIAL_FILTERS,
  trx: TRX_INITIAL_FILTERS,
  bnb: BNB_INITIAL_FILTERS,
}

export const DEFAULT_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  btc: DEFAULT_FILTERS,
  eth: DEFAULT_FILTERS,
  ltc: DEFAULT_FILTERS,
  doge: DEFAULT_FILTERS,
  trx: DEFAULT_FILTERS,
  bnb: DEFAULT_FILTERS,
}

export const DEFAULT_OSINTS_FILTERS_CURRENCY: MappingFiltersByCurrency = {
  btc: BASE_FILTERS,
  eth: BASE_FILTERS,
  ltc: BASE_FILTERS,
  doge: BASE_FILTERS,
  trx: BASE_FILTERS,
  bnb: BASE_FILTERS,
}

export const DEFAULT_TOKENS_FILTERS: Omit<GetTokensPayload, 'clusterId'> = {
  pagination: {
    page: 1,
    pageSize: 1000,
  },
}
