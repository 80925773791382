import { RawClusterTransaction } from '../types/raw/RawClusterTransaction'
import {
  ClusterTransaction,
  ClusterTransactionEvm,
} from '../types/converted/ClusterTransaction'
import { convertToken } from './convertAddressBalance'
import { isEVM } from '@clain/core/types/coin'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { RawClusterTransactionCounterparty } from '@platform/components/ProbeSandbox/types/raw/RawClusterTransactionCounterparty'
import { ClusterTransactionCounterparty } from '@platform/components/ProbeSandbox/types/converted/ClusterTransactionCounterparty'

const convertClusterTransactionCounterparty = ({
  score,
  cluster_id,
  name,
  amount,
  icon,
}: RawClusterTransactionCounterparty): ClusterTransactionCounterparty => {
  return {
    score,
    clusterId: cluster_id,
    name,
    amount,
    icon,
  }
}

export const convertClusterTransaction = (
  rawTransaction: RawClusterTransaction
): ClusterTransaction => {
  if (isEVM(rawTransaction)) {
    return normalizeSnakeToCamelCase(
      rawTransaction
    ) as unknown as ClusterTransactionEvm
  } else {
    return normalizeSnakeToCamelCase(
      rawTransaction
    ) as unknown as ClusterTransaction
  }
}
