import { injectable, inject } from 'inversify'
import { action, computed, makeObservable } from 'mobx'
import { i18next } from '@platform/i18Manager'
import type { IProbeState } from '../../ProbeState'
import type { IAlertState } from '../../states'
import type { IAlertController } from './AlertController.types'
import type { AllowedAddAlertNodes } from './AlertController.types'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class AlertController implements IAlertController {
  private allowedAddAlertNodes: AllowedAddAlertNodes[] = []

  constructor(
    @inject(DI_PROBE_TYPES.AlertState) private state: IAlertState,
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState
  ) {
    this.allowedAddAlertNodes = ['address', 'cluster']
    makeObservable(this)
  }

  @computed
  private get getActiveNodesForAddAlert() {
    return this.probeState.getSelectedNodesDataByType(
      'data.nodeType',
      this.allowedAddAlertNodes
    )
  }

  @computed
  public get getAddAlertData() {
    if (this.hideAddAlert) {
      return
    }

    const activeEntity = this.getActiveNodesForAddAlert[0]

    if (activeEntity.data.nodeType === 'cluster') {
      return {
        id: activeEntity.data.clusterId,
        entities:
          activeEntity.data?.entity?.name || activeEntity.data?.clusterId,
        currency: activeEntity.data?.currency,
        nodeType: activeEntity.data.nodeType,
        score: activeEntity.data?.score,
      }
    }

    if (activeEntity.data.nodeType === 'address') {
      return {
        id: activeEntity.data.address,
        entities: activeEntity.data.address,
        currency: activeEntity.data?.currency,
        nodeType: activeEntity.data.nodeType,
        score: activeEntity.data?.score,
      }
    }
  }

  @computed
  public get hideAddAlert() {
    return (
      this.probeState.selectedNodeIds.size !== 1 ||
      this.getActiveNodesForAddAlert.length !== 1
    )
  }

  @computed
  public get disabled() {
    return !this.probeState.caseData?.id
  }

  @computed
  public get tooltip() {
    return this.disabled
      ? i18next.t('platform:saveGraphToEnableAlerts')
      : i18next.t('platform:addAlert')
  }

  @computed
  get isActive() {
    return this.state.isActive
  }

  @action
  public open = () => {
    this.state.open()
  }

  @action
  public close = () => {
    this.probeState.selectedNodeIds.clear()
    this.probeState.selectedEdgeIds.clear()
    this.state.close()
  }
}
