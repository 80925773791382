export const DI_PROBE_BASE_TYPES = {
  EntityDataToSnapshot: Symbol.for('EntityDataToSnapshot'),
  LayoutController: Symbol.for('LayoutController'),
  GraphEventsSettings: Symbol.for('GraphEventsSettings'),
  ProbeState: Symbol.for('ProbeState'),
  ProbeGraph: Symbol.for('ProbeGraph'),
  ProbeEvents: Symbol.for('ProbeEvents'),
  AnimationEntities: Symbol.for('AnimationEntities'),
  CameraViewModel: Symbol.for('CameraViewModel'),
  CommentsController: Symbol.for('CommentsController'),
  CrossChainSwapActionViewModel: Symbol.for('CrossChainSwapActionViewModel'),
  DemixActionViewModel: Symbol.for('DemixActionViewModel'),
  EntititiesGhosted: Symbol.for('EntititiesGhosted'),
  ProbeViewModel: Symbol.for('ProbeViewModel'),
  EntityEventController: Symbol.for('EntityEventController'),
  CircularMenuEdges: Symbol.for('CircularMenuEdges'),
  CircularMenuNodes: Symbol.for('CircularMenuNodes'),
  CircularMenuWorld: Symbol.for('CircularMenuWorld'),
  ProbeApp: Symbol.for('ProbeApp'),
  EntityLinkingController: Symbol.for('EntityLinkingController'),
  TextController: Symbol.for('TextController'),
  ShortcutMenuController: Symbol.for('ShortcutMenuController'),
  LayersViewModel: Symbol.for('LayersViewModel'),
  SearchController: Symbol.for('SearchController'),
  UtxoController: Symbol.for('UtxoController'),
  ProbeViewModelState: Symbol.for('ProbeViewModelState'),
  PointerController: Symbol.for('PointerController'),
  PositioningController: Symbol.for('PositioningController'),
  AsyncQueue: Symbol.for('AsyncQueue'),
  ExtendedLayoutPanelViewModel: Symbol.for('ExtendedLayoutPanelViewModel'),
  Settings: Symbol.for('Settings'),
  GraphFactoryEntities: Symbol.for('GraphFactoryEntities'),
  EventsGraphReaction: Symbol.for('EventsGraphReaction'),
  GraphEntityEventFacade: Symbol.for('GraphEntityEventFacade'),
  ReziseGraphViewport: Symbol.for('ReziseGraphViewport'),
  Factory: Symbol.for('Factory'),
  FactoryNodeEdge: Symbol.for('FactoryNodeEdge'),
  ProbeService: Symbol.for('ProbeService'),
  EntityServices: Symbol.for('EntityServices'),
  SearchService: Symbol.for('SearchService'),
  AIReportService: Symbol.for('AIReportService'),
  PlotEntitiesController: Symbol.for('PlotEntitiesController'),
  CircularMenuViewModel: Symbol.for('CircularMenuViewModel'),
  ReciveEvents: Symbol.for('ReciveEvents'),
  RearrangeNodesController: Symbol.for('RearrangeNodesController'),
  DeleteEntityController: Symbol.for('DeleteEntityController'),
  GraphHistory: Symbol.for('GraphHistory'),
  GraphHistoryState: Symbol.for('GraphHistoryState'),
  ApplySnapshot: Symbol.for('ApplySnapshot'),
  RemoveSnapshots: Symbol.for('RemoveSnapshots'),
  ProccesingEntityState: Symbol.for('ProccesingEntityState'),
  TextNodeController: Symbol.for('TextNodeController'),
  TextAreaController: Symbol.for('TextAreaController'),
  Currencies: Symbol.for('Currencies'),
  ProbeId: Symbol.for('ProbeId'),
  PaletteController: Symbol.for('PaletteController'),
  PlotParentController: Symbol.for('PlotParentController'),
  AlertController: Symbol.for('AlertController'),
  AlertsViewModel: Symbol.for('AlertsViewModel'),
  SearchState: Symbol.for('SearchState'),
  Theme: Symbol.for('Theme'),
  NodesPositionController: Symbol.for('NodesPositionController'),
  AlertState: Symbol.for('AlertState'),
  NodeEventsController: Symbol.for('NodeEventsController'),
  EdgeEventsController: Symbol.for('EdgeEventsController'),
  WorldEventsController: Symbol.for('WorldEventsController'),
  EntitySelectController: Symbol.for('EntitySelectController'),
  CanvasContainer: Symbol.for('CanvasContainer'),
  CanvasContainerProvider: Symbol.for('CanvasContainerProvider'),
  ReactionGraphEventsSettings: Symbol.for('ReactionGraphEventsSettings'),
  ProbeServiceProvider: Symbol.for('ProbeServiceProvider'),
  CameraState: Symbol.for('CameraState'),
}
