import { Point, Container, FederatedMouseEvent } from 'pixi.js'
import { TypedEmitter } from 'tiny-typed-emitter'

import TextureCache from '../textureCache'
import CurveEdgeGfx from './gfx/CurveEdgeGfx'
import PixiEvent, { TARGET } from '../core/PixiEvent'
import { EdgeOptions, NodeOptions } from '../types'
import getNodeOuterSize from '../node/utils/getNodeOuterSize'
import getTipLength from './utils/getTipLength'
import { getBezierEquation } from './utils/getBezierEquation'
import { getCircleLineIntersection } from './utils/getCircleLineIntersection'
import EdgeTipGfx from './gfx/EdgeTipGfx'
import LabelGfx from '../core/gfx/LabelGfx'

const CURVE_SIZE = 82

export interface CurvePixiEdgeEventPayload {
  id: string
  isExpanding?: boolean
}

interface CurvePixiEdgeEvents {
  mouseover: (event: PixiEvent<CurvePixiEdgeEventPayload>) => void
  mouseout: (event: PixiEvent<CurvePixiEdgeEventPayload>) => void
  mousedown: (event: PixiEvent<CurvePixiEdgeEventPayload>) => void
  mouseup: (event: PixiEvent<CurvePixiEdgeEventPayload>) => void
}

interface IntermediateCalculations {
  edgeCenterPoint?: Point
  edgePoint1?: Point
  edgePoint2?: Point
  sourceTipRotation?: number
  targetTipRotation?: number
  sourceNodeIntersection?: Point
  targetNodeIntersection?: Point
  sourceNodeToTargetAngle?: number
  labelPosition?: Point
}

interface CurvePixiEdgeProps {
  id: string
  options: EdgeOptions
  sourceNodeOptions: NodeOptions
  targetNodeOptions: NodeOptions
  textureCache: TextureCache
}

export class CurvePixiEdge extends TypedEmitter<CurvePixiEdgeEvents> {
  public container = new Container({ isRenderGroup: true })
  public cullContainer = new Container()
  public edgeGfx: CurveEdgeGfx
  public sourceTipGfx: EdgeTipGfx
  public targetTipGfx: EdgeTipGfx
  public edgeLabelGfx: LabelGfx

  private id: string
  private optionsRef: EdgeOptions
  private sourceNodeOptionsRef: NodeOptions
  private targetNodeOptionsRef: NodeOptions
  private textureCache: TextureCache

  private factor = 0

  private intermediateCalculations: IntermediateCalculations = {}

  constructor(props: CurvePixiEdgeProps) {
    super()

    this.id = props.id
    this.optionsRef = props.options
    this.sourceNodeOptionsRef = props.sourceNodeOptions
    this.targetNodeOptionsRef = props.targetNodeOptions
    this.textureCache = props.textureCache

    this.createEdge()
    this.createSourceTip()
    this.createTargetTip()
    this.createLabel()

    this.container.addChild(this.cullContainer)
    this.container.zIndex = props.options.zIndex || 1
  }

  public updateFactor = (factor: number): void => {
    this.factor = factor
  }

  public updatePosition = () => {
    if (this.optionsRef.disabled) {
      this.container.visible = false
      this.cullContainer.visible = false
      return
    }

    this.container.visible = true
    this.cullContainer.visible = true
    const { x: x1, y: y1 } = this.sourceNodeOptionsRef.position
    const { x: x2, y: y2 } = this.targetNodeOptionsRef.position

    if (x1 === x2 && y1 === y2) return

    this.updateIntermediateCalculations()
    this.updateEdge()
    this.updateSourceTipPosition()
    this.updateTargetTipPosition()
    this.updateLabelPosition()
  }

  public updateStyle = (): void => {
    if (this.optionsRef.disabled) {
      this.container.visible = false
      this.cullContainer.visible = false
      return
    }

    this.container.visible = true
    this.cullContainer.visible = true
    const { x: x1, y: y1 } = this.sourceNodeOptionsRef.position
    const { x: x2, y: y2 } = this.targetNodeOptionsRef.position

    if (x1 === x2 && y1 === y2) return

    this.updateIntermediateCalculations()
    this.updateEdge()

    this.updateSourceTipStyle()
    this.updateTargetTipStyle()
    this.updateSourceTipPosition()
    this.updateTargetTipPosition()
    this.updateLabelStyle()
  }

  private updateEdge = (): void => {
    this.edgeGfx.updateGfx({
      edgeOptions: this.optionsRef,
      points: [
        this.intermediateCalculations.edgePoint1,
        this.intermediateCalculations.edgeCenterPoint,
        this.intermediateCalculations.edgePoint2,
      ],
      straight: this.factor === 0,
    })
  }

  private updateLabelPosition = (): void => {
    if (this.optionsRef.label?.text) {
      this.edgeLabelGfx.gfx.position.copyFrom(
        this.intermediateCalculations.labelPosition
      )

      const edgeLabelAngle =
        this.intermediateCalculations.sourceNodeToTargetAngle +
        Math.PI *
          (this.intermediateCalculations.sourceNodeToTargetAngle > 0
            ? 1.5
            : 0.5)
      this.edgeLabelGfx.gfx.rotation = edgeLabelAngle
    }
  }

  private updateLabelStyle = (): void => {
    if (this.optionsRef.label?.text) {
      this.edgeLabelGfx.updateGfx({
        labelOptions: this.optionsRef.label,
        textureCache: this.textureCache,
      })
    }
  }

  private updateSourceTipPosition = (): void => {
    const x =
      this.optionsRef.sourceType === 'arc'
        ? this.intermediateCalculations.edgePoint1.x
        : this.intermediateCalculations.sourceNodeIntersection.x

    const y =
      this.optionsRef.sourceType === 'arc'
        ? this.intermediateCalculations.edgePoint1.y
        : this.intermediateCalculations.sourceNodeIntersection.y

    this.sourceTipGfx.gfx.position.set(x, y)
    this.sourceTipGfx.gfx.rotation =
      this.intermediateCalculations.sourceTipRotation
  }

  private updateSourceTipStyle = (): void => {
    this.sourceTipGfx.updateGfx({
      edgeOptions: this.optionsRef,
      nodeOptions: this.sourceNodeOptionsRef,
      textureCache: this.textureCache,
      type: this.optionsRef.sourceType,
    })
  }

  private updateTargetTipPosition = (): void => {
    const x =
      this.optionsRef.targetType === 'arc'
        ? this.intermediateCalculations.edgePoint2.x
        : this.intermediateCalculations.targetNodeIntersection.x

    const y =
      this.optionsRef.targetType === 'arc'
        ? this.intermediateCalculations.edgePoint2.y
        : this.intermediateCalculations.targetNodeIntersection.y

    this.targetTipGfx.gfx.position.set(x, y)
    this.targetTipGfx.gfx.rotation =
      this.intermediateCalculations.targetTipRotation
  }

  private updateTargetTipStyle = (): void => {
    this.targetTipGfx.updateGfx({
      edgeOptions: this.optionsRef,
      nodeOptions: this.targetNodeOptionsRef,
      textureCache: this.textureCache,
      type: this.optionsRef.targetType,
    })
  }

  private updateIntermediateCalculations = (): void => {
    const { x: x1, y: y1 } = this.sourceNodeOptionsRef.position
    const { x: x2, y: y2 } = this.targetNodeOptionsRef.position

    if (this.factor === 0) {
      this.intermediateCalculations.labelPosition = new Point(
        (x1 + x2) / 2,
        (y1 + y2) / 2
      )

      const sourceNodeR = getNodeOuterSize(this.sourceNodeOptionsRef)
      const targetNodeR = getNodeOuterSize(this.targetNodeOptionsRef)

      const sourceAlpha = -Math.atan2(x1 - x2, y1 - y2)
      const targetAlpha = -Math.atan2(x2 - x1, y2 - y1)

      this.intermediateCalculations.sourceNodeToTargetAngle = sourceAlpha

      this.intermediateCalculations.sourceNodeIntersection = new Point(
        x1 + sourceNodeR * Math.sin(Math.PI - sourceAlpha),
        y1 + sourceNodeR * Math.cos(Math.PI - sourceAlpha)
      )

      this.intermediateCalculations.targetNodeIntersection = new Point(
        x2 + targetNodeR * Math.sin(Math.PI - targetAlpha),
        y2 + targetNodeR * Math.cos(Math.PI - targetAlpha)
      )

      this.intermediateCalculations.sourceTipRotation = sourceAlpha
      this.intermediateCalculations.targetTipRotation = targetAlpha

      const sourceNodeR_ =
        getNodeOuterSize(this.sourceNodeOptionsRef) +
        getTipLength(this.optionsRef, this.optionsRef.sourceType)
      const targetNodeR_ =
        getNodeOuterSize(this.targetNodeOptionsRef) +
        getTipLength(this.optionsRef, this.optionsRef.targetType)

      this.intermediateCalculations.edgePoint1 = new Point(
        x1 + sourceNodeR_ * Math.sin(Math.PI - sourceAlpha),
        y1 + sourceNodeR_ * Math.cos(Math.PI - sourceAlpha)
      )

      this.intermediateCalculations.edgePoint2 = new Point(
        x2 + targetNodeR_ * Math.sin(Math.PI - targetAlpha),
        y2 + targetNodeR_ * Math.cos(Math.PI - targetAlpha)
      )

      return
    }

    const pointBetweenNodes = new Point((x1 + x2) / 2, (y1 + y2) / 2)
    const radiusVectorFromPBNToSecondNode = new Point(
      x2 - pointBetweenNodes.x,
      y2 - pointBetweenNodes.y
    )
    const radiusVectorFromPBNToSecondNodeScaleFactor =
      (CURVE_SIZE * this.factor) /
      Math.hypot(
        radiusVectorFromPBNToSecondNode.x,
        radiusVectorFromPBNToSecondNode.y
      )
    const scaledRadiusVectorFromPBNToSecondNode = new Point(
      radiusVectorFromPBNToSecondNode.x *
        radiusVectorFromPBNToSecondNodeScaleFactor,
      radiusVectorFromPBNToSecondNode.y *
        radiusVectorFromPBNToSecondNodeScaleFactor
    )
    const edgeCenterPoint = new Point(
      pointBetweenNodes.x + scaledRadiusVectorFromPBNToSecondNode.y,
      pointBetweenNodes.y - scaledRadiusVectorFromPBNToSecondNode.x
    )

    this.intermediateCalculations.edgeCenterPoint = edgeCenterPoint

    this.calculateIntersectionPoints(x1, y1, edgeCenterPoint, 'source')
    this.calculateIntersectionPoints(x2, y2, edgeCenterPoint, 'target')

    this.intermediateCalculations.sourceNodeToTargetAngle = -Math.atan2(
      x2 - x1,
      y2 - y1
    )

    const bezierEquation = getBezierEquation(
      this.intermediateCalculations.edgePoint1,
      this.intermediateCalculations.edgeCenterPoint,
      this.intermediateCalculations.edgePoint2
    )

    const [labelPositionX, labelPositionY] = bezierEquation(0.5)
    this.intermediateCalculations.labelPosition = new Point(
      labelPositionX,
      labelPositionY
    )
  }

  private calculateIntersectionPoints = (
    x: number,
    y: number,
    edgeCenterPoint: Point,
    type: 'source' | 'target'
  ) => {
    const nodeOptions =
      type === 'source' ? this.sourceNodeOptionsRef : this.targetNodeOptionsRef
    const edgePointKey = type === 'source' ? 'edgePoint1' : 'edgePoint2'
    const nodeIntersectionKey =
      type === 'source' ? 'sourceNodeIntersection' : 'targetNodeIntersection'
    const tipRotationKey =
      type === 'source' ? 'sourceTipRotation' : 'targetTipRotation'

    // Find line parameters
    const m = (edgeCenterPoint.y - y) / (edgeCenterPoint.x - x)
    const n = y - m * x

    // Find circle parameters
    const r =
      getNodeOuterSize(nodeOptions) +
      getTipLength(this.optionsRef, this.optionsRef[`${type}Type`])
    const h = x
    const k = y

    const [i1, i2] = getCircleLineIntersection(r, h, k, m, n)

    if (!i1 || !i2) return

    const intersection =
      Math.hypot(edgeCenterPoint.x - i1.x, edgeCenterPoint.y - i1.y) <
      Math.hypot(edgeCenterPoint.x - i2.x, edgeCenterPoint.y - i2.y)
        ? i1
        : i2

    this.intermediateCalculations[edgePointKey] = new Point(
      intersection.x,
      intersection.y
    )

    const alpha = -Math.atan2(x - intersection.x, y - intersection.y)
    this.intermediateCalculations[tipRotationKey] = alpha

    const nodeR = getNodeOuterSize(nodeOptions)

    this.intermediateCalculations[nodeIntersectionKey] = new Point(
      x + nodeR * Math.sin(Math.PI - alpha),
      y + nodeR * Math.cos(Math.PI - alpha)
    )
  }

  private createEdge = () => {
    this.edgeGfx = new CurveEdgeGfx()
    this.cullContainer.addChild(this.edgeGfx.gfx)

    this.edgeGfx.gfx.eventMode = 'static'
    this.edgeGfx.gfx.cursor = 'pointer'

    this.edgeGfx.gfx.on('mouseover', this.createEventEmitter('mouseover'))
    this.edgeGfx.gfx.on('mouseout', this.createEventEmitter('mouseout'))
    this.edgeGfx.gfx.on('mousedown', this.createEventEmitter('mousedown'))
    this.edgeGfx.gfx.on('mouseup', this.createEventEmitter('mouseup'))
    this.edgeGfx.gfx.on('rightup', this.createEventEmitter('mouseup'))
  }

  private createTargetTip = () => {
    this.targetTipGfx = new EdgeTipGfx({
      type: this.optionsRef.targetType,
    })
    this.cullContainer.addChild(this.targetTipGfx.gfx)

    this.targetTipGfx.gfx.eventMode = 'static'
    this.targetTipGfx.gfx.cursor = 'pointer'

    this.targetTipGfx.gfx.on(
      'mouseover',
      this.createEventEmitter('mouseover', TARGET.EDGE_TIP)
    )
    this.targetTipGfx.gfx.on(
      'mouseout',
      this.createEventEmitter('mouseout', TARGET.EDGE_TIP)
    )
    this.targetTipGfx.gfx.on(
      'mousedown',
      this.createEventEmitter('mousedown', TARGET.EDGE_TIP)
    )
    this.targetTipGfx.gfx.on(
      'mouseup',
      this.createEventEmitter('mouseup', TARGET.EDGE_TIP)
    )
    this.targetTipGfx.gfx.on(
      'rightup',
      this.createEventEmitter('mouseup', TARGET.EDGE_TIP)
    )
  }

  private createSourceTip = () => {
    this.sourceTipGfx = new EdgeTipGfx({
      type: this.optionsRef.sourceType,
    })
    this.cullContainer.addChild(this.sourceTipGfx.gfx)

    this.sourceTipGfx.gfx.eventMode = 'static'
    this.sourceTipGfx.gfx.cursor = 'pointer'

    this.sourceTipGfx.gfx.on(
      'mouseover',
      this.createEventEmitter('mouseover', TARGET.EDGE_TIP)
    )
    this.sourceTipGfx.gfx.on(
      'mouseout',
      this.createEventEmitter('mouseout', TARGET.EDGE_TIP)
    )
    this.sourceTipGfx.gfx.on(
      'mousedown',
      this.createEventEmitter('mousedown', TARGET.EDGE_TIP)
    )
    this.sourceTipGfx.gfx.on(
      'mouseup',
      this.createEventEmitter('mouseup', TARGET.EDGE_TIP)
    )
    this.sourceTipGfx.gfx.on(
      'rightup',
      this.createEventEmitter('mouseup', TARGET.EDGE_TIP)
    )
  }

  private createLabel = () => {
    this.edgeLabelGfx = new LabelGfx()
    this.cullContainer.addChild(this.edgeLabelGfx.gfx)

    this.edgeLabelGfx.gfx.eventMode = 'static'
    this.edgeLabelGfx.gfx.cursor = 'pointer'

    this.edgeLabelGfx.gfx.on(
      'mouseover',
      this.createEventEmitter('mouseover', TARGET.EDGE_LABEL)
    )
    this.edgeLabelGfx.gfx.on(
      'mouseout',
      this.createEventEmitter('mouseout', TARGET.EDGE_LABEL)
    )
    this.edgeLabelGfx.gfx.on(
      'mousedown',
      this.createEventEmitter('mousedown', TARGET.EDGE_LABEL)
    )
    this.edgeLabelGfx.gfx.on(
      'mouseup',
      this.createEventEmitter('mouseup', TARGET.EDGE_LABEL)
    )
    this.edgeLabelGfx.gfx.on(
      'rightup',
      this.createEventEmitter('mouseup', TARGET.EDGE_LABEL)
    )
  }

  private createEventEmitter =
    (type: keyof CurvePixiEdgeEvents, currentTarget: TARGET = TARGET.EDGE) =>
    (event: FederatedMouseEvent) =>
      this.emit(
        type,
        new PixiEvent({
          event,
          target: TARGET.EDGE,
          currentTarget,
          payload: { id: this.id },
        })
      )
}
