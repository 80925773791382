import { inject, injectable } from 'inversify'
import { isEVM } from '@clain/core/types/coin'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { MIXER_ICON } from '../constants/mixer'
import { DemixTrackTrx } from '../types/converted/DemixTrackTrx'
import { demixKey } from '../utils/key'
import { TransactionProbeNodeUtxo } from '../types/entities/TransactionProbeNodeUTXO'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { GraphEventsOptions, IProbeEvents } from './ProbeEvents'
import { IProbeState } from './ProbeState'
import { ProbeApp } from '../types/ProbeApp'
import { getProbeModule } from '../di'
import {
  TransactionEvmSearchResult,
  TransactionSearchResult,
  TransactionSocketSearch,
  TransactionUtxoSearchResult,
} from '@platform/components/ProbeSandbox/vm/services/SearchService/SearchService.types'
import { SearchService } from '@platform/components/ProbeSandbox/vm/services/SearchService'
import { DemixTrackEvm } from '@platform/components/ProbeSandbox/types/converted/DemixTrackEvm'
import { DemixTrackUtxo } from '@platform/components/ProbeSandbox/types/converted/DimixTrackUtxo'
import { ProbeGraphEventsMeta } from '@platform/components/ProbeSandbox/types/ProbeGraphEvents'

type EnrichedTransactionUTXO = DemixTrackTrx['transactions'][number] &
  TransactionUtxoSearchResult

type EnrichedTransactionEVM = DemixTrackTrx['transactions'][number] &
  TransactionEvmSearchResult

@injectable()
export class DemixActionViewModel {
  @observable private id: null | string = null
  @observable public isTrackListPopupOpened = false
  @observable private trackListPopupPosition = { x: 0, y: 0 }
  @observable private demixTransactionsFetching: Record<number, boolean> = {}

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeApp) private app: ProbeApp,
    @inject(DI_PROBE_TYPES.SearchService)
    private searchService: SearchService
  ) {
    makeObservable(this)
  }

  @action
  public init = () => {
    this.app = getProbeModule(DI_PROBE_TYPES.ProbeApp)
    this.app.on('moved', this.updateTrackListPopupPosition)

    reaction(
      () => this.probeState.nodes.get(this.id)?.position,
      (newCanvasRelatedPosition) => {
        if (newCanvasRelatedPosition) {
          this.updateTrackListPopupPosition()
        }
      }
    )
  }

  @action
  private updateTrackListPopupPosition = () => {
    if (this.probeState.nodes.has(this.id)) {
      const node = this.probeState.nodes.get(this.id)
      this.trackListPopupPosition = this.app.toGlobalCoordinates({
        x: node.position.x,
        y:
          node.position.y -
          (this.app.graph.getNodeAttributes(node.key).withPills ? 50 : 35),
      })
    }
  }

  @computed public get getTrackListPopupPosition() {
    if (this.id) {
      return this.trackListPopupPosition
    }
    return null
  }

  @computed public get getTrackListData(): Array<
    DemixTrackTrx & { isChecked: boolean; isProcessing: boolean }
  > {
    if (this.id) {
      const node = this.probeState.nodes.get(
        this.id
      ) as TransactionProbeNodeUtxo

      return (
        node?.data?.demixTracks?.map((demixTrack) => ({
          ...demixTrack,
          currency: node.data.currency,
          trackIcon: MIXER_ICON[demixTrack.entityId],
          confidenceLevel: +(demixTrack.confidenceLevel * 100).toFixed(2),
          isChecked: this.probeState.nodes.has(demixKey(demixTrack)),
          isProcessing:
            this.probeEvents.meta.nodesInProcessing[demixTrack.id] ||
            this.demixTransactionsFetching[demixTrack.id],
        })) || []
      )
    }
    return null
  }

  @action
  public onSelectDemixTrack = async ({
    data,
    isSelected,
    closeTrackList = true,
  }: {
    data: DemixTrackTrx
    isSelected: boolean
    closeTrackList?: boolean
  }) => {
    if (isSelected) {
      try {
        this.demixTransactionsFetching[data.id] = true
        const transactions = await this.fetchTransactions(data.transactions)
        const enrichedTransactions = this.enrichTransactions(
          data.transactions,
          transactions
        )
        const options = {
          animation: true,
          accumulationEntitiesKeyType: 'newAdded',
          animationType: {
            strategy: 'moveToCentroid',
            scaleStrategy: 'auto',
          },
        } as const
        if (isEVM(data)) {
          this.emitDemixTrackNodeEVM(
            data,
            enrichedTransactions as EnrichedTransactionEVM[]
          )
        } else {
          this.emitDemixTrackNodeUTXO(
            data,
            enrichedTransactions as EnrichedTransactionUTXO[],
            options
          )
        }
      } catch (error) {
        console.error('Error processing DemixTrack:', error)
      } finally {
        this.demixTransactionsFetching[data.id] = false
      }
    } else {
      this.emitDeleteNode(data)
    }

    if (closeTrackList) {
      this.closeDemixTrackListPopup()
    }
  }

  private async fetchTransactions(
    transactions: Array<{ hash: string }>
  ): Promise<TransactionSocketSearch[]> {
    try {
      const bulkData = transactions.map((trx) => trx.hash).join(' ')
      const result = await this.searchService
        .bulkSearch({
          withClusters: false,
          term: bulkData,
        })
        .catch((err) => {
          console.error('Error fetching blockchain results', err as Error)
          return null // Return null for failed requests
        })
      return result.transactions
    } catch (error) {
      console.error('Error fetching transactions:', error)
      throw error // Propagate error to the caller
    }
  }

  private enrichTransactions(
    originalTransactions: DemixTrackTrx['transactions'],
    blockchainTransactions: TransactionSearchResult[]
  ) {
    return originalTransactions.map((trx) => ({
      ...trx,
      ...blockchainTransactions.find(
        (transaction) => transaction.hash === trx.hash
      ),
    }))
  }

  private emitDemixTrackNodeUTXO(
    data: DemixTrackUtxo,
    transactions: EnrichedTransactionUTXO[],
    options?: GraphEventsOptions & ProbeGraphEventsMeta
  ): void {
    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            strategy: 'demix',
            id: data.id,
            currency: data.currency,
            sourceNodeKey: this.id,
            transactions: transactions.map((trx) => {
              const outputToOpen = trx.outputs.find(
                (output) => trx.position === output.position
              )
              return {
                ...trx,
                outputs: outputToOpen ? [outputToOpen] : trx.outputs,
                inputs: [trx.inputs[0]],
              }
            }),
          },
        },
      ],
      options
    )
  }

  private emitDemixTrackNodeEVM(
    data: DemixTrackEvm,
    transactions: EnrichedTransactionEVM[],
    options?: GraphEventsOptions & ProbeGraphEventsMeta
  ): void {
    this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            strategy: 'demix',
            id: data.id,
            currency: data.currency,
            sourceNodeKey: this.id,
            transactions,
            /*transactions: transactions.map((trx) => {
              const internalToOpen = trx.internals.find(
                (item) => trx.position === item.index
              )
              const transferToOpen = trx.transfers.find(
                (item) => trx.position === item.logIndex
              )
              const tokenToOpen = trx.tokens.find(
                (item) => trx.position === item.logIndex
              )

              return {
                ...trx,
                internals: internalToOpen ? [internalToOpen] : trx.internals,
                transfers: transferToOpen ? [transferToOpen] : trx.transfers,
                tokens: tokenToOpen ? [tokenToOpen] : trx.tokens,
              }
            }),*/
          },
        },
      ],
      options
    )
  }

  private emitDeleteNode(data: DemixTrackTrx): void {
    const key = demixKey(data)
    this.probeEvents.emit([{ type: 'delete_node', entity: { key } }])
  }

  @action
  private reset = () => {
    this.id = null
    this.isTrackListPopupOpened = false
    this.trackListPopupPosition = { x: 0, y: 0 }
  }

  @action
  public openDemixTrackListPopup = (id: typeof this.id) => {
    if (id !== this.id && this.id !== null) {
      this.closeDemixTrackListPopup()
    }
    this.id = id
    this.isTrackListPopupOpened = true
  }

  public closeDemixTrackListPopup = () => {
    this.reset()
  }
}
