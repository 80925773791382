import { isEVM, isUTXO, type CoinType } from '@clain/core/types/coin'
import { mergeByKeys } from '@clain/core/utils'
import { action, computed, makeObservable } from 'mobx'
import type { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import type { FlowEdgeData } from '../../../types/edgeEntitiesData/FlowEdgeData'
import type { ServerUpdateEdgeEvent } from '../../../types/serverData'
import { DEFAULT_USD_TOKEN } from '@clain/core/utils'
import { edgeKey } from '../../../utils/key'
import type { IPaletteController } from '../../PaletteController'
import type { IProbeEvents } from '../../ProbeEvents'
import { ActiveEntity } from '../ActiveEntity'
import type { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import type {
  TransactionActiveEntityFetchState,
  TokenByAddressActiveEntityFetchFacade,
  TransactionActiveEntityFetchFacade,
  TokensActiveEntityFetchFacade,
} from '../ActiveEntityFetch'
import {
  DEFAULT_FILTERS_CURRENCY,
  DEFAULT_TOKENS_FILTERS,
  EXCLUDE_FILTERS_CURRENCY,
  INITIAL_FILTERS_CURRENCY,
} from '../constants'
import { applyAllTransferTokens } from '../helpers'
import { normalizeOldTransactionEvm } from '../helpers/normalizeTransaction'
import type {
  FlowEntitiesData,
  FlowEntitiesFetch,
  FlowEntitiesFetchState,
  FlowEntitiesFilters,
  FlowKey,
} from './ActiveEntityFlow.types'
import { checkCoinsByType } from '@clain/core/utils/checkCoinByType'
import { EVM_COINS } from '@clain/core/utils/currency'
import { injectable, inject } from 'inversify'
import { tokenByAddressFilters } from '../ActiveEntityFilters'
import { tokensFilters } from '../ActiveEntityFilters'
import { transactionsFilters } from '../ActiveEntityFilters'
import { activeEntityFlowState } from '../ActiveEntityState'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from '../../ProbeState'
import { IProbeGraph } from '../../ProbeGraph'

@injectable()
export class ActiveEntityFlow {
  private key: FlowKey = { from: '', to: '' }
  public currency: CoinType
  private entityKey = 'flow'
  private activeEntity: ActiveEntity<
    FlowEntitiesFetchState,
    FlowEntitiesFetch,
    FlowEntitiesFilters,
    FlowEntitiesData
  > = null

  constructor(
    @inject(DI_PROBE_TYPES.ActiveEntityEvents)
    private activeEntityEvents: IActiveEntityEvents,
    @inject(DI_PROBE_TYPES.ProbeEvents)
    private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController,
    @inject(DI_PROBE_TYPES.TransactionsFetchStateFacade)
    private transactionsFetch: TransactionActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.TokensFetchStateFacade)
    private tokensFetch: TokensActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.TokenByAddressFetchStateFacade)
    private tokenByAddressFetch: TokenByAddressActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.ProbeState)
    private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeGraph)
    private probeGraph: IProbeGraph
  ) {
    makeObservable(this)
    this.activeEntity = new ActiveEntity(
      {
        entitiesFetchState: {
          transactions: this.transactionsFetch.fetchState,
          tokens: this.tokensFetch.fetchState,
          tokenByAddress: this.tokenByAddressFetch.fetchState,
        },
        entitiesFetch: {
          transactions: this.transactionsFetch,
          tokens: this.tokensFetch,
          tokenByAddress: this.tokenByAddressFetch,
        },
        entitiesFilters: {
          transactions: transactionsFilters,
          tokens: tokensFilters,
          tokenByAddress: tokenByAddressFilters,
        },
        entityState: activeEntityFlowState,
        entityKey: this.entityKey,
      },
      this.probeState
    )
  }

  @action
  public init(currency: CoinType) {
    if (currency) {
      this.activeEntity.init(currency)
      this.currency = currency

      transactionsFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])
      tokensFilters.initFilters(DEFAULT_TOKENS_FILTERS)
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.activeEntity.currency]
  }

  private get transactionFilterTokenId() {
    return this.activeEntity.entitiesFilters.transactions.filters
      ?.includeTokens?.[0]?.id
  }

  private updateFlowEdgeTokenId = (
    ...args: Parameters<
      typeof this.activeEntity.entitiesFilters.transactions.updateFilters
    >
  ) => {
    const [{ includeTokens }] = args
    const tokenId = includeTokens?.[0]?.id || this.transactionFilterTokenId
    this.probeEvents.emit([
      {
        type: 'update_edge',
        key: this.data.edgeKey,
        data: {
          type: 'flow',
          edgeData: {
            tokenId: tokenId != null ? tokenId : DEFAULT_USD_TOKEN.id,
          },
        },
      },
    ])
  }

  private proxyUpdateFilters = (
    entityFilters: typeof this.activeEntity.entitiesFilters,
    cb: typeof this.activeEntity.entitiesFilters.transactions.updateFilters
  ) => {
    return {
      ...entityFilters,
      transactions: {
        ...entityFilters.transactions,
        defaultFilters: entityFilters.transactions.defaultFilters,
        updateFilters: (
          ...args: Parameters<typeof entityFilters.transactions.updateFilters>
        ) => {
          entityFilters.transactions.updateFilters(...args)
          cb(...args)
        },
      },
    }
  }

  @computed
  public get filters() {
    return this.proxyUpdateFilters(
      this.activeEntity.entitiesFilters,
      this.updateFlowEdgeTokenId
    )
  }

  @computed
  public get tokensBalance() {
    return this.tokensFetch.fetchState?.state?.tokens || []
  }

  @computed
  public get tokens() {
    return this.tokensBalance.map((token) => token.token) || []
  }

  @computed
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed
  public get transactionTokens() {
    if (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters?.address,
        EVM_COINS,
        'address'
      )
    ) {
      return this.tokenByAddressFetch.fetchState?.state
        ? [this.tokenByAddressFetch.fetchState?.state]
        : []
    }

    return this.tokensWithoutAggregated.filter(
      (token) => !token?.spam && !token?.scam
    )
  }

  @computed
  public get tokenByAddressLoading() {
    return this.tokenByAddressFetch.fetchState?.loading
  }

  @computed
  public get disabledTransactionAssetStaticSearch() {
    return (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters.address,
        EVM_COINS,
        'address'
      )
    )
  }

  @action
  public setTokenByAddress = (address: string) => {
    if (isUTXO(this.activeEntity.currency)) return

    if (checkCoinsByType(address, EVM_COINS, 'address')) {
      tokenByAddressFilters.updateFilters({ address })
      return
    }

    tokenByAddressFilters.updateFilters({ address: '' })
  }

  @computed.struct
  public get transactions(): TransactionActiveEntityFetchState {
    const result = this.activeEntity.entitiesFetchState.transactions

    if (result.state?.data?.transactions?.length) {
      return mergeByKeys(
        'state.data.transactions',
        applyAllTransferTokens(result.state?.data?.transactions),
        result
      ) as TransactionActiveEntityFetchState
    }

    return result as TransactionActiveEntityFetchState
  }

  @computed
  public get data() {
    return activeEntityFlowState.state
  }

  @action
  public clear() {
    this.activeEntity.clear()
  }

  @action
  public update = (...args: Parameters<typeof this.activeEntity.update>) => {
    const flowEdge = this.probeState.edges.get(args[1].edgeKey)
      ?.data as unknown as FlowEdgeData
    this.activeEntity.updateEntityKey(`${this.entityKey}_${args[1].edgeKey}`)
    const updateFilters = {
      ...INITIAL_FILTERS_CURRENCY[this.currency],
      ...(flowEdge?.token?.id ? { includeTokens: [flowEdge.token] } : {}),
      direction: args[1].direction,
      ...(args[1].counterpartyType === 'address'
        ? { counterpartyAddressId: args[1].counterpartyId }
        : { counterpartyId: args[1].counterpartyId }),
    }

    this.activeEntity.invalidateCache(
      args[0],
      'transactions',
      (cachedFilters) => {
        if (!cachedFilters) {
          return false
        }

        const tokenId = updateFilters.includeTokens?.[0].id

        if (tokenId) {
          return cachedFilters?.includeTokens?.[0].id !== tokenId
        }

        return false
      }
    )

    this.activeEntity.update(...args)

    this.key.from = this.probeGraph.source(args[1].edgeKey)
    this.key.to = this.probeGraph.target(args[1].edgeKey)

    //update
    transactionsFilters.updateFilters(updateFilters, true)
  }

  @computed
  public get mutalAllowed(): boolean {
    return Boolean(this.data?.oppositeAmount)
  }

  @computed
  public get showInOutFlowIcon(): boolean {
    if (
      this.probeState.edges.get(edgeKey(this.key.from, this.key.to)) &&
      this.probeState.edges.get(edgeKey(this.key.to, this.key.from))
    ) {
      return false
    }

    return this.mutalAllowed
  }

  @computed
  public get showNetFlowIcon(): boolean {
    const fromEdge = this.probeState.edges.get(
      edgeKey(this.key.from, this.key.to)
    )
    const toEdge = this.probeState.edges.get(
      edgeKey(this.key.to, this.key.from)
    )

    if (fromEdge && fromEdge.data.edgeType === 'flow') {
      return !fromEdge.data?.net
    }

    if (toEdge && toEdge.data.edgeType === 'flow') {
      return !toEdge.data?.net
    }

    return false
  }

  @action.bound
  private hideDetailsInfo = () => {
    this.probeState.setIsBottombarActive(false)
    this.probeState.setIsInfobarActive(false)
    this.probeState.selectedEdgeIds.clear()
  }

  @action
  public toggleTransaction = async (
    data: ClusterTransactionInputsOutputsAggregate,
    select: boolean
  ) => {
    await this.toggleAllTransactions([data], select)
  }

  @action
  public toggleAllTransactions = async (
    data: Array<ClusterTransactionInputsOutputsAggregate>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transaction',
      normalizeOldTransactionEvm(data),
      select
    )
  }

  @action
  public showInOutFlow = () => {
    this.activeEntityEvents.emit('inOutFlow', {
      entity: [this.data.sourceData, this.data.targetData],
      options: { tokenId: this.transactionFilterTokenId },
    })
    this.hideDetailsInfo()
  }

  @action
  public showNetFlow = () => {
    const { events } = this.activeEntityEvents.emit('netFlow', {
      entity: [this.data.sourceData, this.data.targetData],
      options: { tokenId: this.transactionFilterTokenId },
    })

    const selectedEdge = events.find(
      (event: ServerUpdateEdgeEvent) =>
        event.key === this.probeState.selectedEdge.key
    )

    if (!selectedEdge) {
      this.hideDetailsInfo()
    } else {
      this.activeEntity.update(this.data.sourceData.clusterId, {
        ...this.data,
        net: true,
      })
    }
  }

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
