import { Channel, Presence } from 'phoenix'
import {
  IUserPresenceService,
  ServerUserPresence,
  UsersPresenceDiff,
} from './types'
import { PresenceOnJoinCallback, PresenceOnLeaveCallback } from 'phoenix'

export class UserPresenceService implements IUserPresenceService {
  private readonly presence: Presence | null = null

  constructor(channel: Channel) {
    if (!channel) {
      throw new Error('Invalid channel provided to UserPresenceService')
    }

    try {
      this.presence = new Presence(channel)
    } catch (error) {
      console.error('Failed to initialize PhoenixPresence', error)
      throw error
    }
  }

  public listUsers = <T>(
    callback: (key: string, presence: ServerUserPresence) => T
  ): T[] => {
    if (typeof callback !== 'function') {
      throw new Error('Invalid callback provided to listUsers')
    }

    if (!this.presence) {
      throw new Error('UserPresenceService is not initialized')
    }

    return this.presence.list<T>(callback)
  }

  public onSync = (callback: () => void): void => {
    if (typeof callback !== 'function') {
      throw new Error('Invalid callback provided to onSync')
    }

    if (!this.presence) {
      throw new Error('UserPresenceService is not initialized')
    }

    this.presence.onSync(callback)
  }

  public onUserJoined(callback: PresenceOnJoinCallback) {
    if (typeof callback !== 'function') {
      throw new Error('Invalid callback provided to onUserJoined')
    }

    if (!this.presence) {
      throw new Error('UserPresenceService is not initialized')
    }

    this.presence.onJoin(callback)
  }

  public onUserLeft(callback: PresenceOnLeaveCallback) {
    if (typeof callback !== 'function') {
      throw new Error('Invalid callback provided to onUserLeft')
    }

    if (!this.presence) {
      throw new Error('UserPresenceService is not initialized')
    }

    this.presence.onLeave(callback)
  }

  public onSyncUsers = (callback: (users: UsersPresenceDiff[]) => void) =>
    this.onSync(() =>
      callback(
        this.listUsers((userId, { metas: [firstDevice] }) => {
          return {
            userId,
            meta: {
              color: firstDevice?.color,
              cursorPosition: firstDevice?.cursor_position,
            },
          }
        })
      )
    )
}
