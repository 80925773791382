import {
  action,
  autorun,
  computed,
  makeObservable,
  reaction,
  comparer,
  observable,
} from 'mobx'
import type { IReactionDisposer } from 'mobx'
import { captureException } from '@sentry/react'
import { injectable, inject } from 'inversify'

import ProbeApp from '@clain/graph'
import type { LayoutType } from '@clain/graph-layout'
import type { ProbeApp as ProbeAppType } from '../types/ProbeApp'
import type { ILayersViewModel } from './LayersViewModel'
import type { SearchService } from './services/SearchService/SearchService'
import type { ActiveEntityViewModel } from './active-entity/ActiveEntityViewModel'
import type { IPointerController } from './PointerController'
import type { AsyncQueue } from './queue/AsyncQueue'
import type { IExtendedLayoutPanelViewModel } from './_ExtendedLayoutPanelViewModel'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import type { ProbeFullData } from './services/ProbeService'
import type { ProbeService } from './services/ProbeService'
import { shiftOrMod } from '@clain/core/utils/tools'
import {
  AlertsViewModel,
  SettingsViewModel,
  UserPresenceViewModel,
} from '../../../modules'
import { getAddressIdProbe } from './active-entity/helpers/getAddressId'
import { alertEventsCountState } from '../../../modules/alerts/AlertsViewModel.utils'
import { applyAlertCount } from './vm.utils'
import type { CameraViewModel } from './CameraViewModel'
import type { ReciveEvents } from './ReciveEvents/ReciveEvents'
import type { IProbeState } from './ProbeState'
import type { EventsGraphReaction } from './EventsGraphReaction'
import type { AIReportService } from './services/AIReportService'
import type { IProbeEvents, Position } from './ProbeEvents'
import { normalizeEventToNodeData } from '../utils/normalizeEventToNodeData'
import { compose } from 'ramda'
import { normalizeEventWithAlertCount } from '../utils/normalizeEventWithAlertCount'
import { Notification } from '@clain/core/ui-kit'
import { PROBE_NOTIFICATION_STYLES } from '../constants'
import { render as renderSVG } from '@caudena/probe-svg-renderer'
import type { DemixActionViewModel } from './DemixActionViewModel'
import { normalizeEventReciveDataToRequest } from '../utils/normalizeEventReciveDataToRequest'
import type { EntityDataToSnapshot } from './EntityDataToSnapshot'
import { somePositionIsDiff } from '../utils/somePositionIsDiff'
import type { IEntityServices } from './services/EntitiesServices/types'
import type { GraphEntityEventFacade } from './GraphEntityEvent'
import {
  Settings,
  ServerCamera,
  ServerNodeData,
  ServerEdgeData,
  ServerGraphData,
} from '../types/serverData'
import { moveProbeNodes } from './layout'
import {
  WORLD_WIDTH,
  WORLD_HEIGHT,
  MIN_SCALE,
  MAX_SCALE,
  GRAPH_BACKGROND_COLOR,
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
} from './constants'
import type { IDeleteEntityController } from './DeleteEntityController'
import type { CircularMenuViewModel } from './CircularMenuViewModel/CircularMenuViewModel'
import type { IRearrangeNodesController } from './controllers'
import { GraphFactoryEntities } from '@clain/graph-factory-entities'
import type { NodeData } from '../types/nodeEntitiesData/NodeData'
import type { EdgeData } from '../types/edgeEntitiesData/EdgeData'
import type { ReziseGraphViewport } from './ReziseGraphViewport'
import { probeContainer } from '../di/probeContainer'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type { IPlotEntities, IContainerLayoutState } from '../models'
import type { ProbeViewModelState } from './states/ProbeViewModelState'
import type { PositioningController } from 'packages/graph-entities/src/modules/PositioningController'
import type { IGraphHistory } from './GraphHistory'
import type { CommentsController } from './CommentsController'
import type TextController from './TextController/TextController'
import type { SearchController } from './SearchController'
import type { ShortcutMenuController } from './shortcut-menu/ShortcutMenuController'
import type { IAnimationEntities } from './AnimationEntities'
import type { IProbeGraph } from './ProbeGraph'

@injectable()
class ProbeViewModel {
  public app: ProbeAppType
  private reactionDisposers: Array<IReactionDisposer> = []
  public probeData: ProbeFullData
  @observable public userPresenceVM: UserPresenceViewModel
  public probeService: ProbeService
  private container: HTMLElement

  constructor(
    @inject(DI_PROBE_TYPES.AlertsViewModel)
    public alerts: AlertsViewModel,
    @inject(DI_PROBE_TYPES.LayersViewModel)
    public layers: ILayersViewModel,
    @inject(DI_PROBE_TYPES.CameraViewModel)
    private camera: CameraViewModel,
    @inject(DI_PROBE_TYPES.EntityDataToSnapshot)
    private entityDataToSnapshot: EntityDataToSnapshot,
    @inject(DI_PROBE_TYPES.ReactionGraphEventsSettings)
    private reactionGraphEventsSettings: IReactionDisposer,
    @inject(DI_PROBE_TYPES.ProbeGraph) private probeGraph: IProbeGraph,
    @inject(DI_PROBE_TYPES.GraphEntityEventFacade)
    private graphEntityEventsFacade: GraphEntityEventFacade,
    @inject(DI_PROBE_TYPES.AnimationEntities)
    private animationEntities: IAnimationEntities,
    @inject(DI_PROBE_TYPES.ShortcutMenuController)
    public shortcutMenuController: ShortcutMenuController,
    @inject(DI_PROBE_TYPES.SearchController)
    public searchController: SearchController,
    @inject(DI_PROBE_TYPES.TextController)
    public textController: TextController,
    @inject(DI_PROBE_TYPES.ProbeEvents)
    public probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.CommentsController)
    public commentsController: CommentsController,
    @inject(DI_PROBE_TYPES.ActiveEntityViewModel)
    public activeEntity: ActiveEntityViewModel,
    @inject(DI_PROBE_TYPES.DemixActionViewModel)
    public demixAction: DemixActionViewModel,
    @inject(DI_PROBE_TYPES.ProbeState) public probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeViewModelState)
    private state: ProbeViewModelState,
    @inject(DI_PROBE_TYPES.PointerController)
    public pointerController: IPointerController,
    @inject(DI_PROBE_TYPES.PositioningController)
    public positioningController: PositioningController,
    @inject(DI_PROBE_TYPES.AsyncQueue) public asyncQueue: AsyncQueue,
    @inject(DI_PROBE_TYPES.ExtendedLayoutPanelViewModel)
    public _extendedLayoutPanel: IExtendedLayoutPanelViewModel,
    @inject(DI_PROBE_TYPES.Settings) public settings: SettingsViewModel,
    @inject(DI_PROBE_TYPES.GraphFactoryEntities)
    private graphFactoryEntitiesInstance: GraphFactoryEntities<
      ServerNodeData,
      NodeData,
      ServerEdgeData,
      EdgeData
    >,
    @inject(DI_PROBE_TYPES.EventsGraphReaction)
    public eventsGraphReaction: EventsGraphReaction,
    @inject(DI_PROBE_TYPES.ReziseGraphViewport)
    private reziseGraphViewport: ReziseGraphViewport,
    @inject(DI_PROBE_TYPES.Factory)
    public factory: ReturnType<
      GraphFactoryEntities<
        ServerNodeData,
        NodeData,
        ServerEdgeData,
        EdgeData
      >['getFactoryEntity']
    >,
    @inject(DI_PROBE_TYPES.FactoryNodeEdge)
    public factoryNodeEdge: ReturnType<
      GraphFactoryEntities<
        ServerNodeData,
        NodeData,
        ServerEdgeData,
        EdgeData
      >['getFactoryEntities']
    >,
    @inject(DI_PROBE_TYPES.EntityServices)
    public entityServices: IEntityServices,
    @inject(DI_PROBE_TYPES.SearchService) public searchService: SearchService,
    @inject(DI_PROBE_TYPES.AIReportService)
    public generateAIService: AIReportService,
    @inject(DI_PROBE_TYPES.PlotEntitiesController)
    private plotEntitiesController: IPlotEntities,
    @inject(DI_PROBE_TYPES.CircularMenuViewModel)
    public circularMenuController: CircularMenuViewModel,
    @inject(DI_PROBE_TYPES.ReciveEvents) public reciveEvents: ReciveEvents,
    @inject(DI_PROBE_TYPES.RearrangeNodesController)
    public rearrangeNodesController: IRearrangeNodesController,
    @inject(DI_PROBE_TYPES.DeleteEntityController)
    private deleteEntityController: IDeleteEntityController,
    @inject(DI_PROBE_TYPES.GraphHistory) public history: IGraphHistory,
    @inject(DI_PROBE_TYPES.ContainerLayoutState)
    public readonly containerLayoutState: IContainerLayoutState
  ) {
    makeObservable(this)
  }

  public get _showLayoutPanelButton(): boolean {
    return config.ENV !== 'production'
  }

  @computed
  public get scaled(): number {
    return this.app.scaled
  }

  @computed
  public get center(): Position {
    return this.app.center
  }

  @computed
  public get grabbing(): boolean {
    return this.activeMouse
  }

  @computed
  public get isInitialized() {
    return this.state.isInitialized
  }

  @computed
  public get activeSpace() {
    return this.state.activeSpace
  }

  @computed
  public get activeMouse() {
    return this.state.activeMouse
  }

  @action
  public setIsInitialized(value: boolean) {
    this.state.setIsInitialized(value)
  }

  @action
  public setActiveSpace(value: boolean) {
    this.state.setActiveSpace(value)
  }

  @action
  public createApp = async (container: HTMLElement) => {
    this.state.setIsInitialized(false)
    probeContainer
      .rebind(DI_PROBE_TYPES.CanvasContainer)
      .toDynamicValue(() => container)
    this.container = container

    try {
      this.app = new ProbeApp({
        container: this.container,
        graph: this.probeGraph,
        resolution: window.devicePixelRatio + MAX_SCALE,
        backgroundColor: GRAPH_BACKGROND_COLOR,
        worldWidth: WORLD_WIDTH,
        worldHeight: WORLD_HEIGHT,
        minScale: MIN_SCALE,
        maxScale: MAX_SCALE,
      })

      probeContainer
        .rebind(DI_PROBE_TYPES.ProbeApp)
        .toDynamicValue(() => this.app)
        .inRequestScope()

      this.plotEntitiesController.initReactionRenderEntities()
      await this.app.init()
      this.reziseGraphViewport.observer()

      this.initReactions()
      this.activeEntity.init()
      this.demixAction.init()
      this.commentsController.init()
      this.textController.init()
      this.shortcutMenuController.init()
      this.generateAIService.init()
    } catch (e) {
      console.error(e)
      this.state.setIsInitialized(true)
      throw new Error(e)
    }
  }

  @action
  public initApp = async ({ probeId }: { probeId: string }) => {
    try {
      this.probeState.setProbeId(parseInt(probeId))
      probeContainer
        .rebind(DI_PROBE_TYPES.ProbeId)
        .toDynamicValue(() => probeId)
        .inTransientScope()

      this.probeService = probeContainer.get<ProbeService>(
        DI_PROBE_TYPES.ProbeService
      )

      this.userPresenceVM = this.probeService.userPresenceViewModel
      this.initListeners()
      await this.loadProbe()
    } catch (e) {
      if (e.reason) {
        Notification.notify(
          e.reason,
          { type: 'warning' },
          PROBE_NOTIFICATION_STYLES
        )
      }
    } finally {
      this.state.setIsInitialized(true)
    }
  }

  private factoryEntities = (
    graph: Pick<ServerGraphData, 'edges' | 'nodes'>
  ) => {
    try {
      if (graph?.nodes) {
        Object.entries(graph.nodes).forEach(
          ([, { id, position, nodeData, key }]) => {
            const count = alertEventsCountState(
              this.alerts.counts,
              getAddressIdProbe(nodeData)
            )

            this.factoryNodeEdge.produce('node', {
              key,
              data: {
                id,
                nodeData: applyAlertCount(nodeData, count),
                position,
              },
              settings: { locked: true },
            })
          }
        )
      }

      if (graph?.edges) {
        Object.entries(graph.edges).forEach(([key, data]) => {
          this.factoryNodeEdge.produce('edge', {
            key,
            data,
          })
        })
      }
    } catch (e) {
      console.error(e)
      captureException(e)
    }
  }

  @action
  private loadProbe = async () => {
    const { case: caseData, probe, graph } = await this.probeService.init()

    this.setProbeData(probe)
    this.probeState.setCaseData(caseData)
    if (graph?.settings) {
      this.setIsMagneticGridActive(graph?.settings.grid)
      this.layers.init(graph?.settings.layers)
    }

    this.camera.init(graph?.camera)
    this.app.setIsActiveGrid(this.isMagneticGridActive)
    this.factoryEntities(graph)

    this.positioningController.calculateSpaceMatrix()
    this.userPresenceViewModelInit(caseData?.owner)

    this.history.initSaveRequest(
      compose(
        this.probeService.sendGraphEvent,
        normalizeEventReciveDataToRequest
      )
    )

    this.probeEvents.initSaveRequest(this.probeService.sendGraphEvent)
    this.history.subscribe((snapshot) => {
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(snapshot)
      )
    })

    this.probeEvents.subscribe(({ events, meta }) => {
      if (meta.options.snapshotToHistory) {
        this.history.push(this.entityDataToSnapshot.eventToSnapshot(events))
      }
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(
          normalizeEventWithAlertCount(events, this.alerts.counts)
        )
      )
    })
    this.probeEvents.subscribe(({ emitEntitiesKeys, meta }) => {
      this.animationEntities.execute({
        entitiesKeys: emitEntitiesKeys,
        options: meta.options,
      })
    })

    this.probeEvents.subscribe(({ events }) => {
      const isActiveEntityContextChanged =
        this.activeEntity.selectedKey &&
        events.some(
          (event) => event.type === 'delete_node' || event.type === 'add_node'
        )
      if (isActiveEntityContextChanged) {
        this.activeEntity.detectType({ isForceDetect: true })
      }
    })

    this.probeService.subscribeUpdatedGraphEvent(this.reciveEvents.emitEvent)
    this.reciveEvents.subscribe(this.history.removeSnapshots.emitEvent)
    this.reciveEvents.subscribe((events) => {
      this.eventsGraphReaction.multipleEvents(
        normalizeEventToNodeData(
          normalizeEventWithAlertCount(events, this.alerts.counts)
        )
      )
    })
    this.rearrangeNodesController.subscribe((positions) => {
      this.asyncQueue.enQueue(async () => {
        if (somePositionIsDiff(positions, this.probeState.getNodePosition)) {
          this.probeEvents.emit(
            Object.keys(positions).map((key) => ({
              type: 'update_position',
              key,
              data: { position: positions[key] },
            })),
            { optimistic: true }
          )
          this.history.push(
            this.entityDataToSnapshot.nodesPositionToSnapshot(
              positions,
              this.probeState.getNodePosition
            )
          )
        }
        await moveProbeNodes({
          positions,
          graph: this.app.graph,
          probeNodes: this.probeState.nodes,
        })
      })
    })

    this.probeState.setInitialized(true)
  }

  @action
  private userPresenceViewModelInit = (caseDataOwner: number | null) => {
    this.probeService.userPresenceViewModel.init({
      settings: this.settings,
      pointer: this.pointerController,
      app: this.app,
      caseOwner: caseDataOwner,
    })
  }

  public initListeners() {
    //TODO extract to separate class all handlers
    this.app.on('node:click', this.graphEntityEventsFacade.handleNodeClick)
    this.app.on(
      'node:mouseover',
      this.graphEntityEventsFacade.handleNodeMouseOver
    )
    this.app.on(
      'node:mouseout',
      this.graphEntityEventsFacade.handleNodeMouseOut
    )
    this.app.on(
      'node:mousedown',
      this.graphEntityEventsFacade.handleNodeMouseDown
    )
    this.app.on('node:mouseup', this.graphEntityEventsFacade.handleNodeMouseUp)
    this.app.on('edge:mouseup', this.graphEntityEventsFacade.handleEdgeClick)
    this.app.on(
      'edge:mouseover',
      this.graphEntityEventsFacade.handleEdgeMouseOver
    )
    this.app.on(
      'edge:mouseout',
      this.graphEntityEventsFacade.handleEdgeMouseOut
    )
    this.app.on('select', this.graphEntityEventsFacade.handleSelectArea)
    this.app.on('unselect', this.graphEntityEventsFacade.handleUnSelectArea)
    this.app.on('world:mousedown', this.handleWorldMouseDown)
    this.app.on('world:click', this.graphEntityEventsFacade.handleWorldClick)
    this.app.on('world:mouseup', this.handleWorldMouseUp)
    this.app.on('world:wheel', this.handleWorldZoom)
    this.app.on('animated:zoom', this.handleWorldZoom)

    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    document.addEventListener('mousedown', this.handleMouseDown)
    document.addEventListener('mouseup', this.handleMouseUp)
  }

  public clear() {
    this.activeEntity.analytics?.clear()
    this.setIsAnalyticsLayerActive(false)
    this.reactionGraphEventsSettings()
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.app?.off('node:click', this.graphEntityEventsFacade.handleNodeClick)
    this.app?.off(
      'node:mouseover',
      this.graphEntityEventsFacade.handleNodeMouseOver
    )
    this.app?.off(
      'node:mouseout',
      this.graphEntityEventsFacade.handleNodeMouseOut
    )
    this.app?.off(
      'node:mousedown',
      this.graphEntityEventsFacade.handleNodeMouseDown
    )
    this.app?.off(
      'node:mouseup',
      this.graphEntityEventsFacade.handleNodeMouseUp
    )
    this.app?.off('edge:mouseup', this.graphEntityEventsFacade.handleEdgeClick)
    this.app?.off(
      'edge:mouseover',
      this.graphEntityEventsFacade.handleEdgeMouseOver
    )
    this.app?.off(
      'edge:mouseout',
      this.graphEntityEventsFacade.handleEdgeMouseOut
    )
    this.app?.off('select', this.graphEntityEventsFacade?.handleSelectArea)
    this.app?.off('unselect', this.graphEntityEventsFacade?.handleUnSelectArea)
    this.app?.off('world:mousedown', this.handleWorldMouseDown)
    this.app?.off(
      'world:mousedown',
      this.graphEntityEventsFacade.handleWorldClick
    )
    this.app?.off('world:mouseup', this.handleWorldMouseUp)
    this.app?.off('world:wheel', this.handleWorldZoom)
    this.app?.off('animated:zoom', this.handleWorldZoom)

    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
    document.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('mouseup', this.handleMouseUp)
    this.probeService?.clear()
    this.app?.removeAllListeners()
    this.activeEntity.clear()
    this.textController?.clear()
    this.probeState?.clear()
    this.generateAIService?.closeGenerateAIReportChannel()

    this.setIsRightSidebarActive(false)
    this.setIsAnalyticsLayerActive(false)
    this.probeState.setInitialized(false)
    this.plotEntitiesController?.clear()
    this.probeGraph.clear()
    this.probeEvents.clear()
    this.reciveEvents.clearSubscribers()
    this.history.clear()
    this.app.destroy()
    this.reziseGraphViewport.clear()
    this.rearrangeNodesController.clearSubscribers()
  }

  public downloadSVG = async () => {
    try {
      const svg = await renderSVG({
        nodes: Array.from(this.probeState.nodes.keys()).map((key) => ({
          key,
          options: this.app.graph.getNodeAttributes(key),
        })),
        edges: Array.from(this.probeState.edges.keys())
          .map((key) => ({
            source: this.app.graph.source(key),
            target: this.app.graph.target(key),
            options: this.app.graph.getEdgeAttributes(key),
          }))
          .filter((edge) => edge.options.data.edgeType !== 'comment'),
      })
      const aElement = document.createElement('a')
      aElement.setAttribute(
        'href',
        'data:image/svg+xml;charset=utf-8, ' + encodeURIComponent(svg)
      )
      aElement.setAttribute(
        'download',
        `${this.probeData.name}_${this.probeState.probeId}.svg`
      )
      document.body.appendChild(aElement)
      aElement.click()
      document.body.removeChild(aElement)
    } catch {
      Notification.notify(
        'Something went wrong during SVG generating',
        { type: 'warning' },
        PROBE_NOTIFICATION_STYLES
      )
    }
  }

  @action
  public setProbeData(probeData: ProbeFullData) {
    this.probeData = probeData
  }
  @action
  public setSelectedNodeIds(selectedNodeIds: Set<string>) {
    this.probeState.selectedNodeIds = selectedNodeIds
  }
  @action
  public setSelectedEdgeIds(selectedEdgeIds: Set<string>) {
    this.probeState.selectedEdgeIds = selectedEdgeIds
  }

  @action
  public setIsRightSidebarActive(isRightSidebarActive: boolean) {
    this.state.setIsRightSidebarActive(isRightSidebarActive)
  }

  @action
  public setIsAnalyticsLayerActive(isAnalyticsLayerActive: boolean) {
    this.state.setIsAnalyticsLayerActive(isAnalyticsLayerActive)
  }

  @action
  public setIsShortcutsModalActive(isShortcutsModalActive: boolean) {
    this.state.setIsShortcutsModalActive(isShortcutsModalActive)
  }

  @action
  public setMouseDownNodeKey(mouseDownNodeKey: string) {
    this.state.setMouseDownNodeKey(mouseDownNodeKey)
  }

  @action
  public setIsMagneticGridActive(isMagneticGridActive: boolean) {
    this.state.setIsMagneticGridActive(isMagneticGridActive)
    this.app.setIsActiveGrid(isMagneticGridActive)
  }

  @action
  public saveIsMagneticGridActive(isMagneticGridActive: boolean) {
    this.setIsMagneticGridActive(isMagneticGridActive)
    this.updateSettings({
      grid: isMagneticGridActive,
    })
  }

  @computed
  public get currentSettings(): Settings {
    return {
      grid: this.state.isMagneticGridActive,
      layers: this.layers.getLayers,
    }
  }

  @action
  public updateSettings(settingsData: Settings) {
    const currentSettings = this.currentSettings
    this.probeService.updateSettings({
      grid: this.state.isMagneticGridActive ?? currentSettings.grid,
      layers: {
        ...currentSettings.layers,
        ...settingsData.layers,
      },
    })
  }

  @action
  public updateCamera(cameraData: ServerCamera) {
    this.probeService.updateCamera(cameraData)
  }

  @action public setIsUsdCurrency = (isUsdCurrency: boolean) => {
    this.state.setIsUsdCurrency(isUsdCurrency)
  }

  @action public setLayout = (layout: LayoutType) => {
    this.state.setLayout(layout)
  }

  @action public setActiveMouse = (activeMouse: boolean) => {
    this.state.setActiveMouse(activeMouse)
  }

  @action.bound
  public linkProbeToCase(caseId: number) {
    return this.probeService.linkProbeToCase(caseId)
  }

  @computed public get isDeleteNodeDisabled() {
    return this.probeEvents.meta.nodesIsInProcessing
  }

  public deleteActiveNode = this.deleteEntityController.deleteActiveNode

  public deleteActiveEdge = this.deleteEntityController.deleteActiveEdge

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      this.setActiveSpace(false)
    }
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      this.setActiveSpace(true)
    }
    const eventTarget = event.target as HTMLElement
    if (!['INPUT', 'TEXTAREA'].includes(eventTarget?.tagName)) {
      if (event.key === '?') {
        this.setIsShortcutsModalActive(true)
      }

      if (event.code === 'KeyC' && !shiftOrMod(event)) {
        this.commentsController.addComment()
      }

      if (event.code === 'KeyG') {
        this.setIsMagneticGridActive(!this.state.isMagneticGridActive)
      }

      if (event.code === 'KeyL') {
        this.setIsRightSidebarActive(!this.state.isRightSidebarActive)
      }

      if (event.code === 'KeyT') {
        this.textController.createTextNode()
      }

      // Remove selection only on `escape`
      if (event.code === 'Escape') {
        this.setSelectedNodeIds(new Set())
        this.setSelectedEdgeIds(new Set())
        this.activeEntity.detectType()
        this.circularMenuController.hide()

        // Remove ghosted entities
        this.probeState.aboveOverlayNodeIds.forEach((nodeKey) => {
          this.app.setNodeBelowOverlay(nodeKey)
        })
        this.probeState.aboveOverlayEdgeIds.forEach((edgeKey) => {
          this.app.setEdgeBelowOverlay(edgeKey)
        })
        this.probeState.aboveOverlayNodeIds.clear()
        this.probeState.aboveOverlayEdgeIds.clear()
        this.app.toggleOverlay(false)
      }

      if (event.code === 'Delete' || event.code === 'Backspace') {
        if (this.isDeleteNodeDisabled) return

        this.deleteEntityController.deleteActiveNode()
        this.deleteEntityController.deleteActiveEdge()
      }
    }

    if (event.code === 'KeyR') {
      this.rearrangeNodesController.run()
    }

    if (event.metaKey && event.code === 'KeyZ') {
      this.history.undo()
    }

    if (
      (event.metaKey && event.shiftKey && event.code === 'KeyZ') ||
      (event.metaKey && event.code === 'KeyY')
    ) {
      this.history.redo()
    }
  }

  private handleWorldMouseDown = () => {
    this.demixAction.closeDemixTrackListPopup()
    this.circularMenuController.hide()
  }

  @action
  private handleWorldMouseUp = () => {
    this.camera.updateCamera()
  }

  @action
  private handleWorldZoom = ({ payload }: { payload: { scaled: number } }) => {
    this.camera.setZoom(payload.scaled)
  }

  private handleMouseDown = (event: MouseEvent) => {
    // Left mouse button
    if (event.button === 0) {
      this.setActiveMouse(true)
    }
  }

  private handleMouseUp = (event: MouseEvent) => {
    // Left mouse button
    if (event.button === 0) {
      this.setActiveMouse(false)
    }
  }

  public _getExtendedLayoutOptions(unlocked: Array<string>) {
    return {
      randomize: this._extendedLayoutPanel.randomize,
      alias: this._extendedLayoutPanel.alias as LayoutType,
      opts: this._extendedLayoutPanel.opts,
      unlocked: this._extendedLayoutPanel.lock ? unlocked : undefined,
    }
  }

  @action.bound
  private initReactions() {
    this.reactionDisposers.push(
      autorun(() => {
        const selectedEntitiesCount =
          this.probeState.selectedEdgeIds.size +
          this.probeState.selectedNodeIds.size

        if (selectedEntitiesCount === 0) {
          this.app.toggleOverlay(false)
          this.probeState.aboveOverlayNodeIds.clear()
          this.probeState.aboveOverlayEdgeIds.clear()
        }
      })
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isUsdCurrency: this.isUsdCurrency,
          timezone: this.settings?.userSettings?.timezone,
        }),
        ({ timezone, isUsdCurrency }) => {
          this.graphFactoryEntitiesInstance.layoutSettingsState().setState({
            isUsdCurrency,
            timezone,
            graphBackgroundColor: GRAPH_BACKGROND_COLOR,
          })
        },
        { equals: comparer.structural, fireImmediately: true }
      )
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.nodes.forEach((probeNode) =>
          probeNode.setLetterNotation(
            this.settings.userSettings.graph.letterNotation.graph
          )
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.edges.forEach((probeEdge) =>
          probeEdge.setLetterNotation(
            this.settings.userSettings.graph.letterNotation.graph
          )
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.nodes.forEach((probeNode, key) =>
          probeNode.setHighlighted(this.probeState.selectedNodeIds.has(key))
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.probeState.edges.forEach((probeEdge, key) =>
          probeEdge.setHighlighted(this.probeState.selectedEdgeIds.has(key))
        )
      })
    )

    this.reactionDisposers.push(
      autorun(() => {
        this.shortcutMenuController.items.forEach((item) => {
          if (item.type === 'comment') {
            item.disabled = !this.layers.comments
          }
        })
      })
    )

    this.reactionDisposers.push(
      reaction(
        () => this.alerts.counts,
        () => {
          this.probeState.nodes.forEach((probeNode) => {
            const count = alertEventsCountState(
              this.alerts.counts,
              getAddressIdProbe(probeNode.data)
            )

            probeNode.updateData({ alertCount: count })
          })
        }
      )
    )
  }

  @computed
  public get isAddingNodes() {
    return (
      this.searchController.addMultipleNodesInProgress ||
      this.plotEntitiesController?.plotNodesIsInProcessing
    )
  }

  @computed
  public get isRightSidebarActive() {
    return this.state.isRightSidebarActive
  }

  @computed
  public get isAnalyticsLayerActive() {
    return this.state.isAnalyticsLayerActive
  }

  @computed
  public get isShortcutsModalActive() {
    return this.state.isShortcutsModalActive
  }

  @computed
  public get mouseDownNodeKey() {
    return this.state.mouseDownNodeKey
  }

  @computed
  public get isMagneticGridActive() {
    return this.state.isMagneticGridActive
  }

  @computed
  public get isUsdCurrency() {
    return this.state.isUsdCurrency
  }

  @computed
  public get layout() {
    return this.state.layout
  }
}

export {
  ProbeViewModel,
  DEFAULT_X_COORDINATE,
  DEFAULT_Y_COORDINATE,
  DEFAULT_ZOOM,
}
