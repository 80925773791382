import { isEVM } from '@clain/core/types/coin'
import { ClusterTransaction } from '../types/converted/ClusterTransaction'
import { ClusterTransactionAggregate } from '../types/converted/ClusterTransactionAggregate'
import { mergeDefaultTokenWithProxy } from './convertTokenBalances'
import { Token } from '@clain/api/platform/types'

export const applyAllTransferTokens = (
  transactions: ClusterTransaction[]
): ClusterTransactionAggregate[] => {
  return transactions.map((transaction) => {
    if (isEVM(transaction)) {
      const allTransfersTokens = transaction.transfers.reduce(
        (acc: Token[], transfer, index) => {
          if (index === 0) {
            return transfer.token == null
              ? [
                  mergeDefaultTokenWithProxy(
                    transaction.currency,
                    {}
                  ) as unknown as Token,
                ]
              : [transfer.token]
          }

          return transfer.token !== null &&
            !acc.some((token) => token.id === transfer.token.id)
            ? [...acc, transfer.token]
            : acc
        },
        []
      )

      return { ...transaction, allTransfersTokens }
    } else {
      return transaction
    }
  })
}
