import { observable, action, computed, makeObservable, get } from 'mobx'
import {
  BlockTransactionEVM,
  BlockTransactionUTXO,
} from '@platform/apiServices'
import { BlocksHeightState } from '@platform/states/BlocksHeightState'
import { ctx } from '@platform/ctx'
import { createProbePath } from '@platform/constants'
import {
  LiteTransactionAddressUtxo,
  EventCluster,
  EventTransactionEVM,
  EventTransactionUTXO,
  EventClusterBasePayload,
  EventAddressCounterpartyPayload,
  EventAddress,
  EventAddressCounterparty,
  EventOsintPayload,
  EventOsint,
  EventClusterFlowPayload,
  EventClusterFlow,
  EventAddressFlowPayload,
  EventAddressFlow,
} from '@clain/graph-entities'
import { isUTXO } from '@clain/core/types'
import {
  IPlotEntitiesOnGraph,
  IPlotEntitiesOnGraphInitData,
  IPlotEntitiesSelectUnSelectAllOptions,
  PlotEntitiesOnGraphType,
  PlotEntitiesOnGraphTypeUnion,
} from './PlotEntitiesOnGraph.types'
import { inject, injectable } from 'inversify'
import { DI_TYPES } from '@platform/di/DITypes'
import { IPlotEntities } from '@platform/components/ProbeSandbox/models'
import { ExplorerTransaction } from '@clain/api/platform/types/Transactions'
export interface IPlotEntitiesState<
  TTransaction extends ExplorerTransaction = ExplorerTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TClusterFlow extends EventClusterFlowPayload = EventClusterFlowPayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
  TAddressesFlow extends EventAddressFlowPayload = EventAddressFlowPayload,
  TOsint extends EventOsintPayload = EventOsintPayload,
> {
  _selectedTransactions: TTransaction[]
  transactions: TTransaction[]

  _selectedClusters: TCluster[]
  clusters: TCluster[]

  _selectedClustersFlow: TClusterFlow[]
  clustersFlow: TClusterFlow[]

  _selectedAddresses: TAddresses[]
  addresses: TAddresses[]

  _selectedAddressesFlow: TAddressesFlow[]
  addressesFlow: TAddressesFlow[]

  _selectedOsints: TOsint[]
  osints: TOsint[]
}

@injectable()
export class PlotEntitiesState<
  TTransaction extends ExplorerTransaction = ExplorerTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TClusterFlow extends EventClusterFlowPayload = EventClusterFlowPayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
  TAddressesFlow extends EventAddressFlowPayload = EventAddressFlowPayload,
  TOsint extends EventOsintPayload = EventOsintPayload,
> implements
    IPlotEntitiesState<
      TTransaction,
      TCluster,
      TClusterFlow,
      TAddresses,
      TAddressesFlow,
      TOsint
    >
{
  @observable _selectedTransactions: TTransaction[] = []
  @observable transactions: TTransaction[] = []

  @observable _selectedClusters: TCluster[] = []
  @observable clusters: TCluster[] = []

  @observable _selectedClustersFlow: TClusterFlow[] = []
  @observable clustersFlow: TClusterFlow[] = []

  @observable _selectedAddresses: TAddresses[] = []
  @observable addresses: TAddresses[] = []

  @observable _selectedAddressesFlow: TAddressesFlow[] = []
  @observable addressesFlow: TAddressesFlow[] = []

  @observable _selectedOsints: TOsint[] = []
  @observable osints: TOsint[] = []

  constructor() {
    makeObservable(this)
  }
}

@injectable()
export class PlotEntitiesOnGraph<
  TTransaction extends ExplorerTransaction = ExplorerTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TClusterFlow extends EventClusterFlowPayload = EventClusterFlowPayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
  TAddressesFlow extends EventAddressFlowPayload = EventAddressFlowPayload,
  TOsint extends EventOsintPayload = EventOsintPayload,
> implements
    IPlotEntitiesOnGraph<
      TTransaction,
      TCluster,
      TClusterFlow,
      TAddresses,
      TAddressesFlow,
      TOsint
    >
{
  private blocksHeightState: BlocksHeightState

  constructor(
    @inject(DI_TYPES.PlotProbeEntities)
    private plotEntitiesController: IPlotEntities,
    @inject(DI_TYPES.PlotEntitiesState)
    private plotEntitiesState: IPlotEntitiesState<
      TTransaction,
      TCluster,
      TClusterFlow,
      TAddresses,
      TAddressesFlow,
      TOsint
    >
  ) {
    makeObservable(this)
    this.blocksHeightState = ctx.blocksHeightState
    this.plotEntitiesController = plotEntitiesController
  }

  @action
  public init: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster,
    TClusterFlow,
    TAddresses,
    TAddressesFlow,
    TOsint
  >['init'] = (type, data) => {
    if (Array.isArray(type)) {
      const _data = data as IPlotEntitiesOnGraphInitData<
        PlotEntitiesOnGraphTypeUnion[]
      >

      if (_data?.clusters) {
        this.plotEntitiesState.clusters = Array.isArray(_data.clusters)
          ? (_data.clusters as TCluster[])
          : ([_data.clusters] as TCluster[])
      }

      if (_data?.clustersFlow) {
        this.plotEntitiesState.clustersFlow = Array.isArray(_data.clustersFlow)
          ? (_data.clustersFlow as TClusterFlow[])
          : ([_data.clustersFlow] as TClusterFlow[])
      }

      if (_data?.addresses) {
        this.plotEntitiesState.addresses = Array.isArray(_data.addresses)
          ? (_data.addresses as TAddresses[])
          : ([_data.addresses] as TAddresses[])
      }

      if (_data?.addressesFlow) {
        this.plotEntitiesState.addressesFlow = Array.isArray(
          _data.addressesFlow
        )
          ? (_data.addressesFlow as TAddressesFlow[])
          : ([_data.addressesFlow] as TAddressesFlow[])
      }

      if (_data?.transactions) {
        this.plotEntitiesState.transactions = Array.isArray(_data.transactions)
          ? (_data.transactions as TTransaction[])
          : ([_data.transactions] as TTransaction[])
      }

      if (_data?.osints) {
        this.plotEntitiesState.osints = Array.isArray(_data.osints)
          ? (_data.osints as TOsint[])
          : ([_data.osints] as TOsint[])
      }

      return
    }

    if (type === 'clusters') {
      this.plotEntitiesState.clusters = Array.isArray(data)
        ? (data as TCluster[])
        : ([data] as TCluster[])
    } else if (type === 'clusters-flow') {
      this.plotEntitiesState.clustersFlow = Array.isArray(data)
        ? (data as TClusterFlow[])
        : ([data] as TClusterFlow[])
    } else if (type === 'addresses') {
      this.plotEntitiesState.addresses = Array.isArray(data)
        ? (data as TAddresses[])
        : ([data] as TAddresses[])
    } else if (type === 'addresses-flow') {
      this.plotEntitiesState.addressesFlow = Array.isArray(data)
        ? (data as TAddressesFlow[])
        : ([data] as TAddressesFlow[])
    } else if (type === 'transactions') {
      this.plotEntitiesState.transactions = Array.isArray(data)
        ? (data as TTransaction[])
        : ([data] as TTransaction[])
    } else if (type === 'osints') {
      this.plotEntitiesState.osints = Array.isArray(data)
        ? (data as TOsint[])
        : ([data] as TOsint[])
    }
  }

  // Clusters

  @computed
  private get selectedClusters() {
    return this.plotEntitiesState._selectedClusters
  }

  @computed
  private get selectedClustersCount() {
    return this.plotEntitiesState._selectedClusters.length
  }

  @action
  private setSelectClusters = (data: TCluster, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedClusters = [
        ...this.plotEntitiesState._selectedClusters,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedClusters =
        this.plotEntitiesState._selectedClusters.filter(
          (cluster) => cluster.clusterId !== data.clusterId
        )
    }
  }

  @action
  private resetSelectedClusters = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedClusters = options?.merge
      ? this.plotEntitiesState._selectedClusters.filter(
          (cluster) =>
            !this.plotEntitiesState.clusters.find(
              (el) => el.clusterId === cluster.clusterId
            )
        )
      : []
  }

  @action
  private selectUnSelectAllClusters = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedClusters) {
      this.resetSelectedClusters(options)
      return
    }

    this.selectAllClusters(options)
  }

  @action
  private selectAllClusters = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedClusters = options?.merge
      ? [
          ...this.plotEntitiesState._selectedClusters,
          ...this.plotEntitiesState.clusters.filter(
            (el) =>
              !this.plotEntitiesState._selectedClusters.find(
                (selected) => selected.clusterId === el.clusterId
              )
          ),
        ]
      : this.plotEntitiesState.clusters
  }

  @computed
  private get isAllSelectedClusters() {
    if (!this.plotEntitiesState.clusters?.length) return false

    return this.plotEntitiesState.clusters.every((el) =>
      this.selectedClusters.find(
        (selected) => selected.clusterId === el.clusterId
      )
    )
  }

  // ClusterFlows
  @computed
  private get selectedClustersFlow() {
    return this.plotEntitiesState._selectedClustersFlow
  }

  @computed
  private get selectedClusterFlowCount() {
    return this.plotEntitiesState._selectedClustersFlow.length
  }

  @action
  private setSelectClustersFlow = (data: TClusterFlow, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedClustersFlow = [
        ...this.plotEntitiesState._selectedClustersFlow,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedClustersFlow =
        this.plotEntitiesState._selectedClustersFlow.filter(
          (cluster) => cluster.clusterId !== data.clusterId
        )
    }
  }

  @action
  private resetSelectedClustersFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedClustersFlow = options?.merge
      ? this.plotEntitiesState._selectedClustersFlow.filter(
          (cluster) =>
            !this.plotEntitiesState.clustersFlow.find(
              (el) => el.clusterId === cluster.clusterId
            )
        )
      : []
  }

  @action
  private selectUnSelectAllClustersFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedClustersFlow) {
      this.resetSelectedClustersFlow(options)
      return
    }

    this.selectAllClustersFlow(options)
  }

  @action
  private selectAllClustersFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedClustersFlow = options?.merge
      ? [
          ...this.plotEntitiesState._selectedClustersFlow,
          ...this.plotEntitiesState.clustersFlow.filter(
            (el) =>
              !this.plotEntitiesState._selectedClustersFlow.find(
                (selected) => selected.clusterId === el.clusterId
              )
          ),
        ]
      : this.plotEntitiesState.clustersFlow
  }

  @computed
  private get isAllSelectedClustersFlow() {
    if (!this.plotEntitiesState.clustersFlow?.length) return false

    return this.plotEntitiesState.clustersFlow.every((el) =>
      this.selectedClustersFlow.find(
        (selected) => selected.clusterId === el.clusterId
      )
    )
  }

  // Addresses
  @computed
  private get selectedAddresses() {
    return this.plotEntitiesState._selectedAddresses
  }

  @computed
  private get selectedAddressesCount() {
    return this.plotEntitiesState._selectedAddresses.length
  }

  @action
  private setSelectAddresses = (data: TAddresses, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedAddresses = [
        ...this.plotEntitiesState._selectedAddresses,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedAddresses =
        this.plotEntitiesState._selectedAddresses.filter(
          (cluster) => cluster.id !== data.id
        )
    }
  }

  @action
  private resetSelectedAddresses = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedAddresses = options?.merge
      ? this.plotEntitiesState._selectedAddresses.filter(
          (address) =>
            !this.plotEntitiesState.addresses.find((el) => el.id === address.id)
        )
      : []
  }

  @action
  private selectUnSelectAllAddresses = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedAddresses) {
      this.resetSelectedAddresses(options)
      return
    }

    this.selectAllAddresses(options)
  }

  @action
  private selectAllAddresses = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedAddresses = options?.merge
      ? [
          ...this.plotEntitiesState._selectedAddresses,
          ...this.plotEntitiesState.addresses.filter(
            (el) =>
              !this.plotEntitiesState._selectedAddresses.find(
                (selected) => selected.id === el.id
              )
          ),
        ]
      : this.plotEntitiesState.addresses
  }

  @computed
  private get isAllSelectedAddresses() {
    if (!this.plotEntitiesState.addresses?.length) return false

    return this.plotEntitiesState.addresses.every((el) =>
      this.selectedAddresses.find((selected) => selected.id === el.id)
    )
  }

  // AddressesFlow
  @computed
  private get selectedAddressesFlow() {
    return this.plotEntitiesState._selectedAddressesFlow
  }

  @computed
  private get selectedAddressesFlowCount() {
    return this.plotEntitiesState._selectedAddressesFlow.length
  }

  @action
  private setSelectAddressesFlow = (data: TAddressesFlow, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedAddressesFlow = [
        ...this.plotEntitiesState._selectedAddressesFlow,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedAddressesFlow =
        this.plotEntitiesState._selectedAddressesFlow.filter(
          (address) => address.id !== data.id
        )
    }
  }

  @action
  private resetSelectedAddressesFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedAddressesFlow = options?.merge
      ? this.plotEntitiesState._selectedAddressesFlow.filter(
          (address) =>
            !this.plotEntitiesState.addressesFlow.find(
              (el) => el.id === address.id
            )
        )
      : []
  }

  @action
  private selectUnSelectAllAddressesFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedAddressesFlow) {
      this.resetSelectedAddressesFlow(options)
      return
    }

    this.selectAllAddressesFlow(options)
  }

  @action
  private selectAllAddressesFlow = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedAddressesFlow = options?.merge
      ? [
          ...this.plotEntitiesState._selectedAddressesFlow,
          ...this.plotEntitiesState.addressesFlow.filter(
            (el) =>
              !this.plotEntitiesState._selectedAddressesFlow.find(
                (selected) => selected.id === el.id
              )
          ),
        ]
      : this.plotEntitiesState.addressesFlow
  }

  @computed
  private get isAllSelectedAddressesFlow() {
    if (!this.plotEntitiesState.addressesFlow?.length) return false

    return this.plotEntitiesState.addressesFlow.every((el) =>
      this.selectedAddressesFlow.find((selected) => selected.id === el.id)
    )
  }

  // Transactions
  @computed
  private get selectedTransactions() {
    return this.plotEntitiesState._selectedTransactions
  }

  @computed
  private get selectedTransactionsCount() {
    return this.plotEntitiesState._selectedTransactions.length
  }

  @action
  private setSelectTransactions = (data: TTransaction, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedTransactions = [
        ...this.plotEntitiesState._selectedTransactions,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedTransactions =
        this.plotEntitiesState._selectedTransactions.filter(
          (transaction) => transaction.id !== data.id
        )
    }
  }

  @action
  private resetSelectedTransactions = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedTransactions = options?.merge
      ? this.plotEntitiesState._selectedTransactions.filter(
          (transaction) =>
            !this.plotEntitiesState.transactions.find(
              (el) => el.hash === transaction.hash
            )
        )
      : []
  }

  @action
  private selectUnSelectAllTransactions = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedTransactions) {
      this.resetSelectedTransactions(options)
      return
    }

    this.selectAllTransactions(options)
  }

  @action
  private selectAllTransactions = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedTransactions = options?.merge
      ? [
          ...this.plotEntitiesState._selectedTransactions,
          ...this.plotEntitiesState.transactions.filter(
            (el) =>
              !this.plotEntitiesState._selectedTransactions.find(
                (selected) => selected.hash === el.hash
              )
          ),
        ]
      : this.plotEntitiesState.transactions
  }

  @computed
  private get isAllSelectedTransactions() {
    if (!this.plotEntitiesState.transactions?.length) return false

    return this.plotEntitiesState.transactions.every((el) =>
      this.selectedTransactions.find((selected) => selected.hash === el.hash)
    )
  }

  // Osints
  @computed
  private get selectedOsints() {
    return this.plotEntitiesState._selectedOsints
  }

  @computed
  private get selectedOsintsCount() {
    return this.plotEntitiesState._selectedOsints.length
  }

  @action
  private setSelectOsints = (data: TOsint, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedOsints = [
        ...this.plotEntitiesState._selectedOsints,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedOsints =
        this.plotEntitiesState._selectedOsints.filter(
          (osint) => osint.id !== data.id
        )
    }
  }

  @action
  private resetSelectedOsints = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedOsints = options?.merge
      ? this.plotEntitiesState._selectedOsints.filter(
          (osint) =>
            !this.plotEntitiesState.osints.find((el) => el.id === osint.id)
        )
      : []
  }

  @action
  private selectUnSelectAllOsints = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    if (this.isAllSelectedOsints) {
      this.resetSelectedOsints(options)
      return
    }

    this.selectAllOsints(options)
  }

  @action
  private selectAllOsints = (
    options?: IPlotEntitiesSelectUnSelectAllOptions
  ) => {
    this.plotEntitiesState._selectedOsints = options?.merge
      ? [
          ...this.plotEntitiesState._selectedOsints,
          ...this.plotEntitiesState.osints.filter(
            (el) =>
              !this.plotEntitiesState._selectedOsints.find(
                (selected) => selected.id === el.id
              )
          ),
        ]
      : this.plotEntitiesState.osints
  }

  @computed
  private get isAllSelectedOsints() {
    if (!this.plotEntitiesState.osints?.length) return false

    return this.plotEntitiesState.osints.every((el) =>
      this.selectedOsints.find((selected) => selected.id === el.id)
    )
  }

  public selectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster,
    TClusterFlow,
    TAddresses,
    TAddressesFlow,
    TOsint
  >['selectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClusters
    } else if (type === 'clusters-flow') {
      return this.selectedClustersFlow
    } else if (type === 'addresses') {
      return this.selectedAddresses
    } else if (type === 'addresses-flow') {
      return this.selectedAddressesFlow
    } else if (type === 'osints') {
      return this.selectedOsints
    } else {
      return this.plotEntitiesState._selectedTransactions as any
    }
  }

  public selectedEntitiesCount: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectedEntitiesCount'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClustersCount
    } else if (type === 'clusters-flow') {
      return this.selectedClusterFlowCount
    } else if (type === 'addresses') {
      return this.selectedAddressesCount
    } else if (type === 'addresses-flow') {
      return this.selectedAddressesFlowCount
    } else if (type === 'osints') {
      return this.selectedOsintsCount
    } else {
      return this.selectedTransactionsCount
    }
  }

  @action
  public setSelectEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster,
    TClusterFlow,
    TAddresses,
    TAddressesFlow,
    TOsint
  >['setSelectEntities'] = (type) => {
    if (type === 'clusters') {
      return this.setSelectClusters as any
    } else if (type === 'clusters-flow') {
      return this.setSelectClustersFlow as any
    } else if (type === 'addresses') {
      return this.setSelectAddresses as any
    } else if (type === 'addresses-flow') {
      return this.setSelectAddressesFlow as any
    } else if (type === 'osints') {
      return this.setSelectOsints as any
    } else {
      return this.setSelectTransactions as any
    }
  }

  private selectAllEntities = (type: PlotEntitiesOnGraphType) => {
    if (type === 'clusters') {
      return this.selectAllClusters
    } else if (type === 'clusters-flow') {
      return this.selectAllClustersFlow
    } else if (type === 'addresses') {
      return this.selectAllAddresses
    } else if (type === 'addresses-flow') {
      return this.selectAllAddressesFlow
    } else if (type === 'transactions') {
      return this.selectAllTransactions
    } else if (type === 'osints') {
      return this.selectAllOsints
    }
  }

  public selectUnSelectAllEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectUnSelectAllEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectUnSelectAllClusters
    } else if (type === 'clusters-flow') {
      return this.selectUnSelectAllClustersFlow
    } else if (type === 'addresses') {
      return this.selectUnSelectAllAddresses
    } else if (type === 'addresses-flow') {
      return this.selectUnSelectAllAddressesFlow
    } else if (type === 'transactions') {
      return this.selectUnSelectAllTransactions
    } else if (type === 'osints') {
      return this.selectUnSelectAllOsints
    }

    if (Array.isArray(type)) {
      return () => {
        const isAllSelected = this.isAllSelectedEntities(type)
        if (isAllSelected) {
          this.resetAllEntities()
        } else {
          type.forEach((t) => this.selectAllEntities(t)())
        }
      }
    }
  }

  public isAllSelectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['isAllSelectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.isAllSelectedClusters
    } else if (type === 'clusters-flow') {
      return this.isAllSelectedClustersFlow
    } else if (type === 'addresses') {
      return this.isAllSelectedAddresses
    } else if (type === 'addresses-flow') {
      return this.isAllSelectedAddressesFlow
    } else if (type === 'transactions') {
      return this.isAllSelectedTransactions
    } else if (type === 'osints') {
      return this.isAllSelectedOsints
    }

    if (Array.isArray(type)) {
      return type.every((t) => this.isAllSelectedEntities(t))
    }
  }

  @computed.struct
  public get disabledPlotOnGraph() {
    return (
      !this.plotEntitiesState._selectedTransactions.length &&
      !this.plotEntitiesState._selectedClusters.length &&
      !this.plotEntitiesState._selectedClustersFlow.length &&
      !this.plotEntitiesState._selectedAddresses.length &&
      !this.plotEntitiesState._selectedAddressesFlow.length &&
      !this.plotEntitiesState._selectedOsints.length
    )
  }

  @action
  public plotSelectedOnGraph = () => {
    if (!this.disabledPlotOnGraph) {
      const normalizedAddTransactions =
        this.plotEntitiesState._selectedTransactions.map((_transaction) => {
          if (isUTXO(_transaction.currency)) {
            const transaction = _transaction as unknown as BlockTransactionUTXO

            return {
              createBy: 'by-trx-id',
              strategy: 'transaction',
              currency: transaction.currency,
              id: transaction.id,
              hash: transaction.hash,
              direction: 'out',
              inputs:
                transaction.inputs as unknown as LiteTransactionAddressUtxo[],
              outputs:
                transaction.outputs as unknown as LiteTransactionAddressUtxo[],
            } satisfies EventTransactionUTXO
          } else {
            const transaction = _transaction as BlockTransactionEVM
            return {
              strategy: 'transaction',
              type: 'transfer',
              currency: transaction.currency,
              index: 0,
              sender: transaction.transfers[0]?.sender,
              receiver: transaction.transfers[0]?.receiver,
              hash: transaction.hash,
              id: transaction.id,
            } satisfies EventTransactionEVM
          }
        })

      const normalizeAddClusters = this.plotEntitiesState._selectedClusters.map(
        (cluster) =>
          ({
            strategy: 'cluster',
            ...cluster,
          }) satisfies EventCluster
      )

      const normalizeAddClustersFlow =
        this.plotEntitiesState._selectedClustersFlow.map(
          (cluster) =>
            ({
              strategy: 'cluster-flow',
              ...cluster,
            }) satisfies EventClusterFlow
        )

      const normalizeAddAddresses =
        this.plotEntitiesState._selectedAddresses.map(
          (address) =>
            ({
              strategy: address?.clusterId ? 'address-cluster' : 'address',
              ...address,
            }) satisfies EventAddress | EventAddressCounterparty
        )

      const normalizeAddAddressesFlow =
        this.plotEntitiesState._selectedAddressesFlow.map(
          (address) =>
            ({
              strategy: 'address-flow',
              ...address,
            }) satisfies EventAddressFlow
        )

      const normalizeAddOsints = this.plotEntitiesState._selectedOsints.map(
        (osint) =>
          ({
            strategy: 'osint',
            ...osint,
          }) satisfies EventOsint
      )

      this.plotEntitiesOnGraph([
        ...normalizeAddClusters,
        ...normalizedAddTransactions,
        ...normalizeAddAddresses,
        ...normalizeAddClustersFlow,
        ...normalizeAddAddressesFlow,
        ...normalizeAddOsints,
      ])
      this.resetAllEntities()
    }
  }

  @action
  private plotEntitiesOnGraph = (
    entities: Parameters<typeof this.plotEntitiesController.pushPlotEntities>[1]
  ) => {
    this.blocksHeightState.getNewProbe().then((probe) => {
      window.open(createProbePath(probe.id), '_blank')
      this.plotEntitiesController.pushPlotEntities(probe.id, entities)
    })
  }

  @action
  public resetEntities: IPlotEntitiesOnGraph<TTransaction, TCluster>['clear'] =
    (type) => {
      if (type === 'clusters') {
        this.resetSelectedClusters()
        this.plotEntitiesState.clusters = []
      } else if (type === 'clusters-flow') {
        this.resetSelectedClustersFlow()
        this.plotEntitiesState.clustersFlow = []
      } else if (type === 'addresses') {
        this.resetSelectedAddresses()
        this.plotEntitiesState.addresses = []
      } else if (type === 'addresses-flow') {
        this.resetSelectedAddressesFlow()
        this.plotEntitiesState.addressesFlow = []
      } else if (type === 'transactions') {
        this.resetSelectedTransactions()
        this.plotEntitiesState.transactions = []
      } else if (type === 'osints') {
        this.resetSelectedOsints()
        this.plotEntitiesState.osints = []
      }

      if (Array.isArray(type)) {
        type.forEach((t) => this.resetEntities(t))
      }
    }

  @action
  public resetSelectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['resetSelectedEntities'] = (type) => {
    if (type === 'clusters') {
      this.plotEntitiesState._selectedClusters = []
    } else if (type === 'clusters-flow') {
      this.plotEntitiesState._selectedClustersFlow = []
    } else if (type === 'addresses') {
      this.plotEntitiesState._selectedAddresses = []
    } else if (type === 'addresses-flow') {
      this.plotEntitiesState._selectedAddressesFlow = []
    } else if (type === 'transactions') {
      this.plotEntitiesState._selectedTransactions = []
    } else if (type === 'osints') {
      this.plotEntitiesState._selectedOsints = []
    }

    if (Array.isArray(type)) {
      type.forEach((t) => this.resetSelectedEntities(t))
    }
  }

  public resetAllEntities = () => {
    ;(
      [
        'clusters',
        'clusters-flow',
        'addresses',
        'addresses-flow',
        'transactions',
        'osints',
      ] satisfies PlotEntitiesOnGraphType[]
    ).forEach((type) => this.resetEntities(type))
  }

  @action
  public clear: IPlotEntitiesOnGraph<TTransaction, TCluster>['clear'] = () => {
    this.resetAllEntities()
    this.plotEntitiesController.clear()
  }
}
