import { injectable, inject, optional } from 'inversify'
import { pick } from 'ramda'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateEdge } from './GenerateEdge'
import type { IAddedEntities } from '../AddedEntities'

import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventTransactionUTXO,
  IEntitiesMainState,
  IEntitiesGraph,
  EventUtxoTransactionByTrxAddress,
  LiteTransactionAddressUtxo,
  EventTransactionDirection,
  ServerAddEvents,
  LiteTransactionNodeUtxo,
  CoinTypeUTXO,
} from '../../types'
import {
  transactionAddressKey,
  getSelfTransactionAddress,
  transactionKey,
  getTrxAddressUTXO,
  edgeKey,
} from '../../utils'
import type { ICacheModel } from '../CacheModel'
import type { IEntityServices } from '../../models'

@injectable()
export class GenerateEdgeTransactionUTXO extends GenerateEdge<EventTransactionUTXO> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.CacheModel)
    private cacheModel: ICacheModel,
    @inject(GRAPH_ENTITIES_TYPES.EntityServices)
    @optional()
    private entityServices: IEntityServices
  ) {
    super(probeState, graph, addedEntities, addVirtualNodes)
  }

  private checkInputsOutputs = async (
    {
      inputs,
      outputs,
      hash,
      id,
    }: Pick<LiteTransactionNodeUtxo, 'inputs' | 'outputs' | 'hash' | 'id'>,
    currency: CoinTypeUTXO
  ) => {
    if ((inputs.length && outputs.length) || !this.entityServices) {
      return { inputs, outputs, id }
    }

    const transaction = await this.cacheModel.withCache(
      ['transaction', currency],
      this.entityServices.getTransactionUtxo(currency),
      hash
    )

    return pick(['inputs', 'outputs', 'id'], transaction)
  }

  private getTrxDataByTrxAddressKey = (
    data: EventUtxoTransactionByTrxAddress
  ) => {
    const { direction, trxAddressData, currency } = data

    const selectTrxAddress =
      trxAddressData?.[direction === 'in' ? 'previous' : 'next']

    return {
      currency,
      hash: selectTrxAddress.trxHash,
      id: selectTrxAddress.trxId,
      nodeKey: transactionAddressKey(trxAddressData),
      inputs: [],
      outputs: [],
    }
  }

  private normalizeCreateByData = (data: EventTransactionUTXO) => {
    if (data.createBy === 'by-trxAddress') {
      return this.getTrxDataByTrxAddressKey(data)
    }

    return { ...data, nodeKey: null }
  }

  private findOnGraphTransactionAddress = (
    transactionAddresses: LiteTransactionAddressUtxo[]
  ) => {
    const findedTransactionAddresses: LiteTransactionAddressUtxo[] = []

    if (!transactionAddresses?.length) return findedTransactionAddresses

    if (transactionAddresses.length) {
      for (const transactionAddress of transactionAddresses) {
        if (this.isNodeExists(transactionAddressKey(transactionAddress))) {
          if (findedTransactionAddresses.length < 2) {
            findedTransactionAddresses.push(transactionAddress)
          } else {
            return findedTransactionAddresses
          }
        }
      }
    }

    return findedTransactionAddresses
  }

  private selectTransactionAddress = (
    inputs: LiteTransactionAddressUtxo[],
    outputs: LiteTransactionAddressUtxo[]
  ): {
    select: LiteTransactionAddressUtxo
    input: LiteTransactionAddressUtxo[]
    output: LiteTransactionAddressUtxo[]
    direction: EventTransactionDirection | null
  } => {
    const selectedInputAddress = this.findOnGraphTransactionAddress(inputs)
    const selectedOutputAddress = this.findOnGraphTransactionAddress(outputs)

    return {
      select: selectedInputAddress?.length
        ? selectedInputAddress[0]
        : selectedOutputAddress[0],
      input: selectedInputAddress,
      output: selectedOutputAddress,
      direction: selectedInputAddress
        ? 'out'
        : selectedOutputAddress
        ? 'in'
        : null,
    }
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionUTXO>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    let { direction } = data
    const { hash, currency, nodeKey, ...rest } =
      this.normalizeCreateByData(data)

    const edges = this.edges({ meta })

    const { id, inputs, outputs } = await this.checkInputsOutputs(
      { hash, ...rest },
      currency
    )

    const selectedTransactionAddress = this.selectTransactionAddress(
      inputs,
      outputs
    )

    const selfTransactionAddressData =
      selectedTransactionAddress?.select ??
      getSelfTransactionAddress({ inputs, outputs }, direction)

    direction = nodeKey
      ? direction
      : (selectedTransactionAddress?.direction ?? direction)

    const selfTransactionAddressKey =
      nodeKey ?? transactionAddressKey(selfTransactionAddressData)
    const trxKey = transactionKey({ hash })

    if (direction === 'out') {
      const outputsData = selectedTransactionAddress?.output?.length
        ? selectedTransactionAddress.output
        : getTrxAddressUTXO(outputs, direction)

      inputs.forEach(({ position, ...rest }) => {
        if (
          selfTransactionAddressKey === transactionAddressKey(rest) &&
          !this.isEdgeExists(edgeKey(selfTransactionAddressKey, trxKey))
        ) {
          edges.push({
            type: 'add_edge',
            key: edgeKey(selfTransactionAddressKey, trxKey),
            data: {
              srcKey: selfTransactionAddressKey,
              dstKey: trxKey,
              type: 'utxo_transaction',
              edgeData: {
                type: 'input',
                trxId: id,
                index: position,
                color: data?.color,
              },
            },
          })
        }
      })

      if (outputsData?.length) {
        outputsData.forEach((outputData) => {
          const trxAddressKey = transactionAddressKey(outputData)
          if (!this.isEdgeExists(edgeKey(trxKey, trxAddressKey))) {
            edges.push({
              type: 'add_edge',
              key: edgeKey(trxKey, trxAddressKey),
              data: {
                srcKey: trxKey,
                dstKey: trxAddressKey,
                type: 'utxo_transaction',
                edgeData: {
                  type: 'output',
                  trxId: id,
                  index: outputData.position,
                  color: data?.color,
                },
              },
            })
          }
        })
      }
    }

    if (direction === 'in') {
      const inputsData = selectedTransactionAddress?.input?.length
        ? selectedTransactionAddress.input
        : getTrxAddressUTXO(inputs, direction)

      outputs.forEach(({ position, ...rest }) => {
        if (
          selfTransactionAddressKey === transactionAddressKey(rest) &&
          !this.isEdgeExists(edgeKey(trxKey, selfTransactionAddressKey))
        ) {
          edges.push({
            type: 'add_edge',
            key: edgeKey(trxKey, selfTransactionAddressKey),
            data: {
              srcKey: trxKey,
              dstKey: selfTransactionAddressKey,
              type: 'utxo_transaction',
              edgeData: {
                type: 'output',
                trxId: id,
                index: position,
                color: data.color,
              },
            },
          })
        }
      })

      if (inputsData?.length) {
        inputsData.forEach((inputData) => {
          const trxAddressKey = transactionAddressKey(inputData)

          if (!this.isEdgeExists(edgeKey(trxAddressKey, trxKey))) {
            edges.push({
              type: 'add_edge',
              key: edgeKey(trxAddressKey, trxKey),
              data: {
                srcKey: trxAddressKey,
                dstKey: trxKey,
                type: 'utxo_transaction',
                edgeData: {
                  type: 'input',
                  trxId: id,
                  index: inputData.position,
                  color: data.color,
                },
              },
            })
          }
        })
      }
    }

    return edges.acc
  }
}
