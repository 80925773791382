import { CoinType, BlockchainType } from '../types/coin'

export interface Token {
  blockchain: CoinType | BlockchainType
  decimals: number
  id: number // Asset ID – не путать с Token ID
  name: string | null
  startDate: string
  symbol: string | null
  icon: null | string
}

export type TokenBalance = {
  id: number
  balance: number
  balanceUsd: number
  received: number
  receivedUsd: number
  sent: number
  sentUsd: number
  token: Token
}

export const DEFAULT_BTC_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 1,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'BTC',
    decimals: 8,
    id: 1,
    name: 'Bitcoin',
    symbol: 'BTC',
    startDate: '',
    icon: null,
  },
}

export const DEFAULT_ETH_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 2,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'ETH',
    decimals: 18,
    id: 2,
    name: 'Ethereum',
    symbol: 'ETH',
    startDate: '',
    icon: null,
  },
}

export const DEFAULT_BNB_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 3885,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'BNB' as unknown as any,
    decimals: 18,
    id: 3885,
    name: 'Binance',
    symbol: 'BNB',
    startDate: '',
    icon: null,
  },
}

export const DEFAULT_TRON_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 4232,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'TRX',
    decimals: 6,
    id: 4232,
    name: 'Tron',
    symbol: 'TRX',
    startDate: '',
    icon: null,
  },
}

export const DEFAULT_USD_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 0,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    // @ts-ignore
    blockchain: 'USD',
    decimals: 0,
    id: 0,
    name: 'Aggregated USD',
    symbol: 'USD',
    startDate: '',
    tokenId: 0,
  },
}

export const DEFAULT_DOGE_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 93,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'DOGE',
    decimals: 8,
    id: 93,
    name: 'Dogecoin',
    symbol: 'DOGE',
    startDate: '',
    icon: null,
  },
}

export const DEFAULT_LTC_TOKEN: TokenBalance = {
  balance: 0,
  balanceUsd: 0,
  id: 92,
  received: 0,
  receivedUsd: 0,
  sent: 0,
  sentUsd: 0,
  token: {
    blockchain: 'LTC',
    decimals: 8,
    id: 92,
    name: 'Litecoin',
    symbol: 'LTC',
    startDate: '',
    icon: null,
  },
}

export const getDefaultUSDTToken = (
  blockchain: TokenBalance['token']['blockchain'] = 'BTC'
) =>
  ({
    balance: 0,
    balanceUsd: 0,
    id: 31,
    received: 0,
    receivedUsd: 0,
    sent: 0,
    sentUsd: 0,
    token: {
      blockchain: blockchain.toUpperCase(),
      id: 31,
      name: 'Tether',
      symbol: 'USDT',
      decimals: 8,
      icon: 'https://clainio.s3.us-east-2.amazonaws.com/icons/tokens/0xdac17f958d2ee523a2206206994597c13d831ec7/icon.png',
      startDate: '',
    },
  }) as TokenBalance

export const defaultTokenByCurrency: Record<CoinType | 'bnb', TokenBalance> = {
  btc: DEFAULT_BTC_TOKEN,
  eth: DEFAULT_ETH_TOKEN,
  doge: DEFAULT_DOGE_TOKEN,
  ltc: DEFAULT_LTC_TOKEN,
  trx: DEFAULT_TRON_TOKEN,
  bnb: DEFAULT_BNB_TOKEN,
}

export const DEFAULT_CURRENCY_TOKEN_ID = [
  DEFAULT_ETH_TOKEN.id,
  DEFAULT_BTC_TOKEN.id,
  DEFAULT_BNB_TOKEN.id,
  DEFAULT_TRON_TOKEN.id,
  DEFAULT_DOGE_TOKEN.id,
  DEFAULT_LTC_TOKEN.id,
]

export const proxyTokenId = (tokenId: number) => {
  if (
    DEFAULT_USD_TOKEN.token.id === tokenId ||
    DEFAULT_CURRENCY_TOKEN_ID.includes(tokenId)
  ) {
    return undefined
  }

  return tokenId
}

export const isDefaultTokenId = (tokenId: Token['id']) => {
  if (!tokenId) return false

  return DEFAULT_CURRENCY_TOKEN_ID.includes(tokenId)
}
