export const DI_API_TYPES = {
  TrxFilterSocketAPIService: Symbol.for('TrxFilterSocketAPIService'),
  AddressesFilterSocketAPIService: Symbol.for(
    'AddressesFilterSocketAPIService'
  ),

  ExplorerRestAPIService: Symbol.for('ExplorerRestAPIService'),
  DashboardRestAPIService: Symbol.for('DashboardRestAPIService'),
  CategoriesRestAPIService: Symbol.for('CategoriesRestAPIService'),
}
