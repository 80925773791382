/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'
import { useIsFirstRender } from '@clain/core/hooks'
import { DI_TYPES } from '@platform/di/DITypes'
import { moduleStage } from '@platform/di/rootContainer'
import {
  IPlotEntitiesOnGraph,
  IPlotEntitiesOnGraphInitData,
  PlotEntitiesOnGraphType,
} from '@platform/modules'
import { InstanceStage } from '@clain/core/utils'
import { UsePlotEntititesReturn } from './usePlotEntitiesOnGraph.types'

export const usePlotEntitiesOnGraph = <TType extends PlotEntitiesOnGraphType>(
  type: TType,
  entities?: IPlotEntitiesOnGraphInitData<TType>
): UsePlotEntititesReturn<TType> => {
  const plotEntitiesOnGraphRef = useRef<InstanceStage<IPlotEntitiesOnGraph>>()
  const isFirstRender = useIsFirstRender()
  if (isFirstRender) {
    plotEntitiesOnGraphRef.current =
      moduleStage.getModule<IPlotEntitiesOnGraph>(
        DI_TYPES.PlotEntitiesOnGraph,
        'cached'
      )
  }

  useEffect(() => {
    if (entities && type) {
      plotEntitiesOnGraphRef.current?.instance.init(type, entities)
    }
  }, [entities, type])

  useEffect(() => {
    return () => {
      plotEntitiesOnGraphRef.current?.dispose(type)
    }
  }, [])

  if (Array.isArray(type)) {
    return {
      selectedEntities:
        plotEntitiesOnGraphRef.current?.instance.selectedEntities,
      selectedEntitiesCount:
        plotEntitiesOnGraphRef.current?.instance.selectedEntitiesCount,
      setSelectEntities:
        plotEntitiesOnGraphRef.current?.instance.setSelectEntities,
      selectUnSelectAllEntities:
        plotEntitiesOnGraphRef.current?.instance.selectUnSelectAllEntities(
          type
        ),
      isAllSelectedEntities:
        plotEntitiesOnGraphRef.current?.instance.isAllSelectedEntities(type),
      plotSelectedOnGraph:
        plotEntitiesOnGraphRef.current?.instance.plotSelectedOnGraph,
      disabledPlotOnGraph:
        plotEntitiesOnGraphRef.current?.instance.disabledPlotOnGraph,
      resetSelectedEntities:
        plotEntitiesOnGraphRef.current?.instance.resetSelectedEntities,
    } as any
  }

  return {
    selectedEntities:
      plotEntitiesOnGraphRef.current?.instance.selectedEntities(type),
    selectedEntitiesCount:
      plotEntitiesOnGraphRef.current?.instance.selectedEntitiesCount(type),
    setSelectEntities:
      plotEntitiesOnGraphRef.current?.instance.setSelectEntities(type),
    selectUnSelectAllEntities:
      plotEntitiesOnGraphRef.current?.instance.selectUnSelectAllEntities(type),
    isAllSelectedEntities:
      plotEntitiesOnGraphRef.current?.instance.isAllSelectedEntities(type),
    plotSelectedOnGraph:
      plotEntitiesOnGraphRef.current?.instance.plotSelectedOnGraph,
    disabledPlotOnGraph:
      plotEntitiesOnGraphRef.current?.instance.disabledPlotOnGraph,
    resetSelectedEntities:
      plotEntitiesOnGraphRef.current?.instance.resetSelectedEntities,
  } as any
}
