import { CoinType } from '@platform/types/coin'
import { TransactionAddressUtxoFilters } from '@platform/components/ProbeSandbox/types/filters/TransactionAddressUtxoFilters'
import { getAmountNumber } from '@clain/core/utils/format'
import {
  TransactionAddressUtxoInput,
  TransactionAddressUtxoOutput,
} from '@clain/api/platform/types'

export const getTransactionAddressFilteredAndSortedData = ({
  inputs,
  outputs,
  currency,
  filters,
}: {
  inputs: TransactionAddressUtxoInput[]
  outputs: TransactionAddressUtxoOutput[]
  currency: CoinType
  filters: TransactionAddressUtxoFilters
}) => {
  let filteredOutputs = [...outputs]
  let filteredInputs = [...inputs]
  const filterByAmount = (
    amount: number,
    amountFrom?: string,
    amountTo?: string
  ) => {
    const formattedAmount = getAmountNumber(amount, currency)

    if (amountFrom != null && amountTo != null) {
      return +amountFrom <= formattedAmount && formattedAmount <= +amountTo
    } else if (amountFrom != null) {
      return +amountFrom <= formattedAmount
    } else if (amountTo != null) {
      return formattedAmount <= +amountTo
    }

    return false
  }

  filteredInputs = filteredInputs.filter((el) => {
    return filterByAmount(
      el.amount,
      filters.inputAmountFrom,
      filters.inputAmountTo
    )
  })

  filteredOutputs = filteredOutputs.filter((el) => {
    const filteredByScore =
      filters.scoreFrom <= el.score && el.score <= filters.scoreTo
    return (
      filteredByScore &&
      filterByAmount(
        el.amount,
        filters.outputAmountFrom,
        filters.outputAmountTo
      )
    )
  })
  if (filters.inputSortOrder) {
    filteredInputs.sort(({ amount: a }, { amount: b }) =>
      filters.inputSortOrder === 'asc' ? a - b : b - a
    )
  }
  if (filters.outputSortOrder) {
    filteredOutputs.sort(({ amount: a }, { amount: b }) =>
      filters.outputSortOrder === 'asc' ? a - b : b - a
    )
  }

  return { inputs: filteredInputs, outputs: filteredOutputs }
}
