import { ContainerModule, interfaces } from 'inversify'
import {
  ITrxFilterSocketAPIService,
  IExplorerRestAPIService,
  IDashboardRestAPIService,
  IAddressesFilterSocketAPIService,
  ICategoriesRestAPIService,
} from '../platform/types'
import { DI_TYPES } from './types'
import {
  TrxFilterSocketAPIService,
  AddressesFilterRestAPIService,
} from '../platform/socket'
import {
  DashboardRestAPIService,
  ExplorerRestAPIService,
  CategoriesRestAPIService,
} from '../platform/rest'

const initializeModule = (bind: interfaces.Bind) => {
  /////// SOCKET API SERVICES ///////

  bind<ITrxFilterSocketAPIService>(DI_TYPES.TrxFilterSocketAPIService).to(
    TrxFilterSocketAPIService
  )

  bind<IAddressesFilterSocketAPIService>(
    DI_TYPES.AddressesFilterSocketAPIService
  ).to(AddressesFilterRestAPIService)

  /////// REST API SERVICES ///////

  bind<IExplorerRestAPIService>(DI_TYPES.ExplorerRestAPIService).to(
    ExplorerRestAPIService
  )

  bind<IDashboardRestAPIService>(DI_TYPES.DashboardRestAPIService).to(
    DashboardRestAPIService
  )

  bind<ICategoriesRestAPIService>(DI_TYPES.CategoriesRestAPIService).to(
    CategoriesRestAPIService
  )
}

export const platformAPIModules = new ContainerModule(initializeModule)
