import { EventType } from '../GraphEntityEvent.types'
import { injectable } from 'inversify'

@injectable()
export class EntityEventController {
  private _prevEvent: EventType | null = null

  get prevEvent() {
    return this._prevEvent
  }

  public setPrevEvent = (type: EventType | null) => {
    this._prevEvent = type
  }
}
