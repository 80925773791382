import http from '@clain/core/http'
import {
  ExplorerCfdSearchParams,
  IExplorerRestAPIService,
  RawExplorerCfdSearchResponse,
} from '../types'
import { injectable } from 'inversify'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

const explorerCfdSearchURL = ({
  blockchain,
}: ExplorerCfdSearchParams['query']) =>
  `/api/private/explorer/${blockchain}/cfd_search`

export const explorerCfdSearchCsvURL = ({
  blockchain,
}: ExplorerCfdSearchParams['query']) =>
  `/api/private/explorer/${blockchain}/cfd_search/csv`

export const explorerCfdSearch = (params: ExplorerCfdSearchParams) =>
  http
    .post<RawExplorerCfdSearchResponse>(
      explorerCfdSearchURL(params.query),
      params.data
    )
    .then((res) => normalizeSnakeToCamelCase(res.data))

const explorerCfdSearchCsv = (params: ExplorerCfdSearchParams) =>
  http.post(explorerCfdSearchCsvURL(params.query), params.data)

@injectable()
export class ExplorerRestAPIService implements IExplorerRestAPIService {
  public explorerCfdSearch = explorerCfdSearch
  public explorerCfdSearchCsv = explorerCfdSearchCsv
}
