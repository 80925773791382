import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { inject, injectable } from 'inversify'
import { assertsEntityCurrency } from '../../../utils/assertEntityCurrency'
import {
  FlowId,
  IProbeEvents,
  GraphEventsOptions,
  AccumulationEntitiesKeyType,
} from '../../ProbeEvents'
import { IProbeState } from '../../ProbeState'
import {
  addressAddEvents,
  addressDeleteEvents,
  counterpartyAddEvents,
  counterpartyDeleteEvents,
  counterpartyInflow,
  counterpartyOutflow,
  counterpartyInfowDeleteEvent,
  osintAddEvents,
  osintDeleteEvents,
  transactionAddEvents,
  transactionDeleteEvents,
  counterpartyOutfowDeleteEvent,
  counterpartyNetflow,
  counterpartyNetfowDeleteEvent,
  netFlow,
  inOutFlow,
  deleteEdgeEvent,
  transactionEvmByTypeAddEvents,
  deleteTrxEdgeEvents,
  transactionUtxoInputs,
  transactionAddressUtxoDeleteEvents,
  transactionUtxoOutputs,
  transactionAddressesEvents,
  transactionTokenUtxoDeleteEvents,
  transactionTokenEvents,
  counterpartyAddressInflow,
  counterpartyAddressOutflow,
  counterpartyAddressNetflow,
  counterpartyAddressAddEvents,
  counterpartyAddressInfowDeleteEvent,
  counterpartyAddressOutfowDeleteEvent,
  counterpartyAddressNetfowDeleteEvent,
  counterpartyAddressDeleteEvents,
} from '../helpers/entitiesHandles'
import {
  IActiveEntityData,
  IActiveEntityEvents,
  IActiveEntityStrategies,
  IActiveEntityType,
} from './ActiveEntityEvents.types'

@injectable()
export class ActiveEntityEvents implements IActiveEntityEvents {
  private strategies = new Map<IActiveEntityType, IActiveEntityStrategies>()

  constructor(
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState
  ) {
    this.strategies.set('counterparty', {
      add: counterpartyAddEvents,
      delete: counterpartyDeleteEvents,
    })
    this.strategies.set('counterparty-address', {
      add: counterpartyAddressAddEvents,
      // @ts-expect-error
      delete: counterpartyAddressDeleteEvents,
    })
    this.strategies.set('address', {
      add: addressAddEvents,
      delete: addressDeleteEvents,
    })
    this.strategies.set('osint', {
      add: osintAddEvents,
      delete: osintDeleteEvents,
    })
    this.strategies.set('transaction', {
      add: transactionAddEvents,
      delete: transactionDeleteEvents,
    })
    this.strategies.set('evmTransactionByType', {
      add: transactionEvmByTypeAddEvents,
      delete: deleteTrxEdgeEvents,
    })

    this.strategies.set('counterpartyAddressInflow', {
      add: counterpartyAddressInflow,
      delete: counterpartyAddressInfowDeleteEvent,
    })
    this.strategies.set('counterpartyAddressOutflow', {
      add: counterpartyAddressOutflow,
      delete: counterpartyAddressOutfowDeleteEvent,
    })
    this.strategies.set('counterpartyAddressNetflow', {
      add: counterpartyAddressNetflow,
      delete: counterpartyAddressNetfowDeleteEvent,
    })

    this.strategies.set('counterpartyInflow', {
      add: counterpartyInflow,
      delete: counterpartyInfowDeleteEvent,
    })
    this.strategies.set('counterpartyOutflow', {
      add: counterpartyOutflow,
      delete: counterpartyOutfowDeleteEvent,
    })
    this.strategies.set('counterpartyNetflow', {
      add: counterpartyNetflow,
      delete: counterpartyNetfowDeleteEvent,
    })
    this.strategies.set('transactionUtxoInput', transactionUtxoInputs)
    this.strategies.set('transactionUtxoOutput', transactionUtxoOutputs)
    this.strategies.set('transactionAddresses', {
      add: transactionAddressesEvents,
      delete: transactionAddressUtxoDeleteEvents,
    })
    this.strategies.set('transactionTokens', {
      add: transactionTokenEvents,
      delete: transactionTokenUtxoDeleteEvents,
    })
    this.strategies.set('netFlow', netFlow)
    this.strategies.set('inOutFlow', inOutFlow)
    this.strategies.set('deleteEdge', deleteEdgeEvent)
  }

  private selectedNode = () => {
    let node = this.probeState.selectedNode

    if (!node) {
      node = this.probeState.nodes.get(this.probeState.selectedEdge.sourceKey)
    }

    return node
  }

  private accumulatinEntitiesByType = (
    type: IActiveEntityType
  ): AccumulationEntitiesKeyType => {
    if (type === 'transactionUtxoInput' || type === 'transactionUtxoOutput') {
      return 'all'
    }

    return 'newAdded'
  }

  public emit = (
    type: IActiveEntityType,
    data: IActiveEntityData,
    select = false,
    id?: FlowId
  ) => {
    if (!this.strategies.has(type)) {
      throw new Error(`No entityEvent found for type ${type}`)
    }

    const strategy = this.strategies.get(type)
    const nodeData = this.selectedNode()?.data
    assertsEntityCurrency(nodeData)
    const currency = nodeData?.currency

    const baseOptions: GraphEventsOptions = {
      animation: true,
      animationType: { strategy: 'moveToCentroid', scaleStrategy: 'auto' },
      accumulationEntitiesKeyType: this.accumulatinEntitiesByType(type),
    }

    if (typeof strategy === 'function') {
      const events = strategy(data, currency)
      const { meta } = this.probeEvents.emit(events, {
        id,
        ...baseOptions,
      })
      return { events, meta }
    }

    const events = strategy[select ? 'add' : 'delete'](data, currency)

    const { meta } = this.probeEvents.emit(events, {
      id,
      ...baseOptions,
    })

    return { events, meta }
  }
}
