import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'

import ProbeNode from './ProbeNode'
import { icon } from '@caudena/web-platform/dist/components/Icon/iconFn'
import { OsintNodeData } from '../../types'
import { ellipsis } from '@clain/core/utils'
import { ILinkType } from '@clain/graph/src/types'

const SHAPE = 'square'
const CORE_RADIUS = 48
const CORE_BACKGROUND_COLOR = 'rgba(255, 214, 0, 1)'
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const LABEL_ORBIT_SIZE = 36
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4

@injectable()
export class OsintNode<
  T extends OsintNodeData = OsintNodeData,
> extends ProbeNode<T> {
  public linkType: ILinkType = 'master'

  protected generateAttributes() {
    const title = this.data.title ?? 'untitled'
    const iconSize = parseInt(this.theme.getToken(['icon', 'lg', 'size']))
    const iconColor = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ])

    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      shape: SHAPE,
      linkType: 'master',
      icon: icon({ variant: 'osintOpenSourceIntel' }),
      iconColor: iconColor,
      iconWidth: iconSize,
      iconHeight: iconSize,
      orbits: [
        {
          size: CORE_RADIUS + 2,
          border: {
            width: 0,
            color: OUTER_BORDER_COLOR,
          },
        },
        {
          size: LABEL_ORBIT_SIZE,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
                color: LABEL_COLOR,
                fontSize: LABEL_SIZE,
                fill: LABEL_BACKGROUND_COLOR,
                borderRadius: LABEL_BORDER_RADIUS,
                padding: [2, 1],
                text: ellipsis(title, 22),
              },
            },
          ],
          virtual: true,
        },
      ],
      locked: true,
      disabled: this.disabled || !this.layers.osint,
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.orbits[0].border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    return attributes
  }
}
