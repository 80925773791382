import { action, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'
import type { Osint } from '../../types/converted/Osint'

@injectable()
export class OsintActiveEntityViewModel {
  @observable public data: Osint

  constructor() {
    makeObservable(this)
  }

  @action
  public update(data: Osint) {
    this.data = data
  }

  @action
  public clearData() {
    this.data = undefined
  }

  @action
  public clear() {
    this.clearData()
  }
}
