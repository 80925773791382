import { AppGraph } from '../types'
import PixiNode from '../node'
import { AreaGfx } from '../area'
import { IAnimationRender } from './AnimationRender.module'

export class NodeLinkingModule {
  private nodeKeyToNodeInstance: Map<string, PixiNode>
  private nodeKeyToAreaGfx: Map<string, AreaGfx>
  private graph: AppGraph
  private getScaled: () => number
  public currentOverlappingNode: string | null = null

  constructor(
    private animationRender: IAnimationRender,
    params: {
      nodeKeyToNodeInstance: Map<string, PixiNode>
      nodeKeyToAreaGfx: Map<string, any>
      graph: AppGraph
      getScaled: () => number
    }
  ) {
    this.nodeKeyToNodeInstance = params.nodeKeyToNodeInstance
    this.nodeKeyToAreaGfx = params.nodeKeyToAreaGfx
    this.graph = params.graph
    this.getScaled = params.getScaled
  }

  private getNodeProps = (nodeKey: string) => {
    return this.graph.getNodeAttributes(nodeKey)
  }

  public isOverlappedWith = (slaveNodeKey: string): string | null => {
    const slaveNode = this.nodeKeyToNodeInstance.get(slaveNodeKey)
    if (!slaveNode) return null
    const scale = this.getScaled()

    const slaveNodePosition = {
      x:
        slaveNode.nodeGfx.gfx.getBounds().x +
        slaveNode.nodeGfx.gfx.getBounds().width / 2,
      y:
        slaveNode.nodeGfx.gfx.getBounds().y +
        slaveNode.nodeGfx.gfx.getBounds().height / 2,
    }
    const slaveNodeSize = {
      width: slaveNode.nodeGfx.gfx.getBounds().width,
      height: slaveNode.nodeGfx.gfx.getBounds().height,
    }

    let nearestNodeKey: string | null = null
    let minDistance = Infinity

    const OFFSET = 0

    this.graph.forEachNode((masterNodeKey) => {
      if (masterNodeKey !== slaveNodeKey) {
        const masterNode = this.nodeKeyToNodeInstance.get(masterNodeKey)
        if (
          masterNode &&
          this.getNodeProps(masterNodeKey).linkType === 'master'
        ) {
          const masterNodePosition = {
            x:
              masterNode.nodeGfx.gfx.getBounds().x +
              masterNode.nodeGfx.gfx.getBounds().width / 2,
            y:
              masterNode.nodeGfx.gfx.getBounds().y +
              masterNode.nodeGfx.gfx.getBounds().height / 2,
          }
          const radius = this.getNodeProps(masterNodeKey).area?.radius * scale

          if (
            this.isOverlapping(
              slaveNodePosition,
              slaveNodeSize,
              masterNodePosition,
              radius + OFFSET
            )
          ) {
            const distance = this.calculateDistance(
              slaveNodePosition,
              masterNodePosition
            )
            if (distance < minDistance) {
              minDistance = distance
              nearestNodeKey = masterNodeKey
            }
          }
        }
      }
    })
    return nearestNodeKey
  }

  public hideLinkingArea = () => {
    const node = this.nodeKeyToNodeInstance.get(this.currentOverlappingNode)
    if (node) {
      this.currentOverlappingNode = null
      const area = this.nodeKeyToAreaGfx.get(node.id)
      this.getNodeProps(node.id).area?.hideAnimation?.(area.gfx, {
        onAnimationStart: this.animationRender.onAnimationStart,
        onAnimationComplete: this.animationRender.onAnimationComplete,
      })
    }
  }

  public showLinkingArea = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)

    if (node) {
      if (this.currentOverlappingNode !== nodeKey) {
        this.hideLinkingArea()
        this.currentOverlappingNode = nodeKey
      }
      const area = this.nodeKeyToAreaGfx.get(node.id)
      this.getNodeProps(node.id).area?.showAnimation?.(area.gfx, {
        onAnimationStart: this.animationRender.onAnimationStart,
        onAnimationComplete: this.animationRender.onAnimationComplete,
      })
    }
  }

  private isOverlapping = (
    slaveNodePosition: { x: number; y: number },
    slaveNodeSize: { width: number; height: number },
    masterNodePosition: { x: number; y: number },
    radius: number
  ): boolean => {
    const rectHalfWidth = slaveNodeSize.width / 2
    const rectHalfHeight = slaveNodeSize.height / 2

    const closestX = Math.max(
      slaveNodePosition.x - rectHalfWidth,
      Math.min(masterNodePosition.x, slaveNodePosition.x + rectHalfWidth)
    )
    const closestY = Math.max(
      slaveNodePosition.y - rectHalfHeight,
      Math.min(masterNodePosition.y, slaveNodePosition.y + rectHalfHeight)
    )

    const distanceX = masterNodePosition.x - closestX
    const distanceY = masterNodePosition.y - closestY

    const distanceSquared = Math.pow(distanceX, 2) + Math.pow(distanceY, 2)
    return distanceSquared <= Math.pow(radius, 2)
  }

  private calculateDistance = (
    positionOne: { x: number; y: number },
    positionTwo: { x: number; y: number }
  ): number => {
    return Math.sqrt(
      Math.pow(positionOne.x - positionTwo.x, 2) +
        Math.pow(positionOne.y - positionTwo.y, 2)
    )
  }
}
