import { isEmpty } from 'ramda'
import {
  GraphReaction,
  GraphReactionEvents,
  ProbeEdgesEvent,
  ProbeNodesEvent,
} from '../../types/graphReaction'
import {
  ServerRemoveEdgeEvent,
  ServerRemoveNodeEvent,
  ServerUpdateEdgeReceive,
  ServerUpdateNodeReceive,
  ServerUpdatePositionNodeEvent,
} from '../../types/serverData'
import { IProbeState } from '../ProbeState'
import { inject, injectable } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class EventsGraphReaction implements GraphReaction {
  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState
  ) {}

  public addNode(node: Pick<ProbeNodesEvent, 'data' | 'key'>) {
    if (!this.probeState.nodes.has(node.key)) {
      this.probeState.nodes.set(node.key, node.data)
    }
  }

  public updateNode({ key, data: { nodeData } }: ServerUpdateNodeReceive) {
    if (this.probeState.nodes.has(key) && !isEmpty(nodeData)) {
      this.probeState.nodes.get(key).updateData(nodeData)
    }
  }

  public updateEdge({ key, data }: ServerUpdateEdgeReceive) {
    if (this.probeState.edges.has(key) && !isEmpty(data.edgeData)) {
      this.probeState.edges.get(key).updateData(data.edgeData)
    }
  }

  public removeNode = ({ key }: Pick<ServerRemoveNodeEvent, 'key'>) => {
    if (this.probeState.nodes.has(key)) {
      this.probeState.nodes.get(key).destroy()
      this.probeState.nodes.delete(key)
      this.probeState.selectedNodeIds.delete(key)
    }
  }

  public addEdge(edge: Pick<ProbeEdgesEvent, 'data' | 'key'>) {
    if (!this.probeState.edges.has(edge.key)) {
      this.probeState.edges.set(edge.key, edge.data)
    }
  }

  public updatePosition({
    key,
    data,
  }: Pick<ServerUpdatePositionNodeEvent, 'key' | 'data'>) {
    if (this.probeState.nodes.has(key)) {
      this.probeState.nodes.get(key).moveTo(data.position)
    }
  }

  public removeEdge = ({ key }: Pick<ServerRemoveEdgeEvent, 'key'>) => {
    if (this.probeState.edges.has(key)) {
      this.probeState.edges.get(key).destroy()
      this.probeState.edges.delete(key)
      this.probeState.selectedEdgeIds.delete(key)
    }
  }

  public multipleEvents = async (events: GraphReactionEvents[]) => {
    events.forEach((event) => {
      if (event.type === 'add_node') {
        this.addNode(event)
      } else if (event.type === 'update_node') {
        this.updateNode(event)
      } else if (event.type === 'delete_node') {
        this.removeNode(event)
      } else if (event.type === 'add_edge') {
        this.addEdge(event)
      } else if (event.type === 'update_edge') {
        this.updateEdge(event)
      } else if (event.type === 'delete_edge') {
        this.removeEdge(event)
      } else if (event.type === 'update_position') {
        this.updatePosition(event)
      }
    })
  }
}
