import http from '@clain/core/http'
import { injectable } from 'inversify'
import {
  ICategoriesRestAPIService,
  RawCategoriesResponse,
} from '../types/categories.types'

export const categoriesURL = () => '/api/private/categories'

export const getCategories = () =>
  http.get<RawCategoriesResponse>(categoriesURL()).then((res) => res.data.data)

@injectable()
export class CategoriesRestAPIService implements ICategoriesRestAPIService {
  public getCategories = getCategories
}
