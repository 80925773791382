import { Container } from 'inversify'
import { categoriesModule, probeGraphModules } from '@platform/di/modules'
import { ModuleStage } from '@clain/core/utils'
import { platformAPIModules } from '@clain/api/DI/platformAPIModules'
import { phoenixSocketControllerContainer } from '@clain/api/DI/socketControllerModule'
import { INotificationService, ISentryService } from '@clain/api/WebSocket'
import { DI_TYPES } from '@clain/api/DI/types'
import { Notification } from '@clain/core/ui-kit'
import * as Sentry from '@sentry/react'

const rootContainer = new Container()

rootContainer.parent = phoenixSocketControllerContainer
phoenixSocketControllerContainer.load(platformAPIModules)

phoenixSocketControllerContainer
  .bind<INotificationService>(DI_TYPES.NotificationService)
  .toConstantValue(Notification)
phoenixSocketControllerContainer
  .bind<ISentryService>(DI_TYPES.SentryService)
  .toConstantValue(Sentry)

rootContainer.load(probeGraphModules)
rootContainer.load(categoriesModule)

export const moduleStage = new ModuleStage(rootContainer)

export const getModule = <T>(key: symbol) => {
  return rootContainer.get<T>(key)
}

export { rootContainer }
