import { TokensBalance, Token } from '@clain/api/platform/types'
import { CoinType } from '../../../types/coin'
import { mergeDeepRight } from 'ramda'
import { TokenCategory } from '../types/serverData'
import {
  DEFAULT_USD_TOKEN,
  DEFAULT_CURRENCY_TOKEN_ID,
  defaultTokenByCurrency,
} from '@clain/core/utils/defaultCurrency'

export * from '@clain/core/utils/defaultCurrency'

export const applyDefaultUsdToken = (token?: Token): Token => {
  if (token) {
    return token
  }

  return DEFAULT_USD_TOKEN.token
}

export const proxyDefaultTokenId = (tokenId: Token['id']) => {
  if (DEFAULT_USD_TOKEN.token.id === tokenId) {
    return undefined
  }

  if (DEFAULT_CURRENCY_TOKEN_ID.includes(tokenId)) {
    return 0
  }

  return tokenId
}

export const mergeDefaultTokenWithProxy = (
  currency: CoinType,
  token?: Partial<Token>
): Token => {
  const selectToken = mergeDeepRight(
    defaultTokenByCurrency[currency].token,
    token || {}
  )
  //TODO remove as when issue will be resolved https://github.com/ramda/types/issues/130
  return selectToken as Token
}

export const mergeDefaultToken = (
  currency: CoinType,
  token?: Partial<Token>
): Token => {
  if (!defaultTokenByCurrency[currency]?.token) {
    return token as Token
  }
  //TODO remove as when issue will be resolved https://github.com/ramda/types/issues/130
  return mergeDeepRight(
    defaultTokenByCurrency[currency].token,
    token || {}
  ) as Token
}

export const mergeDefaultTokenByCategory = (
  category: TokenCategory,
  currency: CoinType,
  token?: Partial<Token>,
  aggregateUsd?: boolean
): Token => {
  if (category === 'token') {
    return token as Token
  }

  if (category === 'base') {
    return defaultTokenByCurrency[currency].token
  }

  if (aggregateUsd) {
    return DEFAULT_USD_TOKEN.token
  }
}

export const convertTokenBalances = (
  tokens: TokensBalance = [],
  currency: CoinType
) => {
  return [DEFAULT_USD_TOKEN, defaultTokenByCurrency[currency], ...tokens]
}
