import { injectable, inject } from 'inversify'
import { action, makeObservable, observable, toJS } from 'mobx'

import type { Position } from '../../types/Position'
import type { ShortcutMenuData } from '../../types/graphData/ShortcutMenuData'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeApp } from '../../types/ProbeApp'
import { getProbeModule } from '../../di'

type ShortcutMenuType = 'comment' | 'circularMenu'

interface ShortcutMenuItem {
  type: ShortcutMenuType
  worldPosition: Position
  viewportPosition: Position
  disabled?: boolean
}

@injectable()
export class ShortcutMenuController {
  @observable public items: Map<string, ShortcutMenuItem> = new Map()

  constructor(@inject(DI_PROBE_TYPES.ProbeApp) private app: ProbeApp) {
    makeObservable(this)
  }

  @action
  public init = () => {
    this.app = getProbeModule(DI_PROBE_TYPES.ProbeApp)

    this.app.on(
      'moved',
      action(() => {
        this.items.forEach(
          ({ worldPosition, viewportPosition, disabled }, key) => {
            if (worldPosition) {
              const newViewportPosition =
                this.app.toGlobalCoordinates(worldPosition)

              if (
                newViewportPosition.x !== viewportPosition.x ||
                newViewportPosition.y !== viewportPosition.y
              ) {
                this.items.get(key).viewportPosition = newViewportPosition
              }
            }
          }
        )
      })
    )
  }

  public isOpened = (key: string) => {
    return this.items.has(key)
  }

  @action
  public open = (key: string, type: ShortcutMenuType, position: Position) => {
    this.items.set(key, {
      type,
      worldPosition: position,
      viewportPosition: this.app.toGlobalCoordinates(position),
    })
  }

  @action
  public hide = (key: string) => {
    this.items.delete(key)
  }

  @action
  public save() {
    const data = Array.from(this.items.keys()).map((key) => ({
      key,
      data: toJS(this.items.get(key)),
    }))

    this.app.graph.setAttribute('shortcutMenuData', data)
  }

  @action
  public load(data: ShortcutMenuData) {
    if (data?.length) {
      this.items = new Map(data.map(({ key, data }) => [key, data]))
    }
  }
}
