import { TransactionAddressNodeData } from '../types/nodeEntitiesData/TransactionAddressNodeData'
import { action } from 'mobx'
import { IAutoSelectTransaction } from '../models'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from './ProbeState'
import { IProbeEvents } from './ProbeEvents'

export interface IUtxoController {
  playNext(nodeKey: string): void
  playPrev(nodeKey: string): void
}

@injectable()
export class UtxoController implements IUtxoController {
  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.AutoSelectTransaction)
    private autoSelectTransaction: IAutoSelectTransaction
  ) {}

  @action
  public playNext = (nodeKey: string) => {
    if (this.probeEvents.meta.nodesInProcessing[nodeKey]) return

    const nodeData = this.probeState.nodes.get(nodeKey)
      .data as TransactionAddressNodeData

    const { meta } = this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            type: 'input',
            direction: 'out',
            strategy: 'transaction',
            createBy: 'by-trxAddress',
            currency: nodeData.currency,
            trxAddressData: nodeData,
          },
        },
      ],
      {
        animation: true,
        animationType: {
          strategy: 'moveToNode',
          nodeType: 'utxo_transaction_address',
        },
      }
    )
    this.autoSelectTransaction.setFlowData({
      flowId: meta.id,
      transactionHash: nodeData?.next?.trxHash,
    })
  }

  @action
  public playPrev = (nodeKey: string) => {
    if (this.probeEvents.meta.nodesInProcessing[nodeKey]) return

    const nodeData = this.probeState.nodes.get(nodeKey)
      .data as TransactionAddressNodeData

    const { meta } = this.probeEvents.emit(
      [
        {
          type: 'add_node',
          data: {
            type: 'input',
            direction: 'in',
            strategy: 'transaction',
            createBy: 'by-trxAddress',
            currency: nodeData.currency,
            trxAddressData: nodeData,
          },
        },
      ],
      {
        animation: true,
        animationType: {
          strategy: 'moveToNode',
          nodeType: 'utxo_transaction_address',
        },
      }
    )
    this.autoSelectTransaction.setFlowData({
      flowId: meta.id,
      transactionHash: nodeData?.previous?.trxHash,
    })
  }
}
