import { isEVM, isUTXO, type CoinType } from '@clain/core/types/coin'
import { mergeByKeys } from '@clain/core/utils'
import { action, computed, makeObservable } from 'mobx'
import type { Address } from '../../../types/converted/Address'
import type { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import type { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import type { ClusterTransactionsAggregate } from '../../../types/converted/ClusterTransactions'
import type { Osint } from '../../../types/converted/Osint'
import { DEFAULT_USD_TOKEN } from '../../../utils/convertTokenBalances'
import type { IPlotParentController } from '../../PlotParentController'
import { ActiveEntity } from '../ActiveEntity'
import type { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import { ActiveEntityFetchState } from '../ActiveEntityFetchState'
import {
  counterpartiesFilters,
  osintsFilters,
  tokenByAddressFilters,
  tokensFilters,
  transactionsFilters,
} from '../ActiveEntityFilters'
import { activeEntityAddressState } from '../ActiveEntityState'
import {
  INITIAL_FILTERS_CURRENCY,
  DEFAULT_OSINTS_FILTERS_CURRENCY,
  DEFAULT_TOKENS_FILTERS,
  DEFAULT_FILTERS_CURRENCY,
  EXCLUDE_FILTERS_CURRENCY,
} from '../constants'
import { applyAllTransferTokens } from '../helpers'
import { normalizeOldTransactionEvm } from '../helpers/normalizeTransaction'
import type {
  ClusterEntitiesFetch,
  ClusterEntitiesFetchState,
  ClusterEntitiesFilters,
} from './ActiveEntityAddress.types'
import type { AddressCounterparty } from '@platform/components/ProbeSandbox/types/converted/AddressCounterparty'
import { EVM_COINS } from '@clain/core/ui-kit'
import { checkCoinsByType } from '@clain/core/utils/checkCoinByType'
import { ActiveEntityVisitedLink } from '../ActiveEntityVisited'
import { injectable, inject } from 'inversify'
import type {
  TransactionActiveEntityFetchFacade,
  TokensActiveEntityFetchFacade,
  TokenByAddressActiveEntityFetchFacade,
  CounterpartiesActiveEntityFetchFacade,
  OsintsActiveEntityFetchFacade,
} from '../ActiveEntityFetch'
import { getProbeModule } from '@platform/components/ProbeSandbox/di'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from '../../ProbeState'

@injectable()
export class ActiveEntityAddress {
  private activeEntityVisitedLink = new ActiveEntityVisitedLink()

  private activeEntity: ActiveEntity<
    ClusterEntitiesFetchState,
    ClusterEntitiesFetch,
    ClusterEntitiesFilters,
    Address
  > = null

  constructor(
    @inject(DI_PROBE_TYPES.ActiveEntityEvents)
    private activeEntityEvents: IActiveEntityEvents,
    @inject(DI_PROBE_TYPES.PlotParentController)
    private plotParentController: IPlotParentController,
    @inject(DI_PROBE_TYPES.TransactionsFetchStateFacade)
    private transactionsFetch: TransactionActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.TokensFetchStateFacade)
    private tokensFetch: TokensActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.TokenByAddressFetchStateFacade)
    private tokenByAddressFetch: TokenByAddressActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.CounterpartiesFetchStateFacade)
    private counterpartiesFetch: CounterpartiesActiveEntityFetchFacade,
    @inject(DI_PROBE_TYPES.OsintsFetchStateFacade)
    private osintsFetch: OsintsActiveEntityFetchFacade
  ) {
    makeObservable(this)
    this.activeEntity = new ActiveEntity(
      {
        entitiesFetchState: {
          counterparties: this.counterpartiesFetch.fetchState,
          transactions: this.transactionsFetch.fetchState,
          osints: this.osintsFetch.fetchState,
          tokens: this.tokensFetch.fetchState,
          tokenByAddress: this.tokenByAddressFetch.fetchState,
        },
        entitiesFetch: {
          counterparties: this.counterpartiesFetch,
          transactions: this.transactionsFetch,
          osints: this.osintsFetch,
          tokens: this.tokensFetch,
          tokenByAddress: this.tokenByAddressFetch,
        },
        entitiesFilters: {
          counterparties: counterpartiesFilters,
          transactions: transactionsFilters,
          osints: osintsFilters,
          tokens: tokensFilters,
          tokenByAddress: tokenByAddressFilters,
        },
        entityState: activeEntityAddressState,
        entityKey: 'address',
      },
      getProbeModule<IProbeState>(DI_PROBE_TYPES.ProbeState)
    )
  }

  @action
  public init(currency: CoinType) {
    if (currency) {
      this.activeEntity.init(currency)

      counterpartiesFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      counterpartiesFilters.setDefaultFilters(
        DEFAULT_FILTERS_CURRENCY[currency]
      )

      transactionsFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      transactionsFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])

      osintsFilters.initFilters(DEFAULT_OSINTS_FILTERS_CURRENCY[currency])
      tokensFilters.initFilters(DEFAULT_TOKENS_FILTERS)
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.activeEntity.currency]
  }

  @computed
  public get filters() {
    return this.activeEntity.entitiesFilters
  }

  private get counterpartyFilterTokenId() {
    return counterpartiesFilters.filters?.includeTokens?.[0]?.id
  }

  @computed
  public get tokensBalance() {
    return this.tokensFetch.fetchState?.state?.tokens || []
  }

  @computed
  public get tokensTotalCount() {
    return this.tokensFetch.fetchState?.state?.pagination?.totalEntries || 0
  }

  @computed
  public get tokens() {
    return this.tokensBalance.map((token) => token.token) || []
  }

  @computed
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed
  public get tokensLoading() {
    return this.tokensFetch.fetchState?.loading
  }

  @computed
  public get tokenByAddressLoading() {
    return this.tokenByAddressFetch.fetchState?.loading
  }

  public get disabledTransactionAssetStaticSearch() {
    return (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters.address,
        EVM_COINS,
        'address'
      )
    )
  }

  @computed
  public get transactionTokens() {
    if (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters?.address,
        EVM_COINS,
        'address'
      )
    ) {
      return this.tokenByAddressFetch.fetchState?.state
        ? [this.tokenByAddressFetch.fetchState?.state]
        : []
    }

    return this.tokensWithoutAggregated.filter(
      (token) => !token?.spam && !token?.scam
    )
  }

  @action
  public setTokenByAddress = (address: string) => {
    if (isUTXO(this.activeEntity.currency)) return

    if (checkCoinsByType(address, EVM_COINS, 'address')) {
      tokenByAddressFilters.updateFilters({ address })
      return
    }

    tokenByAddressFilters.updateFilters({ address: '' })
  }

  @computed
  public get counterparties() {
    return this.activeEntity.entitiesFetchState.counterparties
  }

  @computed.struct
  public get transactions(): ActiveEntityFetchState<ClusterTransactionsAggregate> {
    const result = this.activeEntity.entitiesFetchState.transactions

    if (result.state?.data?.transactions?.length) {
      return mergeByKeys(
        'state.data.transactions',
        applyAllTransferTokens(result.state?.data?.transactions),
        result
      ) as ActiveEntityFetchState<ClusterTransactionsAggregate>
    }

    return result as ActiveEntityFetchState<ClusterTransactionsAggregate>
  }

  @computed
  public get osints() {
    return this.activeEntity.entitiesFetchState.osints
  }

  public isVisitedLink = (
    ...args: Parameters<typeof this.activeEntityVisitedLink.has>
  ) => {
    return this.activeEntityVisitedLink.has(...args)
  }

  public visitedLinkAdd = (
    ...args: Parameters<typeof this.activeEntityVisitedLink.add>
  ) => {
    return this.activeEntityVisitedLink.add(...args)
  }

  @computed
  public get data() {
    return activeEntityAddressState.state
  }

  public clear() {
    this.activeEntity.clear()
    this.activeEntityVisitedLink.clear()
  }

  public update(...args: Parameters<typeof this.activeEntity.update>) {
    this.activeEntity.update(...args)
  }

  public toggleCounterparty = (
    data: ClusterCounterparty | AddressCounterparty,
    select: boolean
  ) => {
    this.toggleAllCounterparties(
      [data] as ClusterCounterparty[] | AddressCounterparty[],
      select
    )
  }

  public toggleAllCounterparties = (
    data: (ClusterCounterparty | AddressCounterparty)[],
    select: boolean
  ) => {
    if (data.some((item) => item.type === 'cluster')) {
      this.activeEntityEvents.emit('counterparty', data, select)
    } else {
      this.activeEntityEvents.emit('counterparty-address', data, select)
    }
  }

  public toggleCounterpartyInflow = async (
    data: ClusterCounterparty | AddressCounterparty,
    select: boolean
  ) => {
    if (data.type === 'cluster') {
      this.activeEntityEvents.emit(
        'counterpartyInflow',
        {
          entity: [{ clusterId: this.data.clusterId }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    } else {
      this.activeEntityEvents.emit(
        'counterpartyAddressInflow',
        {
          entity: [{ address: this.data.address }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    }
  }

  public toggleCounterpartyOutflow = async (
    data: ClusterCounterparty | AddressCounterparty,
    select: boolean
  ) => {
    if (data.type === 'cluster') {
      this.activeEntityEvents.emit(
        'counterpartyOutflow',
        {
          entity: [{ clusterId: this.data.clusterId }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    } else {
      this.activeEntityEvents.emit(
        'counterpartyAddressOutflow',
        {
          entity: [{ address: this.data.address }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    }
  }

  public toggleCounterpartyNetflow = async (
    data: ClusterCounterparty | AddressCounterparty,
    select: boolean
  ) => {
    if (data.type === 'cluster') {
      this.activeEntityEvents.emit(
        'counterpartyNetflow',
        {
          entity: [{ clusterId: this.data.clusterId }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    } else {
      this.activeEntityEvents.emit(
        'counterpartyAddressNetflow',
        {
          entity: [{ address: this.data.address }, data],
          options: { tokenId: this.counterpartyFilterTokenId },
        },
        select
      )
    }
  }

  public toggleTransaction = async (
    data: ClusterTransactionInputsOutputsAggregate,
    select: boolean
  ) => {
    this.toggleAllTransactions([data], select)
  }

  public toggleAllTransactions = async (
    data: Array<ClusterTransactionInputsOutputsAggregate>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transaction',
      normalizeOldTransactionEvm(data),
      select
    )
  }

  public toggleOsint = async (data: Osint, select: boolean) => {
    this.toggleAllOsints([data], select)
  }

  public toggleAllOsints = async (data: Array<Osint>, select: boolean) => {
    this.activeEntityEvents.emit('osint', data, select)
  }

  public plotParent = this.plotParentController.plotParentByActiveEntity
}
