import { inject, injectable } from 'inversify'
import { TransactionProbeNodeUtxo } from '../types/entities/TransactionProbeNodeUTXO'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeEvents } from './ProbeEvents'
import { IProbeState } from './ProbeState'

@injectable()
export class CrossChainSwapActionViewModel {
  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents
  ) {}

  public renderSwap = (id: string) => {
    if (id !== null) {
      if (!this.probeState.nodes.has(id)) return

      const node = this.probeState.nodes.get(id) as TransactionProbeNodeUtxo

      const crossChainSwapData = node.data?.crossSwap

      if (crossChainSwapData) {
        this.probeEvents.emit(
          [
            {
              type: 'add_node',
              data: {
                strategy: 'crossChainSwap',
                ...crossChainSwapData,
              },
            },
          ],
          {
            animation: true,
            accumulationEntitiesKeyType: 'all',
            animationType: {
              strategy: 'moveToCentroid',
              scaleStrategy: 'auto',
            },
          }
        )
      }
    }
  }
}
