import { ISubscribable } from './Subscribable.types'
import { injectable } from 'inversify'

@injectable()
export class Subscribable<MessageType> implements ISubscribable<MessageType> {
  private subscribers: Set<(msg: MessageType) => void> = new Set()

  constructor() {}

  public subscribe = (cb: (msg: MessageType) => void) => {
    this.subscribers.add(cb)

    return () => {
      this.subscribers.delete(cb)
    }
  }

  public clearSubscribers = () => {
    this.subscribers.clear()
  }

  public publish = (msg: MessageType) => {
    this.subscribers.forEach((subscriber) => {
      subscriber(msg)
    })
  }
}
