import { action, makeObservable } from 'mobx'
import {
  GraphEntityEvent,
  EntitySelectPayload,
} from '../GraphEntityEvent.types'
import { getBoundedDomainBlock } from '../../../utils/getDomainBlock'
import { IEntititiesGhosted } from '@platform/components/ProbeSandbox/models'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import type { IProbeState } from '@clain/graph-factory-entities'
import type { ProbeApp } from '@platform/components/ProbeSandbox/types/ProbeApp'
import { getProbeModule } from '@platform/components/ProbeSandbox/di'

@injectable()
export class EntitySelectController {
  constructor(
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.EntititiesGhosted)
    private entitiesGhosted: IEntititiesGhosted
  ) {
    makeObservable(this)
  }

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  @action
  public onSelectArea = ({
    payload: { nodeKeys, edgeKeys, isExpanding },
  }: GraphEntityEvent<EntitySelectPayload>) => {
    const nodeKeysSet = new Set(nodeKeys)
    const edgeKeysSet = new Set(edgeKeys)
    this.entitiesGhosted.toggleVisibleEntities({
      nodeKeys,
      edgeKeys,
      isExpanding,
      isToggle: false,
    })

    nodeKeys.forEach((nodeKey) => {
      const transactionBlock = getBoundedDomainBlock(this.app.graph, nodeKey)

      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((edgeKey) => {
          edgeKeysSet.add(edgeKey)
        })
      }
    })

    edgeKeys.forEach((edgeKey) => {
      const transactionBlock = getBoundedDomainBlock(this.app.graph, edgeKey)

      if (transactionBlock) {
        transactionBlock.nodeKeys.forEach((nodeKey) => {
          nodeKeysSet.add(nodeKey)
        })

        transactionBlock.edgeKeys.forEach((edgeKey) => {
          edgeKeysSet.add(edgeKey)
        })
      }
    })

    let nodeKeysSetToApply: Set<string> = nodeKeysSet
    let edgeKeysSetToApply: Set<string> = edgeKeysSet

    if (isExpanding) {
      nodeKeysSetToApply = new Set([
        ...nodeKeysSet,
        ...this.probeState.selectedNodeIds,
      ])
      edgeKeysSetToApply = new Set([
        ...edgeKeysSet,
        ...this.probeState.selectedEdgeIds,
      ])
    }

    if (nodeKeysSetToApply.size) {
      this.probeState.selectedNodeIds = nodeKeysSetToApply
      this.probeState.selectedEdgeIds = edgeKeysSetToApply
    }
  }

  @action
  public onUnSelectArea = ({
    payload: { nodeKeys, edgeKeys },
  }: GraphEntityEvent<EntitySelectPayload>) => {
    nodeKeys.forEach((nodeKey) => {
      const transactionBlock = getBoundedDomainBlock(this.app.graph, nodeKey)

      if (transactionBlock) {
        this.probeState.selectedNodeIds.delete(nodeKey)
        transactionBlock.edgeKeys.forEach((edgeKey) => {
          this.probeState.selectedEdgeIds.delete(edgeKey)
        })
      } else {
        this.probeState.selectedNodeIds.delete(nodeKey)
      }
    })

    edgeKeys.forEach((edgeKey) => {
      const transactionBlock = getBoundedDomainBlock(this.app.graph, edgeKey)

      if (transactionBlock) {
        transactionBlock.nodeKeys.forEach((nodeKey) => {
          this.probeState.selectedNodeIds.delete(nodeKey)
        })

        transactionBlock.edgeKeys.forEach((edgeKey) => {
          this.probeState.selectedEdgeIds.delete(edgeKey)
        })
      } else {
        this.probeState.selectedEdgeIds.delete(edgeKey)
      }
    })

    this.entitiesGhosted.removeGhostedEntitiesByKeys({
      nodeKeys,
      edgeKeys,
    })
  }
}
