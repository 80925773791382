import { ContainerModule } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState, ProbeState } from '../../vm/ProbeState'
import { ProbeViewModelState } from '../../vm/states/ProbeViewModelState'
import {
  SearchState,
  ISearchState,
  AlertState,
  IAlertState,
} from '../../vm/states'
import { GraphHistoryState } from '../../vm/GraphHistory/GraphHistoryState'
import { IGraphEventsSettings } from '../../vm/ProbeEvents/ProbeEvents.types'
import {
  GraphEventsSettings,
  IProccesingEntityState,
  ProccesingEntityState,
} from '@clain/graph-entities'
import { ContainerLayoutState } from '../../vm/states/ContainerLayoutState'
import { IContainerLayoutState } from '../../models/IContainerLayoutState'
import { CameraState } from '../../vm/states/CameraState'
import { ICameraState } from '../../models/ICameraState'

export const stateModule = new ContainerModule((bind) => {
  bind<IProbeState>(DI_PROBE_TYPES.ProbeState).to(ProbeState).inSingletonScope()

  bind<ProbeViewModelState>(DI_PROBE_TYPES.ProbeViewModelState)
    .to(ProbeViewModelState)
    .inSingletonScope()

  bind<ISearchState>(DI_PROBE_TYPES.SearchState)
    .to(SearchState)
    .inSingletonScope()

  bind<IAlertState>(DI_PROBE_TYPES.AlertState).to(AlertState).inSingletonScope()

  bind<IGraphEventsSettings>(DI_PROBE_TYPES.GraphEventsSettings)
    .to(GraphEventsSettings)
    .inSingletonScope()

  bind(DI_PROBE_TYPES.GraphHistoryState)
    .to(GraphHistoryState)
    .inSingletonScope()

  bind<IProccesingEntityState>(DI_PROBE_TYPES.ProccesingEntityState)
    .toDynamicValue(() => new ProccesingEntityState())
    .inSingletonScope()

  bind<ICameraState>(DI_PROBE_TYPES.CameraState)
    .to(CameraState)
    .inSingletonScope()

  bind<IContainerLayoutState>(DI_PROBE_TYPES.ContainerLayoutState)
    .to(ContainerLayoutState)
    .inSingletonScope()
})
