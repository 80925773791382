import { Circle, Sprite, Container, Text, Graphics } from 'pixi.js'
import IconGfx from '../../core/gfx/IconGfx'
import formatColor from '../../core/utils/formatColor'
import getTextureKey from '../../core/utils/getTextureKey'
import TextureCache from '../../textureCache'
import { NodeOptions, TextNodeOptions } from '../../types'
import getNodeLargestOrbitSize from '../utils/getNodeLargestOrbitSize'
import getNodeOuterSize from '../utils/getNodeOuterSize'
import { IAnimationRender } from '../../modules/AnimationRender.module'

interface CreateNodeGfxProps {
  nodeOptions: NodeOptions
}

interface UpdateNodeGfxProps {
  nodeOptions: NodeOptions
  textureCache: TextureCache
}

enum NODE_GFX {
  CORE = 'NODE_CORE',
  OUTER_BORDER = 'NODE_OUTER_BORDER',
  ORBIT = 'NODE_ORBIT',
  TEXT = 'NODE_TEXT',
}

class NodeGfx {
  public gfx: Container
  private iconGfx: IconGfx
  private linkIconGfx: IconGfx

  constructor(
    private animationRender: IAnimationRender,
    props: CreateNodeGfxProps
  ) {
    this.iconGfx = new IconGfx()
    this.linkIconGfx = new IconGfx()
    this.linkIconGfx.gfx.zIndex = 3
    if (props.nodeOptions.type === 'text') {
      this.createTextGfx(props.nodeOptions)
    } else {
      this.createGfx(props)
    }
  }

  private drawShape = (graphics: Graphics, size: number, shape: string) => {
    switch (shape) {
      case 'circle':
        return graphics.circle(size, size, size)

      case 'square':
        return graphics.roundRect(size, size, size, size, size * 0.25)

      case 'rhombus':
        return this.drawRhombus(graphics, size)

      case 'hexagon':
        return this.drawHexagon(graphics, size)
    }
  }

  private drawRhombus = (graphics: Graphics, size: number) => {
    const topX = graphics.width / 2
    const topY = graphics.height / 2 - size / 2
    const rightX = graphics.width / 2 + size / 2
    const rightY = graphics.height / 2
    const bottomX = graphics.width / 2
    const bottomY = graphics.height / 2 + size / 2
    const leftX = graphics.width / 2 - size / 2
    const leftY = graphics.height / 2

    return graphics.poly([
      topX,
      topY,
      rightX,
      rightY,
      bottomX,
      bottomY,
      leftX,
      leftY,
    ])
  }

  private drawHexagon = (graphics: Graphics, size: number) => {
    const height = size * Math.sqrt(3)

    return graphics.poly([
      -size,
      0,
      -size / 2,
      height / 2,
      size / 2,
      height / 2,
      size,
      0,
      size / 2,
      -height / 2,
      -size / 2,
      -height / 2,
    ])
  }

  public updateGfx = async ({
    nodeOptions,
    textureCache,
  }: UpdateNodeGfxProps) => {
    const { increaseHitArea = 0 } = nodeOptions

    if (nodeOptions.type === 'text') {
      this.updateTextGfx(nodeOptions)
      await this.linkIconGfx.updateGfx(
        {
          icon: nodeOptions.linkIcon?.icon,
          width: nodeOptions.linkIcon?.iconWidth,
          height: nodeOptions.linkIcon?.iconHeight,
          color: nodeOptions.linkIcon?.iconColor,
          visibility: nodeOptions.linkIcon?.visible,
        },
        textureCache
      )
      this.linkIconInitVisibility(nodeOptions)
      return
    }
    const nodeLargestOrbitSize = getNodeLargestOrbitSize(nodeOptions)
    const nodeOuterSize = getNodeOuterSize(nodeOptions)

    this.updateNodeCoreGfx(nodeOptions, textureCache)

    this.updateNodeOuterBorderGfx(
      nodeOptions,
      nodeLargestOrbitSize,
      textureCache
    )
    this.updateNodeOrbitsGfx(nodeOptions, textureCache)
    ;(this.gfx.hitArea as Circle).radius = nodeOuterSize + increaseHitArea
    await this.iconGfx.updateGfx(
      {
        icon: nodeOptions.icon,
        width: nodeOptions.iconWidth,
        height: nodeOptions.iconHeight,
        color: nodeOptions.iconColor,
      },
      textureCache
    )
    await this.linkIconGfx.updateGfx(
      {
        icon: nodeOptions.linkIcon?.icon,
        width: nodeOptions.linkIcon?.iconWidth,
        height: nodeOptions.linkIcon?.iconHeight,
        color: nodeOptions.linkIcon?.iconColor,
        visibility: nodeOptions.linkIcon?.visible,
      },
      textureCache
    )
    const linkIconShift = nodeOuterSize + 8
    this.linkIconGfx.gfx.position.set(-linkIconShift, -linkIconShift)

    this.linkIconInitVisibility(nodeOptions)
  }

  private updateTextGfx = (nodeOptions: TextNodeOptions): void => {
    const textNode = this.gfx.getChildByName(NODE_GFX.TEXT) as Text
    if (textNode) {
      textNode.text = nodeOptions.text || ''
      const scaleFactor = nodeOptions.textStyle.scale <= 1.5 ? 3 : 7
      const fontSize = nodeOptions.textStyle.fontSize * scaleFactor
      const lineHeight = fontSize * (nodeOptions.textStyle.lineHeight || 1.15)
      textNode.style = {
        fontSize,
        wordWrapWidth:
          (nodeOptions.width - nodeOptions.textStyle.padding * 2) *
          (scaleFactor / nodeOptions.textStyle.scale),
        lineHeight: parseFloat(lineHeight.toFixed(2)),
        fill: nodeOptions.textStyle.fill || '#161F32',
        wordWrap: true,
        breakWords: true,
      }

      textNode.visible = nodeOptions.visible
      textNode.scale.set(nodeOptions.textStyle.scale / scaleFactor)
    }
  }

  private updateNodeCoreGfx = (
    nodeOptions: NodeOptions,
    textureCache: TextureCache
  ) => {
    const nodeCoreTextureKey = getTextureKey(
      NODE_GFX.CORE,
      nodeOptions.fill,
      nodeOptions.size,
      nodeOptions.opacity,
      nodeOptions.shape
    )
    const nodeCoreTexture = textureCache.get(nodeCoreTextureKey, () => {
      const graphics = new Graphics()
      this.drawShape(graphics, nodeOptions.size, nodeOptions.shape)
      if (nodeOptions.fill) {
        const [color, alpha] = formatColor(nodeOptions.fill)
        const opacity = nodeOptions.opacity ?? alpha
        graphics.fill({ color, alpha: opacity })
      }
      return graphics
    })
    const nodeCore = this.gfx.getChildByName(NODE_GFX.CORE) as Sprite
    if (nodeCore) {
      nodeCore.texture = nodeCoreTexture
    }
  }

  private updateNodeOuterBorderGfx = (
    nodeOptions: NodeOptions,
    nodeLargestOrbitSize: number,
    textureCache: TextureCache
  ) => {
    const nodeOuterBorderTextureKey = getTextureKey(
      NODE_GFX.OUTER_BORDER,
      nodeLargestOrbitSize,
      nodeOptions.size,
      nodeOptions.border?.color,
      nodeOptions.border?.width,
      nodeOptions.border?.opacity,
      nodeOptions.shape
    )

    const nodeOuterBorderTexture = textureCache.get(
      nodeOuterBorderTextureKey,
      () => {
        const graphics = new Graphics()
        const borderSize =
          Math.max(nodeLargestOrbitSize, nodeOptions.size) +
          (nodeOptions.border?.width ?? 0) / 2
        this.drawShape(graphics, borderSize, nodeOptions.shape)
        if (nodeOptions.border?.width) {
          const [color, alpha] = formatColor(nodeOptions.border.color)
          const opacity = nodeOptions.border.opacity ?? alpha
          graphics.stroke({
            width: nodeOptions.border.width,
            color,
            alpha: opacity,
          })
        }
        return graphics
      }
    )
    const nodeOuterBorder = this.gfx.getChildByName(
      NODE_GFX.OUTER_BORDER
    ) as Sprite
    if (nodeOuterBorder) {
      nodeOuterBorder.texture = nodeOuterBorderTexture
    }
  }

  private updateNodeOrbitsGfx = (
    nodeOptions: NodeOptions,
    textureCache: TextureCache
  ) => {
    nodeOptions.orbits?.forEach((orbitOptions, index) => {
      const nodeOrbitTextureKey = getTextureKey(
        NODE_GFX.ORBIT,
        orbitOptions.fill,
        orbitOptions.size,
        orbitOptions.border?.color,
        orbitOptions.border?.opacity,
        orbitOptions.border?.width,
        orbitOptions.segments,
        nodeOptions.shape
      )
      const nodeOrbitTexture = textureCache.get(nodeOrbitTextureKey, () => {
        const graphics = new Graphics()
        const orbitSize =
          orbitOptions?.size + (orbitOptions?.border?.width ?? 0) / 2

        this.drawShape(graphics, orbitSize, nodeOptions.shape)

        if (orbitOptions.border?.width) {
          const [color, alpha] = formatColor(orbitOptions.border.color)
          const opacity = orbitOptions.border.opacity ?? alpha
          graphics.stroke({
            width: orbitOptions.border.width,
            color,
            alpha: opacity,
          })
        }
        if (orbitOptions.fill) {
          const [color, alpha] = formatColor(orbitOptions.fill)
          graphics.fill({ color, alpha })
        }
        if (orbitOptions.segments?.length) {
          const circleSegments: typeof orbitOptions.segments = [
            {} as (typeof orbitOptions.segments)[number],
            ...orbitOptions.segments,
          ]
          circleSegments.forEach((segment) => {
            if (segment.fill) {
              const [color, alpha] = formatColor(segment.fill)
              const offset = segment.centerOffset ?? 0
              const angle = (segment.angleFrom + segment.angleTo) / 2
              const centerX = orbitSize + offset * Math.cos(angle)
              const centerY = orbitSize + offset * Math.sin(angle)
              graphics.moveTo(centerX, centerY)
              graphics.arc(
                centerX,
                centerY,
                orbitSize - offset,
                segment.angleFrom,
                segment.angleTo
              )
              graphics.lineTo(centerX, centerY)
              graphics.fill({ color, alpha })
            }
            graphics.fill({ alpha: 0 })
          })
        }

        return graphics
      })
      const nodeOrbit = this.gfx.getChildByName(
        `${NODE_GFX.ORBIT}_${index}`
      ) as Sprite
      if (nodeOrbit) {
        nodeOrbit.texture = nodeOrbitTexture
      }
    })
  }

  private linkIconInitVisibility = (nodeOptions: NodeOptions): void => {
    if (nodeOptions.linkIcon?.visible) {
      if (nodeOptions.linkIcon?.showAnimation) {
        nodeOptions.linkIcon?.showAnimation?.(
          this.linkIconGfx.gfx as Graphics,
          {
            onAnimationStart: this.animationRender.onAnimationStart,
            onAnimationComplete: this.animationRender.onAnimationComplete,
          }
        )
      } else {
        this.linkIconGfx.gfx.visible = nodeOptions.linkIcon?.visible
      }
    } else {
      if (nodeOptions.linkIcon?.hideAnimation) {
        nodeOptions.linkIcon?.hideAnimation(this.linkIconGfx.gfx as Graphics, {
          onAnimationStart: this.animationRender.onAnimationStart,
          onAnimationComplete: this.animationRender.onAnimationComplete,
        })
      } else {
        this.linkIconGfx.gfx.visible = nodeOptions.linkIcon?.visible
      }
    }
  }

  private createTextGfx = (nodeOptions: TextNodeOptions): void => {
    this.gfx = new Container()
    const { padding, scale, ...style } = nodeOptions.textStyle
    const text = new Text({
      text: nodeOptions.text,
      style,
      position: {
        x: padding || 0,
        y: padding || 0,
      },
      visible: nodeOptions.visible,
      label: NODE_GFX.TEXT,
    })
    this.gfx.addChild(text)
    this.gfx.addChild(this.linkIconGfx.gfx)
    this.linkIconInitVisibility(nodeOptions)
    this.updateTextGfx(nodeOptions)
  }

  private createGfx = ({ nodeOptions }: CreateNodeGfxProps): void => {
    this.gfx = new Container()
    this.gfx.hitArea = new Circle(0, 0, 0)

    const nodeCore = new Sprite()
    nodeCore.label = NODE_GFX.CORE
    nodeCore.anchor.set(0.5)
    this.gfx.addChild(nodeCore)

    const nodeOuterBorder = new Sprite()
    nodeOuterBorder.label = NODE_GFX.OUTER_BORDER
    nodeOuterBorder.anchor.set(0.5)
    this.gfx.addChild(nodeOuterBorder)

    nodeOptions.orbits?.forEach((_, index) => {
      const nodeOrbit = new Sprite()
      nodeOrbit.label = `${NODE_GFX.ORBIT}_${index}`
      nodeOrbit.anchor.set(0.5)
      this.gfx.addChild(nodeOrbit)
    })

    this.gfx.addChild(this.iconGfx.gfx)
    this.gfx.addChild(this.linkIconGfx.gfx)
    this.linkIconInitVisibility(nodeOptions)
  }
}

export default NodeGfx
