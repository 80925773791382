import { injectable, inject } from 'inversify'
import { action, autorun, computed, makeObservable, reaction } from 'mobx'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { Position } from '../../types/Position'
import { ITextNodeController } from './TextNodeController.types'
import { ITextAreaController } from './TextAreaController.types'
import { IProbeState } from '../ProbeState'
import { ProbeApp } from '../../types/ProbeApp'
import { DEFAULT_ZOOM } from '../constants'
import { CameraViewModel } from '../CameraViewModel'
import { ProbeViewModelState } from '../states/ProbeViewModelState'
import { getProbeModule } from '../../di'

@injectable()
export class TextController {
  constructor(
    @inject(DI_PROBE_TYPES.TextNodeController)
    private textNodeController: ITextNodeController,
    @inject(DI_PROBE_TYPES.TextAreaController)
    public textAreaController: ITextAreaController,
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeApp) private app: ProbeApp,
    @inject(DI_PROBE_TYPES.CameraViewModel) private camera: CameraViewModel,
    @inject(DI_PROBE_TYPES.ProbeViewModelState)
    private state: ProbeViewModelState
  ) {
    makeObservable(this)
  }

  @action
  public init() {
    this.app = getProbeModule(DI_PROBE_TYPES.ProbeApp)

    this.app.on('world:mousedown', () => {
      this.probeState.nodes.forEach((node) => {
        if (node.data.nodeType !== 'text') return

        if (
          node.key !== this.state.mouseDownNodeKey &&
          this.textNodeController.isActive
        ) {
          this.textNodeController.deactivateTextNode()
          this.textAreaController.clear()
        }
      })
    })

    reaction(
      () => ({
        textPosition: this.textNodeController?.probeTextNode?.position,
      }),
      ({ textPosition }) => {
        if (textPosition && this.textNodeController.temporaryTextNode) {
          this.textNodeController.temporaryTextNode.moveTo(textPosition)
        }
      }
    )

    reaction(
      () => ({
        textData: this.textNodeController?.probeTextNode?.data,
      }),
      ({ textData }) => {
        if (textData && this.textNodeController.temporaryTextNode) {
          this.textNodeController.temporaryTextNode.setData(textData)
        }
      }
    )

    autorun(() => {
      if (
        this.textNodeController?.probeTextNode?.key &&
        !this.probeState.nodes.has(this.textNodeController?.probeTextNode?.key)
      ) {
        this.clear()
      }
    })
  }

  @action
  public activateTextNode = (key: string) => {
    this.textNodeController.activateTextNode(key)
  }

  @action
  public createTextNode = () => {
    this.probeState.nodes.forEach((node) => {
      if (node.data.nodeType !== 'text') return
      if (this.probeState.selectedNodeIds.has(node.key)) {
        this.probeState.selectedNodeIds.delete(node.key)
      }
    })

    this.textNodeController.createTextNode()
  }

  public get isPositionTextOnCanvasInProgress() {
    return this.textNodeController.isPositionTextOnCanvasInProgress
  }

  @computed
  public get useTextAreaProps() {
    const text = this.textNodeController?.temporaryTextNode?.data.text
    return {
      scale: this.camera?.zoom || DEFAULT_ZOOM,
      position: this.textNodeController.positionOnCanvas,
      size: this.textNodeController.temporaryTextNode.size,
      isAspectRatioLocked: this.textAreaController.isAspectRatioLocked,
      isEditTextMode: this.textAreaController.isEditTextMode,
      onResizeStop: (position: Position) => {
        this.textAreaController.onMoveTextNode(position)
        this.textNodeController.updateServerNodeData('all', true)
        if (this.textNodeController.probeTextNode.text !== text) {
          this.textNodeController.syncProbeStateTextNode()
        }
      },
      onResize: (
        params: Parameters<typeof this.textAreaController.onResize>[0]
      ) => {
        this.textAreaController.onResize(params)
        this.textNodeController.updateServerNodeData('update_node', false)
      },
      deleteTextNode: () => {
        this.textNodeController.deleteTextNode()
      },
      textArea: {
        text,
        width: this.textAreaController.textAreaWidth,
        fontSize: this.textNodeController.temporaryTextNode.data.fontSize,
        scale: this.textAreaController.textAreaScale,
        pivotPosition: this.textAreaController.pivotPosition,
        placeholder: this.textAreaController.placeholder,
        onInput: (
          params: Parameters<typeof this.textAreaController.onTextAreaInput>[0]
        ) => {
          this.textAreaController.onTextAreaInput(params)
          this.textNodeController.updateServerNodeData('update_node', false)
        },
        onBlur: this.textAreaController.onTextAreaBlur,
        onClick: this.textAreaController.onTextAreaClick,
      },
    }
  }

  public get isActivated() {
    return this.textNodeController.isActive
  }

  @action
  public clear = () => {
    this.textNodeController.clear()
    this.textAreaController.clear()
  }
}

export default TextController
