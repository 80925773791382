import http from '@clain/core/http'
import {
  DashboardTopByParams,
  IDashboardRestAPIService,
  RawStat,
  RawStatResponse,
} from '../types'
import useHttp from '@clain/core/useHttp'
import { injectable } from 'inversify'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

export const dashboardTopByURL = (params: DashboardTopByParams) =>
  `/api/private/dashboard/${params.coin}/top/${params.topBy}`

//test
export const getDashboardTopBy = (params: DashboardTopByParams) =>
  http
    .get<RawStatResponse>(dashboardTopByURL(params))
    .then((res) => normalizeSnakeToCamelCase(res.data.data))

export const useDashboardTopBy = (params: DashboardTopByParams) => {
  return useHttp<RawStat[]>(dashboardTopByURL(params))
}

@injectable()
export class DashboardRestAPIService implements IDashboardRestAPIService {
  public getDashboardTopBy = getDashboardTopBy
}
