import { injectable, inject } from 'inversify'
import { pick } from 'ramda'

import type { IAddedEntities } from '../AddedEntities'
import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import {
  EventTransactionToken,
  IEntitiesMainState,
  ServerAddEvents,
  CreateData,
  LiteTransactionNodeUtxo,
} from '../../types'
import { transactionAddressTokenKey, transactionKey } from '../../utils'

@injectable()
export class GenerateNodeTransactionTokenUTXO extends GenerateNode<EventTransactionToken> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventTransactionToken>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { id, currency, hash, tokenTransfers, inputs, outputs } = data

    const nodes = this.nodes({ meta })

    const trxAddressInputKey = transactionAddressTokenKey(
      tokenTransfers.sender,
      hash
    )
    const trxAddressOutputKey = transactionAddressTokenKey(
      tokenTransfers.receiver,
      hash
    )

    const transactionAddresFromData = inputs.find(
      ({ address }) => address === tokenTransfers.sender.address
    )
    const transactionAddresToData = outputs.find(
      ({ address }) => address === tokenTransfers.receiver.address
    )

    const trxNode = this.probeState.nodes.get(
      hash
    ) as CreateData<LiteTransactionNodeUtxo>
    const trxKey = transactionKey({ hash })

    if (!this.isNodeExists(trxKey)) return nodes.acc

    if (!this.isNodeExists(trxAddressInputKey)) {
      nodes.push({
        type: 'add_node',
        key: trxAddressInputKey,
        data: {
          id,
          position: trxNode.position,
          currency: currency,
          type: 'utxo_transaction_address',
          nodeData: {
            ...pick(['id', 'position'], transactionAddresFromData),
            addressType: 'token_input',
          },
        },
      })
    }

    if (!this.isNodeExists(trxAddressOutputKey)) {
      nodes.push({
        type: 'add_node',
        key: trxAddressOutputKey,
        data: {
          id,
          position: trxNode.position,
          currency: currency,
          type: 'utxo_transaction_address',
          nodeData: {
            ...pick(['id', 'position'], transactionAddresToData),
            addressType: 'token_output',
          },
        },
      })
    }

    return nodes.acc
  }
}
