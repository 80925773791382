import axios, { AxiosInstance, AxiosResponse, CancelTokenStatic } from 'axios'

export type { CancelTokenSource } from 'axios'

interface HttpConfigOptions {
  token?: string
  baseURL?: string
}

export interface Http extends AxiosInstance {
  config: (options: HttpConfigOptions) => void
  use: (
    onFulfilled: (value: any) => any,
    onRejected?: (error: any) => any
  ) => number
  CancelToken: CancelTokenStatic
}

export type HttpResponse<T = any> = AxiosResponse<T>

const http = axios.create({
  // withCredentials: true,
}) as Http

http.CancelToken = axios.CancelToken

http.config = ({ token, baseURL }: HttpConfigOptions) => {
  if (token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  if (baseURL) {
    http.defaults.baseURL = baseURL
  }
}

http.use = (onFulfilled, onRejected) => {
  return http.interceptors.response.use(onFulfilled, onRejected)
}

export default http
