import { reaction, IReactionDisposer } from 'mobx'
import { PROBE_INFOBAR_WIDTH } from './constants'
import { IProbeState } from './ProbeState'
import { injectable, inject } from 'inversify'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { ProbeApp } from '@platform/components/ProbeSandbox/types/ProbeApp'
import { getProbeModule } from '../di'
import type { IContainerLayoutState } from '../models'

export interface IResizeGraphViewport {
  observer: () => void
  clear: () => void
}

@injectable()
export class ReziseGraphViewport implements IResizeGraphViewport {
  private reactionDisposers: Array<IReactionDisposer> = []
  private prevOpenState = false
  private readonly minCanvasHeight = 300

  constructor(
    @inject(DI_PROBE_TYPES.ProbeState)
    private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ContainerLayoutState)
    private containerLayoutState: IContainerLayoutState,
    @inject(DI_PROBE_TYPES.CanvasContainerProvider)
    private canvasContainer: () => Promise<HTMLDivElement>
  ) {}

  private get app() {
    return getProbeModule<ProbeApp>(DI_PROBE_TYPES.ProbeApp)
  }

  private resize = async ({
    isInfobarActive,
    bottombarHeight,
  }: Pick<IProbeState, 'isInfobarActive'> & {
    bottombarHeight: number
  }) => {
    const container = await this.canvasContainer()
    if (!container) return

    const { width, height } = container.getBoundingClientRect()
    let infobarWidth = 0

    if (isInfobarActive) {
      infobarWidth = PROBE_INFOBAR_WIDTH
    }

    const offsetX =
      this.prevOpenState === isInfobarActive
        ? 0
        : !this.prevOpenState
          ? infobarWidth
          : -PROBE_INFOBAR_WIDTH

    const resizeHeight = height - bottombarHeight

    this.app.resize(
      { width, height },
      {
        width: width - infobarWidth,
        height:
          resizeHeight > this.minCanvasHeight
            ? resizeHeight
            : this.minCanvasHeight,
        offsetX,
        offsetViewportX: infobarWidth,
      }
    )
    this.prevOpenState = isInfobarActive
  }

  private resizeWithContainers = () => {
    this.resize({
      isInfobarActive: this.probeState.isInfobarActive,
      bottombarHeight: parseInt(this.containerLayoutState.height),
    })
  }

  public observer = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          isBottombarActive: this.probeState.isBottombarActive,
          isInfobarActive: this.probeState.isInfobarActive,
          bottombarHeight: parseInt(this.containerLayoutState.height),
        }),
        this.resize
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isBottombarActive: this.probeState.isBottombarActive,
          isInfobarActive: this.probeState.isInfobarActive,
          bottombarHeight: parseInt(this.containerLayoutState.height),
        }),
        this.resize
      )
    )
    window.addEventListener('resize', this.resizeWithContainers)
  }

  clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    window.removeEventListener('resize', this.resizeWithContainers)
  }
}
