import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import { Position } from '../types/Position'
import { edgeKey } from '../utils/key'
import { CommentPinProbeNode } from '../types/entities/CommentPinProbeNode'
import { ServerEventNodeEdgeReceive } from '../types/serverData'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { IProbeState } from './ProbeState'
import { IProbeEvents } from './ProbeEvents'
import { ProbeApp } from '../types/ProbeApp'
import { EntityLinkingController } from './GraphEntityEvent/controllers/EntityLinkingController'
import type { ProbeViewModelState } from './states/ProbeViewModelState'
import type { ILayersViewModel } from './LayersViewModel'
import type { ShortcutMenuController } from './shortcut-menu/ShortcutMenuController'
import type { IGraphFactoryEntities } from '../models/IGraphFactoryEntities'
import type { IPointerController } from './PointerController'
import type { EventsGraphReaction } from './EventsGraphReaction'
import type { SettingsViewModel } from '../../../modules'
import { getProbeModule } from '../di'

@injectable()
export class CommentsController {
  @observable public isPositioningInProgress: boolean

  constructor(
    @inject(DI_PROBE_TYPES.Factory)
    private factory: ReturnType<IGraphFactoryEntities['getFactoryEntity']>,
    @inject(DI_PROBE_TYPES.ProbeState) private probeState: IProbeState,
    @inject(DI_PROBE_TYPES.ProbeEvents) private probeEvents: IProbeEvents,
    @inject(DI_PROBE_TYPES.ProbeApp) private app: ProbeApp,
    @inject(DI_PROBE_TYPES.EntityLinkingController)
    private entityLinkingController: EntityLinkingController,
    @inject(DI_PROBE_TYPES.ProbeViewModelState)
    private probeViewModelState: ProbeViewModelState,
    @inject(DI_PROBE_TYPES.ShortcutMenuController)
    private shortcutMenuController: ShortcutMenuController,
    @inject(DI_PROBE_TYPES.LayersViewModel) private layers: ILayersViewModel,
    @inject(DI_PROBE_TYPES.PointerController)
    private pointerController: IPointerController,
    @inject(DI_PROBE_TYPES.EventsGraphReaction)
    private eventsGraphReaction: EventsGraphReaction,
    @inject(DI_PROBE_TYPES.Settings)
    private settings: SettingsViewModel
  ) {
    makeObservable(this)
    this.probeEvents.subscribe(({ events }) =>
      this.closeCommentExecutor(events)
    )
  }

  private closeCommentExecutor = (events: ServerEventNodeEdgeReceive[]) => {
    events?.forEach((event) => {
      if (
        event.type === 'delete_node' &&
        this.probeState.nodes.has(event.key) &&
        this.probeState.nodes.get(event.key).type === 'comment_pin'
      ) {
        this.closeComment(event.key)
      }
    })
  }

  @action
  public init() {
    this.app = getProbeModule(DI_PROBE_TYPES.ProbeApp)

    this.app.on('world:mousedown', () => {
      this.probeState.nodes.forEach((node) => {
        if (node.data.nodeType !== 'comment_pin') return

        if (node.key !== this.probeViewModelState.mouseDownNodeKey) {
          if (this.shortcutMenuController.isOpened(node.key)) {
            this.closeComment(node.key)
          }
        }
      })
    })
  }

  @action
  public setIsPositioningInProgress(isPositioningInProgress: boolean) {
    this.isPositioningInProgress = isPositioningInProgress
  }

  @action
  public addComment = () => {
    if (this.isPositioningInProgress) return

    this.layers.setComments(true)
    this.setIsPositioningInProgress(true)
    const { node: nodeCommentPlug, key: commentPlugKey } =
      this.factory.produceCommentPlugNode()

    const { key, node } = this.factory.produceCommentPinNode({
      plugKey: commentPlugKey,
    })

    const { edge: edgeComment, key: edgeCommentKey } =
      this.factory.produceCommentProbeEdge(key, commentPlugKey)

    this.eventsGraphReaction.multipleEvents([
      { type: 'add_node', key, data: node },
      { type: 'add_node', key: commentPlugKey, data: nodeCommentPlug },
      { type: 'add_edge', key: edgeCommentKey, data: edgeComment },
    ])

    nodeCommentPlug.setDisabled(true)
    if (edgeComment) {
      edgeComment.setDisabled(true)
    }
    this.positionComment(key)
  }

  @action
  public positionComment(key: string) {
    const node = this.probeState.nodes.get(key)
    if (!node) return

    node.setInteractive(false)

    const pointerListener = (position: Position) => {
      const worldPosition = this.app.toWorldCoordinates(position)
      node.moveTo(worldPosition)
      if (node?.linkType === 'slave') {
        this.entityLinkingController.startLinkingProcess(key)
      }
    }

    this.pointerController.addListener(pointerListener)

    this.app.once('world:mouseup', () => {
      this.pointerController.removeListener(pointerListener)
      node.setInteractive(true)
      if (this.isPositioningInProgress) {
        this.openComment(key)
      }
      this.setIsPositioningInProgress(false)
    })
  }

  @action
  public openComment(key: string) {
    const pinNode = this.probeState.nodes.get(key) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)

    if (this.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeState.nodes.get(plugKey)
      plugNode.setDisabled(false)

      plugNode.moveTo({
        x: pinNode.position.x + 150,
        y: pinNode.position.y,
      })

      this.shortcutMenuController.open(key, 'comment', plugNode.position)
    }

    if (this.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(false)
    }
  }

  @action
  public closeComment(key: string) {
    if (!this.probeState.nodes.has(key)) return

    const pinNode = this.probeState.nodes.get(key) as CommentPinProbeNode
    const { plugKey, messages } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)
    const hasMessage = messages?.length
    if (this.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeState.nodes.get(plugKey)
      plugNode.setDisabled(true)
    }

    if (this.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(true)
    }

    this.shortcutMenuController.hide(key)
    if (!hasMessage) {
      if (pinNode?.linkType === 'slave') {
        this.entityLinkingController.hideLinkingArea()
      }
      this.eventsGraphReaction.multipleEvents([
        { type: 'delete_node', key },
        { type: 'delete_node', key: plugKey },
      ])
    }
  }

  @action
  public getComments = (key: string) => {
    if (!this.probeState.nodes.has(key)) return []

    const pinNode = this.probeState.nodes.get(key) as CommentPinProbeNode

    return pinNode.data.messages || []
  }

  @action public createComment = (key: string, text: string) => {
    if (!this.probeState.nodes.has(key)) return
    const pinNode = this.probeState.nodes.get(key) as CommentPinProbeNode
    const comments = pinNode.data.messages || []

    const mergedComments = [
      ...comments,
      {
        name: this.settings.userProfile.email,
        text,
        time: Math.floor(Date.now() / 1000),
      },
    ]

    const firstSendComment = !comments.length

    if (firstSendComment) {
      this.probeEvents.emit([
        {
          type: 'add_node',
          data: {
            strategy: 'comment',
            key,
            messages: [
              {
                name: this.settings.userProfile.email,
                text,
                time: Math.floor(Date.now() / 1000),
              },
            ],
          },
        },
      ])
      if (pinNode?.linkType === 'slave') {
        this.entityLinkingController.finishLinkingProcess(key)
      }
    } else {
      this.probeEvents.emit(
        [
          {
            type: 'update_node',
            key: pinNode.key,
            data: {
              type: 'comment_pin',
              nodeData: {
                messages: mergedComments,
              },
            },
          },
        ],
        { optimistic: true }
      )
    }

    pinNode.updateData({ messages: mergedComments })
  }

  @action
  public deleteComment = (key: string) => {
    if (!this.probeState.nodes.has(key)) return

    const pinNode = this.probeState.nodes.get(key) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)
    this.closeComment(key)

    this.probeEvents.emit([
      { type: 'delete_node', entity: { key } },
      { type: 'delete_node', entity: { key: plugKey } },
      {
        type: 'delete_edge',
        entity: {
          strategy: 'none',
          edgeKey: commentEdgeKey,
        },
      },
    ])
  }
}
