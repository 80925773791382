import { isEVM } from '@clain/core/types/coin'
import { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import { TransactionInputsOutputsAggregate } from '../../../types/converted/TransactionInputsOutputsAggregate'
import { EventTransactionEVMPayload } from '../../ProbeEvents'
import { TransactionCommon } from '@clain/api/platform/types/Transactions'

export const normalizeOldTransactionEvm = (
  data: Array<ClusterTransactionInputsOutputsAggregate>
): Array<TransactionInputsOutputsAggregate<TransactionCommon>> => {
  return data.map((transaction) => {
    if (isEVM(transaction)) {
      const { sender, receiver } = transaction.transfers[0]

      return {
        ...transaction,
        id: transaction.trxId,
        currency: transaction.currency,
        sender,
        receiver,
      } as EventTransactionEVMPayload
    }

    return transaction
  })
}
