import socket from './socket'
import { UserAccessState } from '@clain/core/states/UserAcessState'
import { PhoenixChannel } from './WebSocketChannel'
import {
  INotificationService,
  IPhoenixSocketController,
  ISentryService,
  WSStateInit,
} from './WebSocket.types'
import {
  notificationConfig,
  notificationToastOptions,
  REJECT_REASON,
} from '@clain/core/constants'
import { inject, injectable, optional } from 'inversify'
import { DI_TYPES } from '../DI/types'

const TIMEOUT = 60_000

@injectable()
export class PhoenixSocketController implements IPhoenixSocketController {
  public phoenixSocket: typeof socket
  public channels: Map<string, PhoenixChannel> = new Map()
  public userState: UserAccessState
  public refreshToken: () => void
  private WSChannel = PhoenixChannel

  constructor(
    @inject(DI_TYPES.NotificationService)
    @optional()
    private notificationService: INotificationService,
    @inject(DI_TYPES.SentryService)
    @optional()
    private sentryService: ISentryService
  ) {}

  public init({ wss, token, userState, refreshToken }: WSStateInit) {
    this.connect(wss, token)
    this.userState = userState
    this.refreshToken = refreshToken
  }

  public reconnectWithNewToken = (token: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.phoenixSocket) {
          this.phoenixSocket.disconnect(() => {
            console.log('reconnect with new token is executed')
            this.phoenixSocket.reconnect({ token })
            resolve()
          })
        } else {
          resolve()
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  public channel(topic: string, params?: { [key: string]: unknown }) {
    if (!this.channels.has(topic)) {
      this.channels.set(topic, this.createNewChannel(topic, params))
    }

    return this.channels.get(topic)
  }

  public clear(topic: string) {
    if (this.channels.has(topic)) {
      const channel = this.channel(topic)

      channel.leave()
    }

    this.channels.delete(topic)
  }

  private connect(wss: string, token: string) {
    this.phoenixSocket = socket
    this.phoenixSocket.init(wss, { params: { token }, timeout: TIMEOUT })

    this.phoenixSocket.onError((error) => {
      // console.log('error', error)
      // TODO: вот тут происходит разлогин
      // при потере подключения с беком при деплое
      console.log('error socket', error)
      //window.location.replace('/logout')
    })
  }

  private errorHandled: { [key: string]: boolean } = {}

  private handleErrorOncePerTopic(topic: string, reason: string) {
    if (!this.errorHandled[topic]) {
      this.errorHandled[topic] = true

      this.sentryService?.captureException(new Error(reason), {
        extra: { topic },
      })
      this.notificationService?.notify(
        notificationConfig[REJECT_REASON.phx_error].text,
        { type: notificationConfig[REJECT_REASON.phx_error].type },
        notificationToastOptions
      )
    }
  }

  private createNewChannel(
    topic: string,
    params?: { [key: string]: unknown }
  ): PhoenixChannel {
    const errorHandler = (reason) => {
      if (reason?.reason?.toLowerCase() === 'subscription expired') {
        this.userState.setSubscriptionExpired(true)
      }

      if (
        ['no access', 'no subscription'].includes(
          reason?.reason?.toLowerCase() || ''
        )
      ) {
        this.userState.setNoAccess(true)
      }

      if (
        ['token is invalid', 'token is expired'].includes(
          reason?.reason?.toLowerCase() ||
            reason?.reason?.error?.toLowerCase() ||
            ''
        )
      ) {
        console.log('refresh token is executed')
        this.refreshToken()
        return reason
      }

      if (reason?.reason?.toLowerCase() === 'join crashed') {
        this.handleErrorOncePerTopic(topic, reason.reason)
      }
      return reason
    }
    return new this.WSChannel(
      topic,
      this.phoenixSocket?.channel?.(topic, params),
      errorHandler,
      this.notificationService,
      this.sentryService
    )
  }
}
