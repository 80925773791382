import { makeObservable, observable, action, computed } from 'mobx'

import { AnimationsOptions, IArea } from '@clain/graph/src/types'
import { IAnimationNodeArea, ITheme } from '../../../models'
import type { Graphics } from 'pixi.js'
import gsap from 'gsap'

export class NodeAnimationArea implements IAnimationNodeArea {
  @observable private _area: IArea
  private isAnimating = false

  constructor(private theme: ITheme) {
    makeObservable(this)
  }

  private linkedArea = {
    radius: 120,
    color: this.theme.getToken(['color', 'primary', 'container', 'muted']),
    opacity: 1,
    borderColor: this.theme.getToken(['color', 'primary', 'base']),
    borderWidth: 1,
    showAnimation: (nodeGraphics: Graphics, options: AnimationsOptions) => {
      gsap.to(nodeGraphics, {
        onStart: () => {
          nodeGraphics.visible = true
          nodeGraphics.alpha = 0
          options.onAnimationStart?.()
        },
        duration: 0.25,
        alpha: 1,
        ease: 'power2.out',
        onComplete: () => {
          options.onAnimationComplete?.()
        },
      })
    },
    hideAnimation: (nodeGraphics: Graphics, options: AnimationsOptions) => {
      gsap.to(nodeGraphics, {
        duration: 0.25,
        alpha: 0,
        ease: 'power2.in',
        onStart: () => {
          options.onAnimationStart?.()
        },
        onComplete: () => {
          nodeGraphics.visible = false
          options.onAnimationComplete?.()
        },
      })
    },
  }

  private animationRippleArea: IArea = {
    radius: 80,
    color: this.theme.getToken(['color', 'primary', 'high']),
    opacity: 1,
    borderColor: this.theme.getToken(['color', 'primary', 'muted']),
    borderWidth: 1,
    animationImmediately: (nodeGraphics, options) => {
      if (this.isAnimating) return
      this.isAnimating = true
      nodeGraphics.scale = 0.01

      gsap.to(nodeGraphics, {
        onStart: () => {
          nodeGraphics.visible = true
          nodeGraphics.alpha = 0
        },
        duration: 0.25,
        alpha: 1,
        ease: 'power2.out',
      })

      const createRippleEffect = () => {
        nodeGraphics.scale.set(0.01)
        nodeGraphics.alpha = 1

        gsap.to(nodeGraphics.scale, {
          duration: 1,
          x: 0.13,
          y: 0.13,
          ease: 'power2.out',
        })

        gsap.to(nodeGraphics, {
          duration: 1,
          alpha: 0,
          ease: 'power2.out',
          onComplete: () => {
            if (gsap.getProperty(nodeGraphics, 'visible')) {
              createRippleEffect()
            }
          },
        })
      }

      createRippleEffect()

      gsap.delayedCall(5, () => {
        gsap.killTweensOf(nodeGraphics.scale)
        gsap.to(nodeGraphics, {
          duration: 0.25,
          alpha: 0,
          ease: 'power2.in',
          onComplete: () => {
            nodeGraphics.visible = false
            this.isAnimating = false
            options.onAnimationComplete?.()
          },
        })
        options.updateArea(this.linkedArea)
      })
    },
  }

  @computed
  public get area() {
    return this._area
  }

  @action
  public setArea: IAnimationNodeArea['setArea'] = (type) => {
    if (this.isAnimating) {
      return
    }

    this._area = type === 'ripple' ? this.animationRippleArea : this.linkedArea
  }

  public fireAnimationImmediately: IAnimationNodeArea['fireAnimationImmediately'] =
    (type) => {
      this.setArea(type)
    }
}
