import { Color } from 'pixi.js'
import rgba from 'color-rgba'

const colorCache: Record<number | string, [number, number]> = {}

const formatColor = (color: number | string): [number, number] => {
  const cached = colorCache[color]
  if (cached) {
    return cached
  }

  const rgbaColor = rgba(color)
  const hexColor = new Color([
    rgbaColor[0] / 255,
    rgbaColor[1] / 255,
    rgbaColor[2] / 255,
  ]).toNumber()
  const alpha = rgbaColor[3]

  colorCache[color] = [hexColor, alpha]
  return [hexColor, alpha]
}

export default formatColor
