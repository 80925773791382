import {
  CreatePresetDataPayload,
  ITrxFilterSocketAPIService,
  RawPresetData,
  TransactionPresetsServerData,
} from '../types'
import { inject, injectable } from 'inversify'
import { DI_TYPES } from '../../DI/types'
import { IPhoenixSocketController, PhoenixChannel } from '../../WebSocket'

const CHANNEL_KEY = 'trx_filter:global'

@injectable()
export class TrxFilterSocketAPIService implements ITrxFilterSocketAPIService {
  constructor(
    @inject(DI_TYPES.PhoenixSocketController)
    private socket: IPhoenixSocketController
  ) {
    this.channel = this.socket.channel(CHANNEL_KEY)
  }
  private channel: PhoenixChannel

  public joinChannelAndGetPresets = () => {
    return this.channel.join<TransactionPresetsServerData>()
  }

  public createPreset = (data: CreatePresetDataPayload) =>
    this.channel?.push<RawPresetData>('add_preset', data)

  public deletePreset = (id: number | string) =>
    this.channel?.push<void>('remove_preset', { id })
}
