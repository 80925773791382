import { inject, injectable } from 'inversify'
import { action, makeObservable, observable } from 'mobx'
import type {
  CircularMenuEntity,
  CircularMenuItemId,
  CircularMenuItemsType,
} from '../../../types/CircularMenu'
import type { EdgeType } from '../../../types/edgeEntitiesData/EdgeData'
import type { IDeleteEntityController } from '../../DeleteEntityController'
import type { IPaletteController } from '../../PaletteController'
import type { IProbeState } from '../../ProbeState'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'

@injectable()
export class CircularMenuEdges implements CircularMenuEntity {
  private renderMenuIdsByEdgeType = {} as Partial<
    Record<EdgeType, CircularMenuItemId[]>
  >
  @observable public renderMenuIds: CircularMenuItemId[] = []
  @inject(DI_PROBE_TYPES.DeleteEntityController)
  private deleteEntityController: IDeleteEntityController
  @inject(DI_PROBE_TYPES.ProbeState)
  private probeState: IProbeState
  @inject(DI_PROBE_TYPES.PaletteController)
  private paletteController: IPaletteController

  constructor() {
    makeObservable(this)

    this.renderMenuIdsByEdgeType = {
      flow: ['pickerColor', 'delete'],
      cross_chain_swap_flow: ['pickerColor', 'delete'],
      utxo_transaction: ['pickerColor', 'delete'],
      evm_transaction: ['pickerColor', 'delete'],
    }
  }

  private getRenderMenuIdsByType = (key: string) => {
    if (!this.probeState.edges.has(key)) return []

    const menuIdsByEdgeType =
      this.renderMenuIdsByEdgeType[this.probeState.edges.get(key).data.edgeType]

    if (menuIdsByEdgeType) {
      return menuIdsByEdgeType
    } else {
      return []
    }
  }

  private getHideMenuIds = (ids: CircularMenuItemId[]) => {
    if (this.paletteController.hidePalette) {
      return ids.filter((id) => id !== 'pickerColor')
    }

    return ids
  }

  @action
  public init = (key: string) => {
    const renderMenuIds = this.getHideMenuIds(this.getRenderMenuIdsByType(key))
    this.renderMenuIds = renderMenuIds

    return renderMenuIds
  }

  public proxyMenuItem = (
    id: CircularMenuItemId,
    item: CircularMenuItemsType
  ) => {
    const menuItems = {} as Record<
      CircularMenuItemId,
      Partial<CircularMenuItemsType>
    >

    if (!menuItems[id]) return item

    return { ...item, ...menuItems[id] } as CircularMenuItemsType
  }

  public onMenuClick = (item: CircularMenuItemsType) => {
    if (item.id === 'delete') {
      this.deleteEntityController.deleteActiveEdge()
    } else if (item.id === 'color') {
      this.paletteController.paintActiveEntities(item.value)
    } else if (item.id === 'restorePickerColor') {
      this.paletteController.restoreColorActiveEntities()
    }
  }
}
