import { ClusterTransactions } from '../types/converted/ClusterTransactions'
import { RawClusterTransactions } from '../types/raw/RawClusterTransactions'
import { convertClusterTransaction } from './convertClusterTransaction'
import { convertPaginated } from './convertPaginated'

export const convertClusterTransactions = (
  rawClusterTransactions: RawClusterTransactions
): ClusterTransactions => {
  return {
    data: convertPaginated(
      {
        transactions: rawClusterTransactions.data.map(
          convertClusterTransaction
        ),
        page_number: rawClusterTransactions.pagination.page_number,
        page_size: rawClusterTransactions.pagination.page_size,
        total_entries: rawClusterTransactions.pagination.total_entries,
        total_pages: rawClusterTransactions.pagination.total_pages,
      },
      'transactions'
    ),
  }
}
