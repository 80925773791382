export const DI_ACTIVE_ENTITY_TYPES = {
  ActiveEntityEvents: Symbol.for('ActiveEntityEvents'),
  AutoSelectTransaction: Symbol.for('AutoSelectTransaction'),
  TransactionsFetchStateFacade: Symbol.for(
    'TransactionsFetchStateActiveEntity'
  ),
  CounterpartiesFetchStateFacade: Symbol.for(
    'CounterpartiesFetchStateActiveEntity'
  ),
  AddressesFetchStateFacade: Symbol.for('AddressesFetchStateActiveEntity'),
  OsintsFetchStateFacade: Symbol.for('OsintsFetchStateActiveEntity'),
  TokensFetchStateFacade: Symbol.for('TokensFetchStateActiveEntity'),
  TokenByAddressFetchStateFacade: Symbol.for(
    'TokenByAddressFetchStateActiveEntity'
  ),
  ActiveEntityViewModel: Symbol.for('ActiveEntityViewModel'),
}
