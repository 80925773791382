import { action, computed, makeObservable } from 'mobx'
import { pick } from 'ramda'
import { TransactionAddressEvmTableItem } from '../../../../EntitiesTable'
import { TransactionEdgeEvmType } from '../../../types/edgeEntitiesData/TransactionEdgeData'
import { mergeDefaultToken } from '../../../utils/convertTokenBalances'
import { createFlowId, evmTransactionFlowId } from '../../../utils/createFlowId'
import type { IPaletteController } from '../../PaletteController'
import { FlowId } from '../../ProbeEvents'
import { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import { activeEntityTransactionEvmState } from '../ActiveEntityState'
import { injectable, inject } from 'inversify'
import { TokensActiveEntityFetchState } from '../ActiveEntityFetch'
import { DI_PROBE_TYPES } from '@platform/components/ProbeSandbox/di/DITypes'
import { Token } from '@clain/api/platform/types'

@injectable()
export class TransactionEvmActiveEntity {
  constructor(
    @inject(DI_PROBE_TYPES.ActiveEntityEvents)
    private activeEntityEvents: IActiveEntityEvents,
    @inject(DI_PROBE_TYPES.PaletteController)
    private paletteController: IPaletteController,
    @inject(DI_PROBE_TYPES.TokensFetchStateFacade)
    private tokensState: TokensActiveEntityFetchState
  ) {
    makeObservable(this)
  }

  @computed
  public get tokensBalance() {
    return this.tokensState
  }
  @computed
  public get data() {
    const normalizeTransfers =
      activeEntityTransactionEvmState.state.transfers?.map((transfer) => {
        return {
          ...transfer,
          token: mergeDefaultToken(
            activeEntityTransactionEvmState.state.currency,
            transfer?.token
          ) as unknown as Token,
        }
      }) || []

    const normalizeTokenTransfers =
      activeEntityTransactionEvmState.state.tokens.map((token) => {
        return {
          ...token,
          token: mergeDefaultToken(
            activeEntityTransactionEvmState.state.currency,
            token?.token
          ) as unknown as Token,
        }
      })

    const normalizeInternals =
      activeEntityTransactionEvmState.state.internals.map((internal) => {
        return {
          ...internal,
          token: mergeDefaultToken(
            activeEntityTransactionEvmState.state.currency,
            {}
          ) as unknown as Token,
        }
      })

    return {
      ...activeEntityTransactionEvmState.state,
      tokens: normalizeTokenTransfers,
      transfers: normalizeTransfers,
      internals: normalizeInternals,
    }
  }

  @action
  public clear() {
    activeEntityTransactionEvmState.resetState()
  }

  public update = (
    ...args: Parameters<typeof activeEntityTransactionEvmState.setState>
  ) => {
    activeEntityTransactionEvmState.setState(...args)
  }

  @action.bound
  public toggleAllAddresses = (
    items: Array<TransactionAddressEvmTableItem>,
    select: boolean,
    type: TransactionEdgeEvmType,
    id?: FlowId
  ) => {
    const normalizeItems = items.map((item) => ({
      id: this.data.id,
      hash: this.data.hash,
      currency: this.data.currency,
      type: type,
      ...pick(['index'], item),
      sender: item.sender,
      receiver: item.receiver,
    }))

    this.activeEntityEvents.emit(
      'evmTransactionByType',
      normalizeItems,
      select,
      id ? id : createFlowId([type, 'all'])
    )
  }

  @action.bound
  public toggleAddresses = (
    item: TransactionAddressEvmTableItem,
    select: boolean,
    type: TransactionEdgeEvmType
  ) => {
    this.toggleAllAddresses(
      [item],
      select,
      type,
      evmTransactionFlowId({ hash: this.data.hash, type, index: item?.index })
    )
  }

  public paintActiveEntities = this.paletteController.paintActiveEntities
  public restoreColorActiveEntities =
    this.paletteController.restoreColorActiveEntities

  @computed
  public get selectedColor() {
    return this.paletteController.selectedColor
  }
}
