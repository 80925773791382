import { action, makeObservable, observable } from 'mobx'

import { ActiveEntityType } from '../ActiveEntityViewModel.types'
import { CoinType } from '../../../../../types/coin'
import { TokensBalance } from '@clain/api/platform/types'
import { TransactionsFlagsChartViewModel } from '../../../../../modules/analytics/TransactionsFlags/TransactionsFlagsChartViewModel'
import { BreakdownDataViewModel } from '../../../../../modules/analytics/Breakdown/BreakdownDataViewModel'
import { NetflowChartViewModel } from '../../../../../modules/analytics/Netflow/NetflowChartViewModel'
import { Filters } from '../../../../../modules'
import { isUTXO } from '@clain/core/types/coin'
import { DEFAULT_USD_TOKEN } from '../../../utils/convertTokenBalances'

export class ActiveEntityAnalyticsViewModel {
  @observable public isShowTransactionsByFlags = false
  public transactionsFlagsChartViewModel = new TransactionsFlagsChartViewModel()
  public breakdownDataViewModel = new BreakdownDataViewModel()
  public netflowChartViewModel = new NetflowChartViewModel()

  constructor() {
    makeObservable(this)
  }

  @action
  public initAnalytics = ({
    entityId,
    entityType,
    blockchain,
    timezone,
    isInitTransactionsByFlags,
    tokensBalance,
    initialFilters,
  }: {
    entityId: number
    entityType: Omit<ActiveEntityType, 'cluster' | 'address'>
    blockchain: CoinType
    timezone: string
    isInitTransactionsByFlags: boolean
    tokensBalance: TokensBalance
    initialFilters: Filters
  }) => {
    const tokens = tokensBalance?.map((data) => data.token)
    this.clear()
    this.isShowTransactionsByFlags = isInitTransactionsByFlags
    this.breakdownDataViewModel.init(entityId, entityType, blockchain)
    this.netflowChartViewModel.init(
      entityId,
      entityType,
      blockchain,
      timezone,
      isUTXO(blockchain)
        ? tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
        : tokens,
      initialFilters
    )
    if (isInitTransactionsByFlags) {
      this.transactionsFlagsChartViewModel.init(
        entityId,
        blockchain,
        timezone,
        {
          tbfCalendar: initialFilters.period,
          tbfGroupBy: initialFilters.tbfGroupBy,
        }
      )
    }

    this.loadAnalytics({ isInitTransactionsByFlags })
  }

  @action
  private loadAnalytics = ({
    isInitTransactionsByFlags,
  }: {
    isInitTransactionsByFlags: boolean
  }) => {
    this.breakdownDataViewModel.loadData()
    this.netflowChartViewModel.fetchData()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isInitTransactionsByFlags
      ? this.transactionsFlagsChartViewModel.fetchData()
      : null
  }

  @action
  public clear = () => {
    this.transactionsFlagsChartViewModel.clear()
    this.netflowChartViewModel.clear()
    this.breakdownDataViewModel.clear()
  }
}
